import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orwiArrayFilter',
  pure: true
})
export class OrwiArrayFilter<T = any> implements PipeTransform {
  transform(value: T[] = [], key: string, search: number | string | string[]): T[] {
    if (!Array.isArray(value)) {
      return [];
    }

    if(typeof search == 'number') {
      return value.filter(elm => elm[key] == search);
    }
    if (search === null || search === undefined || search.length == 0) {
      return value;
    }
    // console.log('search:', search);
    // console.log('key:', key);

   

    if (typeof search == 'string') {
      return value.filter((el) => {
        // console.log('el:', el);
      
        return (el[key] as string)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      });
    } else {
      return search.length > 0
        ? value.filter((el) => search.includes(el[key]))
        : value;
    }
  }
}
