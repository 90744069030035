import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { SessionQuery } from '../../session/state/session.query';
import { FoodAppAccount, FoodAppModifier, FoodAppProduct } from '../state/dto';
import { FoodAppsIntegrationStore } from '../state/food-apps-integration.store';

@Injectable({
  providedIn: 'root'
})
export class FoodAppsSyncService {
  foodAppAccount: FoodAppAccount[] = []
  constructor(
    private publicApi: PublicApiService,
    private integrationStore: FoodAppsIntegrationStore,
    private sessionQuery: SessionQuery,
    private glb: GlobalService
  ) {
  }

  getFoodAppProducts(cloudId: string, accountId: string, source: string): Promise<FoodAppProduct[]> {
    return new Promise((resolve, reject) => {
      try {
        this.publicApi.getCollection({
          _db: cloudId,
          col: "food-apps-products",
          _filter: { accountId: accountId, source: source }
        }).toPromise().then((o: FoodAppProduct[]) => {

          for (const iterator of o) {
            iterator.changed = false
            iterator.auto = false
          }
          resolve(o)
        })

      } catch (err: any) {
        reject(err)
      }
    })
  }


  getFoodAppModifier(cloudId: string, accountId: string, source: string): Promise<FoodAppModifier[]> {

    return new Promise((resolve, reject) => {
      try {
        this.publicApi.getCollection({
          _db: cloudId,
          col: "food-apps-modifiers",
          _filter: { accountId: accountId, source: source }
        }).toPromise().then((o: FoodAppModifier[]) => {

          for (const iterator of o) {
            iterator.changed = false
            iterator.auto = false
          }
          resolve(o)
        })
      } catch (err: any) {
        reject(err)
      }
    })
  }


  async syncProducts(foodAppProducts: FoodAppProduct[], cloudId: string) {
    this.glb.showLoading()
    for (const iterator of foodAppProducts.filter(o => o.changed == true)) {

      await this.publicApi.upsertFoodAppProduct({
        _db: cloudId,
        col: 'food-apps-products',
        data: iterator
      })

      iterator.changed = false
    }
    this.glb.closeLoading()
  }

  async syncModifiers(foodAppModifiers: FoodAppModifier[], cloudId: string) {
    this.glb.showLoading()
    for (const iterator of foodAppModifiers.filter(o => o.changed == true)) {
      await this.publicApi.upsertFoodAppModifiers({
        _db: cloudId,
        col: 'food-apps-modifiers',
        data: iterator
      })
      iterator.changed = false
    }
    this.glb.closeLoading()
  }

  getFoodAppIntegrations(storeId: string[]) {
    this.publicApi.getIntgrations({
      _db: "food-apps-service",
      col: "accounts",
      _filter: { storeId: { $in: storeId }, foodApp: this.integrationStore.getValue().activeAccount.foodApp, cloudId: this.sessionQuery.activeLicense.orwiStore.cloudId, brand: this.integrationStore.getValue().activeAccount.brand }
    }).subscribe((p: FoodAppAccount[]) => {
      this.foodAppAccount = p
    })
  }
}
