import { Injectable } from '@angular/core';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { MenuService } from 'src/app/modules/menu/state/menu.service';
import {
  OrwiStore,
  PaymentType,
  StoreCover,
} from 'src/app/services/dto/orwi-store';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import { TableService } from 'src/app/modules/tables/state/table.service';
import { OrwiStoreStore } from './store.store';
import { arrayUpdate, resetStores } from '@datorama/akita';
import { DiscontService } from 'src/app/services/folio/discont.service';
import {
  Reasons,
  Discount,
  EcrDepartment,
} from 'src/app/services/dto/orwi-definitions';
import { OfflineServiceService } from 'src/app/services/offline-service.service';
import { PrinterPrepareService } from 'src/app/services/printer/printer.service';
import { SessionQuery } from '../../session/state/session.query';
import { AppUsersService } from '../../pos-users/state/pos-users.service';
import { ParametersService } from '../../parameters/state/parameters.service';
import { PaymentStore } from '../../pos/pos-payment/state/payment.store';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppsIntegrationService } from '../../food-apps-integration/state/food-apps-integration.service';
import { BrandsService } from '../../brands/state/brands.service';

@Injectable({ providedIn: 'root' })
export class OrwiStoreService {
  isInitialized = false;

  constructor(
    private appUserService: AppUsersService,
    private orwiService: OrwiService,
    private sessionQuery: SessionQuery,
    private orwiStoreStore: OrwiStoreStore,
    private appService: AppService,
    private tableService: TableService,
    private folioService: FolioService,
    private menuService: MenuService,
    private paymentStore: PaymentStore,
    private discontService: DiscontService,
    private printerPrepareService: PrinterPrepareService,
    private offlineService: OfflineServiceService,
    // private integrationService: IntegrationService,
    private paremetersService: ParametersService,
    private foodAppIntegretion : FoodAppsIntegrationService,
    // private ecrSettingService: EcrSettingService,
    // private ingenicoWinService: IngenicoWinService,
    private brandService :BrandsService,
    private globalService: GlobalService,
  ) { }

  ecrDepartments: EcrDepartment[] = [];
  async initialize(storeId: string) : Promise<OrwiStore> {
    return new Promise(async (resolve) => {
      this.globalService.showLoading()
      console.log('storeInitialize', "isInitialized:" + this.isInitialized, "storeId" + storeId)

      if (!storeId) return undefined;
      resetStores({
        exclude: ['orwi-auth', 'orwi-Store'],
      });


      if (this.appService.connection == 'offline') {
        this.orwiStoreStore.update({ id: storeId });
        localStorage.setItem('activeStore', storeId);
        this.offlineService.load();
        await this.folioService.initialize();
        this.isInitialized = true;
        return undefined
      } else {

        this.orwiStoreStore.update({ id: storeId });
        localStorage.setItem('activeStore', storeId);

        let storeInfo = await this._getStoreInfo(storeId);
        await this.paremetersService.getParameters()
        await this.fetchPaymentTypes();
        await this.printerPrepareService.getPrinters();
        await this.tableService.initialize();
        await this.menuService.initialize();
        await this.folioService.initialize();
        await this.getStoreDepartments(storeId);
        await this.getReasons();
        await this.getDiscontReasons();
        // await this.getStaffList();
        await this.brandService.getBrands()
        await this.foodAppIntegretion.getFoodAppIntegrations();
        await this.appUserService.getUsers();

        this.isInitialized = true;
        this.orwiStoreStore.update({ storeInitialized: true });
        // const setting = await this.ecrSettingService.getEcrSetting();
        // this.ingenicoWinService.terminalIP = setting.terminalIp;
        // console.log('ECR SETTING', setting);
        this.globalService.overRideClosing = true;
        this.offlineService.initializeOfflineSave();
        this.globalService.closeAllLoading();
        resolve(storeInfo);
      }
    })




  }
  async getDiscontReasons() {
    const reasons: Discount[] = await this.discontService.getDiscountReasons();
    this.paymentStore.update({ predefinedDiscounts: reasons });
  }

  _getStoreInfo(storeId: string): Promise<OrwiStore> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/store/storeInfo',
          { id: storeId },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            this.orwiStoreStore.update({ store: o.response });
            resolve(o.response);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  getStoreDepartments(storeId): Promise<EcrDepartment[]> {
    return new Promise((resolve, reject) => {
      try {
        this.orwiService
          .serviceRequestPromise(
            '/api/pos/ecrDepartments/getEcrDepartments',
            { id: storeId },
            this.sessionQuery.token
          )
          .then((o: any) => {
            this.ecrDepartments = o.response;
            resolve(o.response);
          })
      } catch (err) {
        reject(err)
      }
    });
  }

  fetchPaymentTypes(): Promise<PaymentType[]> {
    console.log('fetchPaymentTypes')
    return new Promise((resolve, reject) => {
      try {
        let storeId = this.orwiStoreStore.getValue().id;
        this.orwiService
          .serviceRequestPromise(
            '/api/pos/payment/getPaymentTypes',
            { id: storeId },
            this.sessionQuery.token
          )
          .then((o: any) => {
            if (o.response) {
              this.orwiStoreStore.update({ payTypes: o.response });
              resolve(o.response);
            }
          });
      } catch (err) {
        reject(err)
      }
    });
  }

  getUserStores(): Promise<StoreCover[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/user/getOwnedStores',
          {},
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              this.orwiStoreStore.update({
                ownedStores: o.response,
              });
              return resolve(Object.assign(o.response));
            }
            if (o.error) {
              return reject(o.error.code);
            }
          },
          (e) => {
            return reject(e.error.code);
          }
        );
    });
  }

  savePaymentType(paymentType: PaymentType) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/payment/savePaymentType',
          {
            ...paymentType,
            storeId: this.orwiStoreStore.getValue().id,
          } as PaymentType,
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            this.orwiStoreStore.update(({ payTypes }) => ({
              payTypes: arrayUpdate(payTypes, o.response.id, o.response),
            }));
            this.fetchPaymentTypes();
            return resolve(o.response);
          }
          return reject(o.error?.code);
        });
    });
  }

  deletePaymentType(paymentType: PaymentType) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/payment/deletePaymentType',
          {
            ...paymentType,
            storeId: this.orwiStoreStore.getValue().id,
          } as PaymentType,
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            this.fetchPaymentTypes();
            return resolve(o.response);
          }
          return reject(o.error?.code);
        });
    });
  }

  async getReasons(): Promise<Reasons[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/reason/getReasons',
          { id: this.sessionQuery.activeLicense.orwiStore.id },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o.response);
            this.orwiStoreStore.update({
              giftReasons: o.response.filter((x) => x.types == 'gift'),
            });
            this.orwiStoreStore.update({
              deleteReasons: o.response.filter((x) => x.types == 'product'),
            });
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  saveReasons(reason: any) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/reason/saveReason',
          {
            ...reason,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          },
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            this.orwiStoreStore.update({
              giftReasons: o.response,
            });
            this.getReasons();
            return resolve(o.response);
          }
          return reject(o.error?.code);
        });
    });
  }

  deleteReason(reason) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/reason/deleteReason',
          {
            ...reason,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          },
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            this.orwiStoreStore.update({
              giftReasons: o.response,
            });
            this.getReasons();
            return resolve(o.response);
          }
          return reject(o.error?.code);
        });
    });
  }
}
