import { Component, OnInit } from '@angular/core';
import { Device } from '@capacitor/device';
import { IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { GeoTrackingService } from 'src/app/modules/geo-tracking/state/geo-tracking.service';
import { OrwiStoreService } from 'src/app/modules/store/state/store.service';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SocketService } from 'src/app/services/helpers/socket.service';
import { SoketPushDeeplinkRoutingService } from 'src/app/services/helpers/soket-push-deeplink-routing.service';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import { SessionQuery } from '../../state/session.query';
import { SSOSessionService } from '../../state/sso-session.service';
import { PushService } from 'src/app/services/helpers/push.service';
import { EcrService } from 'src/app/services/ecr/ecr.service';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {
  versionError = false;
  constructor(
    private geoTranckingService: GeoTrackingService,
    private ssoService: SSOSessionService,
    private sessionQuery: SessionQuery,
    private orwiService: OrwiService,
    private orwiStoreService: OrwiStoreService,
    private routerOutlet: IonRouterOutlet,
    private modalService: ModalService,
    private socketService: SocketService,
    private orwiSocketService: SoketPushDeeplinkRoutingService,
    private appService: AppService,
    private transloco: TranslocoService,
    private navController: NavController,
    private plt: Platform,
    private globalService: GlobalService,
    private pushService: PushService,
    private ecrService: EcrService
  ) { }

  ngOnInit() {
    this.pushService.instance();

    this.plt.ready().then((o) => {
      o; //reserved
      this.socketService.socket.connect();
      if (!this.sessionQuery.authStatus) {
        this.globalService.overRideClosing = false;
        this.globalService.showLoading('');
        this.init();
        this.appService.init();
      } else {
        this.globalService.overRideClosing = false;
        this.globalService.showLoading('');
        this.initStore();
      }
    });
  }

  async init() {
    await this.setUserLanguage();
    // let device = await Device.getInfo()
    // let did = await Device.getId()
    // let bat = await Device.getBatteryInfo()
    // let lc = await Device.getLanguageCode()
    // let lt = await Device.getLanguageTag()
    // console.group()
    // console.log("Device", device)
    // console.log("Devicd Id", did.identifier)
    // console.log("Battery", bat)
    // console.log("Language Code", lc)
    // console.log("Language Tag", lt)
    // console.groupEnd()

    this.ssoService.autoLoginWithToken(this.ssoService.getAccessToken()).then(
      async (o) => {
        if (o) {
          this.geoTranckingService.init();
          this.orwiSocketService.initEvents(this.sessionQuery.token);
          this.modalService.routerOutlet = this.globalService.routerOutlet = this.routerOutlet.nativeEl;
          console.log('autoLoginWithToken')
          this.initStore()
          if (!document.getElementById('pin-lock-modal')) {
            const lockModal = await this.globalService.openLockModal();
            if (lockModal) {
              lockModal.onDidDismiss().then(async (res) => {
                res; //reserved
                // await  this.initStore();
              });
            } else {
              // await this.initStore();
            }
            return;
          }
        } else {
          this.globalService.overRideClosing = true;
          this.globalService.closeAllLoading();
          this.navController.navigateRoot('otp-request');
        }
      },
      () => {
        this.globalService.overRideClosing = true;
        this.globalService.closeAllLoading();
        this.navController.navigateRoot('otp-request');
      }
    );
  }

  login() {
    this.navController.navigateForward('login-home');
  }

  requestOtp() {
    this.navController.navigateForward('otp-request');
  }

  async setUserLanguage() {
    let dlang;
    try {
      dlang = await Device.getLanguageCode();
    } catch (error) {
      dlang = {
        value: 'tr',
      };
    } finally {
      dlang = {
        value: 'tr',
      };
    }

    this.ssoService.userLanguage = dlang.value.substr(0, 2);
    this.ssoService.userRegion = dlang.value.substr(3, 2);
    if (this.ssoService.userRegion == '') {
      this.ssoService.userRegion = this.ssoService.userLanguage.toLocaleUpperCase();
    }
    this.globalService.consolelog(
      'user-language',
      this.ssoService.userLanguage,
      dlang
    );
    this.transloco.setDefaultLang(this.ssoService.userLanguage);
    this.orwiService.userLanguage = this.ssoService.userLanguage;
    this.orwiService.userRegion = this.ssoService.userRegion;
  }

  async initStore() {
    this.orwiStoreService.initialize(
      this.sessionQuery.activeLicense?.orwiStore.id
    ).then(async result=> {
      result
      await this.ecrService.getEcrSettingsAndInit().then(x => {
        x
        this.ecrService.connectEcr()
      })
    })



    // while (!this.orwiStoreStore.getValue().storeInitialized) {
    //  try {
    //   await this.ecrService.getEcrSettingsAndInit().then(x => {
    //     x
    //     this.ecrService.connectEcr()
    //   })
    //  } catch (error) {
    //   console.log('ecr.service',error)
    //  }
    // }
    // setTimeout(async () => {
    //   await this.ecrService.getEcrSettingsAndInit().then(x => {
    //     x
    //     this.ecrService.connectEcr()
    //   })
    // });
    this.sessionQuery.navigateByUser();

  }
}
