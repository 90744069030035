import { Component, Input, OnInit } from '@angular/core';
import { arrayUpsert } from '@datorama/akita';
import { ActionSheetButton, ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { DeliveryStatus, Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { FolioQuery } from '../../../folio/state/folio.query';
import { FolioService } from '../../../folio/state/folio.service';
import { FolioStore } from '../../../folio/state/folio.store';
import { FoodAppsIntegrationService } from '../../../food-apps-integration/state/food-apps-integration.service';

@Component({
  selector: 'delivery-status',
  templateUrl: './delivery-status.component.html',
  styleUrls: ['./delivery-status.component.css'],
})
export class DeliveryStatusComponent implements OnInit {
  @Input() folio: Folio;
  constructor(
    private fs: FolioStore,
    public folioQuery: FolioQuery,
    private folioService: FolioService,
    private foodAppService: FoodAppsIntegrationService,
    private transloco: TranslocoService,
    private glb: GlobalService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    // console.log("delivery folio", this.folio)
    // this.openSheetForMobile()
  }

  updateOrderStatus() {}

  async openSheetForMobile() {
    const actionSheet = await this.glb.actionSheetController.create({
      header: this.transloco.translate('Delivery Status'),
      // subHeader: 'Gerekirse Kullanılacak.',
      buttons: [
        ...this.statusTypes.map(
          (el) =>
            ({
              data: el,
              id: el,
              text: this.transloco.translate(el),
              cssClass:
                this.folio.deliveryStatus == el ? 'active-sheet-item' : '',
              // icon: 'checkmark-outline'
            } as ActionSheetButton)
        ),
        {
          text: this.transloco.translate('Cancel'),
          role: 'cancel',
        },
      ],
    });

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();
    console.log(result, this.folio.deliveryStatus);
    if (result.data && result.data !== this.folio.deliveryStatus)
      this.changeDeliveryStatus(result.data);
  }

  async changeDeliveryStatus(status: DeliveryStatus) {
    await this.glb.openAlert({
      header: this.transloco.translate('Delivery Status'),
      message: `Siparişin durumunu <b>${this.transloco.translate(
        this.folio.deliveryStatus
      )}</b>'dan <b>${this.transloco.translate(
        status
      )}</b>'a güncellemek ister misiniz?`,
      buttons: [
        this.transloco.translate('No'),
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            const value = status; //deliveryStatus.target['value'];
            // popover.dismiss(value);
            this.foodAppService.updateOrderStatus(this.folio.id, value).then(
              (res) => {
                console.log('Response from FoodApp', res);
                this.folio.deliveryStatus = value;

                if (this.folio.deliveryStatus == 'cancelled') {
                  this.folioService._posCloseFolio(this.folio);
                } else {
                  this.fs.update(({ openFolios }) => ({
                    openFolios: arrayUpsert(
                      openFolios,
                      this.folio.id,
                      this.folio
                    ),
                  }));
                }
                this.modalController.dismiss();
                this.glb.toast('', 'İşlem Başarılı', 'middle', 'success');
              },
              (err) => {
                this.modalController.dismiss();
                this.glb.toast('An error occured!', err, 'middle', 'danger');
              }
            );
          },
        },
      ],
    });
  }

  getDeliveryStatus() {
    if (this.folio.deliveryStatus == 'new') {
      return '/assets/order-status/order-new.gif';
    } else if (this.folio.deliveryStatus == 'accepted') {
      return '/assets/order-status/order-accept.gif';
    } else if (this.folio.deliveryStatus == 'prepared') {
      return '/assets/order-status/order-prepering.gif';
    } else if (this.folio.deliveryStatus == 'routing') {
      return '/assets/order-status/order-routing.gif';
    } else if (this.folio.deliveryStatus == 'completed') {
      return '/assets/order-status/order-complated.gif';
    } else if (this.folio.deliveryStatus == 'cancelled') {
      return '/assets/order-status/order-cancel.gif';
    } else {
      return '/assets/order-status/order-cancel.gif';
    }
  }

  statusTypes: any[] = [
    'cancelled',
    'accepted',
    'prepered',
    'routing',
    'completed',
  ];
}
