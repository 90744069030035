import { Pipe, PipeTransform } from '@angular/core';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';

@Pipe({
  name: 'countOfSelectedRows',
  pure: false,
})
export class CountOfSelectedRowsPipe implements PipeTransform {
  transform(value: Folio): FolioRow[] {
    // TODO: Eğer aynı orderStatus durumunda ise geriye bi array dönecek değilse boş array dönecek.
    const selectedRows = value.rows?.filter((el) => el.customChecked);
    return selectedRows;
    let groupArray = [];
    selectedRows.forEach((row) => {
      let foundedGroup = groupArray.find((el) => el.id == row.customStatus);
      if (foundedGroup) {
        foundedGroup.checked += 1;
      } else {
        groupArray.push({
          id: row.customStatus,
          checked: 1,
        });
      }
    });

    if (groupArray.length > 1) {
      return [];
    } else {
      return selectedRows;
    }
  }
}
