import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DeliveryState, DeliveryStore } from './delivery.store';

@Injectable({ providedIn: 'root' })
export class DeliveryQuery extends Query<DeliveryState> {

    deliveryFormVisible$ = this.select("deliveryFormVisible")


    constructor(protected store: DeliveryStore) {
        super(store);
    }


}

