import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ListModalBase } from '../list-modal-generic/list-modal-generic';
@Component({
  selector: 'orwi-list-modal',
  templateUrl: './orwi-list-modal.component.html',
  styleUrls: ['./orwi-list-modal.component.scss'],
})
export class OrwiListModalComponent
  extends ListModalBase<any>
  implements OnInit
{
  selectedItemType: 'radio' | 'list';
  
  closing(item: any) {
    this.globalService.closeModal(item);
  }
  constructor(protected globalService: GlobalService) {
    super();
  }

  

  ngOnInit() {}
}
