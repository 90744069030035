import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Folio } from 'src/app/services/dto/orwi-folio';

@Component({
  selector: 'app-folio-info',
  templateUrl: './folio-info.component.html',
  styleUrls: ['./folio-info.component.scss'],
})
export class FolioInfoComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}
  data: Folio;
  tableName: string;

  close() {
    this.modalCtrl.dismiss();
  }

}
