import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Folio } from 'src/app/services/dto/orwi-folio';

export interface ReportsState {
   key: string;
   folioListData : Folio[],
   cancelledFolioList : Folio[],
   folioListDataTotal: number,
   folioListActiveFolio: Folio,

}

export function createInitialState(): ReportsState {
  return {
    key: '',
    folioListData: [],
    cancelledFolioList : [],
    folioListDataTotal: 0,
    folioListActiveFolio: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'reports' })
export class ReportsStore extends Store<ReportsState> {

  constructor() {
    super(createInitialState());
  }

}
