export class OrwiAddress {
  id = '';
  name = '';
  phone = '';
  cityId = '';
  city = '';
  townId = '';
  town = '';
  districtId = '';
  district = '';
  address = '';
  direction = '';
  country = '';
  countryId = '';
  userId = '';
  storeId = '';
  deleted = false;
  geoLocation: oaLlocation = new oaLlocation();
  type: 'address' | 'location' = 'address';
  building = '';
  flooar = '';
  apartment = '';
}

export class oaLlocation {
  approve: boolean = true;
  latitude: number = 0;
  longitude: number = 0;
}

export class rwiCountry {
  code = '';
  name = '';
  flagEmoji = '';
  dialCode = '';
}
export class OrwiCountry {
  code = '';
  name = '';
  flagEmoji = '';
  dialCode = '';
}

export class OrwiCity {
  countryCode = '';
  code = '';
  name = '';
}

export class OrwiTown {
  countryCode = '';
  cityCode = '';
  code = '';
  name = '';
}

export class poligon {
  minAmount: number = 0;
  name: string = '';
  polyline: poly[] = [];
}
export class poly {
  lat;
  lng;
}
