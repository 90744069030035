<div>
    <div class="table-btn open ripple-parent ion-activatable" *ngIf="folio.status === 'open'" (click)="select()" #card
        (contextmenu)="openContextMenu(contextTemplate)">
        <div class="info-cover">
            <div class="table-btn-timer">
                <ion-icon name="timer"></ion-icon>
                <div style="margin-left: 5px; font-size: 12px">
                    {{ folio?.creation | orwiTime: "D" }}
                </div>
            </div>




            <div (click)="info($event, folio)" class="table-btn-info">
                <ion-label style="font-size: 12px;color:#9de2f3"> #{{folio.sequenceNo}}</ion-label>
                <!-- <ion-icon color="secondary" name="information-circle-outline"></ion-icon> -->
            </div>



        </div>

        <div class="pax-cover" *ngIf="folio.type !== 'delivery'">
            <ion-icon name="people"></ion-icon>
            <div class="pax">{{ folio.pax }}</div>
        </div>
        <div class="table-btn-timer" *ngIf="folio.eftPos">
            <ion-icon *ngIf="paymentDone()" style="color:#0df211" name="cash-outline"></ion-icon>
            <ion-icon style="color:#9de2f3" name="calculator-outline"></ion-icon>
        </div>
        <div class="table-btn-timer" *ngIf="folio.isOrwiPay">
            <img src="assets/icons/orwi.png" width="30">
        </div>
        <div style="margin: auto" *ngIf="folio.deliveryStatus === 'cancelled'">
            <small>
                <ion-text color="danger">{{ "Cancelled" | transloco }} </ion-text>
            </small>
        </div>
        <div style="position: relative;    display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: space-between;">
            <img src="assets/images/delivery/yemeksepeti.ico" style="border-radius: 50px"
                *ngIf="folio.appName === 'yemek-sepeti'" width="20" />
            <img src="assets/images/delivery/fuudy.png" style="border-radius: 50px" *ngIf="folio.appName === 'fuudy'"
                width="20" />
            <img src="assets/images/delivery/getir.png" style="border-radius: 50px" *ngIf="folio.appName === 'getir'"
                width="20" />
            <img src="assets/images/delivery/migros-yemek.jpg" style="border-radius: 50px"
                *ngIf="folio.appName === 'migros-yemek'" width="20" />
            <img src="assets/images/delivery/trendyol.png" style="border-radius: 50px"
                *ngIf="folio.appName === 'trendyol'" width="20" />
            <ion-icon *ngIf="folio.isTimed" style="color: #ffffff; font-size: 20px" name="alarm-outline"></ion-icon>
            <div class="name">
                {{ folio.table.name ? folio.table.name : "No name" }}
            </div>      
            <div class="name" style="text-align: right;">
                {{folio.grandTotal | orwiCurrency}}
            </div>    
        </div>

    </div>

    <div class="custom-context" #contextTemplate>
        <div class="" style="position: absolute">
            <div class="circle-overflow">
                <div class="circle-one"></div>
            </div>
        </div>
        <div class="context-area">
            <div class="fab-item {{ item }}" *ngFor="let item of statusTypes" (click)="onClickDeliveryIcon(item)"
                [title]="item | transloco | titlecase">
                <ion-icon slot="icon-only" icon="/assets/icons/{{ item }}.svg"></ion-icon>
            </div>
        </div>
    </div>

</div>