<div style="width: 100%">

    <div class="table-cover">
        <div class="table-btn open" (click)="newTransaction()">
            {{ "New Transaction" | transloco }}
        </div>
        <orwi-open-folio-button *ngFor="
        let item of tq.openFolios$ | async | orwiArrayFilter: 'type':'delivery'" [folio]="item" (onClickInfo)="onClickInfo($event, item.table.name)">
        </orwi-open-folio-button>
    </div>

</div>