import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TableGroup } from 'src/app/services/dto/tables';
import { GlobalService } from 'src/app/services/global.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { TableQuery } from '../../state/table.query';
import { TableService } from '../../state/table.service';
import { TableStore } from '../../state/table.store';

@Component({
  selector: 'orwi-table-group-container',
  templateUrl: './table-group-container.component.html',
  styleUrls: ['./table-group-container.component.scss'],
})
export class TableGroupContainerComponent implements OnInit, OnDestroy {
  mode: string = '';
  selectedPosMenuSubscription: Subscription;

  constructor(
    public tq: TableQuery,
    public glb: GlobalService,
    private ts: TableService,
    private sideMenuQuery: SideMenuQuery,
    private tableStore: TableStore) {
    this.selectedPosMenuSubscription =
      this.sideMenuQuery.posMenuSelected$.subscribe(
        (value) => (this.mode = value.id)
      );
  }
  ngOnDestroy(): void {
    if (
      this.selectedPosMenuSubscription != null ||
      this.selectedPosMenuSubscription != undefined
    ) {
      this.selectedPosMenuSubscription.unsubscribe();
    }
  }

  ngOnInit() { }

  itemSelected(tableGroup: TableGroup) {
    this.ts.selectTableGroup(tableGroup.id);
  }
  // async addTable() {
  //   this.modalService.showNewTableModal()
  // }


  checkChange(item: TableGroup): boolean {
    if (!this.tableStore.getValue().tables.some((o) => o.selected)) {
      return this.mode === 'change' && +item.info?.open <= 0;
    } else {
      return this.mode === 'change' && +item.info.tables - +item.info?.open <= 0;
    }
  }
}
