<!--FORM-->

<div class="modalContainer" *ngIf="this.content == 'form'">
  <ion-grid style="width: 100%" *transloco="let t">
    <ion-row [formGroup]="departmentForm" class="cover_container">
      <ion-card-title style="padding-left: 50px">{{
        "Department Definition" | transloco
      }}</ion-card-title>
      <div class="inputDepartment">
        <ion-item class="input" lines="none">
          <ion-label position="floating">
            {{ t("department name") }}
          </ion-label>
          <ion-input formControlName="name"></ion-input>
        </ion-item>
      </div>

      <div class="inputDepartment">
        <ion-item lines="none">
          <ion-label position="floating">{{ t("rate") }} </ion-label>
          <ng-select
            style="height: 48px; width: 100%"
            [items]="taxList"
            bindValue="rate"
            bindLabel="rate"
            formControlName="rate"
            appendTo="body"
          ></ng-select>
        </ion-item>
      </div>

      <div class="inputDepartment">
        <ion-item lines="none">
          <ion-label position="floating"> {{ t("sequence") }} </ion-label>
          <ion-input
            [disabled]="true"
            type="number"
            [value]="departmentForm.value.sequence * 1"
          ></ion-input>
        </ion-item>
      </div>

      <footer>
        <ion-buttons style="width: 100%">
          <ion-button
            size="small"
            (click)="this.content = 'list'"
            class="header_giveUp"
          >
            {{ "GiveUp" | transloco }}
          </ion-button>
          <ion-button
            [disabled]="departmentForm.invalid"
            size="small"
            (click)="save()"
            class="header_complete"
          >
            {{ "Complete" | transloco }}
          </ion-button>
        </ion-buttons>
      </footer>
    </ion-row>
  </ion-grid>
</div>

<!--LIST-->

<ion-row
  style="
    box-shadow: 0px 5px 15px #f0f2fe;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
  "
  *ngIf="this.content == 'list'"
>
  <ion-card-title>{{ "Department Definitions" | transloco }}</ion-card-title>
  <div
    style="
      display: flex;
      flex-direction: column;
      max-height: 40vh;
      overflow: scroll;
    "
  >
    <ion-col size="12" *ngFor="let department of departmentList">
      <ion-card class="ion-no-margin">
        <ion-card-content>
          <ion-row>
            <ion-col class="d-flex">
              <ion-row class="definition-container">
                <ion-col size="4" class="info-col">
                  <div class="bkm-content">
                    <b>{{ department.name }}</b>
                  </div>
                </ion-col>
                <ion-col size="3" class="info-col">
                  <div class="bkm-content">
                    <b>{{ "Sequence" | transloco }} </b>
                    <p>{{ department.sequence }}</p>
                  </div>
                </ion-col>
                <ion-col size="3" class="info-col">
                  <div class="bkm-content" *transloco="let t">
                    <b> {{ "Rate" | transloco }} </b>
                    <p>{{ department.rate }}</p>
                  </div>
                </ion-col>
                <ion-col size="3" class="ion-align-items-center">
                  <ion-buttons>
                    <ion-button
                      class="edit-btn"
                      (click)="editDepartment(department)"
                      >{{ "Edit" | transloco }}</ion-button
                    >
                    <ion-button
                      class="cancel-btn"
                      (click)="deleteDepartment(department.id)"
                      >{{ "Delete" | transloco }}</ion-button
                    >
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </div>
  <div class="add-btn">
    <ion-button (click)="openForm()">{{
      "Add New" | transloco | titlecase
    }}</ion-button>
  </div>
  <!-- <ion-col>
    <ion-card>
      <ion-card-content> -->
  <!-- </ion-card-content>
    </ion-card>
  </ion-col> -->
</ion-row>
