import { Injectable } from '@angular/core';
import { Product, ProductGroup } from 'src/app/services/dto/orwi-product';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { MenuStore, SubGroups } from './menu.store';
import * as localforage from 'localforage'; // this works!!!
import { GlobalService } from 'src/app/services/global.service';
import { SessionQuery } from '../../session/state/session.query';
import { Attributes } from 'src/app/services/dto/orwi-store';
import { MenuQuery } from './menu.query';
import { arrayUpdate } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(
    private orwiStore: OrwiStoreStore,
    private orwiService: OrwiService,
    private appService: AppService,
    private menuStore: MenuStore,
    private glb: GlobalService,
    private sessionQuery: SessionQuery,
    private mq: MenuQuery
  ) { }




  initialize() {
    //  this.fetchGroups();
    this.fetchMenu(this.orwiStore.getValue().id).then(
      (o: any) => {
        this.menuStore.update({
          products: o.products,
          allGroups: o.productGroups,
          groups: o.productGroups.filter((o) => o.parentID === '0'),
          groupSub: o.productGroups.filter((o) => o.parentID !== '0'),
          productModifiers: o.productModifiers,
          groupModifiers: o.productGroupModifiers,
        });
      },

      (err) => {
        console.error(err);
        this.menuStore.reset();
      }
    );
  }

  async selectGroup(group: ProductGroup) {
    debugger
    for await (let elm of this.mq.allGroups) {
      elm.selected = false;
    }

    group.selected = true;
    let groupProducts
    if (group.id === 'most-prefered') {
      groupProducts = this.menuStore
        .getValue()
        .products.filter((p) => p.isMostPreferred === true);
    }
    else {
      groupProducts = this.menuStore
        .getValue()
        .products.filter((p) => p.group == group.id);
    }


    if (group.parentID == '0') {
      this.menuStore.update({
        selectedGroup: group,
        activeProducts: groupProducts,
        subGroups: this.initSubGroups(group),
      });
    } else {
      this.menuStore.update({
        selectedSubGroup: group,
        activeProducts: groupProducts,
        subGroups: this.initSubGroups(group),
      });
    }

    if (group.parentID == '0') {
      this.menuStore.update((ms) => {
        ms.groups
          .filter((grp) => grp.selected && grp.id !== group.id)
          .map((o) => (o.selected = false));
      });
    }

    this.menuStore.update((ms) => {
      ms.groupSub
        .filter((grp) => grp.selected && grp.id !== group.id)
        .map((o) => (o.selected = false));
    });
  }

  serachProduct(name: string): Product[] {
    const filtired: Product[] = this.menuStore
      .getValue()
      .products.filter((p) =>
        p.name.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
    if (name.length > 0) {
      this.menuStore.update({ activeProducts: filtired });
    } else {
      if (this.menuStore.getValue().selectedGroup) {
        this.selectGroup(this.menuStore.getValue().selectedGroup);
      } else {
        this.menuStore.update({ activeProducts: null });
      }
    }

    return filtired;
  }

  initSubGroups(group: ProductGroup) {
    let sg: SubGroups[] = [];
    const subGroups = this.menuStore
      .getValue()
      .groupSub.filter((p) => p.parentID == group.id);
    if (subGroups.length > 0) {
      if (group.parentID == '0') {
        sg.push({ parentId: '0', group: subGroups, name: group.name });
      } else {
        sg = this.menuStore.getValue().subGroups;
        sg.push({
          parentId: group.parentID,
          group: subGroups,
          name: group.name,
        });
      }
    } else {
      return group.parentID !== '0' ? this.menuStore.getValue().subGroups : sg;
    }
    return sg;
  }

  clearSub() {
    let sg: SubGroups[] = [];
    this.menuStore.update({ subGroups: sg });
  }

  async fetchMenu(id, _reload = false) {

    return new Promise(async (resolve, reject) => {
      console.log('fetchMenu')
      if (this.appService.connection == 'offline') {
        let menu: any = await localforage.getItem('menu');
        resolve(menu);
        return;
      }

      this.orwiService
        .serviceRequest(
          '/api/store/storeMenu',
          { id: id },
          this.sessionQuery.token
        )
        .subscribe((o: any) => {
          if (o.response) {
            console.log(o.response)
            let menu: any = Object.assign(o?.response);
            if (menu.products.filter(x => x.isMostPreferred === true).length > 0) {
              menu.productGroups.push({
                'hide': false,
                'id': 'most-prefered',
                'kerzzID': '',
                'menuId': 'default',
                'order': 99,
                'name': 'Favoriler',
                'parentID': '0',
                'soldOut': false
              })
            }
            console.log("asdasd", menu)
            this.saveToLocalStorage(menu);
            return resolve(menu);
          }

          reject(o?.error?.code);
        });
    });
  }

  fetchGroups() {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/getGroups/' + this.orwiStore.getValue().id,
          {},
          this.sessionQuery.token
        )
        .subscribe((o: any) => {
          if (o.response) {
            console.log("/api/menu/getGroups/")
            let menu: any = Object.assign(o?.response);
            this.menuStore.update({ allGroups: menu });
            return resolve(menu);
          }

          reject(o?.error?.code);
        });
    });
  }

  fetchProducts() {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/getProducts/' + this.orwiStore.getValue().id,
          {},
          this.sessionQuery.token
        )
        .subscribe((o: any) => {
          if (o.response) {
            let products: any = Object.assign(o?.response);
            this.menuStore.update({ products: products });
            return resolve(products);
          }

          reject(o?.error?.code);
        });
    });
  }

  saveToLocalStorage(menu) {
    localforage.setItem('menu', menu);
  }

  saveProducts(product: Product, bulkUpdate = false) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/saveProduct/' + this.orwiStore.getValue().id,
          product,
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            if (!bulkUpdate) {
              await this.updateMenu();
            }
            this.menuStore.update(({ products }) => ({
              products: arrayUpdate(products, o.response.id, o.response),
            }));
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  async updateMenu() {
    // await this.syncStore();
    // this.initialize();
  }

  saveProductGroup(productGroup: ProductGroup) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/saveGroup/' + this.orwiStore.getValue().id,
          productGroup,
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            // await this.syncStore();
            this.fetchGroups();
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  deleteProduct(productId: string) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/deleteProduct/' + this.orwiStore.getValue().id,
          {
            id: productId,
          },
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            // await this.syncStore();
            this.initialize();
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  deleteGroup(productGroupId: string) {
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest(
          '/api/menu/deleteGroup/' + this.orwiStore.getValue().id,
          {
            id: productGroupId,
          },
          this.sessionQuery.token
        )
        .subscribe(async (o: any) => {
          if (o.response) {
            // await this.syncStore();
            this.initialize();
            return resolve(o.response);
          }

          reject(o?.error?.code);
        });
    });
  }

  syncStore() {
    this.glb.showLoading();
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/store/syncStoreMenu', {
          id: this.orwiStore.getValue().id,
        })
        .then(
          (o: any) => {
            this.glb.closeAllLoading();
            if (o.response) {
              window.location.reload()
              return resolve(Object.assign(o).response);
            }

            return reject(o?.error?.code);
          },
          (err) => {
            console.error(err);
            this.glb.closeAllLoading();
          }
        );
    });
  }

  async getAttributes(): Promise<Attributes[]> {
    return await new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/category/getAttributes', {})
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o.response);
              // this.menuStore.update({
              //   attributes: result,
              // });
              return resolve(result);
            }

            return reject(o?.error?.code);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  addMenuTranslate(data: any) {
    let store = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/translate/saveMenuItemTranslate', {
          ...data,
          storeId: store,
        })
        .then((o: any) => {
          if (o?.response) {
            return resolve(o?.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  getMenuTranslate(data, bulkUpdate = false) {
    let store = localStorage.getItem('activeStore');
    return new Promise(async (resolve, reject) => {
      return this.orwiService
        .serviceRequest('/api/translate/getMenuItemTranslate', {
          ...data,
          storeId: store,
        })
        .subscribe(async (o: any) => {
          if (o?.response) {
            if (!bulkUpdate) {
              await this.updateMenu();
            }
            return resolve(o?.response);
          }

          if (Object.keys(o).length == 0) {
            return resolve({});
          }

          return reject(o?.error?.code);
        });
    });
  }

  deleteMenuTranslate(data: any): Promise<any> {
    let store = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/translate/deleteMenuItemTranslate', {
          ...data,
          storeId: store,
        })
        .then((o: any) => {
          if (o?.response) {
            return resolve(o?.response);
          }

          return reject(o?.error?.code);
        });
    });
  }
}
