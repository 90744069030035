import { Component, EventEmitter, Input,  Output } from '@angular/core';

@Component({
  selector: 'orwi-button',
  templateUrl: './orwi-button.component.html',
  styleUrls: ['./orwi-button.component.scss'],
})
export class OrwiButtonComponent {
  @Input() animated: boolean = true;
  @Input() icon: string = 'add';
  @Input() iconColor: string = 'green';

  @Output() onClick: EventEmitter<null> = new EventEmitter<null>();
  constructor() {}

  click() {
    this.onClick.emit();
  }
}
