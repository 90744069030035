<div class="content-cover">
  <div class="currency-cover">
    <ion-button
      [ngStyle]="{
        '--background':
          (paymentQuery.selectedCurrency$ | async).name == item.name
            ? '#ED4C4C'
            : '#184EAE'
      }"
      (click)="selectCurrency(item)"
      *ngFor="let item of paymentQuery.currencies$ | async"
    >
      {{ item.symbol }} * {{ item.exchangeRate | number }}
    </ion-button>
  </div>
  <div class="banknote-cover">
    <ion-button
      style="--background: #184eae"
      (click)="select(item)"
      *ngFor="let item of (paymentQuery.selectedCurrency$ | async).banknotes"
    >
      {{ item | orwiCurrency: activeSymbol }}
    </ion-button>
  </div>
  <!-- <div>
    <ion-button (click)="close()"
    color="tertiary"
    style="z-index: 200; position: absolute;right: 10px;height: 50px;" >{{'Cancel' | transloco}}</ion-button>
  </div> -->
</div>
