<div style="padding: 10px 12px 0px">
  <ion-item lines="none" class="selector-container">
    <ion-input
      placeholder="{{ 'Search...' | transloco }}"
      [formControl]="serach"
    ></ion-input>
  </ion-item>
</div>
<ion-list class="list" *ngIf="!isLoading; else loading">
  <ion-item
    class="item ion-activatable ripple-parent"
    *ngFor="let item of countries | orwiArrayFilterLive: 'name':serach.value"
    (click)="select(item)"
  >
    <ion-label style="overflow: hidden">
      <div>
        {{ item?.flagEmoji ? item?.flagEmoji : " " }}

        {{ item?.dialCode ? item?.dialCode : " " }}
      </div>

      <span style="font-weight: bolder">
        {{ item?.name }}
      </span>
    </ion-label>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-item>
</ion-list>

<ng-template #loading>
  <ion-list class="list">
    <ion-item>
      <ion-label> <ion-spinner name="dots"></ion-spinner></ion-label>
    </ion-item>
    <ion-item>
      <ion-label> <ion-spinner name="dots"></ion-spinner></ion-label>
    </ion-item>
    <ion-item>
      <ion-label> <ion-spinner name="dots"></ion-spinner></ion-label>
    </ion-item>
    <ion-item>
      <ion-label> <ion-spinner name="dots"></ion-spinner></ion-label>
    </ion-item>
  </ion-list>
</ng-template>
