import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'src/app/shared/shared.module';
import { FolioHeaderComponent } from './components/folio-header/folio-header.component';
import { FolioRowsComponent } from './components/folio-rows/folio-rows.component';
import { FolioComponent } from './components/folio/folio.component';
import { PaymentTypeListComponent } from './components/payment-type-list/payment-type-list.component';

@NgModule({
  imports: [CommonModule, IonicModule, TranslocoModule, SharedModule],
  exports: [FolioComponent, FolioRowsComponent, FolioHeaderComponent],
  declarations: [
    FolioComponent,
    FolioRowsComponent,
    FolioHeaderComponent,
    PaymentTypeListComponent,
  ],
  providers: [],
})
export class FolioModule {}
