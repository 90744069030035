import { Component, OnInit } from '@angular/core';
import { Notification } from '../../state/dto';
import { FoodAppsIntegrationService } from '../../state/food-apps-integration.service';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[] = [];

  page = 0;

  constructor(
    private foodAppService: FoodAppsIntegrationService,
    private acRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    await this.initNotify();
    setTimeout(() => {
      this.acRoute.queryParams.subscribe((params: any) => {
        if (params.selectedNotify) {
          let elmRef = document.getElementById(
            `notify-${params.selectedNotify}`
          );
          if (elmRef) {
            elmRef.scrollIntoView({
              behavior: 'smooth',
              inline: 'center',
              block: 'center'
            });
            setTimeout(() => {
              elmRef.classList.add('shine');
              setTimeout(() => {
                elmRef.classList.remove('shine');
              }, 250);
            }, 300);
          }
        }
      });
    }, 500);
  }

  async initNotify(page = 0) {
    this.foodAppService
      .getNotifications({
        skip: page * 10,
        limit: 10,
      })
      .then((notifications) => {
        this.notifications.push(...notifications);
      });
  }

  async doInfinite(ev: any) {
    this.page += 1;
    await this.initNotify(this.page);
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }
}
