import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { BrandsQuery } from 'src/app/modules/brands/state/brands.query';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { GlobalService } from 'src/app/services/global.service';

import { ModalService } from 'src/app/services/helpers/modal.service';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { FoodAppAccount } from '../../state/dto';
// import { FoodAppsIntegrationOrderService } from '../../state/food-apps-integration-order.service';
import { FoodAppsIntegrationQuery } from '../../state/food-apps-integration.query';
import { FoodAppsIntegrationService } from '../../state/food-apps-integration.service';
import { FoodAppsIntegrationStore } from '../../state/food-apps-integration.store';
import * as moment from 'moment';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'app-menu-home',
  templateUrl: './food-apps-home.component.html',
  styleUrls: ['./food-apps-home.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FoodAppsHome implements OnInit {


  constructor(
    private sessionQuery: SessionQuery,
    // private integrationOrderService: FoodAppsIntegrationOrderService,
    private nav: NavController,
    private ms: ModalService,
    private brandsQuery: BrandsQuery,
    private integrationStore: FoodAppsIntegrationStore,
    private integrationService: FoodAppsIntegrationService,
    public integrationQuery: FoodAppsIntegrationQuery,
    private publicApi: PublicApiService,
    private glb: GlobalService,
    public ssoService: SSOSessionService,
    public storeQuery: OrwiStoreQuery, private toastCtrl: ToastController) { }

  ngOnInit() {

    if (!this.sessionQuery.authStatus) {
      this.nav.navigateRoot("welcome")
    }

    if (this.storeQuery._orwiStore == undefined) {
      this.select()
    }

    // this.sessionQuery.activeLicense$.subscribe(o => {
    //   if (o) {
    //     this.integrationService.getFoodAppIntegrations()
    //   }

    // })



  }

  select() {
    this.ms.selectLicense()
  }

  async openForm(item: FoodAppAccount) {
    this.integrationStore.update({ activeAccount: item })
    await this.integrationService.getFoodAppCategories(item.foodApp)
    await this.integrationService.getFoodAppProducts(item.foodApp)
    await this.integrationService.getRestaurantMenu(item.id)

    await this.integrationService.syncProduct()

    //this.nav.navigateForward("ys-form")
    this.nav.navigateForward("product-sync")
  }


  add() {
    if(!this.ssoService.checkPermission('integration-list-edit')){
      this.glb.permissionToast();
      return
    }
    let account: FoodAppAccount = {
      id: 'new',
      cloudId: this.sessionQuery.activeLicense.orwiStore.cloudId,
      description: '',
      foodApp: undefined,
      isActive: false,
      password: '',
      storeId: this.storeQuery._orwiStore.id,
      userName: '',
      supplierId: '',
      autoOrderAccept: false,
      isDetailLogActive: false,
      brand: '',
      restaurantId: '',
      syncMenuStatus: '',
      syncOrderStatus: '',
      syncStoreStatus: '',
      isDeliveryApp: false,
      syncMenuLast: new Date(),
      syncOrderLast: new Date(),
      syncStoreLast: new Date()

    }

    this.integrationStore.update({ activeAccount: account })
    this.nav.navigateForward("ys-form")
  }

  async changeStatus(e, item: FoodAppAccount) {
    item.isActive = e.detail.checked
    await this.publicApi.upsert({
      _db: "food-apps-service",
      col: 'accounts',
      data: item
    })
    this.glb.toast("Kayıt Edildi", "", "bottom", "success")
  }

  bulkSyncProduct()
  {
    if(!this.ssoService.checkPermission('integration-list-use-bulk-match')){
      this.glb.permissionToast();
      return
    }
    this.nav.navigateForward('bulk-product-sync')
  }

  bulkModifierSync()
  {
    this.nav.navigateForward('bulk-modifier-sync')
  }


  foodAppsLogs()
  {
    if(!this.ssoService.checkPermission('integration-list-use-log-report')){
      this.glb.permissionToast();
      return
    }
    this.nav.navigateForward('food-apps-logs')
  }



  async syncProducts(item) {
    this.glb.showLoading()

    this.integrationStore.update({ activeAccount: item })
    await this.integrationService.getFoodAppCategories(item.foodApp)
    await this.integrationService.getFoodAppProducts(item.foodApp)

    try {
      await this.integrationService.getRestaurantMenu(item.id)
    } catch (error) {
      console.log("error")
      this.glb.closeLoading()
      return
    }



    // await this.integrationService.syncProduct()
    this.glb.closeLoading()
    //this.nav.navigateForward("ys-form")
    this.nav.navigateForward("product-sync")
  }

  async syncModifiers(item) {
    if(!this.ssoService.checkPermission('integration-list-can-use-match')){
      this.glb.permissionToast();
      return
    }
    this.glb.showLoading()
    this.integrationStore.update({ activeAccount: item })
    await this.integrationService.getFoodAppModifiers(item.foodApp)

    try {
      await this.integrationService.getRestaurantMenu(item.id)
    } catch (error) {
      console.log("error")
      this.glb.closeLoading()
      return
    }


    // await this.integrationService.syncModifiers()
    this.glb.closeLoading()

    //this.nav.navigateForward("ys-form")
    this.nav.navigateForward("modifier-sync")
  }

  editAccount(item) {
    if(!this.ssoService.checkPermission('integration-list-edit')){
      this.glb.permissionToast();
      return
    }
    this.integrationStore.update({ activeAccount: item })
    this.nav.navigateForward("ys-form")

    // this.integrationOrderService.getOrders(new Date('01.01.2021'), new Date('01.01.2023'), this.storeQuery._orwiStore.id)

  }

  getBrand(item) {
    return this.brandsQuery.brands.find(o => o.id == item.brand)?.name || "marka tanımlı değil"
  }


  deleteAccound(item) {
    item//reserved
  }


  async showFoodAppMessage(item: FoodAppAccount, type: string) {


    let msg = ''
    let status = 'success'
    let lastDate = ''
    if (type === 'syncOrderStatus') {
      msg = item.syncOrderStatus
      lastDate= moment(item.syncOrderLast).format('DD.MM.YYYY')
    }
    else if (type === 'syncStoreStatus') {
      msg = item.syncStoreStatus
      lastDate= moment(item.syncStoreLast).format('DD.MM.YYYY')
    }
    else {
      msg = item.syncMenuStatus
      lastDate= moment(item.syncMenuLast).format('DD.MM.YYYY')
    }

    if (msg !== 'success')
    {
      status = 'danger'
    }

    let config: any = {
      header: item.description + '( Son İşlem Zamanı : ' + lastDate + ' )' ,
      message: msg,
      position: 'bottom',
      color: status,
      animated: true,
      cssClass: 'toast-custom-class',
    };
    config['buttons'] = [
      {
        text: 'Tamam',
        role: 'cancel',
        handler: () => {
          // this.consolelog('Cancel clicked');
        },
      },
    ];

    const toast = await this.toastCtrl.create(config);
    toast.present();
  }
  }
