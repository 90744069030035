import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { FolioQuery } from '../modules/folio/state/folio.query';
import { FolioStore } from '../modules/folio/state/folio.store';
import { MenuQuery } from '../modules/menu/state/menu.query';
import { MenuStore } from '../modules/menu/state/menu.store';
import { OrwiStoreQuery } from '../modules/store/state/store.query';
import { OrwiStoreStore } from '../modules/store/state/store.store';
import { TableQuery } from '../modules/tables/state/table.query';
import { TableStore } from '../modules/tables/state/table.store';
import { Printer } from './dto/orwi-definitions';
import { Folio } from './dto/orwi-folio';
import { ModifierCover, Product, ProductGroup } from './dto/orwi-product';
import { OrwiStore, PaymentType, StoreCover } from './dto/orwi-store';
import { ITable, TableGroup } from './dto/tables';

@Injectable({
  providedIn: 'root'
})
export class OfflineServiceService {

  constructor(
    private storeStore: OrwiStoreStore,
    private menuStore: MenuStore,
    private tableStore: TableStore,
    private folioStore: FolioStore,
    private tableQuery: TableQuery,
    private menuQuery: MenuQuery,
    private folioQuery: FolioQuery,
    private storeQuery: OrwiStoreQuery) { }


  initializeOfflineSave() {
    this.saveStore()
    this.saveMenu()
    this.saveTables()
    this.saveOpenFolios()
  }

  async load() {
    await this.loadMenu()
    await this.loadStore()
    await this.loadTables()
    await this.loadOpenFolios()

  }

  async loadStore() {


    let store: OrwiStore = await localforage.getItem("store")
    let payTypes: PaymentType[] = await localforage.getItem("payTypes")
    let ownedStores: StoreCover[] = await localforage.getItem("ownedStores")
    let storeid: string = await localforage.getItem("store-id")
    let printers : Printer[] = await localforage.getItem("printers")
    this.storeStore.update({
      store: store,
      payTypes: payTypes,
      ownedStores: ownedStores,
      id: storeid,
      printers: printers
    })

  }



  saveStore() {

    this.storeQuery.store$.subscribe(o => {
      if (o) localforage.setItem("store", o);
    })

    this.storeQuery.payTypes$.subscribe(o => {
      if (o) localforage.setItem("payTypes", o);
    })

    this.storeQuery.ownedStores$.subscribe(o => {
      if (o) localforage.setItem("ownedStores", o);
    })

    this.storeQuery.id.subscribe(o => {
      if (o) localforage.setItem("store-id", o);
    })

    this.storeQuery.printers$.subscribe(o => {
      if (o) localforage.setItem("printers", o);
    })

    this.storeQuery.select("deleteReasons").subscribe(o => {
      if (o) localforage.setItem("delete-reasons", o);
    })


  }


  async loadMenu() {

    let products: Product[] = await localforage.getItem("products")
    let groups: ProductGroup[] = await localforage.getItem("groups")
    let groupSub: ProductGroup[] = await localforage.getItem("groupSub")
    let productModifiers: ModifierCover[] = await localforage.getItem("productModifiers")
    let groupModifiers: ModifierCover[] = await localforage.getItem("groupModifiers")

    this.menuStore.update({
      products: products,
      groups: groups,
      groupSub: groupSub,
      productModifiers: productModifiers,
      groupModifiers: groupModifiers
    })

  }



  saveMenu() {
debugger
    this.menuQuery.select("products").subscribe(o => {
      if (o) localforage.setItem("products", o);
    })

    this.menuQuery.select("groups").subscribe(o => {
      if (o) localforage.setItem("groups", o);
    })

    this.menuQuery.select("groupSub").subscribe(o => {
      if (o) localforage.setItem("groupSub", o);
    })

    this.menuQuery.select("productModifiers").subscribe(o => {
      if (o) localforage.setItem("productModifiers", o);
    })

    this.menuQuery.select("groupModifiers").subscribe(o => {
      if (o)  localforage.setItem("groupModifiers", o);
    })

  }

  async loadTables() {
    let tableGroups: TableGroup[] = await localforage.getItem("tableGroups")
    let tables: ITable[] = await localforage.getItem("tables")

    this.tableStore.update({
      tableGroups: tableGroups,
      tables: tables
    })


  }

  saveTables() {
    this.tableQuery.select("tableGroups").subscribe(o => {
      if (o) localforage.setItem("tableGroups", o);
    })

    this.tableQuery.select("tables").subscribe(o => {
      if (o) localforage.setItem("tables", o);
    })
  }


  async loadOpenFolios() {
    let openFolios: Folio[] = await localforage.getItem("OpenFolios")
  
    this.folioStore.update({
      openFolios: openFolios
    })


  }
  

  saveOpenFolios() {
    this.folioQuery.select("openFolios").subscribe(o => {
      if (o) localforage.setItem("OpenFolios", o);
    })
  }

  offlineSync() {
    
  }
}
