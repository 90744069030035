import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { TableService } from 'src/app/modules/tables/state/table.service';
import { TableStore } from 'src/app/modules/tables/state/table.store';
import { FolioService } from '../../state/folio.service';
import { ITable } from 'src/app/services/dto/tables';

@Component({
  selector: 'app-open-folio',
  templateUrl: './open-folio.component.html',
  styleUrls: ['./open-folio.component.scss'],
})
export class OpenFolioComponent implements OnInit {
  name: UntypedFormControl;
  isSearching: boolean = false;
  searchId;
  btnText : string = "Continue"

  constructor(
    private modalCtrl: ModalController,
    private tableStore: TableStore,
    private folioService: FolioService,
    private idGeneratorService: IdGeneratorService,
    private glb: GlobalService,
    private tableService: TableService
  ) {
    this.nameConditionAsync = this.nameConditionAsync.bind(this);
    this.name = new UntypedFormControl(null, [
      Validators.required,
      this.nameConditionAsync,
    ]);
  }

  ngOnInit() {
    if (this.data?.table) {
      this.name.setValue(this.data.table?.name);
    }
    this.setBtnText()
   
  }
  data: any;
  type: 'folio' | 'add-table' | 'edit-table';

  close() {
    this.modalCtrl.dismiss();
  }
  async continue() {
    if (this.name.invalid) {
      return;
    }



    let table: ITable = {
      id: this.idGeneratorService.generate(),
      name: this.name.value,
      groupId: this.data?.groupId,
      coordinates: { x: "0px", y: "0px" },
      transform: {
        rotate: 0,
        scale: 1,
      },
    } as ITable;

    switch (this.type) {
      case 'add-table':       
        await this.tableService.saveTable(table);
        break;
      case 'edit-table':     
        await this.tableService.saveTable({
          ...this.data?.table,
          ...table,
          id: this.data?.table?.id,
          transform: {
            rotate: 0,
            scale: 1,
          },
        });
        break;
      case 'folio':      
      default:
        await this.folioService.createFolio(table.id, table.name, 'self');
        this.glb.navigateFolioForm();
        break;
    }
    this.close();
  }

  setBtnText()
  {
    debugger
    switch (this.type) {
      case 'add-table':
        this.btnText = "Add"      
        break;
      case 'edit-table':
        this.btnText = "Save"
        break;       
      case 'folio':
        this.btnText = "Continue"
        break;
      default:     
    }
  }

  nameConditionAsync(ctrl: AbstractControl) {
    this.isSearching = (ctrl.value as string)?.length > 0;
    clearTimeout(this.searchId);
    this.searchId = setTimeout(() => {
      this.isSearching = false;
    }, 300);
    if (this.data?.table?.name === this.name?.value) return null;
    return this.tableStore
      .getValue()
      .tables.some(
        (el) => el.name.toLocaleLowerCase() === ctrl.value?.toLocaleLowerCase()
      )
      ? { nameConditionAsync: true }
      : null;
  }
}
