<!-- {{(tq.designMode$ | async)}} -->

<div id="table-cover" class="table-cover" *ngIf="(tq.designMode$ | async) == false">
  <table-button [ngStyle]="{ display: checkChange(item) ? 'none' : 'unset'}" 
    class="c-table-button"
    id="{{ item.id }}" OrwiDraggable  (itemSelected)="itemSelected($event)" *ngFor="let item of tq.activeTables | async"
    [table]="item" (onInfoClick)="onClickInfo($event, item.name)">
  </table-button>
  <orwi-button *ngIf="mode !== 'change' && this.tableStore.getValue().selectedTableGroup" [animated]="true" iconColor="#aaa" (onClick)="addTable()"></orwi-button>
</div>

<div id="table-cover" class="table-cover" *ngIf="(tq.designMode$ | async)">
  <table-button [ngStyle]="{ display: checkChange(item) ? 'none' : 'unset'}" 
    class="c-table-button"
    id="{{ item.id }}" OrwiDesignTable (itemSelected)="itemSelected($event)" *ngFor="let item of tq.activeTables | async"
    [table]="item" (onInfoClick)="onClickInfo($event, item.name)">
  </table-button>
</div>

