<ion-header>
    <div class="container-header">
        <div class="left-header">
            <ion-title class="ion-text-left">{{'Orwi POS' | transloco}}</ion-title>
        </div>
        <div class="right-header">
            <div></div>
        </div>
    </div>
</ion-header>

<ion-content *ngIf="!versionError">
    <div class="container">
        <div class="left">
            <img src="/assets/images/login.png" alt="">
            <div style="padding: 30px;">
                <ion-button>
                    <ion-icon style="color: #184EAE;" name="help-circle-sharp"></ion-icon>
                    <p style="color: #184EAE; margin-left: 10px;">{{'Help' | transloco}}</p>
                </ion-button>
            </div>
        </div>
        <div class="right">

            <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90%;
              margin: auto;
              flex-direction: column;
              height: 100%;
            ">
                <form #f="ngForm" (ngSubmit)="requestOtp()" class="login-form">
                    <div style="
                  padding: 10px 10px 10px;
                  text-align: left;
                  font-size: xx-large;
                  font-weight: 600;
                ">
                        <span> {{"Welcome" | transloco}} </span>
                    </div>
                    <div style="padding: 10px; font-size: 14px">
                        <b>
                  {{"Let's get your started!" | transloco}}
                </b>
                    </div>
                    <div style="width: 100%; display: flex; justify-content: center">
                        <div style="
                    width: 100%;
                    display: flex;
                    padding: 10px;
                  ">
                            <!-- <div (click)="countryList()" class="ion-activatable ripple-parent" style="
                      display: flex;
                      align-items: center;
                      padding-right: 25px;
                      padding-left: 25px;
                      color: #171E4E;
                      /* margin-right: 10px; */
                      border-right: 1px solid #184EAE66;
                      border-radius: 12px 0px 0px 12px;
                      background: white;
                      /* font-size: 12px; */
                      justify-content: center;
                      width: 40%;
                    ">
                                <ion-icon style="margin-right: 10px;
                                color: #184EAE;" name="caret-down-outline"></ion-icon>
                                {{this.countryService.activeCountry.flagEmoji}} {{this.countryService.activeCountry.dialCode}}
                                <ion-ripple-effect></ion-ripple-effect>
                            </div> -->
                            <!-- <div style="width: 100%">
                                <input style="height: 100%;
                                margin: 0px;
                                border-radius: 0px 12px 12px 0px;
                                padding: 15px;
                                color: #171E4E;" required fixed type="tel" minlength="13" [(ngModel)]="gsm" name="gsm" placeholder="Enter Gsm" [spMask]="phoneMask01" [spKeepMask]="false" clearInput />
                            </div> -->
                        </div>
                    </div>
        
                    <div style="width: 97%; margin-top: 20px; margin:auto">
                        <ion-button style="--background: #4CD9ED;
                        --box-shadow: none;
                        --border-radius: 12px;                       
                        font-weight: 600;
                        font-size: large;
                        height: 50px" [disabled]="!f.valid" (click)="login()" expand="block">
                            <ion-spinner *ngIf="false" slot="end" name="bubbles"></ion-spinner>
                            {{"Register" | transloco}}
                        </ion-button>
                        <div style="display: flex; justify-content: center;">
                            <p>{{'I already have an account.' | transloco}}</p>
                            <p (click)="requestOtp()" class="btn-login">{{'Log In' | transloco}}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ion-content>
<ion-content *ngIf="versionError">
    <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <img src="assets/images/logo/orwi-with-text.svg" />
        <ion-spinner name="dots"></ion-spinner>
    </div>

    <div class="version-box">
        Version Hatası!, Lütfen Güncel Versiyonu Yükleyin
    </div>

</ion-content>