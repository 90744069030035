import { Injectable } from '@angular/core';
import { SocketMessage } from './ecr.service';
import { GlobalService } from '../global.service';
import { IonicSafeString, Platform } from '@ionic/angular';
import { EcrBaseService } from './ecr.base.service';
import { BehaviorSubject } from 'rxjs';
import { Folio } from '../dto/orwi-folio';
import { TranslocoService } from '@ngneat/transloco';
import { MenuStore } from 'src/app/modules/menu/state/menu.store';
const endFlag = '©¥¥©';

@Injectable({
  providedIn: 'root',
})
export class EcrIngenicoService implements EcrBaseService {
  socketId = '';
  ipAddress: string = '192.168.1.39';
  port: number = 8099;
  connectionBehavior = new BehaviorSubject<'hasError' | 'connected'>(null);
  saleBehavior = new BehaviorSubject<'hasError' | 'success'>(null);

  constructor(
    private glb: GlobalService,
    private plt: Platform,
    private menuStore: MenuStore,
    private translateService: TranslocoService
  ) {
    if (this.plt.is('capacitor'))
      (<any>window).chrome.sockets.tcp.onReceive.addListener((info) => {
        var socketInfo = this.glb.arrayBuffer2str(info.data);
        console.log('ecrConnect', socketInfo);
        // socketInfo = this.replaceAll(socketInfo, 'u0000\\')
        try {
          let data: SocketMessage = Object.assign(
            new SocketMessage('', ''),
            JSON.parse(socketInfo)
            // JSON.parse(socketInfo.data)
          );

          switch (data.action) {
            case 'connect':
              // if (data.action !== 'connect') return;
              if (JSON.parse(data.jSonData).ResultData.ErrorCode === 0) {
                // this.syncDepartments(this.orwiStoreService.ecrDepartments);
                this.connectionBehavior.next('connected');
                this.connectionBehavior.complete();
              } else {
                this.connectionBehavior.next('hasError');
                this.connectionBehavior.complete();
              }
              break;

            case 'folio-closed':
              let resultCode, resultMessage, result;
              // alert(JSON.stringify(result))
              try {
                result = JSON.parse(data.jSonData);
                result = result.Result;
                resultCode = result.Status;
                resultMessage = this.formatErrorMessage(resultCode); //result.ResultMessage;
              } catch (error) {
                // alert('ERROR:' + JSON.stringify(error));
                resultCode = 0;
              }
              console.log(
                '🚀 ~ file: ecr.ingenico.service.ts ~ line 151 ~ EcrIngenicoService ~ resultCode',
                resultCode
              );
              if (resultCode != 0) {
                this.saleBehavior.next(resultMessage);
                this.saleBehavior.complete();
              } else {
                this.saleBehavior.next('success');
                this.saleBehavior.complete();
              }
              break;
            default:
              break;
          }
        } catch (error) {
          this.connectionBehavior.next('hasError');
          this.connectionBehavior.complete();
          this.saleBehavior.next('hasError');
          this.saleBehavior.complete();
        }
      });
  }
  formatErrorMessage(resultCode: any): any {
    return this.translateService.translate(
      errorTypes[resultCode] ?? 'SomethingWrong'
    );
  }

  init(ipAddress: string, port: number): EcrBaseService {
    this.ipAddress = ipAddress;
    this.port = port;
    console.log(port, ipAddress);
    return this;
  }

  replaceAll(value: string, searchValue = ''): string {
    if (value.indexOf(searchValue) >= 0) {
      return this.replaceAll(value.replace(searchValue, ''), searchValue);
    }

    return value.replace(searchValue, '');
  }

  async connect(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let readySource = await this.plt.ready();
        if (readySource == 'cordova') {
          this.glb.showLoading();
          console.log('socket connect baslatiliyor');
          (<any>window).chrome.sockets.tcp.create({}, (socketInfo) => {
            console.log('socket created:', socketInfo);
            this.socketId = socketInfo.socketId;
            (<any>window).chrome.sockets.tcp.connect(
              this.socketId,
              this.ipAddress,
              this.port,
              (result) => {
                console.log('socket connected:', result);
                let socketMessage = new SocketMessage('connect', '');
                let printByte = this.glb.str2arrayBuffer(
                  JSON.stringify(socketMessage) + endFlag
                );
                (<any>window).chrome.sockets.tcp.send(
                  this.socketId,
                  printByte,
                  (result) => {
                    console.log('SocketResult', JSON.stringify(result));
                    if (result.resultCode == 0) {
                      this.glb.toast(
                        'Orwi Ecr Service',
                        'Yazarkasa Ping işlemi başarılı',
                        'bottom',
                        'success'
                      );
                    }
                  }
                );
              }
            );
          });
          const conRes = await this.connectionBehavior.toPromise();
          if (conRes == 'connected') {
            this.glb.toast(
              'Orwi Ecr Service',
              'Yazarkasa bağlantı işlemi tamamlandı.',
              'bottom',
              'success'
            );
          }
          if (conRes == 'hasError') {
            this.glb.toast(
              'Orwi Ecr Service',
              'Yazarkasaya bağlanılamadı.Lütfen ayarları kontrol ediniz.',
              'bottom',
              'danger'
            );
          }
          this.glb.closeLoading();
          resolve(true);
        }
      } catch (err: any) {
        reject(err)
      }
    });
  }

  async sale(folio: Folio) {
    console.log('ecrSaleFolio', JSON.stringify(folio));
    folio.rows.forEach((elm) => {
      if (elm.rowType == 'modifier') {
        elm.rowType = 'product';

      }
      if (elm.rowType == "product") {
        elm.tax = this.menuStore.getValue().products.find(x => x.id == elm.itemID)?.vat || 10
      }
    });
    let bankRows = folio.rows.filter(
      (el) => el.rowType == 'payment' && el.paymentType == 'bank'
    );
    let otherPaymentRows = folio.rows.filter(
      (el) => el.rowType == 'payment' && el.paymentType != 'bank'
    );

    let formatted: Folio = {
      ...folio,
      rows: [
        ...folio.rows.filter(
          (elm) => elm.rowType != 'payment' && elm.recordStatus != 'deleted' && !elm.isGift
        ),
        ...bankRows,
        ...otherPaymentRows,
      ],
    };
    console.log("formattedFolio", JSON.stringify(formatted))
    return new Promise(async (resolve, reject) => {
      try {
        this.glb.showLoading();
        console.log("formatted-Folio", formatted)
        let readySource = await this.plt.ready();
        if (readySource == 'cordova') {
          let socketMessage = new SocketMessage(
            'folio-closed',
            JSON.stringify(formatted)
          );
          console.log('folio-closed', JSON.stringify(formatted));
          console.log('socketMessage:' + socketMessage);
          let printByte = this.glb.str2arrayBuffer(
            JSON.stringify(socketMessage) + endFlag
          );
          (<any>window).chrome.sockets.tcp.send(
            this.socketId,
            printByte,
            (result) => {
              console.log('SocketResult', JSON.stringify(result));
            }
          );

          console.log('socket sended');

          const res = await this.saleBehavior.toPromise();

          if (res == 'success') {
            this.glb.toast(
              'Orwi Pos',
              'Ödeme İşlemi Başarılı.',
              'bottom',
              'success'
            );
            resolve(true);
          } else {
            this.glb.toast(
              'Orwi Ecr Service',
              new IonicSafeString(res),
              // 'Yazarkasaya gönderilirken bir sorun oluştu.',
              'bottom',
              'danger'
            );
            resolve(false);
          }
          this.glb.closeLoading();
          this.saleBehavior = new BehaviorSubject<'hasError' | 'success'>(null);
        }
      } catch (err: any) {
        reject(err)
      }
    });
  }

  async printXReport() {
    let readySource = await this.plt.ready();
    let xSubject = new BehaviorSubject(null);
    if (readySource == 'cordova') {
      let socketMessage = new SocketMessage('print-xreport', '');
      let printByte = this.glb.str2arrayBuffer(
        JSON.stringify(socketMessage) + endFlag
      );
      (<any>window).chrome.sockets.tcp.send(
        this.socketId,
        printByte,
        (result) => {
          console.log('SocketResult', JSON.stringify(result));
          if (result.resultCode == 0) {
            this.glb.toast(
              'Orwi Ecr Service',
              'Yazarkasa Ping işlemi başarılı',
              'bottom',
              'success'
            );
            xSubject.next(true);
            xSubject.complete();
          }
        }
      );
      this.glb.showLoading('X Raporu Başlıyor...');
      await xSubject.toPromise();
      this.glb.closeLoading();
    }
  }

  async printZReport() {
    let readySource = await this.plt.ready();
    let zSubject = new BehaviorSubject(null);
    if (readySource == 'cordova') {
      let socketMessage = new SocketMessage('print-zreport', '');
      let printByte = this.glb.str2arrayBuffer(
        JSON.stringify(socketMessage) + endFlag
      );
      (<any>window).chrome.sockets.tcp.send(
        this.socketId,
        printByte,
        (result) => {
          console.log('SocketResult', JSON.stringify(result));
          if (result.resultCode == 0) {
            this.glb.toast(
              'Orwi Ecr Service',
              'Yazarkasa Ping işlemi başarılı',
              'bottom',
              'success'
            );
            zSubject.next(true);
            zSubject.complete();
          }
        }
      );
      this.glb.showLoading('X Raporu Başlıyor...');
      await zSubject.toPromise();
      this.glb.closeLoading();
    }
  }

  async syncDepartments(departments: any) {
    let readySource = await this.plt.ready();
    if (readySource == 'cordova') {
      let socketMessage = new SocketMessage(
        'setDepartment',
        JSON.stringify(departments)
      );
      this.glb.showLoading();
      let printByte = this.glb.str2arrayBuffer(
        JSON.stringify(socketMessage) + endFlag
      );
      (<any>window).chrome.sockets.tcp
        .send(
          this.socketId,
          printByte
        )(<any>window)
        .chrome.sockets.tcp.onReceive.addListener((info) => {
          var socketInfo = this.glb.arrayBuffer2str(info.data);
          console.log('syncDepartment', socketInfo);
          this.glb.closeLoading();
          this.connectionBehavior.next('connected');
          this.connectionBehavior.complete();
        });
    }
  }
}

const errorTypes = {
  0: 'TRAN_RESULT_OK',
  1: 'TRAN_RESULT_TIMEOUT',
  2: 'TRAN_RESULT_NOT_ALLOWED',
  4: 'TRAN_RESULT_USER_ABORT',
  8: 'TRAN_RESULT_EKU_PROBLEM',
  10: 'TRAN_RESULT_CONTINUE',
  20: 'TRAN_RESULT_NO_PAPER',
  2317: 'APP_ERR_GMP3_INVALID_HANDLE',
  2080: 'APP_ERR_ALREADY_DONE',
  2085: 'APP_ERR_PAYMENT_NOT_SUCCESSFUL_AND_NO_MORE_ERROR_CODE',
  2086: 'APP_ERR_PAYMENT_NOT_SUCCESSFUL_AND_MORE_ERROR_CODE',
  61468: 'DLL_RETCODE_RECV_BUSY',
  2078: 'APP_ERR_TICKET_HEADER_ALREADY_PRINTED',
  2077: 'APP_ERR_TICKET_HEADER_NOT_PRINTED',
  2067: 'APP_ERR_FIS_LIMITI_ASILAMAZ',
  61443: 'DLL_RETCODE_TIMEOUT',
  2226: 'APP_ERR_FILE_EOF',
};

// const TRAN_RESULT_OK = 0x0000;
// const TRAN_RESULT_NOT_ALLOWED = 0x0001;
// const TRAN_RESULT_TIMEOUT = 0x0002;
// const TRAN_RESULT_USER_ABORT = 0x0004;
// const TRAN_RESULT_EKU_PROBLEM = 0x0008;
// const TRAN_RESULT_CONTINUE = 0x0010;
// const TRAN_RESULT_NO_PAPER = 0x0020;
// const APP_ERR_GMP3_INVALID_HANDLE = 2317; // Handle var fakat yanlış
// const APP_ERR_ALREADY_DONE = 2080;
// const APP_ERR_PAYMENT_NOT_SUCCESSFUL_AND_NO_MORE_ERROR_CODE = 2085;
// const APP_ERR_PAYMENT_NOT_SUCCESSFUL_AND_MORE_ERROR_CODE = 2086;
// const DLL_RETCODE_RECV_BUSY = 0xf01c;
// const APP_ERR_TICKET_HEADER_ALREADY_PRINTED = 2078;
// const APP_ERR_TICKET_HEADER_NOT_PRINTED = 2077;
// const APP_ERR_FIS_LIMITI_ASILAMAZ = 2067;
// const DLL_RETCODE_TIMEOUT = 0xf003;
// const APP_ERR_FILE_EOF = 2226;
