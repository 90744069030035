import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OrwiStoreService } from 'src/app/modules/store/state/store.service';
import { Folio } from '../dto/orwi-folio';
import { GlobalService } from '../global.service';
import { EcrBaseService } from './ecr.base.service';
import { EcrHuginService } from './ecr.hugin.service';
import { PavoService } from './ecr.pavo.service';
import { EcrIngenicoService } from './ecr.ingenico.service';
import { IngenicoWinService } from './ecr.ingenico.win.service';
import { OrwiService } from '../orwi/orwi.service';
import { EcrSettingService } from './ecr-setting.service';
import { EcrSetting } from './dto/ecr-setting.model';
import { PavoIntentService } from './ecr-pavo-intent.service';
import { EcrDepartment, TaxRate } from '../dto/orwi-definitions';
// import { Device } from '@capacitor/device';
import { ParametersStore } from 'src/app/modules/parameters/state/parameters.store';

@Injectable({
  providedIn: 'root',
})
export class EcrService {
  socketId: any;
  activeService: EcrBaseService;
  serviceType: 'hugin' | 'profilo' | 'ingenico' | 'none' | 'pavo' = 'none';
  constructor(
    private plt: Platform,
    private glb: GlobalService,
    private orwiStoreService: OrwiStoreService,
    private orwiService: OrwiService,
    private PavoService: PavoService,
    private IngenicoService: EcrIngenicoService,
    private IngenicoWinService: IngenicoWinService,
    private HuginService: EcrHuginService,
    private ecrSetting: EcrSettingService,
    private pavoIntentService: PavoIntentService,
    private parameterStore: ParametersStore
  ) {

  }

  async getEcrSettingsAndInit(): Promise<any> {
    this.ecrSetting.getEcrSetting()
    return new Promise(async (resolve) => {

        let ecrServiceData: Partial<EcrSetting> = this.ecrSetting.ecrSetting;
        if (!ecrServiceData) {
          ecrServiceData = {
            ecrIp: this.parameterStore.getValue().posParameters.ecrParameters.ipAddress,
            terminalIp: this.parameterStore.getValue().posParameters.winServiceIpAddress,
            port: +this.parameterStore.getValue().posParameters.ecrParameters.port ?? 9000,
            ecrSerialNo : this.parameterStore.getValue().posParameters.ecrParameters.ecrSerialNo,
            ecrModel: this.parameterStore.getValue().posParameters.ecrParameters.ecrModel as any
          }
          console.log('getEcrSettingsAndInit', ecrServiceData, this.parameterStore.getValue().posParameters)
        }

        console.warn('ecrServiceData', ecrServiceData)
        if (ecrServiceData) {
          this.serviceType = ecrServiceData.ecrModel ?? 'none';
          this.IngenicoWinService.terminalIP = ecrServiceData.terminalIp;
          switch (this.serviceType) {
            case 'none':
              break;
            case 'hugin':
              this.activeService = this.HuginService.init(
                ecrServiceData.ecrIp,
                ecrServiceData.port
              );
              break;
            case 'pavo':
              // const device = await Device.getInfo();
              if (this.plt.is('capacitor')) {
                this.activeService = this.pavoIntentService.init(
                  ecrServiceData.ecrIp,
                  ecrServiceData.port
                );
                break;
              }
              else {
                this.activeService = this.PavoService.init(
                  ecrServiceData.ecrIp,
                  ecrServiceData.port
                );
              }
              break;
            case 'ingenico':
              if (this.plt.is('capacitor')) {
                this.activeService = this.IngenicoService.init(
                  ecrServiceData.ecrIp,
                  ecrServiceData.port
                );
                break;
              } else {
                this.activeService = this.IngenicoWinService.init(
                  ecrServiceData.ecrIp,
                  ecrServiceData.port,
                  ecrServiceData.terminalIp
                );
                break;
              }

            default:
              this.activeService = this.HuginService.init(
                ecrServiceData.ecrIp,
                ecrServiceData.port
              );
              break;
          }
        }
        console.log('ECR SERVICE DATA:::::', ecrServiceData, this.activeService,this.parameterStore.getValue().posParameters.ecrParameters);
        resolve(true)

    })
  }

  syncDepartment() {
    if (this.serviceType == 'none') return Promise.resolve(true);
    return this.activeService.syncDepartments(
      this.orwiStoreService.ecrDepartments
    );
  }
  saveErcDepartment(definition: any): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    let url = '/api/pos/ecrDepartments/saveEcrDepartment';

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(url, {
          ...definition,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }
          return reject(o?.error?.code);
        });
    });
  }
  getErcDepartment(): Promise<EcrDepartment[]> {
    let storeId = localStorage.getItem('activeStore');
    let url = '/api/pos/ecrDepartments/getEcrDepartments';
    return new Promise((resolve, reject) => {
      // return resolve([{id:1}] as any);
      return this.orwiService
        .serviceRequestPromise(url, {
          id: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o?.response);

              if (result) return resolve(result);
            }
            return reject(o?.error?.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  deleteErcDepartment(definitionId): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    let url = '/api/pos/ecrDepartments/deleteEcrDepartment';

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(url, {
          id: definitionId,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  saveTaxDefinition(definition: any): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    let url = '/api/pos/taxRate/saveTaxRate';
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(url, {
          ...definition,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  getTaxDefinitions(): Promise<TaxRate[]> {
    let storeId = localStorage.getItem('activeStore');
    let url = '/api/pos/taxRate/getTaxRates';
    return new Promise((resolve, reject) => {
      // return resolve([{id:1}] as any);
      return this.orwiService
        .serviceRequestPromise(url, {
          id: storeId,
        })
        .then(
          (o: any) => {
            if (o.response) {
              let result = Object.assign(o?.response);

              if (result) return resolve(result);
            }
            return reject(o?.error?.code);
          },
          (err) => {
            return reject(err);
          }
        );
    });
  }

  deleteTaxDefinition(definitionId): Promise<any> {
    let storeId = localStorage.getItem('activeStore');
    let url = '/api/pos/taxRate/deleteTaxRate';

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(url, {
          id: definitionId,
          storeId: storeId,
        })
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }
          return reject(o?.error?.code);
        });
    });
  }

  connectEcr(): Promise<any> {
    console.log('connectEcr', this.serviceType, this.activeService)
    if (this.serviceType == 'none') return Promise.resolve(true);
    return this.activeService.connect();
  }

  sendFolioToEcr(totalBank: number = 0, folio: Folio): Promise<any> {
    if (this.serviceType == 'none') return Promise.resolve(true);
    console.log('TOTAL BANK: ', totalBank, 'SERVICE TYPE: ', this.serviceType);

    return this.activeService.sale(folio, totalBank);
  }

  async createXreport(): Promise<any> {
    if (this.serviceType == 'none') return Promise.resolve(true);
    return await this.activeService.printXReport().then((x) => {
      console.log('createXreport', x);
      this.glb.closeLoading();
    });
  }

  async createZreport() {
    if (this.serviceType == 'none') return Promise.resolve(true);
    return await this.activeService.printZReport();
  }

  arrayBuffer2str(buf) {
    var str = '';
    var ui8 = new Uint8Array(buf);
    for (var i = 0; i < ui8.length; i++) {
      str = str + String.fromCharCode(ui8[i]);
    }
    return str.replace('Â©Â¥Â¥Â©', '');
  }
}

export class SocketMessage {
  id: string;
  action: string;
  jSonData: any;
  apiKey: string;
  constructor(action: string, jSonData: string) {
    this.id = '1235';
    this.action = action;
    this.jSonData = jSonData;
    this.apiKey = 'YWtzaW5pIHNveWxleWVuZSBrYWRhciBvcndpIHBvcw';
  }
}
