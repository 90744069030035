import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhoneFolioRoutingModule } from './phone-folio-routing.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PhoneFolioPageComponent } from './components/phone-folio-page/phone-folio-page.component';
import { ResizableModule } from 'angular-resizable-element';
import { PhoneFolioRowsComponent } from './components/phone-folio-rows/phone-folio-rows.component';
import { ComponentsModule } from 'src/app/components/components.module';


@NgModule({
  declarations: [
    PhoneFolioPageComponent,
    PhoneFolioRowsComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    SharedModule,
    ResizableModule,
    PhoneFolioRoutingModule,
    ComponentsModule,
  
  ]
})
export class PhoneFolioModule { }
