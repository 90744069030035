<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>
    <div class="header-center">
      <ion-title>{{ "Bulk Modifier Sync" | transloco }}</ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="scroll-container">
      <dx-data-grid
        #dataGrid
        id="grid-container"
        [showRowLines]="false"
        [showColumnLines]="false"
        [dataSource]="foodAppModifier"
        [showBorders]="false"
        [rowAlternationEnabled]="true"
        (onRowUpdated)="onRowUpdated($event)"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        columnResizingMode="nextColumn"
        style="padding-bottom: 40px"
        noDataText="{{ 'No Data' | transloco }}"
      >
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

        <dxo-editing
          [allowAdding]="false"
          [allowUpdating]="true"
          mode="cell"
        ></dxo-editing>
        <dxi-column
          dataField="accountId"
          caption="Hesap"
          [allowEditing]="false"
        >
          <dxo-lookup
            [dataSource]="foodAppAcoounts"
            displayExpr="description"
            valueExpr="id"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="brand"
          caption="Marka"
          [allowEditing]="false"
        >
          <dxo-lookup
            [dataSource]="brands"
            displayExpr="name"
            valueExpr="id"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="name"
          caption="Varyasyon Adı"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column dataField="internalId" caption="Kerzz Varyasyon Adı">
          <dxo-lookup
            [dataSource]="internalModifiers"
            displayExpr="name"
            valueExpr="id"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="price"
          caption="Fiyat"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="source"
          caption="Platform"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="isActive"
          caption="Durum"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="editDate"
          caption="Son İşlem Tarihi"
          format="dd.MM.yyyy"
          dataType="date"
          [allowEditing]="false"
        ></dxi-column>
        <!-- <dxo-search-panel
          [visible]="true"
          [highlightCaseSensitive]="true"
          placeholder="{{ 'Search' | transloco }}"
        ></dxo-search-panel> -->
        <dxo-paging [pageSize]="100"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="false"></dxo-header-filter>
      </dx-data-grid>
    </ion-row>
  </ion-grid>
</ion-content>

<!-- [showRowLines]="false"
[showColumnLines]="false"
[dataSource]="foodAppsProducts"
[showBorders]="false"
[rowAlternationEnabled]="true"
[(selectedRowKeys)]="selectedWarehouseTransfer"
[allowColumnResizing]="true"
[columnAutoWidth]="true"
columnResizingMode="nextColumn"
style="padding-bottom: 40px"
noDataText="{{'No Data' | transloco}}" -->
