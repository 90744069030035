import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { FoodAppAccount, FoodAppsLogs } from '../../state/dto';

import { FoodAppsIntegrationService } from '../../state/food-apps-integration.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';
import { StoreInfo } from 'src/app/services/dto/orwi-home';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import { FormBuilder } from '@angular/forms';
import { Brand } from 'kerzz-base';


@Component({
  selector: 'food-apps-logs',
  templateUrl: './food-apps-logs.component.html',
  styleUrls: ['./food-apps-logs.component.scss'],
})
export class FoodAppsLogsComponent implements OnInit {
  foodAppsLogs: FoodAppsLogs[] = [];
  foodAppAcoounts: FoodAppAccount[] = [];
  today = new Date();
  moment: any = moment
  brands: Brand[] = [];
  constructor(
    private publicApiService: PublicApiService,
    private sessionQuery: SessionQuery,
    public glb: GlobalService,
    private storeService: StoreService,
    private fb: FormBuilder,

    public foodAppsService: FoodAppsIntegrationService
  ) { }

  ngOnInit() {

    this.fillData();
  }

  dateFilterForm = this.fb.group({
    date: [
      new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        this.today.getDate(),
        9,
        0,
        0
      ).toISOString(),
    ],
  });

  fillData() {
    this.storeService
      .getStoreInfo(this.sessionQuery.getValue().activeLicense.orwiStore.id)
      .then((storeInfo: StoreInfo) => {
        console.log(storeInfo);
        if (storeInfo.parentId !== '') {
          this.foodAppAcoounts = this.foodAppsService.foodAppAccounts
          this.getFoodAppsLogs(false);
        } else {
          this.glb.showLoading()
          this.publicApiService.getCollection({
            _db: 'food-apps-service',
            col: 'accounts',
            _filter: { cloudId: this.sessionQuery.getValue().activeLicense.orwiStore.cloudId },
            _sort : {description : 1}
          }).subscribe((x : FoodAppAccount[])=> {
            this.foodAppAcoounts = x.sort(function(a, b){
              if(a.description < b.description) { return -1; }
              if(a.description > b.description) { return 1; }
              return 0;
          })
            // let ps = [...this.foodAppAcoounts.map(n => n.orderId)]
            this.getFoodAppsLogs(true)
          })
        }
      });
  }

  refresh() {
    this.fillData();
  }



  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('FoodAppsLogs');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'FoodAppsLogs.xlsx');
      });
    });
  }


  getFoodAppsLogs(isHead: boolean) {
console.log(this.dateFilterForm.value)

    debugger;
    // let currentDate = new Date().toISOString();

    let startDate = moment(this.dateFilterForm.value.date).startOf('day').toISOString();
    let endDate = moment(this.dateFilterForm.value.date).utc().endOf('day').toISOString();
    let filter: any;
    if (isHead) {
      filter = {
        cloudId: this.sessionQuery.activeLicense.orwiStore.cloudId,
        createDate: { $gte: startDate, $lt: endDate },
      };
    } else {
      this.glb.showLoading();
      filter = {
        storeId: this.sessionQuery.activeLicense.orwiStore.id,
        createDate: { $gte: startDate, $lt: endDate },
      };
    }

    this.publicApiService
      .getCollection({
        _db: 'food-apps-service',
        col: 'order-mapping',
        _filter: filter,
        _sort: { createDate: -1 },
      })
      .subscribe((x: FoodAppsLogs[]) => {
        console.log('mapping', x);
        this.foodAppsLogs = x;
        let ps = [...x.map(n => n.orderId)]
        console.log('ps', ps)
        this.publicApiService.getCollection({
          _db: this.sessionQuery.getValue().activeLicense.orwiStore.cloudId,
          col: 'pos-folio-items',
          _filter: {
            rowType: 'payment', folioId: {
              $in: ps,
            },
          },
        }).subscribe(c => {
          this.foodAppsLogs.map(f => {
            c.map((fl: any) => {
              if (f.orderId === fl.folioId) {
                f.closedDate = fl.creation
                // f.orderStatus = fl.deliveryStatus.toString()
              }
            })
          })
          this.glb.closeAllLoading();
        })


      });
  }
}
