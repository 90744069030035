<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        icon="/assets/icons/back-btn.svg"
      ></ion-back-button>
    </ion-buttons>
    <ion-title>{{ "Integrations" | transloco }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <!-- <ion-item>
            <ion-label position="floating">
                Uygulama
            </ion-label>
            <ion-select [(ngModel)]="integrationQuery.activeAccount.foodApp" placeholder="Uygulama Seçin">
                <ion-select-option value="orwi">Orwi</ion-select-option>
                <ion-select-option value="yemek-sepeti">Yemek Sepeti</ion-select-option>
                <ion-select-option value="getir">Getir Yemek</ion-select-option>
                <ion-select-option value="fuudy">Fuudy</ion-select-option>
                <ion-select-option value="trendyol">Trendyol Go</ion-select-option>
                <ion-select-option value="migros-yemek">Migros Yemek</ion-select-option>
            </ion-select>
        </ion-item> -->

    <ion-item lines="none">
      <ion-label position="floating">
        {{ "Application" | transloco }}
      </ion-label>
      <ion-select
        [interfaceOptions]="customApplicationOptions"
        cancelText="{{ 'Cancel' | transloco }}"
        okText="{{ 'Done' | transloco }}"
        (ionChange)="appChanged($event)"
        [(ngModel)]="integrationQuery.activeAccount.foodApp"
        placeholder="{{ 'Choose Application' | transloco }}"
      >
        <ion-select-option
          *ngFor="let item of integrationQuery.foodAppAplications"
          [value]="item.id"
          >{{ item.name }}</ion-select-option
        >
      </ion-select>
      <ion-text color="danger" *ngIf="!integrationQuery.activeAccount.foodApp">
        <small>{{ "This field is required" | transloco }}</small>
      </ion-text>
    </ion-item>
    <ion-item lines="none">
      <ion-label position="floating">
        {{ "Description" | transloco }}
      </ion-label>
      <ion-input
        [(ngModel)]="integrationQuery.activeAccount.description"
      ></ion-input>
      <ion-text
        color="danger"
        *ngIf="!integrationQuery.activeAccount.description"
      >
        <small>{{ "This field is required" | transloco }}</small>
      </ion-text>
    </ion-item>

    <ion-item lines="none">
      <ion-label position="floating">
        {{ "Brand" | transloco }}
      </ion-label>

      <ion-select
        [(ngModel)]="integrationQuery.activeAccount.brand"
        placeholder="{{ 'Select Brand' | transloco }}"
        [interfaceOptions]="customBrandOptions"
        cancelText="{{ 'Cancel' | transloco }}"
        okText="{{ 'Done' | transloco }}"
      >
        <ion-select-option
          *ngFor="let item of brandsQuery.brands"
          [value]="item.id"
          >{{ item.name }}</ion-select-option
        >
      </ion-select>
      <ion-text color="danger" *ngIf="!integrationQuery.activeAccount.brand">
        <small>{{ "This field is required" | transloco }}</small>
      </ion-text>
    </ion-item>

    <ion-item lines="none">
      <ion-label>{{ "Active" | transloco }}</ion-label>
      <ion-toggle
        slot="end"
        (ionChange)="changeActiveStatus($event)"
        [checked]="integrationQuery.activeAccount.isActive"
        [disabled]="false"
      ></ion-toggle>
    </ion-item>

    <ion-item lines="none">
      <ion-label>{{ "Detailed Log Active" | transloco }}</ion-label>
      <ion-toggle
        slot="end"
        (ionChange)="changeLogStatus($event)"
        [checked]="integrationQuery.activeAccount.isDetailLogActive"
        [disabled]="false"
      ></ion-toggle>
    </ion-item>

    <ion-item lines="none">
      <ion-label>{{ "Auto Order Confirmation" | transloco }}</ion-label>
      <ion-toggle
        slot="end"
        (ionChange)="changeAcceptOrderStatus($event)"
        [checked]="integrationQuery.activeAccount.autoOrderAccept"
        [disabled]="false"
      ></ion-toggle>
    </ion-item>

    <div
      *ngIf="activeApp"
      style="display: flex; flex-direction: column; gap: 10px"
    >
      <ion-item lines="none" *ngIf="activeApp.userName?.visible">
        <ion-label position="floating">
          {{ activeApp.userName.title }}
        </ion-label>
        <ion-input [(ngModel)]="integrationQuery.activeAccount.userName">
        </ion-input>
      </ion-item>

      <ion-item lines="none" *ngIf="activeApp.password?.visible">
        <ion-label position="floating">
          {{ activeApp.password.title }}
        </ion-label>
        <ion-input [(ngModel)]="integrationQuery.activeAccount.password">
        </ion-input>
      </ion-item>

      <ion-item lines="none" *ngIf="activeApp.supplierId?.visible">
        <ion-label position="floating">
          {{ activeApp.supplierId.title }}
        </ion-label>
        <ion-input [(ngModel)]="integrationQuery.activeAccount.supplierId">
        </ion-input>
      </ion-item>
      <ion-item lines="none" *ngIf="activeApp.restaurantId?.visible">
        <ion-label position="floating">
          {{ activeApp.restaurantId.title }}
        </ion-label>
        <ion-input [(ngModel)]="integrationQuery.activeAccount.restaurantId">
        </ion-input>
      </ion-item>

      <ion-item lines="none" >
        <ion-label position="floating">
         Account ID
        </ion-label>
        <ion-input readonly="true" [(ngModel)]="integrationQuery.activeAccount.id">
        </ion-input>
      </ion-item>
    </div>

    <div class="footer-btn">
      <ion-button
        style="width: 40%"
        color="danger"
        *ngIf="this.integrationQuery.activeAccount.id !== 'new'"
        expand="block"
        (click)="delete()"
        >{{ "Delete Account" | transloco }}</ion-button
      >

      <ion-button
        [disabled]="
          integrationQuery.activeAccount.description.length < 5 ||
          !integrationQuery.activeAccount.foodApp ||
          !integrationQuery.activeAccount.brand
        "
        class="confirmation-btn"
        expand="block"
        (click)="save()"
        >{{ "Save" | transloco }}</ion-button
      >
    </div>
  </ion-list>
</ion-content>
