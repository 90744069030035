import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NavController, Platform } from '@ionic/angular';
import { AppUser, Role, UserInfo, UserLicance as UserLicense } from './dto/sso-dto';
import { SessionState, SessionStore } from './session.store';


@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {


    roleRoutingTable: { role: string, path: string }[] = []
    user$ = this.select("user")
    userInfo$ = this.select("userInfo")
    status$ = this.select("status")
    activeCountry$ = this.select("activeCountry")
    activeLicense$ = this.select("activeLicense")

    activeLicense: UserLicense

    licances: UserLicense[] = []
    authStatus = true
    token = ""
    user: AppUser
    userInfo: UserInfo


    constructor(

        protected orwiStore: SessionStore, private nav: NavController, private plt: Platform) {
        super(orwiStore);

        this.roleRoutingTable = [
            { role: '*', path: 'pos-home' },
            { role: "Courier", path: 'folio-list' },
            // { role: "Food App Manager", path: 'folio-list' },
            { role: 'Kitchen Display', path: 'kitchen-display-home' },
            // { role: 'Branch User', path: 'folio-list' }
        ]


        this.select("token").subscribe(o => {
            this.token = o
        })

        this.select("userInfo").subscribe(o => {
            if (o) {
                this.token = o.accessToken
                this.licances = o.licances

            }
        })

        this.select("status").subscribe(o => {
            if (o == "logged-in") {
                this.authStatus = true
            } else {
                this.authStatus = false
            }
        })
        this.select("user").subscribe(o => {
            if (o) {
                this.user = o
            }
        })

        this.select("userInfo").subscribe(o => {
            if (o) {
                this.userInfo = o
            }
        })

        this.select("authStatus").subscribe(o => {
            this.authStatus = o
        })

        this.select("activeLicense").subscribe(o => {

            if (o) {
                this.activeLicense = o
                localStorage.setItem("active-license", o.licanceId)
            }

        })


    }


    getHigerRole(): Role {
        let higerRole = this.activeLicense.roles.sort((a, b) => b.level - a.level)[0]
        return higerRole
    }

    navigateByUser() {
        let path = this.roleRoutingTable.find(o => o.role == this.getHigerRole().name)
        if (!path) path = this.roleRoutingTable.find(o => o.role == "*")
        if (path.path == "pos-home") {
            this.navigateHomeForm()
        } else {
            this.nav.navigateRoot(path.path)
        }
    }

    navigateHomeForm(_replaceUrl = false) {
        if (this.plt.width() <= 1024) {
            this.nav.navigateRoot('pos-home', { replaceUrl: _replaceUrl });
        } else {
            this.nav.navigateRoot('pos-home', { replaceUrl: _replaceUrl });
        }
    }

    getActiveLicense() {
        return localStorage.getItem("active-license")
    }


    is(ps: 'Courier' | 'Veri Super Admin') {
        ps//reserved
    }

}

