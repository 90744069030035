import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PaymentQuery } from '../../state/payment.query';
import { PaymentService } from '../../state/payment.service';
import { PaymentStore } from '../../state/payment.store';

@Component({
  selector: 'payment-banknote',
  templateUrl: './payment-banknote.component.html',
  styleUrls: ['./payment-banknote.component.scss'],
})
export class PaymentBanknoteComponent implements OnInit {
  activeSymbol = '₺';
  select(item) {
    console.log('select', item);
    this.paymentStore.update({ banknoteVisible: false });
    this.paymetService.addBanknoteToHistory(item);
    this.ms.dismiss();
  }

  constructor(
    public paymentQuery: PaymentQuery,
    private ms: ModalController,
    private paymentStore: PaymentStore,
    private paymetService: PaymentService
  ) {}

  ngOnInit() {}

  selectCurrency(item) {
    console.log('select currency', item);
    this.activeSymbol = item.symbol;
    this.paymentStore.update({ selectedCurrency: item });
  }

  close() {
    // this.paymentStore.update({banknoteVisible: false})
    this.ms.dismiss();
  }
}
