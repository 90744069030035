import { Component, Input, OnInit } from '@angular/core';
import { arrayUpdate } from '@datorama/akita';
import { AlertController } from '@ionic/angular';
import { ModifiersService } from 'src/app/modules/menu/modifiers/state/modifiers.service';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { SideMenuStore } from 'src/app/modules/side-menu/state/side-menu.store';
import { FolioQuery } from '../../state/folio.query';
import { FolioService } from '../../state/folio.service';
import { FolioStore } from '../../state/folio.store';
import { TranslocoService } from '@ngneat/transloco';
import { SessionQuery } from 'src/app/modules/session/state/session.query';

@Component({
  selector: 'folio-rows',
  templateUrl: './folio-rows.component.html',
  styleUrls: ['./folio-rows.component.scss'],
})
export class FolioRowsComponent implements OnInit {
  @Input() folio: Folio;
  @Input() totalRowVisible = false;
  @Input() multiSelect = false;
  @Input() segmentValuePayment: 'all' | 'paidfull' | 'unpaidfull' = 'all';
  @Input() segmentValueFolio: 'all' | 'old' | 'new' = 'all';
  @Input() showPaymentRows = false;
  @Input() component: 'payment' | 'folio' = 'folio';

  folio$ = this.folioQuery.activeFolio$;
  folioRows$ = this.folioQuery.folioRowsWODeleted$;
  total$ = this.folioQuery.total$;

  id = '';

  initOk = false;
  selectedRowIndex: number;
  constructor(
    private folioStore: FolioStore,
    public folioQuery: FolioQuery,
    private sideMenuStore: SideMenuStore,
    private modifiersService: ModifiersService,
    private folioService: FolioService,
    private transloco: TranslocoService,
    public alertController: AlertController,
    private sessionQuery: SessionQuery
  ) {}

  ngOnInit() {
    this.folioQuery.selectedRow$.subscribe((folioRow) => {
      let hasModifiers = false;

      if (folioRow)
        hasModifiers = this.modifiersService.hasModifiers(folioRow.itemID);

      let ids = [];
      let rows = this.sideMenuStore
        .getValue()
        .folioMenu.filter(
          (menuItem) =>
            menuItem.id == 'delete' ||
            menuItem.id == 'gift' ||
            menuItem.id == 'modifier' ||
            menuItem.id == 'note'
        );

      rows.map((o) => (o.disabled = folioRow == null));

      rows.find((o) => o.id == 'modifier').disabled = !hasModifiers;

      for (const iterator of rows) {
        ids.push(iterator.id);

        this.sideMenuStore.update(({ folioMenu }) => ({
          folioMenu: arrayUpdate(folioMenu, [iterator.id], iterator, 'id'),
        }));
      }
    });

    this.folioQuery.prepareDelete$.subscribe((o) => {
      if (o) {
        //let el = document.getElementById("folio-row-" + o.id)
        //if (el) el.classList.add("animate__slideOutLeft")
      }
    });

    this.folioQuery.newRow$.subscribe((o) => {
      if (o) {
        setTimeout(() => {
          let el = document.getElementById('folio-row-' + o.id);
          if (el) el.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    });
  }

  calculateRowTotal(item: FolioRow): number {
    let allItems = this.folioStore.getValue().activeFolio.rows;
    let total = 0;
    if (allItems.length > 0) {
      let modifiers = allItems.filter((elm) => elm.parentID == item.id);
      total = modifiers.reduce((acc, curr) => acc + curr.unitPrice, 0);
    }
    return (item.unitPrice + total) * item.qty;
  }

  ngAfterViewInit() {}

  getRows() {
    return [];
    // if (this.component == "payment") {
    //   return this.getRowsPaymentComponent()
    // } else {
    //   return this.getRowFolioComponent()
    // }
  }

  getRowsPaymentComponent() {
    return [];
    // if (this.segmentValuePayment == "all") {
    //   return this.fs.getRows(this.folio)
    // } else if (this.segmentValuePayment == "paidfull") {
    //   return this.fs.getPaidRows(this.folio)
    // } else {
    //   return this.fs.getUnpaidRows(this.folio)
    // }
  }

  getRowFolioComponent() {
    return [];
    // if (this.segmentValueFolio == "all") {
    //   return this.fs.getRows(this.folio)
    // } else if (this.segmentValueFolio == "old") {
    //   return this.fs.getOldRows(this.folio)
    // } else if (this.segmentValueFolio == "new") {
    //   return this.fs.getNewRows(this.folio)
    // }
  }

  getPaymentRows() {
    return this.folioQuery
      .getValue()
      .activeFolio.rows.filter(
        (x) =>
          (x.rowType === 'discount' || x.rowType === 'payment') &&
          x.recordStatus !== 'deleted'
      );
    //return this.folio.rows.filter(x=> x.rowType === 'discount' || x.rowType === 'payment' && x.recordStatus !== 'deleted')
  }

  select(item: FolioRow, index) {
    if (this.folioStore.getValue().selectedRow?.id == item.id) {
      this.folioStore.update({ selectedRow: null, activeFolioRow: null });
    } else {
      this.folioStore.update({ selectedRow: item, activeFolioRow: item });
    }
    this.selectedRowIndex = index;
  }

  segmentChangedFolio(e) {
    this.segmentValueFolio = e.detail.value;
    this.folioStore.update({ activeSegment: e.detail.value });
  }

  getColor(item: Folio) {
    item; //reserved
    // return this.fs.activeFolio.id == item.id ? "secondary" : "success"
  }

  trackBy(item, id) {
    id; //reserved
    if (!item) return null;
    return item.id;
  }
  async deletNote(e, item: FolioRow) {
    e.preventDefault();
    e.stopPropagation();

    const { role } = await this.askForDelete();
    if (role === 'cancel') {
      return;
    }
    const activeFolio: Folio = this.folioQuery.getValue().activeFolio;
    activeFolio.rows.map((fr) => {
      if (fr.id == item.id) {
        fr.recordStatus = 'deleted';
        fr.deleters.push({
          qty: 1,
          reason: 'note-delete',
          time: new Date(),
          userID: this.sessionQuery.userInfo.id,
          reasonID: 'note-delete',
          userName: this.sessionQuery.userInfo.name,
        });
      }
    });
    this.folioStore.update({ activeFolio });
    await this.folioService._posSaveFolio(activeFolio, 'low');
  }

  async askForDelete() {
    const alert = await this.alertController.create({
      //cssClass: 'my-custom-class',
      header: this.transloco.translate('Deleteing'),
      message: this.transloco.translate('Do you want delete this note?'),
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          role: 'yes',
          cssClass: 'secondary',
        },
        {
          text: this.transloco.translate('Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
        },
      ],
    });

    await alert.present();
    return await alert.onDidDismiss();
  }
}
