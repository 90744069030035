import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { ChangeCombineModalType } from 'src/app/modules/tables/model/table.model';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { Old_FolioService } from 'src/app/services/folio/folio.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';
import { StoreListModalComponent } from 'src/app/shared/components/store-list-modal/store-list-modal.component';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { TableChangeCombineComponent } from 'src/app/modules/tables/componets/table-change-combine/table-change-combine.component';
import { environment } from 'src/environments/environment';
import { OrwiStoreService } from 'src/app/modules/store/state/store.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { ModalService } from 'src/app/services/helpers/modal.service';

@Component({
  selector: 'phone-pos-page',
  templateUrl: './phone-pos-page.component.html',
  styleUrls: ['./phone-pos-page.component.scss'],
})
export class PhonePosPageComponent implements OnInit {

  serviceType$ = this.sideMenuQuery.serviceType$;
  selectedPosMenu$ = this.sideMenuQuery.posMenuSelected$;
  mainMenu = this.sideMenuQuery.posMenu$;
  $oldValue;
  version = ""

  constructor(
    private sessionService:SSOSessionService,
    private sideMenuQuery: SideMenuQuery,
    private fs: Old_FolioService,
    private ss: StoreService,
    private orwiStoreService: OrwiStoreService,
    private glb: GlobalService,
    private plt: Platform,
    private nav: NavController,
    public orwiStoreQuery: OrwiStoreQuery,
    private sessionQuery: SessionQuery,
    private modalService: ModalService,


  ) { }

  async ngOnInit() {

    this.plt.resize.subscribe(_o => {
      console.log("size",this.plt.width())
      if (this.plt.width() > 768) {
        //this.glb.navigateHomeForm()
      }
    })

    this.version = environment.appVersion
    if (!this.sessionQuery.user) {
      this.nav.navigateRoot('welcome');
      return;
    }

    if (this.sessionQuery.activeLicense) {
      if (!this.orwiStoreService.isInitialized) {
        await this.orwiStoreService.initialize(this.sessionQuery.activeLicense.orwiStore.id)
      }
    } else {
      this.modalService.selectLicense()
    }
   
  }






  async menuSelected(item) {
    if (item.id == 'lock') {
      //this.glb.screenLocked = true;
      //this.nav.navigateRoot(item.route);
    } else if (item.id == 'delivery') {
      // this.deliveryService();
    } else if (item.id == 'change') {
      this.openChangeModal(ChangeCombineModalType.Change);
    } else if (item.id == 'open-folios') {
      this.openTables();
    } else if (item.id == 'table-service') {
      //this.tableService();
    } else if (item.id == 'self-service') {
      this.selfServices();
    } else if (item.id == 'take-a-way') {
      this.deliveryService();
    } else if (item.id == 'web-order') {
      this.onlineOrders();
    } else if (item.id == 'combine') {
      this.openChangeModal(ChangeCombineModalType.Combine);
      //this.glb.combineTableVisible = true
    }
  }
  async openChangeModal(type: ChangeCombineModalType) {
    return await this.glb.openModal({
      component: TableChangeCombineComponent,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'change-combine-modal',
      componentProps: {
        modalType: type,
      },
    });
  }

  openTables() {
    //this.glb.menuActionType = 'Open Tables';
  }

  selfServices() {
    //this.glb.menuActionType = 'Selft Service';
    this.fs.folio = new Folio();
    this.fs.folio.type = 'self';
    //this.fs.folio.table.id = this.oe.activeTable.id
    //this.fs.folio.table.name = this.oe.activeTable.name
    this.fs.folio.storeId = this.ss?.store?.id;
    // this.nav.navigateForward('folio');
  }

  takeAway() {
    //this.glb.menuActionType = 'TakeAway Service';
    this.fs.folio = new Folio();
    this.fs.folio.type = 'take-away';
    //this.fs.folio.table.id = this.oe.activeTable.id
    //this.fs.folio.table.name = this.oe.activeTable.name
    this.fs.folio.storeId = this.ss?.store?.id;
    // this.nav.navigateForward('folio');
  }

  async openStoreModal(backdropDismiss = true) {
    await this.glb.openModal({
      component: StoreListModalComponent,
      backdropDismiss: backdropDismiss,
    });
  }

  onlineOrders() {
    //this.glb.menuActionType = 'Online Orders';
  }

  openChecks() {
    //this.glb.menuActionType = 'Open Checks';
  }

  deliveryService() {
    //this.glb.menuActionType = "Delivery Service"
    this.fs.folio = new Folio();
    this.fs.folio.type = 'delivery';
    //this.fs.folio.table.id = this.oe.activeTable.id
    //this.fs.folio.table.name = this.oe.activeTable.name
    this.fs.folio.storeId = this.ss?.store?.id;
    //this.nav.navigateForward("/delivery-address-form")
  }

  atableService() {
    //this.glb.menuActionType = 'Table Service';
  }

  logOut() {
    this.sessionService.logout();
  }

  list() {
    this.nav.navigateForward('folio-list');
  }
}
