import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { Folio } from 'src/app/services/dto/orwi-folio';

@Component({
  selector: 'folio-list',
  templateUrl: './folio-list.component.html',
  styleUrls: ['./folio-list.component.scss'],
})
export class FolioListComponent implements OnInit {
  @Output() onSelectedChange: EventEmitter<Folio> = new EventEmitter<Folio>();
  selected: Folio;
  constructor(public folioStore: FolioStore, public folioQuery: FolioQuery) {}

  ngOnInit() {}

  onSelect(e: Folio) {
    this.selected = e.id == this.selected?.id ? null : e;
    this.onSelectedChange.emit(this.selected);
  }
}
