import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {  ModifierCover, Product, ProductGroup } from 'src/app/services/dto/orwi-product';

export interface MenuState {
    products: Product[]
    groups: ProductGroup[]
    groupSub: ProductGroup[]
    productModifiers: ModifierCover[]
    groupModifiers: ModifierCover[]

    selectedProduct: ISelectedProduct
    selectedGroup: ProductGroup
    selectedSubGroup: ProductGroup

    menuLoaded: boolean

    activeProducts: Product[]

    subGroups: SubGroups[],
    allGroups: ProductGroup[],
}

export interface SubGroups {
    parentId: string,
    name: string,
    group: ProductGroup[]
}

export interface ISelectedProduct {
    time: any
    product: Product
}

export function createInitialState(): MenuState {
    return {
        products: [],
        groups: [],
        groupSub: [],
        productModifiers: [],
        groupModifiers: [],
        selectedProduct: null,
        selectedGroup: null,
        menuLoaded: false,
        activeProducts: [],
        subGroups: [],
        selectedSubGroup: null,
        allGroups: []
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'menu', resettable: true })
export class MenuStore extends Store<MenuState> {

    constructor() {
        super(createInitialState());
    }

}