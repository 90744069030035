<ion-header>
  <ion-toolbar>
    <div style="padding: 10px">
      <div>
        <ion-searchbar
          class="custom"
          mode="ios"
          (ionChange)="setFilter($event)"
          placeholder="{{ 'Search' | transloco }}"
        ></ion-searchbar>
      </div>
      <div
        style="padding: 10px; font-weight: bold; color: #171E4E;"
        *ngIf="selectedStore | async as store"
      >
        {{ "Selected Store" | transloco }}: {{ store?.name }}
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div>
    <ion-item lines="none" *ngIf="filteredStore.length == 0">
      {{ this.loadingText | transloco }}
    </ion-item>

    <ion-item
      button
      (click)="selectLicance(lasSelected)"
      *ngIf="lasSelected !== null"
    >
      <ion-label style="color: #184EAE;">
        {{ lasSelected.brand }}
      </ion-label>
      <ion-text> {{'Last Selected' | transloco}} </ion-text>
    </ion-item>

    <ion-item (click)="selectLicance(item)" *ngFor="let item of filteredStore">
      <ion-label style="color:#171E4E;">
        {{ item.brand }}
      </ion-label>
    </ion-item>
    <!-- 
        <ion-virtual-scroll [items]="filteredStore">
            <ion-item button (click)="selectStore(store)" *virtualItem="let store">
                {{ store.storeInfo.name }}
            </ion-item>
        </ion-virtual-scroll> -->
  </div>
</ion-content>
