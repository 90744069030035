import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { IonicModule } from '@ionic/angular';
import { KitchenDisplayRoutingModule } from './kitchen-display-routing.module';
import { KitchenDisplayPage } from './components/kitchen-display-main/kitchen-display.page';
import { CountOfSelectedRowsPipe } from './components/count-of-selected-rows.pipe';
import { KitchenItemComponent } from './components/kitchen-item/kitchen-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { KitchenDisplaySettingsComponent } from './components/kitchen-display-settings/kitchen-display-settings.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { ColorPickerModule } from 'ngx-color-picker';





@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    KitchenDisplayPage,
    CountOfSelectedRowsPipe,
    KitchenItemComponent,
    KitchenDisplaySettingsComponent,
    OrderDetailsComponent,
  ],
  imports: [
    ColorPickerModule,
    CommonModule,
    IonicModule,
    KitchenDisplayRoutingModule, 
    SharedModule,
  ],
  providers: [CountOfSelectedRowsPipe]
})
export class KitchenDispalyModule { }
