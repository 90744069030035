import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OtpRequestPage } from './components/otp-request/otp-request.page';
import { OtpVerifyPage } from './components/otp-verify/otp-verify.page';
import { SelectCountryPage } from './components/select-country/select-country.page';
import { WelcomePage } from './components/welcome/welcome.page';

const routes: Routes = [
  { path: '', component: WelcomePage },
  { path: 'login-home', component: OtpRequestPage },
  { path: 'select-country', component: SelectCountryPage },
  { path: 'otp-request', component: OtpRequestPage },
  { path: 'otp-verify', component: OtpVerifyPage },
  { path: 'welcome', component: WelcomePage },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SessionRoutingModule { }
