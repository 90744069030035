import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';

@Component({
  selector: 'orw-phone-input-list',
  templateUrl: './orw-phone-input-list.component.html',
  styleUrls: ['./orw-phone-input-list.component.scss'],
})
export class OrwPhoneInputListComponent {
  countries: any[] = [];
  isLoading: boolean = true;
  serach: UntypedFormControl = new UntypedFormControl();
  constructor(
    private countryCodesService: CountryCodesService,
    public popoverController: PopoverController
  ) {
    this.initCountries();
  }
  async initCountries() {
    this.countries = (await this.countryCodesService.getCountries()) as any[];
    this.isLoading = false;
  }

  select(e) {
    this.popoverController.dismiss(e);
  }
}
