import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AppUser, PosCountry, UserInfo, UserLicance } from './dto/sso-dto';


export interface SessionState {
    user: AppUser
    status: 'logged-in' | 'logged-out',
    startingRoute: string,
    token: string,
    activeCountry: PosCountry,
    activeLicense: UserLicance,

    userInfo: UserInfo
    authStatus: boolean,
}

export function createInitialState(): SessionState {
    return {
        user: null,
        status: "logged-out",
        startingRoute: '',
        token: '',
        activeCountry: undefined,
        activeLicense: undefined,
        userInfo: undefined,
        authStatus: false
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class SessionStore extends Store<SessionState> {

    constructor() {
        super(createInitialState());
    }

}