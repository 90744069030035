import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BrandsQuery } from 'src/app/modules/brands/state/brands.query';
import { IntegrationStore } from 'src/app/modules/integration/integration.store';
import { UserLicance } from 'src/app/modules/session/state/dto/sso-dto';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { StoreCover } from 'src/app/services/dto/orwi-store';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppsSyncService } from '../../service/food-apps-sync.service';
import { FoodAppAccount } from '../../state/dto';

@Component({
  selector: 'sync-store-list',
  templateUrl: './sync-store-list.component.html',
  styleUrls: ['./sync-store-list.component.scss'],
})
export class SyncStoreListComponent {
  stores: StoreCover[] = [];
  selectedAccounts: FoodAppAccount[] = [];
  filteredStore: UserLicance[] = [];
  loadingText: string = this.transloco.translate('Loading stores');
  selectedStore = this.orwiStoreQuery.store$;
  constructor(
    private glb: GlobalService,
    public foodAppSyncService: FoodAppsSyncService,
    public sessionQuery: SessionQuery,
    public integrationStore: IntegrationStore,
    private transloco: TranslocoService,
    private orwiStoreQuery: OrwiStoreQuery,
    private brandsQuery: BrandsQuery
  ) {}

  ngOnInit() {
    this.foodAppSyncService.getFoodAppIntegrations(
      this.sessionQuery.licances.map((n) => n.orwiStore.id)
    );
    this.filteredStore = this.sessionQuery.licances;
  }

  // selectStore(store: StoreCover) {
  // this.integrationStore.getValue().activeAccount.brand
  //   this.glb.closeModal();
  // }

  async selectAccount(e, item: FoodAppAccount) {
    console.log(e.detail.checked);
    if (e.detail.checked) {
      this.selectedAccounts.push(item);
    } else {
      this.selectedAccounts = this.selectedAccounts.filter(
        (x) => x.id !== item.id
      );
    }
  }

  close() {
    this.glb.closeModal([]);
  }

  save() {
    this.glb.closeModal(this.selectedAccounts);
  }

  getBrand(item) {
    return (
      this.brandsQuery.brands.find((o) => o.id == item.brand)?.name ||
      this.transloco.translate('Brand not defined')
    );
  }

  getStore(item) {
    return (
      this.sessionQuery.licances.find((l) => l.orwiStore.id == item.storeId)
        .orwiStore.name || this.transloco.translate('Store name not found')
    );
  }

  setFilter(e) {
    if (e.detail.value != '') {
      this.filteredStore = this.sessionQuery.licances.filter((o) =>
        Object.keys(o).some((_k) =>
          o.brand
            .toString()
            .toLowerCase()
            .includes(e.detail.value.toLowerCase())
        )
      );
      if (this.filteredStore.length == 0) {
        this.loadingText = this.transloco.translate('Store Not Found');
      }
    } else {
      this.filteredStore = this.sessionQuery.licances;
    }
  }
}
