import { HttpClientModule } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import {
  IonicModule,
  IonicRouteStrategy,
  IonRouterOutlet,
} from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { PhoneModule } from './modules/phone/phone.module';
import { PaymentRowsComponent } from './modules/folio/components/payment-rows/payment-rows.component';

import { WebServer } from '@awesome-cordova-plugins/web-server/ngx';
import { WebSocketServer } from '@awesome-cordova-plugins/web-socket-server/ngx';
import { SessionModule } from './modules/session/session.module';
import { FoodAppsIntegrationModule } from './modules/food-apps-integration/food-apps-integration.module';

import { SyncStoreListComponent } from './modules/food-apps-integration/components/sync-store-list/sync-store-list.component';
import { KitchenDispalyModule } from './modules/kitchen-display/order.module';
import { ComponentsModule } from './components/components.module';
import { EcrTransactionsComponent } from './components/ui/ecr-settings/ecr-transactions/ecr-transactions.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationToastrComponent } from './components/ui/notification-toastr/notification-toastr.component';

// import { GitReasonsComponent } from './modules/folio/components/git-reasons/git-reasons.component';
// url: 'http://service-test.orwi.app:50151',
@Injectable()
export class SocketOrwi extends Socket {
  constructor() {
    super({
      url: 'https://service-test.orwi.app:50151',
      options: {
        path: '/pos',
        reconnection: true,
        reconnectionAttempts: 10000,
        reconnectionDelayMax: 10000,
      },
    });
  }
}

@Injectable()
export class SocketKerzz extends Socket {
  constructor() {
    super({
      url: 'https://public.kerzz.com:50503',
      options: {
        reconnection: true,
        reconnectionAttempts: 10000,
        reconnectionDelayMax: 10000,
      },
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    PaymentRowsComponent,
    SyncStoreListComponent,
    EcrTransactionsComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    SocketIoModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    TranslocoRootModule,
    TranslocoModule,
    SharedModule,
    PhoneModule,
    HammerModule,
    SessionModule,
    //ReportsModule,
    //GeoTrackingModule,
    //CourierModule,
    //PosUsersModule,
    //ParametersModule,
    FoodAppsIntegrationModule,
    KitchenDispalyModule,
    ComponentsModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      toastComponent: NotificationToastrComponent,
    }), // ToastrModule added
  ],
  providers: [
    SocketOrwi,
    SocketKerzz,
    WebServer,
    WebSocketServer,
    {
      provide: IonRouterOutlet,
      useValue: {
        //add whatever property of IonRouterOutlet you're using in component class
        nativeEl: '',
      },
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
