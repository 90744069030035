<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button style="width: 233px!important;" (click)="refresh()">
        <p>{{ "Refresh" | transloco }}</p>
        <ion-icon slot="icon-only" name="sync-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <div class="header-center">
      <ion-title>{{ "Food App Logs" | transloco }}</ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-row id="toolbar"
  class="d-flex ion-align-items-center"
  style="margin-top: 20px; width: 100%; flex-wrap: nowrap"
  [formGroup]="dateFilterForm" >
    <div class="datetime-container">
      <ion-item id="date-time" lines="none" class="selector-container">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          "
        >
          <ion-icon
            style="font-size: large; color: #184eae"
            icon="calendar-outline"
          ></ion-icon>
          <ion-datetime-button
            datetime="dateTimeStartNewInventory"
          ></ion-datetime-button>
          <ion-modal [keepContentsMounted]="true" #startDateModal>
            <ng-template>
              <ion-datetime
                id="dateTimeStartNewInventory"
                displayFormat="DD/MM/YY"
                placeholder="01/10/21"
                formControlName="date"
                presentation="date"
                [max]="moment().utc().toISOString()"
                (ionChange)="startDateModal.dismiss();fillData()"
                [locale]="glb.userLocale"
              ></ion-datetime>
            </ng-template>
          </ion-modal>
        </div>
      </ion-item>
    </div>
  </ion-row>

  <ion-grid>
    <ion-row class="scroll-container">
      <dx-data-grid
        #dataGrid
        id="grid-container"
        [showRowLines]="false"
        [showColumnLines]="false"
        [dataSource]="foodAppsLogs"
        [showBorders]="false"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        columnResizingMode="nextColumn"
        (onExporting)="onExporting($event)"
        style="padding-bottom: 40px"
        noDataText="{{ 'No Data' | transloco }}"
        [height]="1500"
      >
      <dxo-export [enabled]="true" [allowExportSelectedData]="false"></dxo-export>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
        <!-- <dxo-scrolling mode="infinite"></dxo-scrolling> -->
        <dxo-editing
          [allowAdding]="false"
          [allowUpdating]="false"
          mode="cell"
        ></dxo-editing>
        <dxi-column
        dataField="source"
        caption="Kaynak"
        [allowEditing]="false"
      >
      </dxi-column>
        <dxi-column
          dataField="accountId"
          caption="Hesap"
          [allowEditing]="false">
          <dxo-lookup
            [dataSource]="foodAppAcoounts"
            displayExpr="description"
            valueExpr="id"
          ></dxo-lookup>
          <dxo-header-filter>
            <dxo-search [enabled]="true"></dxo-search>
          </dxo-header-filter>
        </dxi-column>
        <dxi-column
          dataField="sourceId"
          caption="Kaynak Sipariş No"
          [allowEditing]="false"
        >
        </dxi-column>
        <dxi-column
          dataField="orderId"
          caption="Sipariş no"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="createDate"
          caption="Oluşturma Tarihi"
          format="dd.MM.yyyy HH:mm:ss"
          dataType="date"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column dataField="status" caption="Durum" [width]="120" [allowEditing]="false">
        </dxi-column>
        <dxi-column
          dataField="isSendedTarget"
          caption="Gönderilme Durumu"
          [allowEditing]="false"
          [width]="80">
        </dxi-column>
        <!-- <dxi-column
        dataField="orderStatus"
        caption="Sip. Durum"
        [allowEditing]="false"
        [width]="100">
      </dxi-column> -->
        <dxi-column
          dataField="targetSendDate"
          caption="Gönderilme Tarihi"
          format="dd.MM.yyyy HH:mm:ss"
          dataType="date"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
        dataField="targetMessage"
        caption="Hedef Mesaj"       
        [allowEditing]="false"
      ></dxi-column>
        <dxi-column
        dataField="closedDate"
        caption="Kapanma Tarihi"
        format="dd.MM.yyyy HH:mm:ss"
        dataType="date"
        [allowEditing]="false"
      ></dxi-column>
        <dxo-search-panel
          [visible]="false"
          [highlightCaseSensitive]="true"
          placeholder="{{ 'Search' | transloco }}"
        ></dxo-search-panel>
        <dxo-paging [pageSize]="30"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
      </dx-data-grid>
    </ion-row>
  </ion-grid>
</ion-content>
