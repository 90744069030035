<div id="folio-coverc-folio" class="folio-row-cover">
  <div id="folio-content-{{ id }}" class="scroll-cover">
    <ion-list lines="none" *ngIf="folioQuery.mainRows().length > 0">
      <ion-item-sliding #slideItem *ngFor="
          let item of folioQuery.mainRows$() | async;
          let i = index;
          trackBy: trackBy
        ">
        <ion-item-options side="start">
          <ion-item-option (click)="setQty('decrease', item)">
            <ion-icon slot="icon-only" name="remove-outline"></ion-icon>
          </ion-item-option>
          <ion-item-option (click)="setQty('increase', item)">
            <ion-icon slot="icon-only" name="add-outline"></ion-icon>
          </ion-item-option>
          <ion-item-option (click)="slideItem.close(); applyGift(item, i)" color="primary">{{ "Gift" | transloco
            }}</ion-item-option>
          <ion-item-option (click)="slideItem.close(); applyModifiers(item, i)" color="secondary">
            {{ "Modifiers" | transloco }}
          </ion-item-option>
        </ion-item-options>

        <ion-item-options side="end">
          <ion-item-option (click)="slideItem.close(); delete(item, i)" color="primary">{{ "Delete" | transloco }}
          </ion-item-option>
          <ion-item-option (click)="slideItem.close(); applyNote(item, i)" color="secondary">
            {{ "Note" | transloco }}</ion-item-option>
        </ion-item-options>

        <ion-item>
          <div (click)="toggleSelect(item, i)" [ngStyle]="{
              backgroundColor: item.recordStatus === 'new' ? '#e0ffe0' : ''
            }" [ngClass]="{
              selected:
                (folioQuery.selectedRow$ | async)?.id === item.id &&
                i === selectedRowIndex,
              paid: item.isPaidFull
            }" class="folio-row">
            <ion-icon size="small" slot="end" *ngIf="component === 'payment' && !item.isPaidFull" name="ellipse">
            </ion-icon>

            <ion-icon size="small" slot="end" color="primary" *ngIf="component === 'payment' && item.isPaidFull"
              name="checkmark-done-circle"></ion-icon>

            <div id="row-{{ item.id }}" style="width: 100%; display: flex; justify-content: space-between">
              <div class="qty qty-price">
                {{ item.qty }}
              </div>

              <div style="width: 100%">
                <div class="item-row-cover">
                  <div class="product-name">
                    <ion-icon *ngIf="item.isGift" name="gift-outline"
                      style="margin-right: 5px; color: lime; font-size: 18px">
                    </ion-icon>
                    <ion-icon *ngIf="item.recordStatus === 'new'" name="checkmark-outline"></ion-icon>
                    <ion-icon *ngIf="item.recordStatus === 'old'" name="checkmark-done-outline"></ion-icon>
                    {{ item.name }}
                  </div>
                  <!-- <div class="product-price" >
                    {{ item.unitPrice * item.qty | orwiCurrency }}
                  </div> -->
                  <div class="product-price" *ngIf="item.price > 0 && !item.isGift">
                  {{ item.price  | orwiCurrency }}
                  </div>
                  <div class="product-price" *ngIf="item.isGift">
                    {{0 | orwiCurrency}}
                  </div>
                </div>
                <div style="
                    font-size: 10px;
                    text-align: right;
                    color: rgb(26, 25, 25);
                  ">
                  {{ item.creation | date: "HH:mm:ss" }} /
                  {{ item.updaters[0]?.userName }}
                </div>
                <div *ngTemplateOutlet="
                    tplModifier;
                    context: {
                      parentID: item.id,
                      isSelected:
                        (folioQuery.selectedRow$ | async)?.id === item.id &&
                        i === selectedRowIndex
                    }
                  "></div>
              </div>
            </div>
          </div>
        </ion-item>
      </ion-item-sliding>
    </ion-list>

    <ion-list lines="none" *ngIf="showPaymentRows" style="margin-top: 20px">
      <ion-item *ngFor="let item of this.getPaymentRows()">
        <div class="payment-row-cover">
          <div class="product-name">
            <ion-icon *ngIf="item.isGift" name="gift-outline" style="margin-right: 5px; color: lime; font-size: 18px">
            </ion-icon>
            {{ item.name }}
          </div>
          <div class="product-price">
            {{ item.unitPrice * item.qty | orwiCurrency }}
          </div>
        </div>
        <div *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"></div>
      </ion-item>
    </ion-list>
  </div>
  <!--    TOTAL ROW -->

  <div class="total-row-cover">
    <div class="folio-total-label">
      {{ "Total" }}
    </div>
    <div class="folio-total-price">
      {{ folioQuery.total | orwiCurrency }}
    </div>
  </div>
</div>

<!-- MODIFIER TEMPLATE -->
<ng-template #tplModifier let-parentId="parentID" let-isSelected="isSelected">
  <!-- <div *ngFor="let item of folioQuery.mustModifiers(parentId)">
    <div style="margin-left: 10px; font-size: 12px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          style="width: 100%; padding-left: 5px"
          [ngStyle]="{
            'text-decoration': item.isDetachableModifier
              ? 'line-through'
              : 'unset'
          }"
        >
          <ion-icon
            [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }"
            name="return-down-forward-outline"
          >
          </ion-icon
          >&nbsp;{{ item.name }}
        </div>
      </div>
      <div
        style="margin-left: 10px"
        *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"
      ></div>
    </div>
  </div> -->

  <div style="height: 5px" *ngIf="
      folioQuery.modifiers(parentId).length > 0 &&
      folioQuery.mustModifiers(parentId).length > 0
    "></div>

  <div *ngFor="let item of folioQuery.modifiers(parentId)">
    <div style="margin-left: 10px; font-size: 12px">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <div style="width: 100%; padding-left: 5px" [ngStyle]="{
            'text-decoration': item.isDetachableModifier
              ? 'line-through'
              : 'unset'
          }">
          <ion-icon [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }" name="return-down-forward-outline">
          </ion-icon>&nbsp;{{ item.name }}
        </div>
        <div *ngIf="item.price > 0 && !item.isGift">
          {{item.price | orwiCurrency}}
        </div>
        <div *ngIf="item.isGift">
          {{0 | orwiCurrency}}
        </div>
      </div>
      <div style="margin-left: 10px" *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"></div>
    </div>
  </div>

  <!-- flio note start -->
  <div *ngFor="let item of folioQuery.notes(parentId)" style="margin-top: 5px">
    <div style="margin-left: 10px; font-size: 12px">
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
        <div style="width: 100%; padding-left: 5px; font-weight: lighter">
          <ion-icon style="color: rgb(28, 167, 1); font-weight: 600" name="document-text-outline"></ion-icon>
          &nbsp;
          <span style="color: rgb(28, 167, 1); font-weight: 600">{{
            item.name
            }}</span>
        </div>
        <div class="ion-activatable ripple-parent" (click)="deletNote($event, item)">
          <ion-icon name="trash-outline" style="
              color: rgb(223, 50, 116);
              margin-right: 15px;
              transform: scale(1.5);
              cursor: pointer;
            "></ion-icon>
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </div>
    </div>
  </div>
  <!-- Folio note end-->
</ng-template>