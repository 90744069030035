<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ "Gift Reasons" | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <span class="material-icons"> close </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid id="delete-resasons" fixed style="overflow-y: auto">
    <ion-row>
      <ion-col size="12">
        <ion-list [formGroup]="form" lines="none">
          <ion-radio-group
            (ionChange)="change($event)"
            formControlName="reasonId"
          >
            <ion-item
              class="reason-item"
              *ngFor="let item of sQ.giftReasons$ | async"
              (click)="selectReason(item)"
              [ngClass]="{ selected: item.id == form.get('reasonId').value }"
            >
              <ion-radio [value]="item.id"></ion-radio>
              <ion-label>{{ item.name | titlecase }}</ion-label>
            </ion-item>
            <ion-item
              lines="none"
              [ngClass]="{ selected: 'other' == form.get('reasonId').value }"
            >
              <ion-radio [value]="'other'"></ion-radio>
              <ion-label> {{ "other" | transloco }} </ion-label>
              <ion-input
                class="custom-reason"
                *ngIf="form.get('reasonId').value == 'other'"
                [formControl]="reasonText"
                [placeholder]="'please define' | transloco"
              >
              </ion-input>
            </ion-item>
          </ion-radio-group>

          <ion-item
            *ngIf="form.touched && form.get('reasonId').hasError('required')"
          >
            <ion-text color="danger" style="padding: 10px 25px">
              <small>{{ "reason required" | transloco }}.</small>
            </ion-text>
          </ion-item>

          <ion-item
            *ngIf="
              form.get('reasonId').value == 'other' &&
              form.touched &&
              form.get('reasonName').hasError('required')
            "
          >
            <ion-text color="danger" style="padding: 10px 25px">
              <small>{{ "other reason required" | transloco }}.</small>
            </ion-text>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-item lines="none" class="">
      <div class="footer-btns">
        <ion-button class="cancel-btn" (click)="close()">
          {{ "cancel" | transloco }}</ion-button
        >
        <ion-button class="done-btn" (click)="save()">
          {{ "done" | transloco }}
        </ion-button>
      </div>
    </ion-item>
  </ion-toolbar>
</ion-footer>
