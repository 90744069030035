import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { EcrDepartment, TaxRate } from 'src/app/services/dto/orwi-definitions';
import { EcrService } from 'src/app/services/ecr/ecr.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'tax-definitions',
  templateUrl: './tax-definitions.component.html',
  styleUrls: ['./tax-definitions.component.scss'],
})
export class TaxDefinitionsComponent implements OnInit {
  taxList: TaxRate[] = [];
  departmentList: EcrDepartment[] = [];
  content: 'form' | 'list' = 'list';

  constructor(
    private ecrService: EcrService,
    private glb: GlobalService,
    public modalController: ModalController,
    private transloco: TranslocoService
  ) {
    this.getDepartments();
    this.getTaxes();
  }

  getDepartments() {
    this.ecrService.getErcDepartment().then((res) => {
      this.departmentList = res;
    });
  }

  canDelete(rate: number) {
    return this.departmentList.some((dp) => +dp.rate == +rate);
  }

  ngOnInit(): void {}

  taxForm = new UntypedFormGroup({
    rate: new UntypedFormControl(null, [
      Validators.required,
      sameRateValidators(this.taxList),
    ]),
    status: new UntypedFormControl(false, Validators.required),
  });

  getTaxes() {
    this.ecrService.getTaxDefinitions().then((res) => {
      console.log(res);
      this.taxList = res;
      this.taxForm.get('rate').addValidators(sameRateValidators(this.taxList));
    });
  }

  errorText = '';
  save() {
    this.taxForm.markAllAsTouched();
    console.log(this.taxList, this.taxForm);
    if (this.taxList.some((tax) => +tax.rate == +this.taxForm.value.rate)) {
      this.glb.toast(
        '',
        this.transloco.translate('same rate registration is available'),
        'middle',
        'warning'
      );
      return;
    }

    if (this.taxForm.valid) {
      this.taxForm.markAllAsTouched();

      if (this.taxForm.valid) {
        this.ecrService
          .saveTaxDefinition({
            ...this.taxForm.value,
          })
          .then(
            (o: any) => {
              o; //reserved
              this.glb.toast(
                '',
                this.transloco.translate('Successfully Added'),
                'bottom',
                'success'
              );
              this.getTaxes();
              this.modalController.dismiss();
              this.content = 'list';
            },
            (err) => {
              this.glb.toast(
                this.transloco.translate('An error occured'),
                err,
                'bottom',
                'danger'
              );
            }
          );
      }
    }
  }

  deleteTaxRate(e) {
    this.glb.openAlert({
      header: this.transloco.translate('Tax Definition Deletion'),
      subHeader: this.transloco.translate(
        'Are you sure you would like to delete?'
      ),
      backdropDismiss: false,
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            this.glb.showLoading();
            this.ecrService.deleteTaxDefinition(e.id).then(
              (o: any) => {
                o; //reserved
                this.glb.toast(
                  this.transloco.translate('Successfully Deleted!'),
                  '',
                  'bottom',
                  'success'
                );
                this.getTaxes();
                this.taxList = this.taxList.filter((item) => item.id !== e.id);
                this.glb.closeLoading();
              },
              (err) => {
                this.glb.toast(
                  this.transloco.translate('An error occured'),
                  err,
                  'bottom',
                  'danger'
                );
                this.glb.closeLoading();
              }
            );
          },
        },
        {
          text: this.transloco.translate('No'),
        },
      ],
    });
  }
  openForm() {
    this.content = 'form';
  }
}

export function sameRateValidators(taxList: TaxRate[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    console.log(control.value, taxList);
    return taxList.some((tax) => +tax.rate == +control.value)
      ? { isSameRate: true }
      : null;
  };
}
