<orwi-modal-header [title]="'Folio Select' | transloco" (onClose)="dismiss()"></orwi-modal-header>
<ion-content>
  <ion-list>
    <ion-item class="ion-activatable ripple-parent"
      *ngFor="let item of table.folios; let index = index; let isEven = even" (click)="onRowClick(item)">
      <ion-label> {{ "Folio" | transloco }} {{ index + 1 }}</ion-label>
      <ion-text>{{item | orwiFoliosTotalPrice | orwiCurrency}}</ion-text>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-item>
  </ion-list>
</ion-content>