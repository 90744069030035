<ion-content>
  <div>
    <div style="width: 100%">
      <div class="table-group-cover-modal">
        <orwi-table-group-button *ngFor="let item of tq.tableGroups$ | async" [ngClass]="{ hide: isShowTableGroup(item) }"
          class="button c-table-button-group"
          id="{{ item.id }}"
          (itemSelected)="tableGroupSelected($event)"
          [tableGroup]="item">
        </orwi-table-group-button>
      </div>
      <div id="table-cover" class="table-cover" style="flex-grow: 2">
        <table-button
          [ngClass]="{
            hide: item.state !== showType || isHide(item),
            selected: isSelected(item)}"
          class="c-table-button"
          (itemSelected)="tableSelected($event)"
          *ngFor="
            let item of tq.tables$
              | async
              | orwiArrayFilterLive
                : 'groupId'
                : (selectedTableGroup?.id ? selectedTableGroup?.id : '@~')
          "
          [table]="item"
          [active]="selectedTable?.id === item.id"
        >
        </table-button>
      </div>
    </div>
  </div>
</ion-content>
