import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { GlobalService } from '../global.service';
import { PublicApiService } from '../public-api/public-api.service';
import { SoketPushDeeplinkRoutingService } from './soket-push-deeplink-routing.service';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(
    private publicApiService: PublicApiService,
    private sessionQuery: SessionQuery,
    platform: Platform,
    private glb: GlobalService,
    private spdr: SoketPushDeeplinkRoutingService
  ) {
    this.sessionQuery.activeLicense$.subscribe((o) => {
      if (o && platform.is('capacitor')) this.initPush();
    });
  }

  instance() {
    //initialize constructor
  }

  initPush() {
    PushNotifications.requestPermissions().then(async (result) => {
      if (result.receive === 'granted') {
        try {
          await PushNotifications.register();

          PushNotifications.createChannel({
            description: 'Orwi Pos Channel General Notifications',
            id: 'fcm_default_channel',
            importance: 5,
            lights: true,
            name: 'Orwi Pos Channel',
            sound: 'new_order.wav',
            vibration: true,
            visibility: 1,
          })
            .then(() => {
              console.log('Orwi Pos Channel error push channel created: ');
            })
            .catch((error) => {
              console.error(
                'Orwi Pos Channel error push channel error: ',
                error
              );
            });

          PushNotifications.createChannel({
            description: 'Orwi Pos Channel Error Notifications',
            id: 'fcm_error_channel',
            importance: 5,
            lights: true,
            name: 'Orwi Pos Error Channel',
            sound: 'order_accept_error.wav',
            vibration: true,
            visibility: 1,
          })
            .then(() => {
              console.log('Orwi Pos Error Channel created: ');
            })
            .catch((error) => {
              console.error('Orwi Pos Error Channel channel error: ', error);
            });
        } catch (error) {
          console.log('error', error);
        }
      } else {
        this.glb.PushToken = 'push_permission_error';

        Device.getId().then((x) => {
          this.insert({
            id: x.identifier,
            userId: this.sessionQuery.user.id,
            userName: this.sessionQuery.user.name,
            licenseId: this.sessionQuery.activeLicense?.licanceId || '0',
            deviceId: x.identifier,
            orwiStore: this.sessionQuery.activeLicense?.orwiStore,
            role: this.sessionQuery.activeLicense?.roles,
            higerRole: this.sessionQuery.getHigerRole(),
            token: 'permission_error',
          });
        });
      }
    });

    Device.getId().then((x) => {
      PushNotifications.addListener('registration', (token: Token) => {
        this.insert({
          id: x.identifier,
          userId: this.sessionQuery.user.id,
          userName: this.sessionQuery.user.name,
          licenseId: this.sessionQuery.activeLicense?.licanceId || '0',
          deviceId: x.identifier,
          token: token.value,
          orwiStore: this.sessionQuery.activeLicense?.orwiStore,
          role: this.sessionQuery.activeLicense?.roles,
          higerRole: this.sessionQuery.getHigerRole(),
        });

        this.glb.PushToken = token.value;
      });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.parsePushData(notification);
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.parsePushData(notification);
      }
    );
  }

  parsePushData(notification) {
    let orwipush = JSON.parse(notification.data.orwi);
    if (orwipush.action == 'folio-received') {
      this.spdr.route({
        type: 'folio-received',
        pushData: orwipush.folioId,
        posClientMessage: undefined,
      });
    } else if (orwipush.actin == 'folio-closed') {
      this.spdr.route({
        type: 'folio-closed',
        pushData: orwipush.folioId,
        posClientMessage: undefined,
      });
    } else if (orwipush.actin == 'folio-changed') {
      //this.spdr.route({ type: "folio-changed", pushData: orwipush.folioId })
    }

    //actions: folio-received | folio-closed | folio-changed | user-point-changed | show-scratch
    //folio-recived, closed, changed: folioId
    //user-point-changed
    //new-scratch: coupon-id
    //new-coupon: coupon-id

    /*    if (this.glb.applicationReady) {
 
         let orwipush = JSON.parse(notification.data.orwi)
        
         if (orwipush.action == "folio-received") {
           this.glb.consolelog("*************************")
           this.glb.consolelog(orwipush)
           this.glb.consolelog("*************************")
 
           let se: SocketEvent = new SocketEvent("folio-received", orwipush.folioId)
           this.oe.socketEvents.next(se)
 
         } else if (orwipush.action == "folio-closed") {
 
           let se: SocketEvent = new SocketEvent("folio-closed", orwipush.folioId)
           this.oe.socketEvents.next(se)
 
         }
 
       } */

    /* if (this.userService.user) {
      if (notification.data.orwi) {
        let orwipush = JSON.parse(notification.data.orwi)
        if (orwipush.action == "qr-payment") {
          this.storeService.storeCheckIn(orwipush.folioId).then(o => {
            this.nav.navigateRoot(["/payment-preview", { id: "qrPayment" }])
          })
        } else {
          if (orwipush.url) {
            this.glb.orwiContent = orwipush
            this.nav.navigateForward("/browser")
          }
        }
      }
    } */
  }

  async insert(data) {
    await this.publicApiService.upsert({
      _db: 'orwi-pos',
      col: 'push-tokens',
      data: data,
    });
  }
}
