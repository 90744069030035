import { Component, OnInit, inject, signal } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { Customer } from 'src/app/services/customer/customer.model';
import { GlobalService } from 'src/app/services/global.service';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { AccountAddModalComponent } from '../account-add-modal/account-add-modal.component';

@Component({
  selector: 'account-list-modal',
  templateUrl: './account-list-modal.component.html',
  styleUrls: ['./account-list-modal.component.scss'],
})
export class AccountListModalComponent implements OnInit {
  publicApiService = inject(PublicApiService);
  sessionQuery = inject(SessionQuery);
  modalRef = inject(ModalController);
  globalService = inject(GlobalService);
  searchValue = signal(null);
  loaded = false;
  accounts: Customer[] = [];
  ngOnInit() {
    lastValueFrom(
      this.publicApiService.getCollection({
        col: 'customers',
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      })
    ).then((response: Customer[]) => {
      this.accounts = response;
      this.loaded = true;
    }, () => {
      this.loaded = true;
    });
  }

  select(customer: Customer) {
    this.modalRef.dismiss(customer);
  }

  close() {
    this.modalRef.dismiss();
  }

  async addNew() {
    const addModalRef = await this.globalService.openModal({
      component: AccountAddModalComponent,
    });

    const { data } = await addModalRef.onDidDismiss();
    if (data) {
      this.select(data);
    }
  }
}
