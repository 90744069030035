<ion-header>
    <div class="container-header">
        <div class="left-header">
            <ion-title class="ion-text-left">{{'Orwi POS' | transloco}}</ion-title>
        </div>
        <div class="right-header">
            <div></div>
        </div>
    </div>
</ion-header>

<ion-content>
    <div class="container">
        <div class="left">
            <img src="/assets/images/login.png" alt="">
            <div style="padding: 30px;">
                <ion-button>
                    <ion-icon style="color: #184EAE;" name="help-circle-sharp"></ion-icon>
                    <p style="color: #184EAE; margin-left: 10px;">{{'Help' | transloco}}</p>
                </ion-button>
            </div>
        </div>
        <div class="right">

            <div style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90%;
              margin: auto;
              flex-direction: column;
              height: 100%;
            ">
                <form #f="ngForm" (ngSubmit)="requestOtp()" class="login-form">
                    <div style="
                  padding: 10px 10px 100px;
                  text-align: left;
                  font-size: xx-large;
                ">
                        <span *ngIf="id === 'register' "> {{"Welcome" | transloco}} </span>
                        <span *ngIf="id === 'login' "> {{"Welcome" | transloco}} </span>
                    </div>
                    <div style="padding: 10px; font-size: 14px">
                        <b *ngIf="id === 'register' ">
                  {{"You need to enter a mobile phone, we will start the membership with the code that comes with a text message." | transloco}}
                  <!-- Cep telefonu girmen gerekiyor, kısa mesajla gelen kod ile üyeliğini başlatacağız. -->
                </b>
        
                        <b *ngIf="id === 'login' ">
                  {{"Please enter your mobile phone to send us your one-time password."
                  | transloco}}
                  <!-- Tek kullanımlık şifreni göndermemiz için Cep telefonunu gir lütfen. -->
                </b>
                    </div>
                    <div style="width: 100%; display: flex; justify-content: center">
                        <div style="
                    width: 100%;
                    display: flex;
                    padding: 10px;
                  ">
                            <div (click)="countryList()" class="ion-activatable ripple-parent" style="
                      display: flex;
                      align-items: center;
                      padding-right: 25px;
                      padding-left: 25px;
                      color: #171E4E;
                      /* margin-right: 10px; */
                      border-right: 1px solid #184EAE66;
                      border-radius: 12px 0px 0px 12px;
                      background: white;
                      /* font-size: 12px; */
                      justify-content: center;
                      width: 40%;
                    ">
                                <ion-icon style="margin-right: 10px;
                                color: #184EAE;" name="caret-down-outline"></ion-icon>
                                {{this.countryService.activeCountry.flagEmoji}} {{this.countryService.activeCountry.dialCode}}
                                <ion-ripple-effect></ion-ripple-effect>
                            </div>
                            <div style="width: 100%">
                                <input style="height: 100%;
                                margin: 0px;
                                border-radius: 0px 12px 12px 0px;
                                padding: 15px;
                                color: #171E4E;" required fixed type="tel" minlength="13" [(ngModel)]="gsm" name="gsm" placeholder="Enter Gsm" [spMask]="phoneMask01" [spKeepMask]="false" clearInput />
                            </div>
                        </div>
                    </div>
        
                    <div style="width: 97%; margin-top: 20px; margin:auto">
                        <ion-button style="--background: none;
                        border-color: #4CD9ED;
                        --box-shadow: none;
                        border-style: solid;
                        border-radius: 12px;" [disabled]="!f.valid" (click)="requestOtp()" expand="block">
                            <ion-spinner *ngIf="false" slot="end" name="bubbles"></ion-spinner>
                            {{"Keep Going" | transloco}}
                        </ion-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ion-content>