import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FolioRow } from 'src/app/services/dto/orwi-folio';
import {
  KitchenDisplayStore,
  KitchenDisplayState,
} from './kitchen-display.store';

@Injectable({ providedIn: 'root' })
export class KitchenDisplayQuery extends Query<KitchenDisplayState> {
  folioListData$ = this.select('folioListData');
  orderLogListData = this.select('orderLogListData');

  mainRows(folioId) {
    return this.store
      .getValue()
      .folioListData.find((f) => f.id == folioId)
      ?.rows?.filter(
        (r) => r.rowType == 'product'
      );
  }

  modifiers(folioId, parentId) {
    return this.store
      .getValue()
      .folioListData.find((f) => f.id == folioId)
      ?.rows?.filter((o) => o.parentID == parentId && o.rowType == 'modifier') ?? [];
  }

  mustModifiers(folioId, parentId) {
    return this.store
      .getValue()
      .folioListData.find((f) => f.id == folioId)
      ?.rows?.filter(
        (o) =>
          o.parentID == parentId && o.rowType == 'modifier' && o.isMustModifier
      ) ?? [];
  }

  notes(folioId, parentId) {
    const rows: FolioRow[] = this.store
      .getValue()
      .folioListData.find((f) => f.id == folioId)
      ?.rows?.filter((o) => o.parentID == parentId && o.itemID === 'note');
    return rows;
  }

  constructor(protected store: KitchenDisplayStore) {
    super(store);
  }
}
