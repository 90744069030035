import { Component, OnInit } from '@angular/core';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { SideMenuStore } from 'src/app/modules/side-menu/state/side-menu.store';
import { FolioQuery } from '../../state/folio.query';
import { FolioService } from '../../state/folio.service';
import { OpenFoliosQuery } from '../../state/open-folios/open-folios.query';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'orwi-open-folios',
  templateUrl: './open-folios.component.html',
  styleUrls: ['./open-folios.component.scss'],
})
export class OpenFoliosComponent implements OnInit {
  constructor(
    private ms: SideMenuStore,
    public ofq: OpenFoliosQuery,
    public tq: FolioQuery,
    public mq: SideMenuQuery,
    private fs: FolioService,
    private glb: GlobalService,
    private modalService: ModalService,
    private ssoService: SSOSessionService
  ) {}

  ngOnInit() {}

  newTransaction() {
    if(!this.ssoService.checkPermission('folio-open')){
      this.glb.permissionToast();
      return
    }
    let type = this.ms.getValue().servicetType;
    this.fs.createFolio(type, type, type);
    this.glb.navigateFolioForm();
  }

  trackBy(item, id) {
    id//reserved
    return item.id;
  }
  async onClickInfo(e, tableName: string, folio: Folio) {
    this.modalService.showTableInfoModal(e, tableName, folio);
  }
}
