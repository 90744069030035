import { GlobalService } from 'src/app/services/global.service';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideMenu } from 'src/app/modules/side-menu/state/side-menu.store';
import { SideMenuService } from 'src/app/modules/side-menu/state/side-menu.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { FolioService } from 'src/app/modules/folio/state/folio.service';

@Component({
  selector: 'orwi-cloud-menu',
  templateUrl: './cloud-menu.component.html',
  styleUrls: ['./cloud-menu.component.scss'],
})
export class CloudMenuComponent implements OnInit {
  @Input() menuType: 'pos' | 'folio' = 'pos';
  @Output() itemSelected = new EventEmitter<SideMenu>();
  @Input() absolute = "false"

  posMenu$ = this.sideMenuQuery.posMenu$;
  folioMenu$ = this.sideMenuQuery.folioMenu$;
  selectedPosMenu$ = this.sideMenuQuery.posMenuSelected$;
  selectedFolioMenu$ = this.sideMenuQuery.folioMenuSelected$;

  constructor(
    private sideMenuQuery: SideMenuQuery,
    public glb: GlobalService,
    private sideMenuService: SideMenuService,
    private folioStore: FolioStore,
    public ssoService: SSOSessionService,
    private folioService :FolioService
  ) { }

  selectMenuItem(item: SideMenu) {
    /* console.log(item);
    if (item.selectable) {
      //this.menuItems.map((o) => (o.selected = false));
      item.selected = true;
    }*/

    if (item.id === 'open-folios')
    {
      this.folioService.loadOpenFolios()
    }

    console.log(item);
    if(!this.ssoService.checkPermission('pos-service-type-take-away') && item.id === "take-away" && item.menuType === "pos"){
      this.glb.permissionToast();
      return
    }
    if(!this.ssoService.checkPermission('pos-service-type-delivery') && item.id === "delivery" && item.menuType === "pos"){
      this.glb.permissionToast();
      return
    }
    if(!this.ssoService.checkPermission('pos-service-type-self') && item.id === "self-service" && item.menuType === "pos"){
      this.glb.permissionToast();
      return
    }
    if(!this.ssoService.checkPermission('pos-service-type-table') && item.id ==="table-service" && item.menuType === "pos"){
      this.glb.permissionToast();
      return
    }
    // if(!this.ssoService.checkPermission('pos-service-types') &&
    //  (item.id ==="table-service" && item.menuType === "pos"
    //   || item.id === "self-service" && item.menuType === "pos"
    //   || item.id === "delivery" && item.menuType === "pos"
    //   || item.id === "take-away" && item.menuType === "pos"
    //  )){
    //   this.glb.permissionToast();
    //   return
    // }
    this.itemSelected.emit(item);
    //item.selected = true
    this.sideMenuService.selectMenuItem(item);
  }

  ngOnInit() { }

  isHaveNote(item: SideMenu): boolean {
    // console.log('activeFolio:', this.folioStore.getValue().activeFolio);

    if (this.folioStore.getValue().selectedRow) {
      return (
        this.folioStore.getValue().selectedRow.note !== undefined &&
        this.folioStore.getValue().selectedRow.note !== null &&
        this.folioStore.getValue().selectedRow.note !== '' &&
        item.title === 'Note'
      );
    }
    return (
      this.folioStore.getValue().activeFolio.note !== undefined &&
      this.folioStore.getValue().activeFolio.note !== null &&
      this.folioStore.getValue().activeFolio.note !== '' &&
      item.title === 'Note'
    );
  }
}
