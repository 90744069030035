import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardService } from '../session/state/guard.service';
import { PhoneFolioPageComponent } from './modules/phone-folio/components/phone-folio-page/phone-folio-page.component';


const routes: Routes = [
    {
        path: '', component: PhoneFolioPageComponent, canActivate: [GuardService]

    },

   


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PhoneRoutingModule { }
