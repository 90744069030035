import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PosParameters } from 'kerzz-base';

export interface ParametersState {
   key: string;
   posParameters: PosParameters
}

export function createInitialState(): ParametersState {
  return {
    key: '',
    posParameters : new PosParameters()
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'parameters' })
export class ParametersStore extends Store<ParametersState> {

  constructor() {
    super(createInitialState());
  }

}
