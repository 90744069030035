import { Component } from '@angular/core';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { GlobalService } from 'src/app/services/global.service';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { FoodAppsIntegrationService } from '../../state/food-apps-integration.service';
import { FoodAppsIntegrationQuery } from '../../state/food-apps-integration.query';
import {
  FoodAppAccount,
  FoodAppModifier,
  RestoranModifier,
} from '../../state/dto';
import { Brand } from 'kerzz-base';

@Component({
  selector: 'bulk-modifier-sync',
  templateUrl: 'bulk-modifier-sync.component.html',
  styleUrls: ['bulk-modifier-sync.component.scss'],
})
export class BulkModifierSyncComponent {
  foodAppAcoounts: FoodAppAccount[] = [];
  brands: Brand[] = [];
  foodAppModifier: FoodAppModifier[] = [];
  internalModifiers: RestoranModifier[] = [];
  constructor(
    private publicApiService: PublicApiService,
    private sessionQuery: SessionQuery,
    private glb: GlobalService,
    private integrationService: FoodAppsIntegrationService,
    private integrationQuery: FoodAppsIntegrationQuery
  ) {
    this.fetchModifiers()
    this.integrationQuery.restaurantMenu$.subscribe((o) => {
      if (o) {
        this.internalModifiers = o.modifiers;
      }
    });
  }

  async getIntenalMenu(accountId) {
    try {
      await this.integrationService.getRestaurantMenu(accountId);
    } catch (error) {
      console.log('error');
      this.glb.closeLoading();
      return;
    }
  }

  onRowUpdated(e)
  {
    console.log('onRowUpdated',e)
  }

  fetchModifiers() {
    this.glb.showLoading();
    try {
      this.publicApiService
        .getCollection({
          _db: 'food-apps-service',
          col: 'accounts',
          _filter: {
            cloudId: this.sessionQuery.activeLicense.orwiStore.cloudId,
          },
        })
        .subscribe(async (acc: FoodAppAccount[]) => {
          console.log('acc', acc);
          this.foodAppAcoounts = acc;
          await this.getIntenalMenu(this.foodAppAcoounts[0].id);
          this.publicApiService
            .getCollection({
              _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
              col: 'brands',
            })
            .subscribe((brand: Brand[]) => {
              console.log('brand', brand);
              this.brands = brand;
              this.publicApiService
                .getCollection({
                  _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
                  col: 'food-apps-modifiers',
                })
                .subscribe((mod: iFoodAppModifier[]) => {
                  mod.map((p) => {
                    acc.map((a) => {
                      if (a.id === p.accountId) {
                        p.brand = a.brand;
                      }
                    });
                  });
                  console.log('foodAppProducts', mod);
                  this.foodAppModifier = mod;
                  this.glb.closeAllLoading();
                });
            });
        });
    } catch (error) {
      console.error('Bulk FoodApp Products', error);
      this.glb.closeAllLoading();
    }
  }
}

export interface iFoodAppModifier extends FoodAppModifier {
  brand?: string;
}
