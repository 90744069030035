import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Reasons, Printer } from 'src/app/services/dto/orwi-definitions';
import { OrwiStore } from 'src/app/services/dto/orwi-store';
import { OrwiStoreState, OrwiStoreStore } from './store.store';

@Injectable({ providedIn: 'root' })
export class OrwiStoreQuery extends Query<OrwiStoreState> {
  payTypes$ = this.select('payTypes');

  payTypesCash = this.select((o) =>
    o.payTypes.filter((c) => c.payType == 'cash')
  );
  payTypesMealVoucher = this.select((o) =>
    o.payTypes.filter((c) => c.payType == 'meal-voucher')
  );
  payTypesBank = this.select((o) =>
    o.payTypes.filter((c) => c.payType == 'bank')
  );
  payTypesBankTransfer = this.select((o) =>
    o.payTypes.filter((c) => c.payType == 'bank-transfer')
  );
  payTypesExpenseSlip = this.select((o) =>
    o.payTypes.filter((c) => c.payType == 'expense-slip')
  );
  payTypesAccount = this.select((o) =>
    o.payTypes.filter((c) => c.payType == 'account')
  );
  store$ = this.select('store');
  ownedStores$ = this.select('ownedStores');
  printers$ = this.select('printers');
  folioPrinters: Printer[] = [];
  unitPrinters: Printer[] = [];
  deleteReasons$ = this.select('deleteReasons');
  giftReasons$ = this.select('giftReasons');
  deleteReasons: Reasons[];
  giftReasons: Reasons[];
  _orwiStore: OrwiStore = undefined;
  activeStore$ = this.select('activeStore')
  storeInitialized$ = this.select('storeInitialized')

  constructor(protected store: OrwiStoreStore) {
    super(store);

    // id: null,
    // store: null,
    // payTypes: null,
    // ownedStores: [],

    // this.select("id").subscribe(o => {
    //     localforage.setItem("store-id",o)
    // })

    // this.select("store").subscribe(o => {
    //     localforage.setItem("store",o)
    // })

    // this.select("payTypes").subscribe(o => {
    //     localforage.setItem("payTypes",o)
    // })

    // this.select("ownedStores").subscribe(o => {
    //     localforage.setItem("ownedStores",o)
    // })

    this.printers$.subscribe((o) => {
      this.folioPrinters = o.filter((p) => p.printerType == 'folio');
      this.unitPrinters = o.filter((p) => p.printerType == 'unit');
    });

    this.select('deleteReasons').subscribe((o) => {
      this.deleteReasons = o;
    });

    this.select('store').subscribe((o) => {
      this._orwiStore = o;
    });
  }

  get id() {
    return this.select('id');
  }
}
