<button
  *ngIf="options.closeButton"
  (click)="remove()"
  type="button"
  class="toast-close-button"
  aria-label="Close"
>
  <span aria-hidden="true">&times;</span>
</button>
<div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
  {{ title }}
  <ng-container *ngIf="duplicatesCount"
    >[{{ duplicatesCount + 1 }}]</ng-container
  >
</div>
<div
  *ngIf="message && options.enableHtml"
  role="alert"
  [class]="options.messageClass"
  [innerHTML]="message"
></div>
<div
  *ngIf="message && !options.enableHtml"
  role="alert"
  [class]="options.messageClass"
  [attr.aria-label]="message"
>
  {{ message }}
</div>
<ion-button
  expand="block"
  fill="clear"
  size="small"
  color="light"
  (click)="action(options.payload)"
>
  {{ "Detail" | transloco }}
</ion-button>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width() + '%'"></div>
</div>
