<ion-header>
  <ion-toolbar>
    <div style="padding: 10px">
      <div>
        <ion-searchbar
          class="custom"
          (ionChange)="setFilter($event)"
        ></ion-searchbar>
      </div>
      <div
        style="padding: 10px; font-weight: bold; color: #171E4E"
        *ngIf="selectedStore | async as store"
      >
        {{ "Selected Store" | transloco }}: {{ store?.name }}
      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div>
    <ion-item lines="none" *ngIf="filteredStore.length == 0">
      {{ this.loadingText | transloco }}
    </ion-item>

    <!-- <div *ngFor="let item of filteredStore">          
              <div class="ion-padding"  slot="content" *ngFor="let item of foodAppSyncService.foodAppAccount">
               <ion-item  (click)="selectAccount(item)"> {{getStore(item)}}  {{item.foodApp}} ({{getBrand(item)}})</ion-item> 
              </div>
         
          </div> -->

    <!-- </ion-accordion-group> -->
    <div *ngFor="let item of foodAppSyncService.foodAppAccount">
      <ion-item>
        <ion-checkbox
          (ionChange)="selectAccount($event, item)"
          slot="start"
        ></ion-checkbox>
        <ion-label>
          {{ getStore(item) }} {{ item.foodApp }} ({{ getBrand(item) }})
        </ion-label>
      </ion-item>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-item lines="none" class="">
      <div
        style="
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 10px;
          width: 100%;
          gap: 10px;
        "
      >
        <ion-button (click)="close()" style="--background: #ED4C4C">
          {{ "cancel" | transloco }}</ion-button
        >
        <ion-button (click)="save()" style="--background: #4CD9ED">
          {{ "done" | transloco }}
        </ion-button>
      </div>
    </ion-item>
  </ion-toolbar>
</ion-footer>
