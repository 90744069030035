import { registerPlugin } from '@capacitor/core';

import type { HuginProtocolPlugin } from './definitions';

const HuginProtocol = registerPlugin<HuginProtocolPlugin>('HuginProtocol', {
  web: () => import('./web').then(m => new m.HuginProtocolWeb()),
});

export * from './definitions';
export { HuginProtocol };
