import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SessionRoutingModule } from './session-routing.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { LoginHomePage } from './components/login-home/login-home.page';
import { OtpRequestPage } from './components/otp-request/otp-request.page';
import { OtpVerifyPage } from './components/otp-verify/otp-verify.page';
import { SelectCountryPage } from './components/select-country/select-country.page';
import { WelcomePage } from './components/welcome/welcome.page';
import { TranslocoModule } from '@ngneat/transloco';
import { ComponentsModule } from 'src/app/components/components.module';
import { FoodAppAccountPipe } from '../food-apps-integration/pipes/food-apps-account.pipe';



@NgModule({
  declarations: [
    LoginHomePage,
    OtpRequestPage,
    OtpVerifyPage,
    SelectCountryPage,
    WelcomePage,
    FoodAppAccountPipe,
    

  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslocoModule,
    SessionRoutingModule,
    ComponentsModule,
   
  ],

  exports : [
    FoodAppAccountPipe,
  ]
})
export class SessionModule { }
