import { Injectable } from '@angular/core';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { SessionQuery } from '../../session/state/session.query';
import { BrandsStore } from './brands.store';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { Brand } from 'kerzz-base';

@Injectable({ providedIn: 'root' })
export class BrandsService {


  constructor(
    private publicApi: PublicApiService,
    private sessionQuery: SessionQuery,
    private brandsStore: BrandsStore,
    private orwiService : OrwiService,
    ) {
      this.getBrands()
  }

  getBrands(): Promise<Brand[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise('/api/pos/brand/getBrands',
          {id : this.sessionQuery.activeLicense?.orwiStore?.id},
         this.sessionQuery.token
        )
        .then((o: any) => {
          this.brandsStore.update({ brands: o.response });
        resolve(o.response)
        })
        .catch((e: any) => {
          reject(e);
        });
    });


  }


  async saveBrand(item: Brand) {
    await this.publicApi.upsert({
      _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      col: "brands",
      data: item
    })

    await this.getBrands()
  }

  async delete(item: Brand) {
    await this.publicApi.delete({
      _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      col: "brands",
      filter: { id: item.id }
    })
    await this.getBrands()
  }

}
