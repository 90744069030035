import { Injectable } from '@angular/core';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { Platform } from '@ionic/angular';
import { OrwiCourierGeoLocation, UserLocation } from './dto';
import { SessionQuery } from '../../session/state/session.query';
import { GeoTrackingStore } from './geo-tracking.store';
import { AppService } from 'src/app/services/utils/app.service';
import { Geolocation } from '@capacitor/geolocation';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { GlobalService } from 'src/app/services/global.service';
import { ParametersQuery } from '../../parameters/state/parameters.query';
import BackgroundGeolocation, {
  Subscription,
} from '@transistorsoft/capacitor-background-geolocation';
import { Device } from '@capacitor/device';

@Injectable({ providedIn: 'root' })
export class GeoTrackingService {
  watcherId;
  ready: boolean = false;
  enabled: boolean = false;
  events: any = [];
  subscriptions: Subscription[] = [];

  constructor(
    private parametersQuery: ParametersQuery,
    private globalService: GlobalService,
    private ig: IdGeneratorService,
    private appService: AppService,
    private geoTrackingStore: GeoTrackingStore,
    private publicApi: PublicApiService,
    private platform: Platform,
    private sessionQuery: SessionQuery
  ) {
    this.sessionQuery.activeLicense$.subscribe((al) => {
      if (al) {
        this.checkGeoLocation();
      }
    });

    this.appService.stateChanged$.subscribe((o) => {
      if (o && platform.is('capacitor')) {
        this.checkGeoLocation();
      }
    });
  }

  init() {
    console.log('geo-init');
    this.sessionQuery.activeLicense$.subscribe((o) => {
      if (o) {
        if (this.platform.is('capacitor')) {
          this.getUserLocationCapacitor();
        } else {
          this.getUserLocationWeb();
        }
      }
    });
  }

  getOs() {
    if (this.platform.is('capacitor')) {
      if (this.platform.is('ios')) {
        return 'ios';
      } else {
        return 'android';
      }
    } else {
      return 'web';
    }
  }

  async checkGeoLocation() {
    return
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.model == 'N86') return;

    let ocg: OrwiCourierGeoLocation = {
      id: this.ig.generateMaxi(),
      activityType: 'still',
      battery: 0,
      deviceOS: this.getOs(),
      deviceUUID: this.globalService.DeviceUUID,
      editDate: new Date(),
      heading: 0,
      isMoving: false,
      lat: 0,
      lng: 0,
      mock: false,
      speed: 0,
      userId: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      uuidd: this.ig.generateMaxi(),
      event: 'motionchange',
      provider: undefined,
      granted: true,
    };

    Geolocation.checkPermissions().then(
      async (o) => {
        o; //reserved
        this.geoTrackingStore.update({ status: true });
        let position = await Geolocation.getCurrentPosition();
        ocg.lat = position.coords.latitude;
        ocg.lng = position.coords.longitude;
        ocg.granted = true;

        this.publicApi.upsert({
          _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
          col: 'geo-locations-live',
          data: ocg,
        });
      },
      (e) => {
        e; //reserved
        ocg.granted = false;
        this.geoTrackingStore.update({ status: false });
      }
    );
  }

  getUserLocationWeb() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        this.geoTrackingStore.update({
          currentLocation: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    } else {
      console.log('User not allow');

      let ul: UserLocation = {
        id: this.sessionQuery.user.id,
        name: this.sessionQuery.user.name,
        location: undefined,
        error: 'User not allow geo location',
        source: 'geo-location-web',
      };
      this.publicApi.upsert({
        _db: 'tolga-test',
        col: 'geo-test-2',
        data: ul,
      });
    }
  }

  async getUserLocationCapacitor() {
    return
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.model == 'N86') return;

    // /// Step 1:  Subscribe to BackgroundGeolocation events.

    // this.subscriptions.push(BackgroundGeolocation.onLocation((location) => {

    //   console.log("oks-location", location)
    //   this.geoTrackingStore.update({ status: true })

    //   let ul: UserLocation = {
    //     id: this.sessionQuery.user.id,
    //     name: this.sessionQuery.user.name,
    //     location: { lat: location.coords.latitude, lng: location.coords.longitude },
    //     error: '',
    //     source: "background-geo-location",
    //   }

    //   console.log("oks-location-post", ul.location.lat, ul.location.lng)

    //   this.geoTrackingStore.update({ currentLocation: { lat: ul.location.lat, lng: ul.location.lng } })
    //   this.publicApi.upsertNative({ _db: "tolga-test", col: "geo-test-2", data: ul })

    // }))

    // this.subscriptions.push(BackgroundGeolocation.onMotionChange((event) => {
    //   console.log("oks-onMotionChange", event)

    // }))

    // this.subscriptions.push(BackgroundGeolocation.onHttp((event) => {
    //   console.log("oks-onHttp", event)
    // }))

    // this.subscriptions.push(BackgroundGeolocation.onActivityChange((event) => {
    //   console.log("oks-onActivityChange", event)
    // }))

    // this.subscriptions.push(BackgroundGeolocation.onProviderChange((event) => {
    //   console.log("oks-onProviderChange", event)
    // }))

    // console.log("oks-start")
    // /// Step 2:  Ready the plugin.

    let locationTemplate = '{"lat": <%= latitude %>, ';
    locationTemplate += '"lng" : <%= longitude %>,';
    locationTemplate += '"activityType": "<%= activity.type %>",';
    locationTemplate += '"event" : "<%= event %>",';
    locationTemplate += '"heading" : <%= heading %>,';
    locationTemplate += '"speed" : <%= speed %>,';
    locationTemplate += '"isMoving" : <%= is_moving %>,';
    locationTemplate += '"battery" : <%= battery.level %>,';
    locationTemplate += '"uuidd": <%= uuid %>';
    locationTemplate += '}';

    if (this.platform.is('ios')) {
      locationTemplate = `{
      "lat": <%= latitude %>, 
      "lng" : <%= longitude %>,
      "activityType": "<%= activity.type %>",
      "event" : "<%= event %>",
      "heading" : <%= heading %>,
      "speed" : <%= speed %>,
      "isMoving" : <%= is_moving %>,
      "battery" : <%= battery.level %>
      }`;
    }

    let userId = localStorage.getItem('userId');
    let userName = localStorage.getItem('userName');
    let cloudId = localStorage.getItem('cloudId');
    let _detailedGeoTracking =
      localStorage.getItem('detailedGeoTracking') == 'false' ? false : true;

    console.log('detailedGeoTracking', _detailedGeoTracking);

    let _params = {
      job: 'upsert',
      database: cloudId,
      collection: 'geo-locations-live',
      filter: { id: this.ig.generateMaxi() },
    };

    let _extras = {
      deviceUUID: this.globalService.DeviceUUID,
      deviceOS: this.platform.is('android') ? 'android' : 'ios',
      userId: userId,
      userName: userName,
      id: this.ig.generateMaxi(),
      granted: true,
    };

    if (!_detailedGeoTracking) {
      console.log('_detailed-not');
      _extras.id = this.sessionQuery.user.id;
      _params.filter = { id: this.sessionQuery.user.id };
      _params.collection = 'geo-locations';
    } else {
      console.log('_detailed-yep');
    }

    BackgroundGeolocation.ready({
      url: this.publicApi.postMongoUrl,
      headers: this.publicApi.getHeadersJson(),
      autoSync: true,
      method: 'POST',
      autoSyncThreshold: 0,
      batchSync: false,
      maxBatchSize: 50,
      httpRootProperty: 'data',
      extras: _extras,
      locationTemplate: locationTemplate,
      params: _params,
      // Geolocation Config
      desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      distanceFilter: this.parametersQuery.getValue().posParameters.locationPrecision,
      // Activity Recognition
      stopTimeout: 5,
      // Application config
      debug: false, // <-- enable this hear sounds for background-geolocation life-cycle.
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
      stopOnTerminate: false, // <-- Allow the background-service to continue tracking when user closes the app.
      startOnBoot: true, // <-- Auto start tracking when device is powered-up.
    }).then(async (state) => {
      // BackgroundGeolocation is now ready to use.
      this.ready = true;
      this.enabled = state.enabled;
      await BackgroundGeolocation.start();
    });
  }

  getAllUsers() {
    return this.publicApi
      .getCollection({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: 'geo-locations',
        _filter: {},
      })
      .toPromise();
  }

  getUserLastPosition(userId: string): Promise<OrwiCourierGeoLocation[]> {
    return new Promise((resolve, reject) => {
      this.publicApi
        .getCollection({
          _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
          col: 'geo-locations-live',
          _filter: { userId: userId },
          _sort: { _id: -1 },
          _limit: 1,
        })
        .subscribe(
          (o) => {
            resolve(o);
          },
          (e) => {
            console.log('getUserLastPosition', e);
            reject(e);
          }
        );
    });
  }
}
