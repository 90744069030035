import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhonePosRoutingModule } from './phone-pos-routing.module';
import { PhonePosPageComponent } from './phone-pos/components/phone-pos-page/phone-pos-page.component';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from 'src/app/components/components.module';
import { DeliveryModule } from 'src/app/modules/delivery/delivery.module';


@NgModule({
  declarations: [
    PhonePosPageComponent,
  ],
  imports: [
    ComponentsModule,
    CommonModule,
    IonicModule,
    PhonePosRoutingModule,
    DeliveryModule,
  ]
})
export class PhonePosModule { }
