<ion-card
  class="container"
>
  <!-- [class]="orderStatusStyle(item) == 'primary' ? 'new-order' : ''" -->

  <ion-card-header style="min-height: 21%" class="ion-no-padding">
    <div
      style="
        padding: 0;
        display: flex;
        font-size: 14px;
        color: white;
        max-height: 88px;
        min-height: 100%;
      "
    >
      <div
        [class]="'bg-' + item.appName"
        class="d-flex"
        style="gap: 10px; padding: 5px 10px; align-items: center; width: 100%"
      >
        <div>
          <img
            style="height: 32px; border-radius: 50%"
            [title]="item.appName"
            [alt]="item.appName"
            [src]="foodAppQuery.getIcon(item.appName)"
          />
        </div>
        <div>
          <div class="product-name" [title]="item.table.name">
            {{ item.table.name }}
          </div>
          <div style="font-size: smaller">
            {{ calcOrderTime(item) }} {{ "min" | transloco }}.
          </div>
        </div>
        <div style="text-align: center">
          <h5
            *ngIf="this.folioSettings.value.orderNumber"
            style="text-align: center; margin-left: auto"
          >
            # {{ item.sequenceNo }}
          </h5>
          <h5
            *ngIf="this.folioSettings.value.waiter"
            style="text-align: center; margin-left: auto"
          >
            <ion-icon name="person"></ion-icon> {{ item.creator.userName }}
          </h5>
          <div
            *ngIf="this.folioSettings.value.table"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
            "
          >
            <span class="material-icons"> table_restaurant </span>
            <h5 style="text-align: center">
              {{ item.table.name }}
            </h5>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="d-flex" style="margin-left: auto">
        <ion-button
          (click)="presentPopover($event)"
          expand="full"
          style="
            margin: 0px;
            padding: 0px;
            height: 100%;
            opacity: 1;
            --background: #184eae;
          "
          size="small"
        >
          <ion-icon name="information-circle"></ion-icon>
        </ion-button>
        <ion-popover
          #popover
          [isOpen]="popoverToggle"
          side="left"
          (didDismiss)="popoverToggle = false"
          [dismissOnSelect]="true"
          [showBackdrop]="false"
        >
          <ng-template>
            <ion-list>
              <ion-item
                [button]="true"
                [detail]="false"
                *ngIf="checkWaiting(item)"
                (click)="cancelOrder(item); popoverToggle = false"
                [disabled]="sessionQuery.getHigerRole().level <= 20"
              >
                {{ "Cancel Order" | transloco }}
                <ion-icon slot="end" name="close-circle-outline"></ion-icon>
              </ion-item>
              <ion-item (click)="print()" [button]="true" [detail]="false">
                {{ "Print Order" | transloco }}
                <ion-icon slot="end" name="print-outline"></ion-icon>
              </ion-item>
              <ion-item [button]="true" [detail]="false" (click)="openNote()">
                {{ "Open Note" | transloco }}
                <ion-icon slot="end" name="document-text-outline"></ion-icon>
              </ion-item>
            </ion-list>
          </ng-template>
        </ion-popover>
        <ion-button
          expand="full"
          style="margin: 0px; padding: 0px; height: 100%; --background: #4cd9ed"
          [disabled]="(item | countOfSelectedRows).length === 0"
          (click)="startOrder(item)"
          size="small"
        >
          {{ selectedProductsType | transloco }}
        </ion-button>
      </div>
      <!--  -->
    </div>
  </ion-card-header>
  <ion-card style="margin: 0; min-height: 190px; flex: 1; overflow-y: auto; border-radius: 0;">
    <ion-card-content class="ion-no-padding">
      <ion-accordion-group
        [value]="['waiting', 'prepering', 'prepered', 'canceled']"
      >
        <ion-accordion
          *ngFor="let formattedItem of formattedRows"
          [value]="formattedItem.name"
          [disabled]="formattedItem.rows.length === 0"
          [ngStyle]="{ display: formattedItem.rows.length === 0 ? 'none' : '' }"
        >
          <ion-item [ngStyle]="{ '--background': orderStatusStyle(item)}" slot="header">
            <ion-label style="display: flex; justify-content: space-between">
              <span>
                {{ formattedItem.name | transloco }}
              </span>
              <span>
                ({{ formattedItem.rows.length }} {{ "pc" | transloco }})
              </span>
            </ion-label>
          </ion-item>
          <div slot="content" style="padding: 10px">
            <div
              [ngClass]="{ deleted: row.recordStatus === 'deleted' }"
              (click)="
                row.recordStatus !== 'deleted'
                  ? (checkBox.checked = !checkBox.checked)
                  : ''
              "
              *ngFor="let row of formattedItem.rows"
            >
            <span *ngIf="moment(this.item.scheduler).isBefore(moment(row.creation))">-- {{ 'Additional Order' | transloco}} --</span>
            <div 
            class="row-item">
              <div class="product-qty">
                <ion-checkbox
                  (ionChange)="checkItem(checkBox, row, item)"
                  [disabled]="
                    row.customStatus === 'prepered' ||
                    row.recordStatus === 'deleted'
                  "
                  [checked]="row.customChecked"
                  #checkBox
                ></ion-checkbox>
              </div>
              <div style="display: flex; align-items: center">
                <div class="qty-box">{{ row.qty }}</div>
                <div class="productName">
                  <div class="product-name" [title]="row.name">
                    <span class="name">{{ row.name }}</span>
                    <span
                      class="deleted-text"
                      *ngIf="row.recordStatus === 'deleted'"
                      > **{{ "Cancelled" | transloco }}**</span
                    >
                  </div>
                  <div *ngIf="row.customStatus !== 'waiting'">
                    ({{ calcDiffTime(row.customCreated) }}
                    {{ "min" | transloco }})
                  </div>
                  <div>
                    <div
                      *ngTemplateOutlet="
                        tplModifier;
                        context: {
                          folioID: item.id,
                          parentID: row.id,
                          isModifier: row.isModifier
                        }
                      "
                    ></div>
                  </div>
                </div>
              </div>

            </div>
            </div>
          </div>
        </ion-accordion>
      </ion-accordion-group>
    </ion-card-content>
  </ion-card>
  <ion-icon
    class="enlarge-icon"
    (click)="select(item)"
    name="search-sharp"
  ></ion-icon>
  <!-- <div style="display: grid; grid-auto-flow: column; grid-gap: 0px">
    <ion-button
      expand="full"
      style="margin: 0px; padding: 0px"
      [disabled]="(item | countOfSelectedRows).length == 0"
      (click)="startOrder(item)"
    >
      START ORDER
    </ion-button>
    <ion-button expand="full" style="margin: 0px; padding: 0px" color="success">
      READY
    </ion-button>
  </div> -->
</ion-card>

<!-- MODIFIER TEMPLATE -->
<ng-template
  #tplModifier
  let-folioId="folioID"
  let-parentId="parentID"
  let-isModifier="isModifier"
>
  <div
    style="height: 5px"
    *ngIf="
      (kitchenDisplayQuery.modifiers(folioId, parentId).length > 0 &&
        kitchenDisplayQuery.mustModifiers(folioId, parentId).length > 0) ||
      isModifier === true
    "
  ></div>

  <div *ngFor="let item of kitchenDisplayQuery.modifiers(folioId, parentId)">
    <!-- <div *ngIf="item.qtyID !== oldID;" class="modifier-qtID">
        {{showID(item.qtyID)}}
      </div> -->

    <div style="margin-left: 10px; font-size: 12px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div
          style="width: 100%; padding-left: 5px; color: red"
          [ngStyle]="{
            'text-decoration': item.isDetachableModifier
              ? 'line-through'
              : 'unset'
          }"
        >
          <ion-icon
            [ngStyle]="{ color: item.isMustModifier ? 'red' : 'unset' }"
            name="return-down-forward-outline"
          >
          </ion-icon
          >&nbsp;{{ item.name }}
        </div>
        <!-- <div *ngIf="fs.getRowTotal(item) > 0"> -->
        <!-- {{fs.getRowTotal(item) | orwiCurrency}} -->
        <!-- </div> -->
      </div>
      <div
        style="margin-left: 10px"
        *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"
      ></div>
    </div>
  </div>

  <!-- flio note start -->
  <div
    *ngFor="let item of kitchenDisplayQuery.notes(folioId, parentId)"
    style="margin-top: 5px"
  >
    <div style="margin-left: 10px; font-size: 12px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="width: 100%; padding-left: 5px; font-weight: lighter">
          <ion-icon
            style="color: rgb(28, 167, 1); font-weight: 600"
            name="document-text-outline"
          ></ion-icon>
          &nbsp;
          <span style="color: rgb(28, 167, 1); font-weight: 600">{{
            item.name
          }}</span>
        </div>

        <!-- <div *ngIf="fs.getRowTotal(item) > 0"> -->
        <!-- {{fs.getRowTotal(item) | orwiCurrency}} -->
        <!-- </div> -->
      </div>
      <!-- <div style="margin-left: 10px;" *ngTemplateOutlet="tplModifier; context: { parentID: item.id }"></div> -->
    </div>
  </div>
  <!-- Folio note end-->
  <!-- </ng-container> -->
</ng-template>
