import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { InvoiceListItem } from 'kerzz-base';


export interface InvoiceState {
    invoices: InvoiceListItem[];
    activeInvoice: InvoiceListItem
}

export function createInitialState(): InvoiceState {
    return {
        invoices: [],
        activeInvoice: null
    };
}



@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invoice-Store' })
export class InvoiceStore extends Store<InvoiceState> {
    constructor() {
        super(createInitialState());
    }
}
