import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NumpadState, NumpadStore, NumpedType } from './numpad.store';

@Injectable({ providedIn: 'root' })
export class NumpadQuery extends Query<NumpadState> {
  visible$ = this.select('visible');
  for$ = this.select('for');

  constructor(protected store: NumpadStore) {
    super(store);
  }
  value$(key?: NumpedType) {
    if (!key) {
      return this.select((state) => state.value[this.store.getValue().for]);
    } else {
      return this.select((state) => state.value[key]);
    }
  }
  value(key?: NumpedType) {
    if (!key) {
      return this.getValue().value[this.store.getValue().for]??'0.0';
    } else {
      return this.getValue().value[key]??'0.0';
    }
  }
}
