import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { MyMaskUtil } from 'src/app/helpers/directives/mask-util';
import { GlobalService } from 'src/app/services/global.service';
import { SSOSessionService } from '../../state/sso-session.service';


@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.page.html',
  styleUrls: ['./otp-verify.page.scss'],
})
export class OtpVerifyPage implements OnInit {
  // @ViewChild('nameModal') orwiModal: OrwiModalComponent;

  public otpMask = MyMaskUtil.OTP_GENARATOR;

  timerId;
  reSend = true;
  resendSeconds = 60;
  id = 'login';
  otp;
  error = '';

  constructor(
    // private fs: Old_FolioService,
    public ssoService: SSOSessionService,
    private transloco: TranslocoService,
    private nav: NavController,
    public glb: GlobalService
  ) {
    
  }

  ngOnInit() { }

  //? implement
  confirmOtp() {
    this.glb.showLoading('');

    // this.us.verifyOtp(this.us.userGsm, this.otp).then(
    //   async (o: any) => {
    //     this.glb.closeLoading();
    //     this.error = '';
    //     if (o.status !== 'registered') {
    //       this.glb.toast( this.transloco.translate('Not a Member'), '', 'bottom', 'danger');
    //     } else {
    //       if (!document.getElementById('pin-lock-modal')) {
    //         this.nav.navigateForward('/welcome');
    //       }
    //     }
    //   },
    //   (e) => {
    //     if (e == 'otp_invalid') {
    //       this.glb.closeLoading();
    //       this.glb.toast(this.transloco.translate('Sms Code Invalid'),'','bottom', 'danger'
    //       );
    //     }
    //   }
    // );

    this.ssoService.verifyOtp(this.ssoService.userGsm, this.otp).then(
    
      async (o: any) => {
        if (o) {
          this.glb.closeLoading();
          this.nav.navigateForward('welcome');
        }
      },
      (e) => {
        
        if (e.error.message == 'otp_invalid') {
          this.glb.closeLoading();
          this.glb.toast(this.transloco.translate('Sms Code Invalid'),'','bottom', 'danger'
          );
        }
      }
    );

   
  }

  resendOtp() {
    this.ssoService.requestOtp(this.ssoService.userGsm);
    this.startInterval();
  }

  ionViewWillLeave() {
    clearInterval(this.timerId);
    this.resendSeconds = 60;
  }

  ionViewDidEnter() {
    this.glb.closeLoading();
    this.startInterval();
  }

  startInterval() {
    this.resendSeconds = 60;
    this.timerId = setInterval(() => {
      this.resendSeconds--;
      if (this.resendSeconds == 0) {
        clearInterval(this.timerId);
      }
    }, 1000);
  }
  //after login function
  async executeAfterLoginFunction() {
    await this.nav.pop();
    await this.nav.pop();
    // this.us.afterLoginFunction.bind(this);
    // this.us.afterLoginFunction();
  }

  showProfileForm() {
    // this.orwiModal.show();
  }

  canceProfileForm() {
    //this.orwiModal.close();
    setTimeout(async () => {
      await this.nav.pop();
      await this.nav.pop();
    }, 500);
  }

  saveProfileForm() {
    //this.orwiModal.close();
    this.saveProfile();
  }

  saveProfile() {
    // this.us.user.name = this.glb.titleCase(this.us.user.name);
    // this.glb.showLoading('');
    // this.us.saveUser().then((o: any) => {
    //   if (o.response) {
    //     this.us.user = o.response;
    //   }
    //   this.glb.closeLoading();
    //   if (this.us.afterLoginFunction) {
    //     this.executeAfterLoginFunction();
    //   }
    //   this.glb.closeLoading();
    // });
  }

  async back() {
    // await this.us.autoLogin();
    // this.nav.pop();
  }
}