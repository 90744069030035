import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ZReport } from '../dto/zReport';
import { OrwiService } from '../orwi/orwi.service';
import { TranslocoService } from '@ngneat/transloco';


@Injectable({
  providedIn: 'root'
})
export class ZreportService {

  constructor(
    private os: OrwiService,
    private transloco : TranslocoService
  ) { }


  getZReport(
    zReportDateFilter: Partial<ZReportDateFilter> = new ZReportDateFilter()
  ): Promise<ZReport> {
    // report/pos/getzReportItem
    const storeId = localStorage.getItem('activeStore');
    if (storeId) {
      return new Promise((resolve, reject) => {
        this.os
          .serviceRequestPromise('/api/report/pos/getzReportItem', {
            ...zReportDateFilter,
            storeId,
          })
          .then((o: any) => {
            if (o.response) {
              let zreport  :ZReport = o.response
              let ms = zreport.totalsOfItem.sort((a, b) => a.totalCount - b.totalCount)
              if (zreport.totalsOfPaymentType.filter(c=>c.itemName === this.transloco.translate('Change Money')).length > 0)
              {
                zreport.totalsOfPaymentType.map(x=> {               
                  if(x.itemName === "Nakit" || x.itemName ==="Cash") {
                    x.totalAmount = Number(x.totalAmount - zreport.totalsOfPaymentType.find(c=>c.itemName === this.transloco.translate('Change Money'))?.totalAmount)
                  }
                })
              }
           

                const index: number =zreport.totalsOfPaymentType.indexOf(zreport.totalsOfPaymentType.find(c=>c.itemName === this.transloco.translate('Change Money')));
                if (index != -1) {
                  zreport.totalsOfPaymentType.splice(index, 1);
                }
              zreport.totalsOfItem = ms

              console.log("z-report", zreport)
              console.log("z-report-ms", ms)

              resolve(zreport);
              return;
            }
            return reject(o?.error?.code);
          });
      });
    } else {
      return undefined
    }
  }
  getZReportChoosenDate(
    sDate: any, eDate: any
  ): Promise<ZReport> {
    const storeId = localStorage.getItem('activeStore');
    let data = {
      endDate: eDate,
      startDate: sDate,
      storeId: storeId
    }
    // report/pos/getzReportItem
    if (storeId) {
      return new Promise((resolve, reject) => {
        this.os
          .serviceRequestPromise('/api/report/pos/getzReportItem',
            { ...data },
          )
          .then((o: any) => {
            if (o.response) {
              let zreport = o.response
              zreport.totalsOfItem = zreport.totalsOfItem.sort((a, b) => {
                return a.totalCount - b.totalCount
              })
              
              if (zreport.totalsOfPaymentType.filter(c=>c.itemName === this.transloco.translate('Change Money')).length > 0)
              {
                zreport.totalsOfPaymentType.map(x=> {               
                  if(x.itemName === "Nakit" || x.itemName ==="Cash") {
                    x.totalAmount = Number(x.totalAmount - zreport.totalsOfPaymentType.find(c=>c.itemName === this.transloco.translate('Change Money'))?.totalAmount)
                  }
                })
              }

                const index: number =zreport.totalsOfPaymentType.indexOf(zreport.totalsOfPaymentType.find(c=>c.itemName === this.transloco.translate('Change Money')));
                if (index != -1) {
                  zreport.totalsOfPaymentType.splice(index, 1);
                }
              console.log("z-report", zreport)
              resolve(zreport);
              return
            }
            return reject(o?.error?.code); 
          });
      });
    } else {
      return undefined
    }
  }
}



class ZReportDateFilter {
  startDate: Date = moment().utc().startOf('day').toDate();
  endDate: Date = moment().utc().endOf('day').toDate();
  storeId: string;
}
