import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { GlobalService } from '../global.service';
import { AppService } from '../utils/app.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';

@Injectable({
  providedIn: 'root',
})
export class OrwiService {
// orwiServiceUrl = 'http://192.168.1.149:80';
orwiServiceUrl = 'https://service-test.orwi.app';
  //oriwServiceUrl = 'https://gandalf.orwi.app';
  apiKey = 'YWxpLTEyMzQ1MTEyNDU2NTQzMg';

  // get orwiServiceUrl() {
  //   const type: 'local' | 'test' | 'prod' = 'test';
  //   return this.urls[type];
  // }
  // urls = {
  //   local: 'https://192.168.1.93',
  //   test: 'https://service-test.orwi.app',
  //   prod: 'https://gandalf.orwi.app',
  // }



  token = '';
  userLanguage = 'en';
  userRegion = 'us';

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private sessionQuery: SessionQuery
  ) {}

  serviceRequest(path, postData, _token = '') {
    this.token = this.sessionQuery.token;
    let url = `${this.orwiServiceUrl}${path}`;
    return this.http.post(url, postData, this.getHeaders());
  }

  serviceRequestPromise(path, postData, _token = '') {
    return new Promise((resolve, reject) => {
      if (this.appService.connection == 'offline') {
        reject('offline');
        return;
      }

      // this.token = _token;
      this.token = this.sessionQuery.token;
      let url = `${this.orwiServiceUrl}${path}`;
      console.warn(url)
      this.http.post(url, postData, this.getHeaders()).subscribe(
        (o) => {
          resolve(o);
        },
        (e) => {
          this.glb.consolelog('error', e);
          this.glb.closeLoading();

          if (e.status == 0) {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast(
                'Opss!',
                this.transloco.translate(
                  'There is a problem, try again after a while'
                ),
                'middle',
                'danger'
              );
            }, 500);
          } else if (e.status == 404 || e.status == 401) {
          } else {
            this.glb.closeLoading();

            setTimeout(() => {
              this.glb.toast('Opss!', e.error.message, 'middle', 'danger');
            }, 500);
          }

          reject(e.error.message);
        }
      );
    });
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        accept: 'application/json',
        socketid: this.glb.socketId,
        apikey: this.apiKey,
        token: this.token,
        language: this.userLanguage,
        region: this.userRegion
      }),
    };
    return httpOptions;
  }

  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  getIP() {
    this.getIPAddress().subscribe((res: any) => {
      this.glb.paramId =
        this.sessionQuery.getValue()?.activeLicense?.orwiStore?.id +
        '-' +
        res.ip;
      this.glb.ipAddress = res.ip;
    });
  }
}
  //  orwiServiceUrl = 'https://192.168.1.93';
  // orwiServiceUrl = 'https://service-test.orwi.app';
  //orwiServiceUrl = 'https://gandalf.orwi.app';