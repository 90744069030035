import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { GlobalService } from 'src/app/services/global.service';
import { FolioQuery } from '../../state/folio.query';

@Component({
  selector: 'git-reasons',
  templateUrl: './git-reasons.component.html',
  styleUrls: ['./git-reasons.component.scss']
})
export class GitReasonsComponent implements OnInit {

  constructor(
    private glb: GlobalService,
    public fQ: FolioQuery,
    private fb: UntypedFormBuilder,
    public sQ: OrwiStoreQuery,
  ) { 

    this.reasonText.valueChanges.subscribe((data) => {
      this.form.patchValue({ reasonId: 'other', reasonName: data });
    });
  }

  form = this.fb.group({
    reasonId: [null, Validators.required],
    reasonName: [null, Validators.required],
  });

  reasons: any[] = [];
  reasonText = new UntypedFormControl('', Validators.required);

  close() {
    this.glb.closeModal();
  }

  change(e) {
    if(e?.detail?.value == 'other') {
      this.form.get('reasonName').setValue(this.reasonText.value);
    }
  }

  selectReason(reason: any) {
    let patchData = {
      reasonId: reason.id,
      reasonName: reason.name,
    };
    console.log(patchData);
    this.form.patchValue(patchData);
  }

  save() {
    this.form.markAllAsTouched();
    console.log(this.form.value);
    if (this.form.valid) {
      this.glb.closeModal({
        reason: this.form.value
      });
    } else {
      this.glb.shakeElement("delete-resasons")
    }
      
  }

  ngOnInit(): void {
  }

}
