import { Injectable } from '@angular/core';
import { FolioLog, FolioLogTypes } from 'src/app/services/dto/orwi-folio';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';

@Injectable({ providedIn: 'root' })
export class folioLogService {

    db: string
    col = "folio-logs"
    constructor(
        private publicApi: PublicApiService,
        private sessionQuery: SessionQuery,
        private ig: IdGeneratorService,
    ) {

        sessionQuery.activeLicense$.subscribe(o => {
            if (o) this.db = o.orwiStore.cloudId
        })


    }

    folioLogs: FolioLog[]

    getFolioLogs(folioId: string[], logType?: FolioLogTypes): Promise<FolioLog[]> {

        if (logType) {
            let filter = {
                folioId: {
                    $in: folioId
                }, logType: logType
            }
            return this.publicApi.getCollection({ _db: this.db, col: this.col, _filter: filter }).toPromise()
        } else {
            let filter = {
                folioId: {
                    $in: folioId
                }
            }
            return this.publicApi.getCollection({ _db: this.db, col: this.col, _filter: filter }).toPromise()
        }

    }


    insertFolioLog(entry: logEntry) {

        let _data: FolioLog = {
            id: this.ig.generateMaxi(),
            folioId: entry.folioId,
            date: new Date(),
            logType: entry.logType,
            description: entry.description || entry.logType,
            data: entry.data,
            user: {id: this.sessionQuery.user.id, name: this.sessionQuery.user.name}
        }

        return this.publicApi.upsert({
            _db: this.db,
            col: this.col,
            data: _data
        })
    }
}

export interface logEntry {
    logType: FolioLogTypes
    folioId: string
    description?: string
    data?: any
}