import { Injectable, inject } from '@angular/core';
import {
  arrayAdd,
  arrayRemove,
  arrayUpdate,
  arrayUpsert,
} from '@datorama/akita';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { Discount } from 'src/app/services/dto/orwi-definitions';
import { PaymentType } from 'src/app/services/dto/orwi-store';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { OrwiService } from 'src/app/services/orwi/orwi.service';

import { PaymentQuery } from './payment.query';
import { OrwiCurrency, PaymentHistory, PaymentStore } from './payment.store';
import { AccountListModalComponent } from 'src/app/components/ui/account-list-modal/account-list-modal.component';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  folioStore = inject(FolioStore);
  constructor(
    private idGen: IdGeneratorService,
    private paymentStore: PaymentStore,
    private paymentQuery: PaymentQuery,
    private glb: GlobalService,
    private orwiService: OrwiService,
    private orwiStoreStore: OrwiStoreStore,
    private sessionQuery: SessionQuery,
    private transloco : TranslocoService,
  ) {
    let tl: OrwiCurrency = {
      symbol: '₺',
      banknotes: [10, 20, 50, 100, 200],
      name: 'TRY',
      exchangeRate: 1,
    };

    let usd: OrwiCurrency = {
      symbol: '$',
      banknotes: [5, 10, 20, 50, 100],
      name: 'USD',
      exchangeRate: this.glb.exchangeRates.find((o) => o.id == 'usd')?.rate,
    };

    let eur: OrwiCurrency = {
      symbol: '€',
      banknotes: [5, 10, 20, 50, 100, 200, 500],
      name: 'EUR',
      exchangeRate: this.glb.exchangeRates.find((o) => o.id == 'eur')?.rate,
    };

    let gbp: OrwiCurrency = {
      symbol: '£',
      banknotes: [5, 10, 20, 50],
      name: 'GBP',
      exchangeRate: this.glb.exchangeRates.find((o) => o.id == 'gbp')?.rate,
    };

    let curr = [tl, usd, eur, gbp];
    this.paymentStore.update({ currencies: curr, selectedCurrency: tl });

    this.paymentQuery.history$.subscribe((o) => {
      o; //reserved
      if (this.paymentQuery.getValue().folios.length > 0) {
        if (
          this.paymentQuery.folioIsDone(
            this.paymentQuery.getValue().activeFolioId
          )
        ) {
          this.glb.playCash();
        } else {
          this.glb.playPayment();
        }
      }
    });
  }

  async addHistory(
    item: PaymentType,
    initialConfig: InitialConfig = new InitialConfig()
  ) {
    let _numpadValue =
      this.paymentStore.getValue().value || this.paymentQuery.balance;
    if (initialConfig.value != 0) _numpadValue = initialConfig.value;
    if (_numpadValue == 0) {
      this.glb.shakeElement('payment-numpad-value');
      return;
    }

    if (item.payType == 'cash') {
      this.addBanknoteToHistory(_numpadValue, item);
      return;
    }

    if (_numpadValue > this.paymentQuery.balance) {
      this.glb.shakeElement('payment-numpad-value');
      return;
    }

    if (item.payType == 'account') {
      const selectedAccountModal = await this.glb.openModal({
        component: AccountListModalComponent,
      });

      const {
        data,
      }: { data?: any } = await selectedAccountModal.onDidDismiss();
      if (data) {
        console.log(data);
        initialConfig.name = data.customerName;
        initialConfig.itemID = data.id;
        this.folioStore.update(({ activeFolio }) => ({
          activeFolio: {
            ...activeFolio,
            invoiceInfo: {
              ...activeFolio.invoiceInfo,
              name: data.customerName,
              address: data.address,
              addressCity: data.city,
              addressTown: data.town,
              email: data.mail,
              taxNo: data.taxNumber,
              taxOffice: data.taxOffice,
              phone: data.gsm,
            },
          },
        }));
      } else return;
    }

    if(item.payType == 'expense-slip' && _numpadValue != this.paymentQuery.balance) {
      _numpadValue = this.paymentQuery.balance;
      this.glb.toast('', 'Expense slip cannot be partial', 'middle', 'warning');
    }

    console.log('addPayment',item)
    this.paymentStore.update(({ history }) => ({
      history: arrayUpsert(history, initialConfig.id ?? this.idGen.generate(), {
        folioId: this.paymentStore.getValue().activeFolioId,
        desc: initialConfig.name ?? item.name,
        value: _numpadValue,
        id: initialConfig.id ?? this.idGen.generate(),
        parentId: '0',
        paymentId: item.id,
        payType: item.payType,
        already: initialConfig.already,
        rowType: initialConfig.rowType,
        payRowID: initialConfig.itemID,
      }),
    }));

    this.paymentStore.update({ value: this.paymentQuery.balance });
  }

  addCashToHistory(amount: number, item: PaymentType, desc?, ch?) {
    if (!desc) desc = 'Cash';
    if (!ch) ch = 'Change';

    if (amount > this.paymentQuery.balance) {
      let id = this.idGen.generate();
      let pu = this.paymentQuery.balance - amount;
      this.paymentStore.update(({ history }) => ({
        history: arrayAdd(history, {
          folioId: this.paymentStore.getValue().activeFolioId,
          desc: desc,
          value: amount,
          id: id,
          parentId: '0',
          paymentId: item?.id,
          payType: 'cash',
        }),
      }));

      this.paymentStore.update(({ history }) => ({
        history: arrayAdd(history, {
          folioId: this.paymentStore.getValue().activeFolioId,
          desc: ch,
          value: pu,
          id: this.idGen.generate(),
          parentId: id,
          paymentId: item?.id,
          payType: 'cash',
        }),
      }));
    } else {
      this.paymentStore.update(({ history }) => ({
        history: arrayAdd(history, {
          folioId: this.paymentStore.getValue().activeFolioId,
          desc: desc,
          value: amount,
          id: this.idGen.generate(),
          parentId: '0',
          paymentId: item?.id,
          payType: 'cash',
        }),
      }));
    }

    this.paymentStore.update({ value: this.paymentQuery.balance });
  }

  addBanknoteToHistory(bankNote: number, item?: PaymentType) {
    let cur = this.paymentStore.getValue().selectedCurrency;

    console.log(cur);
    let amount = cur?.exchangeRate * bankNote;

    let desc = 'Nakit';
    let ch = this.transloco.translate('ChangeMoney');

    if (cur?.exchangeRate !== 1) {
      desc = `Cash (${cur.symbol}:${bankNote} * ${cur.exchangeRate})`;
    }

    this.addCashToHistory(amount, item, desc, ch);
  }

  deleteHistory(item: PaymentHistory) {
    if (item.payType == 'account') {
      this.folioStore.update(({ activeFolio }) => ({
        activeFolio: {
          ...activeFolio,
          invoiceInfo: {
            name: null,
            address: null,
            addressCity: null,
            addressTown: null,
            email: null,
            taxNo: null,
            taxOffice: null,
            phone: null,
          },
        },
      }));
    }
    let historyies = this.paymentStore
      .getValue()
      .history.filter((h) => h.parentId == item.id);

    for (const iterator of historyies) {
      this.paymentStore.update(({ history }) => ({
        history: arrayRemove(history, iterator.id),
      }));
    }

    this.paymentStore.update(({ history }) => ({
      history: arrayRemove(history, item.id),
    }));
  }
  fetchDiscountDef(): Promise<Discount[]> {
    return new Promise((resolve, reject) => {
      let storeId = this.orwiStoreStore.getValue().id;
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/discountReason/getDiscountReasons',
          { id: storeId },
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            this.paymentStore.update({ predefinedDiscounts: o.response });
            resolve(o.response);
          }
        }),
        (e) => {
          reject(e);
        };
    });
  }

  saveDiscountDef(definition: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/discountReason/saveDiscountReason',
          {
            ...definition,
            storeId: this.orwiStoreStore.getValue().id,
          },
          this.sessionQuery.token
        )
        .then((o: any) => {
          console.log(o.response);
          if (o.response) {
            this.paymentStore.update(({ predefinedDiscounts }) => ({
              predefinedDiscounts: arrayUpdate(
                predefinedDiscounts,
                o.response.id,
                o.response
              ),
            }));
            this.fetchDiscountDef();
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }

  deleteDiscount(definition: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/discountReason/deleteDiscountReason',
          {
            ...definition,
            storeId: this.orwiStoreStore.getValue().id,
          },
          this.sessionQuery.token
        )
        .then((o: any) => {
          console.log(o.response);
          if (o.response) {
            this.paymentStore.update(({ predefinedDiscounts }) => ({
              predefinedDiscounts: arrayUpdate(
                predefinedDiscounts,
                o.response.id,
                o.response
              ),
            }));
            this.fetchDiscountDef();
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        });
    });
  }
}

export class InitialConfig {
  value = 0;
  id = undefined;
  already = false;
  rowType = undefined;
  name = undefined;
  itemID? = undefined;
}
