import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { FolioStore } from '../../state/folio.store';

@Component({
  selector: 'pax',
  templateUrl: './pax.component.html',
  styleUrls: ['./pax.component.scss'],
})
export class PaxComponent implements OnInit {
  child :number = 0
  male: number = 0
  female : number = 0
  constructor(public folioStore: FolioStore,private glb : GlobalService) { }

  ngOnInit() {  
    this.setStartingSettings();
  }

  setStartingSettings(){
    this.folioStore.getValue().activeFolio.paxChild == undefined ? this.child = 0 : this.child = this.folioStore.getValue().activeFolio.paxChild;
    this.folioStore.getValue().activeFolio.paxFemale == undefined ? this.female = 0 : this.female = this.folioStore.getValue().activeFolio.paxFemale;
    this.folioStore.getValue().activeFolio.paxMale == undefined ? this.male = 0 : this.male = this.folioStore.getValue().activeFolio.paxMale;
  }

  pinFormatter(value: number) {
    return `${value}`;
  }

  pax(v, c) {
    let value;
    v.target.classList[0] == 'input' ? value = v.target.value : value = v.detail.value;
    if(Number(value) > 15){
      value = 15;
    }
    if (c == "c") {
      this.child = value
      this.folioStore.getValue().activeFolio.paxChild = this.child
    } else if (c == "f") {
      this.female = value
      this.folioStore.getValue().activeFolio.paxFemale = this.female
    } else {
      this.male = value
      this.folioStore.getValue().activeFolio.paxMale = this.male
    }
   
   
    this.folioStore.getValue().activeFolio.pax = Number(this.folioStore.getValue().activeFolio.paxMale) + Number(this.folioStore.getValue().activeFolio.paxFemale) + Number(this.folioStore.getValue().activeFolio.paxChild)
  
  }

  done()
  {
    this.glb.closeModal();
  }

}
