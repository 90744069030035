import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { ITable } from 'src/app/services/dto/tables';

@Component({
  selector: 'select-table-folio',
  templateUrl: './select-table-folio.component.html',
  styleUrls: ['./select-table-folio.component.scss'],
})
export class SelectTableFolioComponent implements OnInit {
  @Input() table: ITable;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  dismiss(value: Folio | null = null) {
    this.modalCtrl.dismiss(value);
  }
  onRowClick(e: Folio) {
    this.dismiss(e);
  }
}
