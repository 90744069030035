<ion-header>
  <ion-toolbar>
    <ion-title>Barcode List</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let barcode of barcodes" (click)="selectBarcode(barcode)">
      <ion-label>
        {{barcode.text}}-{{barcode.barcode}}
      </ion-label>
      <ion-text slot="end">
        {{'Quantity' | transloco}}: {{barcode.quantity}}
      </ion-text>
    </ion-item>
  </ion-list>
</ion-content>