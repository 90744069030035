<ion-header>
  <ion-toolbar>
    <ion-title>{{ "Please select a payment type" | transloco }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="true">
  <ion-list>
    <ion-item
      style="cursor: pointer"
      (click)="chooseType(item)"
      *ngFor="let item of getPaymentType()"
    >
      <ion-label>
        <div
          style="display: flex; align-items: center; gap: 10px; color: #171e4e"
        >
          <div>{{ item.name }}</div>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
