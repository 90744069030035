import { Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Injectable({
  providedIn: 'root'
})
export class HapticService {

  constructor() { }



  delay(ms): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          resolve(true)
        }, ms);
      } catch (err: any) {
        reject(err)
      }
    })
  }

  async hapticsImpactMedium(wait = 500) {
    await Haptics.impact({ style: ImpactStyle.Medium });
    return this.delay(wait)
  }

  async hapticsImpactLight(wait = 500) {
    await Haptics.impact({ style: ImpactStyle.Light });
    return this.delay(wait)
  }

  async hapticsImpactHeavy(wait = 500) {
    await Haptics.impact({ style: ImpactStyle.Heavy });
    return this.delay(wait)
  }

  async hapticsVibrate(wait = 500) {
    await Haptics.vibrate();
    return this.delay(wait)
  }

  async hapticsSelectionStart() {
    return Haptics.selectionStart();
  }

  async hapticsSelectionChanged() {
    return Haptics.selectionChanged();
  }


  async hapticsSelectionEnd() {
    return Haptics.selectionEnd();
  }





}
