<div class="content-cover">
  <div
    style="
      margin: auto;
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 20px;
    "
  >
    <div *ngIf="(pq.predefinedDiscounts$ | async).length > 0">
      <div class="pre-caption">{{ "Predefined Discounts" | transloco }}</div>

      <div style="display: flex; flex-wrap: wrap; justify-content: center">
        <div
          class="pre-button"
          *ngFor="let item of pq.predefinedDiscounts$ | async as list"
        >
          <div
            (click)="selectDiscont(item)"
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div class="pre-title">
              {{ item.name }}
            </div>
            <div style="display: flex; flex-direction: column">
              <ng-container *ngIf="item.rate > 0">
                <div class="pre-amount">
                  {{ item.rate / 100 | percent }}
                </div>
                <div class="pre-discont">
                  {{ pq.balance | orwiDiscont: item | orwiCurrency }}
                </div>
              </ng-container>
              <span class="pre-amount" *ngIf="item.amount > 0">
                - {{ item.amount | orwiCurrency }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- <ion-row>
        <ion-col size="12" sizeMd="5" *ngFor="let item of pq.predefinedDiscounts$ | async as list" lines="none">
          <ion-button class="pre-definet-discont-button" >
     
          </ion-button>
        </ion-col>
      </ion-row> -->
    </div>
  </div>
  <div class="numpad-cover">
    <div class="discount-buttons">
      <ion-button
        [ngClass]="{ selected: type == 'rate' }"
        (click)="setDiscont('rate')"
        >{{ "Discount with %" | transloco }}</ion-button
      >
      <ion-button
        [ngClass]="{ selected: type == 'amount' }"
        (click)="setDiscont('amount')"
      >
        {{ "Discount with Total" | transloco }}</ion-button
      >
    </div>

    <div style="padding: 10px; color: #171e4e">
      <ng-container *ngIf="pq.activeFolio$ | async as folio">
        <div style="display: flex; justify-content: space-between">
          <div>
            {{ "Total" | transloco }}
          </div>
          <div>
            {{ pq.balance | orwiCurrency }}
          </div>
        </div>

        <div style="display: flex; justify-content: space-between">
          <div>
            {{ "Discount" | transloco }}
          </div>
          <div>
            {{ pq.balance | orwiDiscont: discont | orwiCurrency }}
          </div>
        </div>

        <div style="display: flex; justify-content: space-between">
          <div>
            {{ "Grand Total" | transloco }}
          </div>
          <div>
            {{
              pq.balance - (pq.balance | orwiDiscont: discont) | orwiCurrency
            }}
          </div>
        </div>
      </ng-container>
    </div>

    <div
      class="numpad"
      [ngStyle]="{ filter: pq.balance == 0 ? 'opacity(0.5)' : 'opacity(1)' }"
    >
      <div *ngIf="type == 'rate'" class="input">
        {{ value / 100 | percent }}
      </div>
      <div *ngIf="type == 'amount'" class="input">
        {{ value | orwiCurrency }}
      </div>

      <div class="button-row">
        <ion-button (click)="select('7')" class="discount-button">
          7
        </ion-button>
        <ion-button (click)="select('8')" class="discount-button">
          8
        </ion-button>
        <ion-button (click)="select('9')" class="discount-button">
          9
        </ion-button>
      </div>

      <div class="button-row">
        <ion-button (click)="select('4')" class="discount-button">
          4
        </ion-button>
        <ion-button (click)="select('5')" class="discount-button">
          5
        </ion-button>
        <ion-button (click)="select('6')" class="discount-button">
          6
        </ion-button>
      </div>

      <div class="button-row">
        <ion-button (click)="select('1')" class="discount-button">
          1
        </ion-button>
        <ion-button (click)="select('2')" class="discount-button">
          2
        </ion-button>

        <ion-button (click)="select('3')" class="discount-button">
          3
        </ion-button>
      </div>

      <div class="button-row">
        <ion-button (click)="select('0')" class="discount-button">
          0
        </ion-button>
        <ion-button (click)="select('.')" class="discount-button">
          .
        </ion-button>
        <ion-button (click)="select('delete')" class="discount-button">
          <ion-icon name="backspace"></ion-icon>
        </ion-button>
      </div>
    </div>

    <div style="display: flex; margin-top: 10px">
      <ion-button
        class="footer-btns"
        style="--background: #4cd9ed"
        (click)="done()"
      >
        {{ "Done" | transloco }}
      </ion-button>
      <ion-button
        class="footer-btns"
        style="--background: #ed4c4c"
        (click)="cancel()"
      >
        {{ "Cancel" | transloco }}
      </ion-button>
    </div>
  </div>
</div>
