import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import * as moment from 'moment';
import { firstValueFrom, map } from 'rxjs';
import { ParametersState, ParametersStore } from './parameters.store';

@Injectable({ providedIn: 'root' })
export class ParametersQuery extends Query<ParametersState> {
  posParemeters$ = this.select('posParameters');

  startDate = (date: any = new Date()) =>
    firstValueFrom(
      this.posParemeters$.pipe(
        map((posParameters) =>
          moment(
            moment(date).format('YYYY-MM-DD') +
              'T' +
              moment(posParameters.globalParameters.openTime).format('HH:mm')
          )
            .utc(true)
            .toISOString()
        )
      )
    );

  endDate = (date: any = new Date()) =>
    firstValueFrom(
      this.posParemeters$.pipe(
        map((posParameters) =>
          moment(
            moment(date).format('YYYY-MM-DD') +
              'T' +
              moment(posParameters.globalParameters.closeTime).format('HH:mm')
          )
            .utc(true)
            .toISOString()
        )
      )
    );

  constructor(protected store: ParametersStore) {
    super(store);
  }
}
