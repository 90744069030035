
<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'Product Transfer' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
   <ion-button (click)="close()">
    <span class="material-icons">
      close
      </span>
   </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<!-- <orwi-modal-header [title]="'Product Transfer' | transloco"  (onClose)="close()"></orwi-modal-header> -->
<ion-content>
  <div class="container">
    <div class="left">
      <div class="top">
        {{ "Change" | transloco }}/ {{ "Combine" | transloco }}
      </div>
      <foli-row-list
        [dataSource]="folioQuery.mainRows$() | async"
        (onSelectedChange)="onSelectedChangeRows($event)"
      ></foli-row-list>
      <div class="buttons">
        <div class="ripple-parent ion-activatable">
          <a
            class="table-btn open"
            [ngStyle]="{ filter: isValid() ? '' : 'opacity(0.5)' }"
            style="background-color:#4CD9ED; color: #FFF; text-transform: none; font-size: 16px; box-shadow: none;"
            (click)="isValid() ? transfer() : ''"
            >{{ "Transfer" | transloco }}
            <ion-ripple-effect *ngIf="isValid()"></ion-ripple-effect>
          </a>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <!-- <a class="back-button" (click)="close()">
          <ion-icon name="backspace-outline"></ion-icon>
          <span> {{ "Go Back" | transloco }} </span>
        </a> -->
      </div>

      <folio-list (onSelectedChange)="onSelectedChange($event)"></folio-list>
    </div>
  </div>
</ion-content>
