
export interface FoodAppApplication {
  id: string
  name: string
  icon: string
  type: string
  userName: FoodAppApllicationCustomField
  password: FoodAppApllicationCustomField
  supplierId: FoodAppApllicationCustomField
  restaurantId: FoodAppApllicationCustomField
}

export interface FoodAppApllicationCustomField {
  title: string
  visible: boolean
}

export interface FoodAppAccount {
  id: string
  cloudId: string
  description: string
  foodApp: 'yemek-sepeti' | 'getir' | 'trendyol' | 'fuudy' | 'orwi' | 'migros-yemek' | 'custom' | 'hemen-yolda' | 'migpack' | 'kiosk'
  isActive: boolean
  password: string
  storeId: string
  userName: string
  supplierId: string,
  brand: string,
  autoOrderAccept: boolean
  isDetailLogActive: boolean
  restaurantId: string
  syncOrderStatus: string
  syncStoreStatus: string
  syncMenuStatus: string
  syncOrderLast: Date
  syncStoreLast: Date
  syncMenuLast: Date
  isDeliveryApp: boolean
}

export class FoodAppCancelReason {
  id: string
  name: string
  description: string
  source: string
}

export class FoodAppStore {
  id: string
  source: string
  accountId: string
  internalId: string
  name: string
  averagePreparationTime: number
  lastEditDate: Date
  status: string
  statusDesc: string
  usefoodAppCourier: boolean

}

export interface FoodAppProduct {
  auto: boolean
  id: string
  source: string
  accountId: string
  group: string
  internalId: string
  name: string
  price: number
  changed: boolean
}

export interface FoodAppModifier {
  auto: boolean

  id: string
  source: string
  accountId: string
  internalId: string
  name: string
  price: number,
  productId: string
  type: string
  changed: boolean
}


export class Notification {
  id : string = ""
  accountId :string = ""
  createDate : Date = new Date()
  deleted : boolean = false
  detail : string = ""
  readed : boolean = false
  source : string = ""
  storeId : string = ""
  text : string =""
  title : string =""
  userName : string =""
}


export interface FoodAppCategory {

  id: string
  source: string
  accountId: string
  name: string
}

export interface RestaurantMenu {
  modifiers: RestoranModifier[]
  products: RestaurantProduct[]
  categories: RestoranCategory[]
}

export interface RestoranModifier {
  id: string,
  accountId: string,
  name: string,
  price: number,
  productId: string,
  type: string,
  source: string,
  internalId: string

}

export interface RestaurantProduct {

  id: string,
  accountId: string,
  price: number,
  name: string,
  group: string,
  source: string,
  internalId: string

}
export interface RestoranCategory {
  id: string,
  accountId: string,
  name: string,
  source: string
}


export interface FoodAppServiceStatus {
  id: string
  status: number
  title: { tr: string, en: string }
  selected: boolean
}


export class FoodAppsLogs {
  id: string
  source: string
  sourceId: string
  accountId: string
  cloudId: string
  createDate: Date
  isSendedTarget: boolean
  isTimed: boolean
  orderId: string
  status: string
  storeId: string
  targetId: string
  usefoodAppCourier: string
  closedDate: Date
  targetMessage: string
  orderStatus: string
}
