<div style="position: relative; color: #171e4e" *ngIf="folio$ | async">
  <div
    style="display: flex; flex-direction: column; float: left"
    class="folio-header"
  >
    <div style="display: flex">
      <div style="width: 90px" class="header">
        {{ "#/Table" | transloco }} :
      </div>
      <div class="info">
        {{ (folio$ | async).table.name }}
      </div>
    </div>

    <div style="display: flex">
      <div style="width: 90px" class="header">{{ "Pax" | transloco }} :</div>
      <div class="info">
        {{ (folio$ | async).pax }}
      </div>
    </div>
    <div style="display: flex">
      <div style="width: 90px" class="header">{{ "sequence" | transloco }} :</div>
      <div class="info">
      <strong>  {{ (folio$ | async).sequenceNo }}</strong>
      </div>
    </div>
    <div style="display: flex">
      <div style="width: 90px" class="header">{{ "Waiter" | transloco }} :</div>
      <div class="info">
        {{ (folio$ | async).creator.userName }}
      </div>
      <div *ngIf="waiterList.length > 0">
        <ion-icon (click)="showWaiters($event)" name="chevron-down-outline"></ion-icon>
        <ion-popover #waiterPopover (didDismiss)="waiterMenuToggle = false;" side="right" alignment="end">
          <ng-template>
            <ion-list>
              <ion-item style="cursor: pointer;" (click)="changeWaiter(waiter)" *ngFor="let waiter of waiterList">
                {{waiter.name}}
              </ion-item>
            </ion-list>
          </ng-template>
        </ion-popover>
      </div>
    </div>
    <div
      style="display: flex"
      class="deliver-folio-text"
      *ngIf="(folio$ | async).source == 'food-app'"
    >
      <div style="width: 90px" class="header">
        {{ "Delivery Payment Type" | transloco }} :
      </div>
      <div class="info">
        {{ (folio$ | async).deliveryPaymentType }}
      </div>
    </div>
    <div
      style="display: flex"
      class="deliver-folio-text"
      *ngIf="(folio$ | async).isTimed"
    >
      <div style="width: 90px" class="header">
        {{ "Delivery Time" | transloco }} :
      </div>
      <div class="info">
        {{ (folio$ | async).scheduler | date: "HH:mm" }}
      </div>
    </div>
    <div style="display: flex">
      <div style="width: 90px" class="header">
        {{ "Opining Time" | transloco }} :
      </div>
      <div class="info">
        {{ (folio$ | async).creation | date: "dd.MM.yyyy HH:mm" }}
      </div>
    </div>

    <div style="display: flex" class="folio-note">
      <div class="header">{{ "Folio Note" | transloco }} :</div>
      <div class="info">
        {{ (folio$ | async).note }}
      </div>
    </div>
    <div
      *ngIf="(folio$ | async).deliveryPaymentType !== ''"
      style="display: flex"
      class="folio-note"
    >
      <div class="header">{{ "Delivery Payment" | transloco }} :</div>
      <div class="info">
        {{ getPaymentType((folio$ | async).deliveryPaymentType) }}
      </div>
    </div>
  </div>

  <div style="display: flex" class="folio-note">
    <div class="header">{{ "Folio Note" | transloco }} :</div>
    <div class="info">
      {{ (folio$ | async).note }}
    </div>
  </div>
</div>
<!--
  <div *ngIf="type == 'folio' &&  (this.folio$ | async).source == 'food-app'" style="flex-direction: column;background-color: white;"  class="folio-header">
    <img [src]="getDeliveryStatus()" width="60" />
  </div> -->

<div
  *ngIf="type == 'payment'"
  style="flex-direction: column"
  class="folio-header"
>
  <button class="header-btn">
    <span class="material-icons" style="color: #8f908f"> print </span>
    <span class="header-btn-text">{{ "Print" | transloco }}</span>
  </button>
  <button class="header-btn">
    <span class="material-icons" style="color: #8f908f"> receipt </span>
    <span class="header-btn-text">{{ "Split" | transloco }}</span>
  </button>
</div>

<!-- <div *ngIf="fs.showPax" style="position: absolute; right: 10px; width: 400px; height: 200px; z-index: 1000;">
    <app-pax></app-pax>
  </div> -->
