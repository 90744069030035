<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ "Cancel Reason" | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <span class="material-icons">close</span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>



  <ion-grid id="delete-resasons" fixed style="overflow-y: auto">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-radio-group>
            <ion-item
              lines="none"
              *ngFor="let item of foodAppCancelReason"
              (click)="selectReason(item)"
            >
              <ion-radio [value]="item.id"></ion-radio>
              <ion-label> &nbsp;{{ item.name | titlecase }} </ion-label>
              <!-- <small>{{item.description}}</small> -->
            </ion-item>

            <!-- <ion-item lines="none" [ngClass]="{ selected: 'other' == form.get('reasonId').value }">
                            <ion-radio [value]="'other'"></ion-radio>
                            <ion-label>&nbsp;{{ "other" | transloco }} </ion-label>
                            <ion-input style="margin-left: 5px" [formControl]="reasonText" [placeholder]="'please define' | transloco">
                            </ion-input>
                        </ion-item> -->
          </ion-radio-group>
        </ion-list>

        <ion-item>
          <ion-label>{{'Cancellation Reason' | transloco}}</ion-label>
          <ion-textarea
            [(ngModel)]="cancelReason"
            placeholder="{{'Please enter cancellation reason.' | transloco}}"
          ></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-item lines="none" class="">
      <div
        style="
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 10px;
          width: 100%;
          gap: 10px;
        "
      >
        <ion-button (click)="close()" style="width: 100%; --background: #ed4c4c">
          {{ "cancel" | transloco }}</ion-button
        >
        <ion-button
          [disabled]="cancelReason === ''"
          (click)="save()"
          style="width: 100%; --background: #4cd9ed"
        >
          {{ "done" | transloco }}
        </ion-button>
      </div>
    </ion-item>
  </ion-toolbar>
</ion-footer>
