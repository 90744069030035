import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { OrwiCountry } from 'src/app/services/dto/orwi-address';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';
import { OrwPhoneInputListComponent } from '../orw-phone-input-list/orw-phone-input-list.component';

@Component({
  selector: 'orwi-phone-input',
  templateUrl: './orwi-phone-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrwiPhoneInputComponent),
      multi: true,
    },
  ],
  styleUrls: ['./orwi-phone-input.component.scss'],
})
export class OrwiPhoneInputComponent implements ControlValueAccessor {
  @Output()
  onCountryChange: EventEmitter<OrwiCountry> = new EventEmitter<OrwiCountry>();
  country: OrwiCountry;
  countries: OrwiCountry[] = [];

  value: string = '';
  isDisabled: boolean = true;
  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(
    public popoverController: PopoverController,
    private countryCodesService: CountryCodesService
  ) {
    this.initCountry();
  }
  writeValue(value: string): void {
    /** +905458772665 */

    if (value) {
      const index = value.indexOf(this.country.dialCode);
      // Detect dialCode
      if (index !== 0) {
        this.country = this.countries.find(
          (cty) => value.indexOf(cty.dialCode) === 0
        );
      }
      const newValue = value.substring(this.country.dialCode.length);
      if (newValue !== value && newValue.length > 0) {
        this.value = newValue;
        this.onChange(value);
        this.onTouch(value);
      } else {
        this.onChange(null);
        this.onTouch(null);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ionChange(e) {
    const value: string = this.country.dialCode + e?.detail?.value;

    this.writeValue(value);
  }
  async initCountry() {
    this.countries = (await this.countryCodesService.getCountries()) as any[];
    this.country = this.countries.find((el) => el.code === 'TR');
    this.setDisabledState(false);
  }

  async presentPopover(ev: any) {
    if (this.isDisabled) {
      return;
    }
    const popover = await this.popoverController.create({
      component: OrwPhoneInputListComponent,
      cssClass: 'country-list-popover',
      event: ev,
      translucent: true,
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();
    if (data) {
      this.country = data as OrwiCountry;
      const value: string = this.country.dialCode + this.value;
      this.writeValue(value);
      this.onCountryChange.emit(this.country);
    }
    // console.log('onDidDismiss resolved with ', data);
  }
}
