import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TableGroup } from 'src/app/services/dto/tables';
import { TableQuery } from 'src/app/modules/tables/state/table.query';

@Component({
  selector: 'orwi-table-group-button',
  templateUrl: './table-group-button.component.html',
  styleUrls: ['./table-group-button.component.scss'],
})
export class TableGroupButtonComponent implements OnInit {
  @Input() tableGroup: TableGroup;
  @Output() itemSelected = new EventEmitter<TableGroup>();
  @Output() onInfoClick: EventEmitter<TableGroup> =
    new EventEmitter<TableGroup>();

  constructor(
    public tq: TableQuery
  ) {}

  ngOnInit() {}

  selectItem() {
    this.itemSelected.emit(this.tableGroup);
    // this.ts.selectTableGroup(this.tableGroup.id);
  }

  info(e, table) {
    e.preventDefault();
    e.stopPropagation();
    this.onInfoClick.emit(table);
    // this.glb.toast("hello", "", "bottom", "danger")
  }


  deneme(e) {
    console.log("deneme",e)
  }
}
