<ion-header id="phone-folio-header">
  <ion-toolbar>
    <div style="display: flex; align-items: center">
      <div
        style="
          padding: 10px;
          font-size: 12px;
          font-weight: 500;
          font-family: 'Inter';
        "
      >
        {{ (fQ.activeFolio$ | async)?.table?.name }}
      </div>
      <div style="width: 100%;" (click)="checkPermission()">
        <ion-searchbar
          [disabled]="!ssoService.checkPermission('folio-find-product')"
          class="custom"
          (keyup)="key($event)"
          debounce="500"
          [placeholder]="'menu-search' | transloco"
          (ionChange)="handleInput($event)"
        >
        </ion-searchbar>

      </div>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="false" [fullscreen]="true">
  <div style="display: flex; flex-direction: column">
    <div id="product" class="menu-cover">
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
        "
      >
        <div id="group-cover" class="group-cover">
          <div
            [ngClass]="{
              'selected-group': (mQ.selectedGroup$ | async)?.id === item.id
            }"
            (click)="select(item)"
            class="group ripple-parent ion-activatable"
            *ngFor="let item of mQ.groups$ | async as list"
          >
            {{ item.name }}
            <ion-ripple-effect></ion-ripple-effect>
          </div>
        </div>

        <div id="menu-cover" style="overflow: scroll">
          <div
            *ngFor="let item of mQ.subGroups$ | async"
            class="sub-group-cover"
          >
            <div
              [ngClass]="{
                'selected-subgroup':
                  (mQ.selectedSubGroup$ | async)?.id === subGroupItem.id
              }"
              (click)="select(subGroupItem)"
              class="sub-group ripple-parent ion-activatable"
              *ngFor="let subGroupItem of item.group"
            >
              {{ subGroupItem.name }}
              <ion-ripple-effect></ion-ripple-effect>
            </div>
          </div>
          <hr />
          <div class="product-cover">
            <button
              (click)="productClick(productItem)"
              [disabled]="productItem.soldOut"
              style="display: inline"
              class="product ripple-parent ion-activatable"
              *ngFor="let productItem of mQ.activeProducts$ | async as list"
            >
              <label style="clear: both"> {{ productItem.name }}</label> <br />
              <label
                *ngIf="productItem.soldOut"
                style="
                  color: #171e4e99;
                  font-size: 12px;
                  font-weight: bold;
                  text-decoration: line-through;
                "
                >{{ "SoldOut" | transloco }}</label
              >
              <ion-ripple-effect></ion-ripple-effect>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      id="folio-resize"
      mwlResizable
      (resizeEnd)="onResizeEnd($event)"
      [enableGhostResize]="true"
      class="folio-cover"
    >
      <div
        class="resize-handle-top"
        mwlResizeHandle
        [resizeEdges]="{ top: true }"
      ></div>
      <phone-folio-rows></phone-folio-rows>
    </div>
  </div>
</ion-content>
<ion-footer id="phone-folio-footer">
  <ion-toolbar>
    <div style="display: flex; justify-content: space-between">
      <ion-button
        fill="clear"
        id="cancel-button"
        size="small"
        [disabled]="(fQ.showModifiers$ | async) || onSave"
        (click)="cancel()"
      >
        <span class="material-icons"> cancel </span>
      </ion-button>

      <div>
        <!-- <ion-button fill="clear" size="small" [disabled]="(fQ.showModifiers$ | async) || onSave"
          (click)="showModifiers()">
          <span class="material-icons"> add </span>
        </ion-button> -->

        <ion-button
          fill="clear"
          size="small"
          [disabled]="(fQ.showModifiers$ | async) || onSave"
          (click)="setGift()"
        >
          <span class="material-icons"> redeem </span>
        </ion-button>

        <ion-button
          fill="clear"
          size="small"
          [disabled]="(fQ.showModifiers$ | async) || onSave"
          (click)="setPax()"
        >
          <span class="material-icons"> groups </span>
        </ion-button>

        <ion-button
          fill="clear"
          size="small"
          [disabled]="(fQ.showModifiers$ | async) || onSave"
          (click)="printFolio()"
        >
          <span class="material-icons"> receipt_long </span>
        </ion-button>

        <ion-button
          fill="clear"
          size="small"
          [disabled]="(fQ.showModifiers$ | async) || onSave"
          (click)="payment()"
        >
          <span class="material-icons"> point_of_sale </span>
        </ion-button>
      </div>

      <ion-button
        fill="clear"
        id="done-button"
        size="small"
        [disabled]="(fQ.showModifiers$ | async) || onSave"
        (click)="done()"
      >
        <span class="material-icons"> check_circle </span>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>

<!-- <orwi-modifiers-form></orwi-modifiers-form> -->
