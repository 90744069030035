import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'orwi-barcode-list-modal',
  templateUrl: './orwi-barcode-list-modal.component.html',
  styleUrls: ['./orwi-barcode-list-modal.component.scss'],
})
export class OrwiBarcodeListModalComponent  implements OnInit {

  barcodes: any[] = [];

  constructor(
    private globalService: GlobalService,
  ) { }

  ngOnInit() {}

  selectBarcode(barcode: any) {
    this.globalService.closeModal(barcode);
  } 

}
