import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface GeoTrackingState {
   key: string;
   currentLocation : {lat: number, lng: number}
   status: boolean
}

export function createInitialState(): GeoTrackingState {
  return {
    key: '',
    currentLocation: {lat: 0, lng:0},
    status: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'geo-tracking' })
export class GeoTrackingStore extends Store<GeoTrackingState> {

  constructor() {
    super(createInitialState());
  }

}
