import { Injectable } from '@angular/core';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { IdGeneratorService } from '../helpers/id-generator.service';
import { OrwiService } from '../orwi/orwi.service';
import { mongo_upsert_params } from '../public-api/dto';
import { PublicApiService } from '../public-api/public-api.service';

import { Account, Customer } from './customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  selectedCustomer: Customer;
  col = 'accounts';
  constructor(
    private os: OrwiService,
    private sessionQuery: SessionQuery,
    private publicService: PublicApiService,
    private ig: IdGeneratorService
  ) {}

  getAddressByGSM(gsm: string): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise(
          '/api/pos/customer/getCustomerByGsm',
          { gsm: gsm, id: this.sessionQuery.activeLicense.orwiStore.id },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            let result = Object.assign(
              o?.response ?? ({ addresses: [] } as Customer)
            );
            this.selectedCustomer = result;
            resolve(result);
          },
          (err) => reject(err)
        );
    });
  }

  save(value: Customer): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise(
          '/api/pos/customer/saveCustomer',
          {
            ...value,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          } as Customer,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            let result = Object.assign(
              o?.response ?? ({ addresses: [] } as Customer)
            );
            resolve(result);
          },
          (err) => reject(err)
        );
    });
  }

  saveAccount(account: Account) {
    const prm = {} as mongo_upsert_params;
    if (account.id == '') {
      account.id = this.ig.generateMaxi();
    }
    let filter = { id: account.id };
    prm._filter = filter;
    prm.data = account;
    prm.col = this.col;
    prm._db = this.sessionQuery.activeLicense.orwiStore.cloudId;
    return this.publicService.upsertGeneric(prm);
  }

  getAccount(key: string): Promise<Account[]> {
    let filter = {
      name: {
        $in: [key],
      },
    };
    return this.publicService
      .getCollection({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: this.col,
        _filter: filter,
      })
      .toPromise();
  }

  getAccounts() {
    return this.publicService
      .getCollection({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: this.col,
        _filter: {},
      })
      .toPromise();
  }
}
