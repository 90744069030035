import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/services/helpers/modal.service';

@Component({
  selector: 'orwi-modal-header',
  templateUrl: './orwi-modal-header.component.html',
  styleUrls: ['./orwi-modal-header.component.scss'],
})
export class OrwiModalHeaderComponent {
  @Input() title: string = '';
  @Output() onClose: EventEmitter<null> = new EventEmitter<null>();
  constructor(private modalService: ModalService) { }
  close() {
    this.modalService.dismiss();
    this.onClose.emit();
  }
}
