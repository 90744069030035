<orwi-modal-header [title]="title" (onClose)="close()"></orwi-modal-header>
<ion-grid fixed style="overflow-y: auto">
  <ion-row>
    <ion-col size="12">
      <ion-list>
        <ion-radio-group [formControl]="listForm">
          <ion-item
            *ngFor="let item of list"
            (click)="selectItem(item)"
            [ngClass]="{
              selected:
                item[value] === listForm.value && selectedItemType === 'list'
            }"
          >
            <ion-radio
              [value]="item[value]"
              [ngClass]="{ 'radio-btn': selectedItemType === 'list' }"
              >{{ item[label] | titlecase }}</ion-radio
            >
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <div *ngIf="listForm.touched && listForm.hasError('required')">
      <ion-text color="danger" style="padding: 10px 25px">
        <small> {{ requiredText | transloco }} </small>
      </ion-text>
    </div>

    <ion-item lines="none" class="">
      <ion-buttons
        style="
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 10px;
          width: 100%;
        "
      >
        <ion-button style="--background: #ed4c4c" (click)="close()">
          {{ "Cancel" | transloco }}
        </ion-button>
        <ion-button style="--background: #4cd9ed" (click)="save()">
          {{ "Done" | transloco }}
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ion-toolbar>
</ion-footer>
