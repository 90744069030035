import { UntypedFormControl, Validators } from '@angular/forms';

export abstract class ListModalBase<T> implements ListModal<T> {
  constructor() {}
  list: T[] = [];
  value: string = 'id';
  label: string = 'name';
  title: string = 'Orwi Modal';
  requiredText: string = 'Please make a select';
  selectionMap: (element: T) => Partial<T>;
  selectedItem: T;
  abstract selectedItemType: 'radio' | 'list';

  abstract closing(item: any): void;
  listForm: UntypedFormControl = new UntypedFormControl('', Validators.required);
  save() {
    this.listForm.markAllAsTouched();
    if (this.listForm.valid) {
      if (!this.selectionMap) {
        this.closing?.(this.listForm.value);
      } else {
        this.closing?.(this.selectionMap(this.selectedItem));
      }
    }
  }
  selectItem(item: T) {
    this.selectedItem = item;
  }
  close() {
    this.closing?.(null);
  }
}

interface ListModal<T> {
  list: T[];
  value: string;
  label: string;
  title: string;
  requiredText: string;
  selectionMap: (element: T) => Partial<T>;
  selectedItem: T;
  listForm: UntypedFormControl;
  save: () => void;
  selectItem: (item: T) => void;
  close: () => void;
  closing: (item) => void;
  selectedItemType: 'radio' | 'list';
}
