import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { IonItem } from '@ionic/angular';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'orwi-lock-modal',
  templateUrl: './orwi-lock-modal.component.html',
  styleUrls: ['./orwi-lock-modal.component.scss'],
})
export class OrwiLockModalComponent implements OnInit {
  constructor(
    private glb: GlobalService,
    private cd: ChangeDetectorRef,
    private sessionService: SSOSessionService,

  ) {}

  ngOnInit() {}

  @ViewChild('pin-input', { static: false }) pinInput: IonItem;

  numberAndSymbols = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'backspace',
    '0',
    'checkmark',
  ];

  pinControl = new UntypedFormControl(
    { value: '', disabled: true },
    Validators.required
  );

  isNumeric = (value: string): boolean =>
    !new RegExp(/[^\d]/g).test(value.trim());

  handlePin() {
    let pinValue = this.pinControl.value;
    if (!pinValue) return;

    if (pinValue == '1453') {
      this.glb.closeModal(null, null, 'pin-lock-modal');
      localStorage.setItem('isLocked', '0');
    } else {
      // console.log(pinValue,this.pinControl)
      let el = document.getElementById('pin-input');
      console.log(el);

      el.classList.add('animate__shakeX');
      this.cd.detectChanges();
      setTimeout(() => {
        el.classList.remove('animate__shakeX');
        this.cd.detectChanges();
      }, 1000);
    }
  }

  changeInput(value) {
    let pinValue = this.pinControl.value;
    if (value === 'backspace') {
      this.pinControl.setValue(pinValue.slice(0, -1));

      return;
    }

    if (value === 'checkmark') {
      this.handlePin();
      return;
    }

    this.pinControl.setValue(pinValue + value);
  }

  logOut() {
    this.glb.closeModal();
    this.sessionService.logout();
  }
}
