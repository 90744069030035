<ion-header>
  <ion-toolbar>
    <ion-title>{{ "Notifications" | transloco }}</ion-title>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="refresh()">
        <p>{{ "Refresh" | transloco }}</p>
        <ion-icon slot="icon-only" name="add-outline"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-padding">
    @for (notify of notifications; track $index) {
    <ion-card id="notify-{{notify.id}}">
      <ion-card-header>
        <ion-card-subtitle>{{ notify.title }} - ({{notify.userName}})</ion-card-subtitle>
        <ion-card-title>{{ notify.source | titlecase }}</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-text>
          {{ notify.text }}
        </ion-text>
        <ion-text>
          {{ notify.detail }}
        </ion-text>
      </ion-card-content>
    </ion-card>
    } @empty {
    <ion-item>
      {{ "No Notifications" | transloco }}
    </ion-item>
    }
  </div>
  <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
