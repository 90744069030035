import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface DeliveryState {

    deliveryFormVisible : boolean
  

}

export function createInitialState(): DeliveryState {
  return {
    deliveryFormVisible: false

  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Delivery-Store',resettable: true })
export class DeliveryStore extends Store<DeliveryState> {

  constructor() {
    super(createInitialState());
  }

}