import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import { IndividualConfig, Toast } from 'ngx-toastr';

@Component({
  selector: '[notification-toastr]',
  templateUrl: './notification-toastr.component.html',
  styleUrls: ['./notification-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition('active => removed', animate('{{ easeTime }}ms {{ easing }}')),
    ]),
  ],
  preserveWhitespaces: false,
})
export class NotificationToastrComponent<ConfigPayload = any> extends Toast {
  options: IndividualConfig<ConfigPayload>;

  action(btn: any) {
    event.stopPropagation();
    this.toastPackage.triggerAction(btn);
    this.toastrService.clear();
    return false;
  }
}
