<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button icon="/assets/icons/back-btn.svg" text="{{'Cancel' | transloco}}"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
        <ion-searchbar [formControl]="searchField" debounce="500" placeholder="{{'Search Courier' | transloco}}"></ion-searchbar>
    </ion-toolbar>
</ion-header>

<ion-content>


    <ion-list>

        <ion-item (click)="selectBossUser(item)" *ngFor="let item of list$| async as list" detail>
            <ion-label>
                <h3>{{item.name}} <span *ngIf="sQ.user.id == item.id">({{'You' | transloco}})</span></h3>
                <p *ngIf="isOwner()">{{item.phone}}</p>
            </ion-label>
            <ion-note>
                {{getRole(item)}}
            </ion-note>
        </ion-item>
        <ion-item (click)="cancel()" color="warning">
            <ion-label>
                <h3>
                    {{'Cancel' | transloco}}
                </h3>
            </ion-label>
        </ion-item>
        <ion-item (click)="clear()" color="danger">
            <ion-label>
                <h3>
                    {{'Clear' | transloco}}
                </h3>
            </ion-label>
        </ion-item>

    </ion-list>
</ion-content>