import { Injectable } from '@angular/core';
import { PublicApiService } from '../public-api/public-api.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';

import { EcrSetting } from './dto/ecr-setting.model';
import { ParametersService } from 'src/app/modules/parameters/state/parameters.service';
import { GlobalService } from '../global.service';


@Injectable({
  providedIn: 'root',
})
export class EcrSettingService {
  isEcrActive: boolean = false;
  ecrSetting: EcrSetting | any = {};

  constructor(
    private publicApi: PublicApiService,
    private sessionQuery: SessionQuery,
    private parametersService: ParametersService,
    private glb : GlobalService,
  ) {
    // this.checkEcrIsActive()
  }


  async getEcrSetting(): Promise<Partial<EcrSetting>> {
    return new Promise(async (resolve, reject) => {     
      this.checkEcrIsActive()
      try {
        console.log("this.parametersService.posParameters",this.parametersService.posParameters.ecrParameters)
        this.ecrSetting.ecrIp = this.parametersService.posParameters.ecrParameters.ipAddress
        this.ecrSetting.ecrModel = this.parametersService.posParameters.ecrParameters.ecrModel
        this.ecrSetting.ecrSerialNo = this.parametersService.posParameters.ecrParameters.ecrSerialNo
        this.ecrSetting.port = this.parametersService.posParameters.ecrParameters.port
        this.ecrSetting.terminalIp = this.parametersService.posParameters.winServiceIpAddress
        resolve(this.ecrSetting)
      } catch (err: any) {
        reject(err);
      }
    });

  }

  async checkEcrIsActive() {
    this.publicApi
      .getCollection({
        _db: this.sessionQuery.activeLicense?.orwiStore.cloudId,
        col: 'pos-params',
        _filter: {
          "ecrParameters.ecrModel" : {$ne : 'none'},
          uuid : {$ne : this.glb.DeviceUUID },
          storeId: this.sessionQuery.activeLicense?.orwiStore.id,
        },
        _sort: { _id: -1 },
        _limit: 1,
      })
      .subscribe((res: EcrSetting[]) => {
        console.log('checkEcrIsActive', res)
        if (res.length > 0) {
          this.isEcrActive = true;
        } else {
          this.isEcrActive = false;
        }
      });
  }

  async saveEcrSetting(data: EcrSetting) {
    return new Promise((resolve, reject) => {
      try {
        this.publicApi
          .upsert({
            _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
            col: 'ecr-settings',
            data: data,
          })
          .then((res) => {
            resolve(res);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }
}
