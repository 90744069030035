import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface NumpadState {
  visible: boolean;
  value: any;
  for: NumpedType;
}
export type NumpedType = 'price' | 'qty' | 'pax';

export function createInitialState(): NumpadState {
  return {
    visible: false,
    value: { qty: '1.0', pax: '1.0', price: '1.0' },
    for: 'qty',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orwi-numpad', resettable: true })
export class NumpadStore extends Store<NumpadState> {
  constructor() {
    super(createInitialState());
  }
}
