<ion-header>
  <ion-toolbar>
    <ion-title>{{ "Eft Pos Revoke" | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <span class="material-icons"> close </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="padding: 10px">
    <ion-row class="header">
      <ion-col size="2"> {{ "Check No" | transloco }}</ion-col>
      <ion-col size="3"> {{ "Table Name" | transloco }}</ion-col>
      <ion-col size="4"> {{ "Waiter" | transloco }}</ion-col>
      <ion-col size="3"> {{ "Total Price" | transloco }}</ion-col>
    </ion-row>
    <!-- selected: (folioQuery.selectedRow$ | async)?.id == item.id  -->
    <ion-row
      (click)="selectItem(item)"
      *ngFor="let item of eftPosList; let isEven = even; let last = last"
      [ngClass]="{
        gray: isEven,
        'last-item': last,
        selected: item.id == this.selectedFolio.id
      }"
    >
      <ion-col size="2"> #{{ item.sequenceNo }}</ion-col>
      <ion-col size="3"> {{ item.table?.name }}</ion-col>
      <ion-col size="4"> {{ item.creator?.userName }}</ion-col>
      <ion-col size="3">
        {{ item.grandTotal | orwiCurrency }}
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<div style="display: flex; justify-content: space-between">
  <ion-button
    class="revoke-btn"
    [disabled]="this.selectedFolio.creator.userID == ''"
    (click)="revokeEftPos()"
    style="width: 70%"
  >
    <ion-icon slot="start" name="play-skip-back-outline"></ion-icon>
    {{ "Eft Pos Revoke" | transloco }}
  </ion-button>

  <ion-button class="cancel-btn" (click)="close()">
    {{ "Close" | transloco }}
  </ion-button>
</div>
