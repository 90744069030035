import { Folio } from "./orwi-folio"

export interface TableLock {
    status: 'unlocked' | 'locked';
    time?: Date;
    userName?: string;
    userID?: string
    folioId?: string;
}

export type TableState = "open" | "close" | "open-reserved" | "close-reserved" | 'multiple-open';

export class TableGroup {
    id = "";
    name = "";
    info = {
        occupancyRatio: 0,
        tables: 0,
        open: 0,
    };
    selected = false;
    tableGroupImage?: string;
}

export interface ITable {
    storeId: string;
    id: string;
    groupId: string;
    name: string;
    defaultPax?: number;
    state: TableState;
    lock: TableLock;
    info: {
        duration: number,
        amount: number,
        waiter: string,
        pax: number,
        lastOrderDuration: number
    };
    selected: boolean;
    folios: Folio[];
    multiLock?: TableLock[];
    coordinates?: { x: string, y: string };
    transform?: { rotate: number, scale: number };
    color?: string;
    tableImage?: string;
}
 