<ion-card>
  <ion-card-header>
    <div class="delivery_form_customer_label">
      <div>
        <div *ngIf="!isEditing">
          <ion-card-title>
            {{ customerName }}
          </ion-card-title>
          <ion-card-subtitle>
            {{ customerGsm }}
          </ion-card-subtitle>
        </div>
      </div>

      <div class="customer-card-btns">
        <ion-button
          style="--background: #4383ec"
          (click)="changeEditing()"
          *ngIf="!isEditing"
          >{{ "Edit" | transloco }}
        </ion-button>
        <ion-button
          style="--background: #ed4c4c"
          *ngIf="isEditing"
          (click)="cancel()"
          >{{ "Cancel" | transloco }}
        </ion-button>
        <ion-button
          style="--background: #4cd9ed"
          *ngIf="isEditing"
          (click)="save()"
          >{{ "Save" | transloco }}
        </ion-button>
        <ion-button
          style="--background: #184eae"
          *ngIf="!isEditing"
          (click)="SelectCustomer()"
          >{{ "Select" | transloco }}
        </ion-button>
      </div>
    </div>
  </ion-card-header>
  <ion-card-content>
    <ion-row [formGroup]="customerForm" *ngIf="isEditing">
      <ion-col size="12">
        <ion-item class="selector-container">
          <ion-input
            label="{{ 'Customer Name' | transloco }}"
            formControlName="customerName"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-list>
      <ion-list-header>
        <div class="delivery_form_customer_label">
          <div style="color: #171e4e">
            {{ "Defined Addresses" | transloco }}
          </div>
          <div class="add-address-btn">
            <ion-button (click)="addNewAddress()">
              <ion-icon style="color: #4383ec" name="add-outline"></ion-icon>
              {{ "Add New Address" | transloco }}
            </ion-button>
          </div>
        </div>
      </ion-list-header>
      <ion-accordion-group [value]="selectedAddressAccordion">
        <ion-radio-group
          [value]="selectedAddressId"
          (ionChange)="changeSelectedAddress($event)"
        >
          <customer-address-item
            *ngFor="let adres of Addresses.controls; let i = index"
            [addressFormGroup]="adres"
            [isEditing]="isEditing"
            (onDeleteAddress)="onDeleteAddress.emit(i)"
            (onSaveAddress)="save()"
            (onCancelAddress)="cancel()"
          ></customer-address-item>
        </ion-radio-group>
      </ion-accordion-group>
    </ion-list>
  </ion-card-content>
</ion-card>
