<ion-header class="header">
    <ion-toolbar>
        <div style="display: flex">
            <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
            <ion-title>{{'Country' | transloco}}</ion-title>
            <ion-searchbar class="custom" (ionChange)="search($event)" [placeholder]="'Search' | transloco"></ion-searchbar>
        </div>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item (click)="select(item)" *ngFor="let item of countries">
            <ion-note class="flag" slot="start">{{item.flagEmoji}}</ion-note>
            <ion-label>{{item.name}}</ion-label>
            <ion-note class="c-Code" slot="end">{{item.dialCode}}</ion-note>
        </ion-item>


        <ion-item *ngIf="countries.length == 0">
            <ion-label>{{"No Country Found" | transloco}}...</ion-label>
        </ion-item>


    </ion-list>

</ion-content>