<div
  class="   
    table-btn
    open
    ripple-parent
    ion-activatable
  "
  (click)="click()"
>
  <!-- <div class="info-cover">
    <div class="table-btn-timer">
      <ion-icon name="timer"></ion-icon>
      <div style="margin-left: 5px; font-size: 12px">
        <div>test</div>
        <div>test2</div>
      </div>
    </div>
    <div class="table-btn-info">
      <ion-icon color="secondary" name="information-circle-outline"></ion-icon>
    </div>
  </div>
  <div style="position: relative">
    <div class="name">t3</div>
 
    <div class="pax-cover">
      <ion-icon name="people"></ion-icon>
      <div class="pax">t1</div>
    </div>
  </div> -->
  <div class="lock-cover">
    <ion-icon
      [ngClass]="{ animated: animated }"
      [ngStyle]="{ color: iconColor }"
      [name]="icon"
    ></ion-icon>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>
