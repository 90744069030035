import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhonePosPageComponent } from './phone-pos/components/phone-pos-page/phone-pos-page.component';

const routes: Routes = [
  {path: 'phone-pos', component: PhonePosPageComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhonePosRoutingModule { }
