import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { ChangeCombineModalType } from 'src/app/modules/tables/model/table.model';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { ITable } from 'src/app/services/dto/tables';
import { GlobalService } from 'src/app/services/global.service';
import { SideMenuService } from 'src/app/modules/side-menu/state/side-menu.service';
import { OrwiTableListComponent } from '../orwi-table-list/orwi-table-list.component';
import { OpenTableFoliosSelectComponent } from '../open-table-folios-select/open-table-folios-select';
import { TranslocoService } from '@ngneat/transloco';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SSOSessionService } from 'src/app/modules/session/state/sso-session.service';

@Component({
  selector: 'table-change-combine',
  templateUrl: './table-change-combine.component.html',
  styleUrls: ['./table-change-combine.component.scss'],
})
export class TableChangeCombineComponent implements OnInit {
  modalType: ChangeCombineModalType = ChangeCombineModalType.Change;
  ChangeCombineModalType = ChangeCombineModalType;
  @ViewChild(OpenTableFoliosSelectComponent, { static: false })
  opTableList: OpenTableFoliosSelectComponent;
  @ViewChild(OrwiTableListComponent, { static: false })
  orwiTableList: OrwiTableListComponent;

  constructor(
    private modalCtrl: ModalController,
    private folioService: FolioService,
    private globalService: GlobalService,
    private sideMenuService: SideMenuService,
    private modalService: ModalService,
    public transloco: TranslocoService,
    private ssoService: SSOSessionService
  ) {}
  @ViewChild(IonContent) content: IonContent;

  targetTable: ITable;

  selectedTable: ITable = undefined;

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss();
  }

  scrollToBottom() {
    this.content.scrollToBottom(500);
  }

  ifSelectedTables() {
    return !this.targetTable || !this.selectedTable;
  }

  async moveTablesToTargetTable() {
    const folio: Folio =
      this.selectedTable?.folios.length > 1
        ? await this.selectFolio(this.selectedTable)
        : this.selectedTable.folios[0];
    // console.log('posted folio:', folio);
    if (!folio) {
      return;
    }
    if (folio.eftPos)
    {
      this.globalService.toast("TSM","EFT Pos'a gönderişmiş çek üzerinde işlem yapamazsınız!","bottom","danger")
      return
    }
    this.globalService.openAlert({
      header: this.transloco.translate(
        'Do You Want to ' +
          (this.modalType == ChangeCombineModalType.Change
            ? 'Change'
            : 'Combine')
      ),
      buttons: [
        {
          text: this.transloco.translate('Yes'),
          handler: () => {
            this.changeOrCombine(folio);
          },
        },
        this.transloco.translate('No'),
      ],
    });
  }

  async changeOrCombine(selectedFolio: Folio) {
    if (this.targetTable.folios.length > 0) {
      // combine
      if(!this.ssoService.checkPermission('folio-combine')){
        this.globalService.permissionToast()
        return
      }
      this.folioService.combineFolio(
        { id: this.targetTable.id, name: this.targetTable.name },
        selectedFolio,
        this.targetTable.folios[0]
      );
    } else {
      // move
      this.folioService.moveFolio(
        { id: this.targetTable.id, name: this.targetTable.name },
        selectedFolio
      );
    }

    this.selectedTable.folios = this.selectedTable.folios.filter(
      (el) => el.id != selectedFolio.id
    );
    this.orwiTableList.clear();
    this.opTableList.clear();
    this.globalService.toast(
      this.transloco.translate(
        `Table ${
          this.modalType == ChangeCombineModalType.Change ? 'change' : 'combine'
        } is completed.`
      ),
      '',
      'bottom',
      'success'
    );
    this.sideMenuService.selectMenuById('open-folios');
    this.modalCtrl.dismiss();
    
  }
  async selectFolio(selectedTable: ITable): Promise<Folio> {
    let  data = await this.modalService.showSelectFolioModal(selectedTable)
    return data
  }
  onSelectTables(e: ITable) {
    this.scrollToBottom();
    this.selectedTable = e;
  }
}
