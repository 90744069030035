import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FoodAppAccount, FoodAppApplication, FoodAppCategory, FoodAppModifier, FoodAppProduct, FoodAppServiceStatus, FoodAppStore, RestaurantMenu, RestaurantProduct, RestoranModifier } from './dto';

export interface FoodAppsIntegrationState {
  id: string
  accounts: FoodAppAccount[]
  activeAccount: FoodAppAccount,

  activeFoodAppProduct: FoodAppProduct
  activeFoodAppModifier: FoodAppModifier
  activeRestaurantProduct: RestaurantProduct
  activeRestaurantModifier: RestoranModifier

  foodAppAplications: FoodAppApplication[]
  foodAppCategories: FoodAppCategory[]
  foodAppProducts: FoodAppProduct[],
  foodAppModifiers: FoodAppModifier[],
  foodAppAccounts: FoodAppAccount[],

  restaurantMenu: RestaurantMenu
  restaurantProduct: RestaurantProduct[],

  foodAppStores: FoodAppStore[],


  fooAppStatuses: FoodAppServiceStatus[] 
  fooAppApplications: FoodAppApplication[] 

 

}

export function createInitialState(): FoodAppsIntegrationState {
  return {
    id: null,
    accounts : [],
    activeAccount: undefined,
    activeFoodAppProduct: undefined,
    activeFoodAppModifier: undefined,
    activeRestaurantProduct: undefined,
    activeRestaurantModifier: undefined,
    
    foodAppAplications: [],

    foodAppAccounts: [],
    foodAppCategories:[],
    foodAppProducts:[],
    foodAppModifiers:[],

    restaurantMenu: undefined,
    restaurantProduct: [],

    foodAppStores: [],

    fooAppStatuses : [],
    fooAppApplications: []


    
    
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'integration-Store' })
export class FoodAppsIntegrationStore extends Store<FoodAppsIntegrationState> {

  constructor() {
    super(createInitialState());
    
  }

}