import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardService } from '../session/state/guard.service';
import { KitchenDisplayPage } from './components/kitchen-display-main/kitchen-display.page';
import { KitchenDisplaySettingsComponent } from './components/kitchen-display-settings/kitchen-display-settings.component';




const routes: Routes = [
  {path: '', component: KitchenDisplayPage, canActivate: [GuardService]},
  {path: 'kitchen-display-home', component: KitchenDisplayPage, canActivate: [GuardService]},
  {path: 'kitchen-display-settings', component: KitchenDisplaySettingsComponent, canActivate: [GuardService]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KitchenDisplayRoutingModule { }
