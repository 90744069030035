import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUser, IoPermission, UserInfo } from './dto/sso-dto';
import { SessionStore } from './session.store';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { SessionQuery } from './session.query';
import { NavController } from '@ionic/angular';


@Injectable({ providedIn: 'root' })
export class SSOSessionService {
  apiKey: string = '097d9497-8ae4-9546-b0c4-34d41a420902!?@6048!ba5c**fd72';
  loginApiUrl: string = 'https://sso-service.kerzz.com:4500/api/user/login';
  lostPasswordUrl: string =
    'https://sso-service.kerzz.com:4500/api/user/resetpwd';
  remember: boolean = false;
  acessToken: string = '';
  ssoUrl = 'https://sso-service.kerzz.com:4500';

  userInfo: UserInfo | undefined;
  perms: IoPermission | undefined;
  userStatus: string;
  userRegion: string;
  userLanguage: string;
  userGsm: string;

  constructor(
    private nav: NavController,
    private sessionQuery: SessionQuery,
    private publicApi: PublicApiService,
    private sessionStore: SessionStore,
    private http: HttpClient
  ) {
    this.remember = localStorage.getItem('remember') == 'true' ? true : false;
  }

  loadAppUsers() {
    let persons = [];
    this.publicApi
      .getCollection({
        _db: 'sso-db',
        col: 'user-apps',
        _filter: { app_id: 'this.glb.appId' },
        _sort: { user_name: 1 },
      })
      .toPromise()
      .then((o) => {
        console.log('app-users', o);
        o.forEach(async (element) => {
          let userO = await this.publicApi
            .getCollection({
              _db: 'sso-db',
              col: 'users',
              _filter: { id: element.user_id },
            })
            .toPromise();
          let user = userO[0];

          if (user) {
            // console.log(user.email)
            persons.push({
              id: element.user_id,
              name: element.user_name,
              icon: undefined,
              mail: user.mail,
              phone: user.phone,
            });
          }
        });
        //this.sessionStore.update({ appUsers: persons })
      })
      .catch((e) => {
        console.log(e);
      });
  }

  autoLogin(): Promise<any> {
    let email = localStorage.getItem('email');
    let pwd = localStorage.getItem('password');
    if (email && email !== 'undefined') {
      return this.loginWithEmail(email, pwd);
    } else {
      return new Promise((reject) => {
        reject('not-remember');
      });
    }
  }

  loginWithEmail(email, password): Promise<any> {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          apiKey: this.apiKey,
        }),
      };
      this.http
        .post(
          this.loginApiUrl,
          { email: email, password: password },
          httpOptions
        )
        .subscribe(
          async (o: any) => {
            if (o.licances.length == 0) {
              reject('Bu Aplikasyon İçin Lisansınız Yok');
              return;
            }
            await this.loginTransactions(o);
            resolve(this.userInfo);
          },
          (e) => {
            this.sessionStore.update({ authStatus: false });
            reject(this.parseError(e));
          }
        );
    });
  }

  requestOtp(gsm): Promise<'ok' | 'new'> {
    return new Promise((resolve, reject) => {
      console.log('requestOtpSms');
      this.http
        .post(
          this.ssoUrl + '/api/login/requestOtpSms',
          { gsm: gsm },
          this.getSSOHttpHeader()
        )
        .subscribe(
          (o: any) => {
            let result = Object.assign(o);
            console.log(result);
            this.userStatus = result.message == 'ok' ? 'registered' : 'new';
            resolve(result.message);
          },
          (e) => {
            console.log(e);
            reject(e);
          }
        );
    });
  }

  verifyOtp(_gsm, _otp): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.ssoUrl + '/api/login/verifyOtpSms',
          { gsm: _gsm, otpCode: _otp },
          this.getSSOHttpHeader()
        )
        .subscribe(
          (o: UserInfo) => {
            if (o.licances.length == 0) {
              reject('Bu Aplikasyon İçin Lisansınız Yok');
              return;
            }
            this.loginTransactions(o);
            resolve(this.userInfo);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }

  autoLoginWithToken(token): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.ssoUrl + '/api/login/autoLogin',
          { token },
          this.getSSOHttpHeader()
        )
        .subscribe(
          async (o: UserInfo) => {
            console.log('autoLoginWithToken', o)
            if (o.licances.length == 0) {
              reject('Bu Aplikasyon İçin Lisansınız Yok');
              return;
            }
            await this.loginTransactions(o);
            resolve(this.userInfo);
          },
          (e) => {
            console.error('autoLoginWithToken', e)
            reject(this.parseError(e));
          }
        );
    });
  }

  loginTransactions(o: UserInfo) {
    return new Promise((resolve, reject) => {
      try {
        console.log(o);
        let user: AppUser = {
          id: o.id,
          name: o.name,
          mail: o.mail,
          phone: o.phone,
          gender: o.gender,
          image: o.image,
          permissions: o.permissions,
          legalNotePermissions: o.legalNotePermissions,
          userLanguage: o.userLanguage,
          userRegion: o.userRegion,
          licances: o.licances,
          dateOfBirth: o.dateOfBirth,
        };

        this.userInfo = o;
        let activeLicanceId = this.sessionQuery.getActiveLicense();
        this.userInfo.licances.map((el) => {
          el.active = activeLicanceId == el.licanceId ? true : false;
        });
        let activeLicense = this.userInfo.licances.find(
          (o) => o.licanceId == activeLicanceId
        );
        if (!activeLicense) {
          activeLicense = this.userInfo.licances[0];
        }

        this.acessToken = this.userInfo.accessToken;

        this.sessionStore.update({
          userInfo: this.userInfo,
          authStatus: true,
          user: user,
        });

        console.log('activeLicense', activeLicense);
        this.sessionStore.update({ activeLicense: activeLicense });
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        localStorage.setItem('userId', this.userInfo.id);
        localStorage.setItem('userName', this.userInfo.name);
        localStorage.setItem('cloudId', activeLicense.orwiStore.cloudId);

        resolve(true);
      } catch (err: any) {
        reject(err);
      }
    });
  }

  checkPermission(permId: string) {
    if (
      this.sessionStore
        .getValue()
        .activeLicense.allPermissions.filter((x) => x.id === permId).length !==
      0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getAccessToken() {
    let uinf = localStorage.getItem('userInfo');
    let oUinf = JSON.parse(uinf);
    let at = oUinf?.accessToken;
    return at;
  }

  setRemember(value) {
    localStorage.setItem('remember', value);
    this.remember = value;
  }

  logout() {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('credentials');

    this.setRemember(false);

    this.sessionStore.update({
      activeLicense: undefined,
      user: undefined,
      userInfo: undefined,
      authStatus: false,
    });

    this.nav.navigateRoot('');
  }

  lostPassword(mail): Promise<any> {
    return new Promise((resolve, reject) => {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          apiKey: this.apiKey,
        }),
      };
      this.http
        .post(this.lostPasswordUrl, { email: mail }, httpOptions)
        .subscribe(
          (o) => {
            o; //reserved
            resolve('ok');
          },
          (e) => {
            reject(this.parseError(e));
          }
        );
    });
  }

  parseError(e) {
    if (e.error.message) {
      return e.error.message;
    } else {
      console.log(e);
      return 'Server Error';
    }
  }

  getSSOHttpHeader() {
    let httpOptions = {
      reportProgress: true,
      headers: new HttpHeaders({
        'x-user-token': this.acessToken,
        'x-api-key': this.apiKey,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };
    return httpOptions;
  }

}
