import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Folio } from 'src/app/services/dto/orwi-folio';

export interface OpenFoliosState extends EntityState<Folio, string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'open-folios',resettable: true })
export class OpenFoliosStore extends EntityStore<OpenFoliosState> {
  constructor() {
    super() ;
  }
}