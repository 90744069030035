import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionQuery } from '../../session/state/session.query';
import { AppUsersQuery } from './pos-users.query';
import { AppUsersStore } from './pos-users.store';
import { SSOSessionService } from '../../session/state/sso-session.service';
import { AppUser, Role } from '../../session/state/dto/sso-dto';

@Injectable({ providedIn: 'root' })
export class AppUsersService {



  constructor(
    private sQ: SessionQuery,
    private kbQ: AppUsersQuery,
    private bS: AppUsersStore,
    private http: HttpClient,
    private ss: SSOSessionService
  ) {

  }

  addUser() {
    return new Promise((resolve, reject) => {
      try {
        this.http.post(this.ss.ssoUrl + "/api/user/addUser", this.kbQ.activeAppUser, this.ss.getSSOHttpHeader()).subscribe((o: any[]) => {
          resolve(o)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  deleteLicance() {
    return new Promise((resolve, reject) => {
      try {
        this.http.post(this.ss.ssoUrl + "/api/user/deleteLicance",
          { user_id: this.kbQ.activeAppUser.id, licance_id: this.sQ.activeLicense.licanceId },
          this.ss.getSSOHttpHeader()).subscribe((o: any[]) => {
            resolve(o)
          })
      } catch (err) {
        reject(err)
      }
    })
  }



  getRoles() {
    this.http.post(this.ss.ssoUrl + "/api/role/getRoles", {}, this.ss.getSSOHttpHeader()).subscribe((o: Role[]) => {
      let level = Math.min.apply(Math, this.sQ.activeLicense.roles.map(function (item) { return item.level }));
      console.log("level", level)
      let rl = o.filter(rlx => rlx.level <= level)
      this.bS.update({ roles: rl })
    })
  }


  getUsers() {



    //this.getBranches(lic.licanceId)

    let usrs: AppUser[] = []

    this.http.post(this.ss.ssoUrl + "/api/user/getusers", { licance_id: this.sQ.activeLicense.licanceId }, this.ss.getSSOHttpHeader()).subscribe((o: AppUser[]) => {


      let mainLevel = Math.min.apply(Math, this.sQ.activeLicense.roles.map(function (item) { return item.level }));

      for (const iterator of o) {
        let rl = iterator.licances.find(lic => lic.licanceId == this.sQ.activeLicense.licanceId)

        let level = Math.min.apply(Math, rl?.roles?.map(function (item) { return item.level }))

        if (mainLevel >= level) {
          usrs.push(iterator)
        }

      }

      this.bS.update({ appUsers: usrs })


    })







  }


}


