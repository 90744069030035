import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'orwi-prompt',
  templateUrl: './orwi-prompt.component.html',
  styleUrls: ['./orwi-prompt.component.scss'],
})
export class OrwiPromptComponent implements OnInit {


  inputValue
  @Input() backDropEnabled = true
  @Input() closeOnBackDropClick = false
  @Input() buttons: promptButton[]
  @Input() message = "Are you like Orwi?"
  @Input() title = "Orwi Prompt"
  @Input() closeable
  @Input() inputs: promptInput[] = []
  @Input() height = "200px"

  @Output() click = new EventEmitter<promptResponse>()
  @Output() closeClick = new EventEmitter<any>()
  // @Output() valueEmit = new EventEmitter<{ button: promptButton, inputs?: promptInput[] }>()


  constructor(
    private transloco: TranslocoService,
    private glb: GlobalService,
    //private cl: OrwiPromptService
  ) {


    this.buttons = [{ id: 'cancel', text: this.transloco.translate("Cancel"), color: 'secondary' }, { id: 'ok', text: this.transloco.translate("Ok"), color: 'primary' }]

  }

  ngOnInit() {
    let prmpt = document.getElementById('orwi-prompt-prompt')
    let title = document.getElementById('orwi-prompt-title')
    title.innerHTML = "<div>" + this.title + "</div>"

    let el = document.getElementById('orwi-prompt-content')
    el.innerHTML = "<div>" + this.message + "</div>"

    if (this.inputs.length > 0) {
      prmpt.style.maxHeight = "300px"
      el.style.height = "40%"
    } else {
      prmpt.style.maxHeight = this.height
    }


  }

  ngAfterViewInit() {
    let comp = document.getElementById('orwi-prompt-prompt')
    comp.classList.add("animate__bounceIn")

    setTimeout(() => {
      comp.classList.remove("animate__bounceIn")
    }, 1000);

  }

  closeAntimate() {
    return new Promise((resolve, reject) => {
      try {
        let comp = document.getElementById('orwi-prompt-prompt')
        comp.classList.add("animate__bounceOut")
        setTimeout(() => {
          comp.classList.remove("animate__bounceOut")
          resolve(true)
        }, 500);
      } catch (err: any) {
        reject(err)
      }
    })
  }


  closeButtonClick() {
    if (!this.closeable) return
    this.click.emit({ inputs: [], button: { id: "cancel", text: "" } })
    this.closeAntimate().then(o => {
      o//reserved
      //this.cl.destroyComponent(this)
    })
  }

  async btnClick(item: promptButton) {

    if (item.id == "ok") {

      if (await this.checkRequiredInputs()) {
        this.click.emit({ button: item, inputs: this.inputs })
      } else {
        return
      }

    } else {
      this.click.emit({ button: item })
    }

  }

  async checkRequiredInputs(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      try {
        let returnStatus = true
        let reqs = this.inputs.filter(o => o.required)
        if (reqs.length > 0) {
          for (const iterator of reqs) {
            if (!iterator.value || iterator.value == '') {
              this.glb.shakeElement("input-" + iterator.id)
              returnStatus = false
            }
          }
          resolve(returnStatus)
        } else {
          resolve(returnStatus)
        }

      } catch (err: any) {
        reject(err)
      }
    })
  }
}

export interface promptButton {
  id: string
  text: string
  color?: 'success' | 'error' | 'warning' | 'danger' | 'primary' | 'secondary' | 'tertiary'
}

export interface promptInput {
  type: 'text' | 'tel' | 'number' | 'textarea'
  id: string
  placeholder?: string
  value?: any
  required?: boolean
}

export interface promptResponse {
  button: promptButton
  inputs?: promptInput[]
}

export interface orwiPrompt {
  title?: string
  message: string
  closable?: boolean
  buttons?: promptButton[]
  backDropEnabled?: boolean
  closeOnBackDropClick?: boolean
  inputs?: promptInput[]
  height?
}