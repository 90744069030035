import { Injectable } from '@angular/core';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { OrwiCountry } from '../dto/orwi-address';
import { OrwiService } from '../orwi/orwi.service';


@Injectable({
  providedIn: 'root',
})
export class CountryCodesService {
  countries: OrwiCountry[] = [];
  activeCountry: OrwiCountry = new OrwiCountry();

  constructor(
    private orwi: OrwiService,
    private sessionQuery: SessionQuery
  ) { }

  /* 
  init() {
    this.status = "loading"
    return new Promise((resolve, reject) => { 
      this.getCountries().then(o => {
        this.glb.consolelog("hello")
        this.status = "done"
        this.activeCountry = this.countries.find(o => o.code == this.glb.user_region)
        resolve(true)
      })

    })  
  } */

  getCountries() {
    return new Promise((resolve, reject) => {
      return this.orwi
        .serviceRequest('/api/helper/getCountries', {}, this.sessionQuery.token)
        .subscribe((o: any) => {
          console.log("getCountries", o)
          this.countries = Object.assign(o.response);
          return resolve(this.countries);
        }, err => {
          console.log("getCountries-error", err)
          return reject(err);
        });
    });
  }

  getCities(countryCode: string) {
    return new Promise((resolve, reject) => {
      try {
        this.orwi
          .serviceRequest(
            '/api/helper/getCities',
            { countryCode: countryCode },
            this.sessionQuery.token
          )
          .subscribe((o) => {
            resolve(o['response']);
          });
      } catch (err: any) {
        reject(err)
      }
    });
  }

  getTowns(countryCode: string, cityCode: string) {
    return new Promise((resolve, reject) => {
      try {
        this.orwi
          .serviceRequest(
            '/api/helper/getTowns',
            {
              countryCode: countryCode,
              cityCode: cityCode,
            },
            this.sessionQuery.token
          )
          .subscribe((o) => {
            resolve(o['response']);
          });
      } catch (err: any) {
        reject(err)
      }
    });
  }
}
