import { Injectable } from '@angular/core';
import { TableStore } from './table.store';
import * as moment from 'moment';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { ITable, TableGroup } from 'src/app/services/dto/tables';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import * as localforage from 'localforage'; // this works!!!
import { GlobalService } from 'src/app/services/global.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { arrayUpdate } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SessionQuery } from '../../session/state/session.query';
import { SSOSessionService } from '../../session/state/sso-session.service';

@Injectable({ providedIn: 'root' })
export abstract class TableService {
  constructor(
    private orwiStoreStore: OrwiStoreStore,
    private glb: GlobalService,
    private folioStore: FolioStore,
    private orwiService: OrwiService,
    private appService: AppService,
    private fq: FolioQuery,
    private tableStore: TableStore,
    private folioService: FolioService,
    private translocoService: TranslocoService,
    private modalService: ModalService,
    private sessionquery: SessionQuery,
    private ssoService: SSOSessionService
  ) {
    //update table & table groups for open folios

    this.fq.openFolios$.subscribe((openFolios) => {
      this.updateTableInfo(openFolios);
      this.updateTableGroupInfo();
      openFolios = undefined;
      console.warn('Open Folios Array Cleaned');
    });

    //update table & table groups for closed folios
    this.fq.closedFolio$.subscribe((closed) => {
      if (closed.length > 0) {
        //#checkthis
        this.updateTableInfo(closed);
        this.updateTableGroupInfo();
      }
    });
  }

  combineFolio(source, target) {
    let el1 = document.getElementById(source.id);
    let el2 = document.getElementById(target.id);
    this.animateTables(el1, el2, true);

    let title = '';
    let msg = '';

    title = this.translocoService.translate('Combine Tables');
    msg = this.translocoService.translate(
      `Are you want combine table {{name}} with {{tname}} ?`,
      { name: source.name, tname: target.name }
    );
    this.glb.toastButton(title, msg, 'top', 'success', true, true).then((o) => {
      if (o === 'yes') {
        this.changeOrCombineByFolio(source, target);
      }
      this.animateTables(el1, el2, false);
    });
  }

  changeOrCombine(id: string, findedTableId: string) {
    //  debugger;
    let source = this.tableStore.getValue().tables.find((o) => o.id == id);
    let target = this.tableStore
      .getValue()
      .tables.find((o) => o.id == findedTableId);

    if (target.folios[0]?.eftPos || source.folios[0]?.eftPos) {
      this.glb.toast(
        'TSM',
        "EFT Pos'a gönderilmiş çek üzerinde işlem yapamazsınız!",
        'bottom',
        'danger'
      );
      return;
    }
    let el1 = document.getElementById(source.id);
    let el2 = document.getElementById(target.id);
    this.animateTables(el1, el2, true);

    let title = '';
    let msg = '';
    let action: 'combine' | 'move';
    if (target.state == 'open' && source.state == 'open') {
      title = this.translocoService.translate('Combine Tables');
      msg = this.translocoService.translate(
        `Are you want combine table {{name}} with {{tname}} ?`,
        { name: source.name, tname: target.name }
      );
      // msg = `Are you want combine table ${source.name} with ${target.name} ?`;
      action = 'combine';
    } else if (target.state == 'open' && source.state == 'close') {
      title = this.translocoService.translate('Move Table');
      action = 'move';
      msg = `Are you want move table ${source.name} to ${target.name} ?`;
    } else if (
      (target.state == 'open' && source.state == 'close') ||
      (target.state == 'close' && source.state == 'open')
    ) {
      title = 'Move Table';
      msg = this.translocoService.translate(
        `Are you want move table {{sourceName}} to {{Targetname}} ?`,
        { Targetname: target.name, sourceName: source.name }
      );
      // msg = `Are you want move table ${source.name} to ${target.name} ?`;
      action = 'move';
    } else {
      return;
    }

    this.glb.toastButton(title, msg, 'top', 'success', true, true).then((o) => {
      console.log(o);
      if (o === 'yes') {
        if (action == 'move') {
          this.changeOrCombineByFolio(source, target);

          // this.moveTable(source, target);
        } else if (action == 'combine') {
          this.changeOrCombineByFolio(source, target);
        }
      }
      this.animateTables(el1, el2, false);
    });
  }

  animateTables(el1: HTMLElement, el2: HTMLElement, animate: boolean) {
    if (animate) {
      if (el1) {
        el1.classList.add('animate__infinite');
        el1.classList.add('animate__bounce');
      }

      if (el2) {
        el2.classList.add('animate__infinite');
        el2.classList.add('animate__bounce');
      }
    } else {
      if (el1) {
        el1.classList.remove('animate__infinite');
        el1.classList.remove('animate__bounce');
      }
      if (el2) {
        el2.classList.remove('animate__infinite');
        el2.classList.remove('animate__bounce');
      }
    }
  }

  initialize(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.fetchTables(this.orwiStoreStore.getValue().id).then((o) => {
          this.tableStore.update({
            tableGroups: o.tableGroups,
            tables: o.tables,
          });
          resolve(true);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  selectTableGroup(id: string) {
    let tableGroup = this.tableStore
      .getValue()
      .tableGroups.find((_table_group) => _table_group.id == id);
    tableGroup.selected = true;
    let tables = this.tableStore
      .getValue()
      .tables.filter((_table) => _table.groupId == tableGroup.id);

    this.tableStore.update({
      selectedTableGroup: tableGroup,
      activeTables: tables,
    });
    this.tableStore.update((tableState) => {
      tableState.tableGroups
        .filter((_tableGroup) => _tableGroup.id !== id)
        .map((__tableGroup) => {
          __tableGroup.selected = false;
        });
    });
  }

  async selectTable(tableId: string, folioId?: string) {
    // await this.folioService.loadOpenFolios();
    let table = this.tableStore.getValue().tables.find((o) => o.id == tableId);
    //MASADA BİRDEN FAZLA ÇEK VAR İSE
    if (table.folios.length > 1) {
      let modal = this.modalService.showSelectFolioModal(table);
      modal.then(async (o) => {
        if (o) {
          console.log('finding in db');
          let folio = await this.folioService._posGetFolioById(o.id);

          if (folio.lock.status == 'locked') {
            let diff = moment(table.lock.time);
            let msg = `Masa  ${
              this.folioStore.getValue().activeFolio.lock.userName
            } tarafından  ${moment().diff(
              diff,
              'minute'
            )} dakika önce kilitlenmiştir.`;

            if (this.ssoService.checkPermission('user-lock-table')) {
              msg = msg + ' Masa Kilidini Kaldırmak İster misiniz?';
              this.glb
                .toastButton('Masa Kilit', msg, 'bottom', 'warning', true, true)
                .then(async (o) => {
                  if (o === 'yes') {
                    this.folioService.lockUnlockFolio(folioId, 'unlocked');
                    this.tableStore.update({ selectedTable: table });
                    if (table.state == 'open') {
                      if (folioId) {
                        console.log('finding in db');
                        let folio = await this.folioService._posGetFolioById(
                          folioId
                        );
                        let ix = table.folios.findIndex((f) => f.id == folioId);
                        if (ix) table.folios[ix] = folio;
                        this.folioService.updateActiveFolio(
                          table.folios.find((f) => f.id === folioId),
                          'low'
                        );
                      } else {
                        console.log('finding in db');
                        let fid = table.folios[0].id;
                        let folio = await this.folioService._posGetFolioById(
                          fid
                        );
                        table.folios[0] = folio;
                        this.folioService.updateActiveFolio(
                          table.folios[0],
                          'low'
                        );
                      }
                    } else {
                      if (!this.ssoService.checkPermission('folio-open')) {
                        this.glb.permissionToast();
                        return;
                      }
                      this.folioService.createFolio(table.id, table.name);
                    }

                    this.glb.navigateFolioForm();
                  }
                });
            } else {
              this.glb.toast('Masa Kilidi', msg, 'bottom', 'warning');
              return;
            }
          } else {
            let ix = table.folios.findIndex((f) => f.id == o.id);
            if (ix) table.folios[ix] = folio;
            this.folioService.updateActiveFolio(folio, 'low');
            this.glb.navigateFolioForm();
          }
        }
      });
      return;
    }

    if (table.lock.status == 'locked') {
      let diff = moment(table.lock.time);
      let msg = `Masa  ${
        this.folioStore.getValue().activeFolio.lock.userName
      } tarafından  ${moment().diff(
        diff,
        'minute'
      )} dakika önce kilitlenmiştir.`;

      if (this.ssoService.checkPermission('user-lock-table')) {
        msg = msg + ' Masa Kilidini Kaldırmak İster misiniz?';
        this.glb
          .toastButton('Masa Kilit', msg, 'bottom', 'warning', true, true)
          .then(async (o) => {
            if (o === 'yes') {
              this.folioService.lockUnlockFolio(folioId, 'unlocked');
              this.tableStore.update({ selectedTable: table });
              if (table.state == 'open') {
                if (folioId) {
                  console.log('finding in db');
                  let folio = await this.folioService._posGetFolioById(folioId);
                  let ix = table.folios.findIndex((f) => f.id == folioId);
                  if (ix) table.folios[ix] = folio;
                  this.folioService.updateActiveFolio(
                    table.folios.find((f) => f.id === folioId),
                    'low'
                  );
                } else {
                  console.log('finding in db');
                  let fid = table.folios[0].id;
                  let folio = await this.folioService._posGetFolioById(fid);
                  table.folios[0] = folio;
                  this.folioService.updateActiveFolio(table.folios[0], 'low');
                }
              } else {
                if (!this.ssoService.checkPermission('folio-open')) {
                  this.glb.permissionToast();
                  return;
                }
                this.folioService.createFolio(table.id, table.name);
              }

              this.glb.navigateFolioForm();
            }
          });
      } else {
        this.glb.toast('Masa Kilidi', msg, 'bottom', 'warning');
        return;
      }
    } else {
      this.tableStore.update({ selectedTable: table });
      if (table.state == 'open') {
        if (folioId) {
          console.log('finding in db');
          let folio = await this.folioService._posGetFolioById(folioId);
          let ix = table.folios.findIndex((f) => f.id == folioId);
          if (ix) table.folios[ix] = folio;
          this.folioService.updateActiveFolio(
            table.folios.find((f) => f.id === folioId),
            'low'
          );
        } else {
          console.log('finding in db');
          let fid = table.folios[0].id;
          let folio = await this.folioService._posGetFolioById(fid);
          table.folios[0] = folio;
          this.folioService.updateActiveFolio(table.folios[0], 'low');
        }
      } else {
        if (!this.ssoService.checkPermission('folio-open')) {
          this.glb.permissionToast();
          return;
        }
        this.folioService.createFolio(table.id, table.name);
      }

      this.glb.navigateFolioForm();
    }
  }

  findTableAndNavigate() {}

  private updateTableInfo(folios: Folio[] = []) {
    if (!folios || folios?.length == 0) return;
    let { tables } = this.tableStore.getValue();
    let unfind = tables.filter((o) => {
      return (
        o.state == 'open' && folios.findIndex((fl) => fl.table.id == o.id) == -1
      );
    });

    unfind.forEach((x) => {
      this.tableStore.update(({ tables }) => {
        const table = tables.find((tbl) => tbl.id == x.id);

        if (table && table.state == 'open') {
          table.folios = [];
          table.state = 'close';
          table.info.pax = 0;
          table.info.amount = 0;
          table.info.amount = 0;
          table.info.duration = 0;
          table.info.lastOrderDuration = 0;
        }
      });
    });

    for (const iterator of tables) {
      iterator.folios = [];
    }
    const updatedTables: ITable[] = [];
    for (const folio of folios) {
      const table = tables.find((t) => t.id == folio.table.id);
      if (folio.status == 'open') {
        const duration = moment().diff(folio.creation, 'minutes');

        if (table) {
          // let tableFolio = table.folios.findIndex((tf) => tf.id == folio.id);
          // if (tableFolio > -1) {
          //   table.folios[tableFolio] = folio;
          // } else {
          //   table.folios.push(folio);
          // }

          table.folios.push(folio);
          table.state = 'open';
          table.info.pax = folio.pax;
          table.info.amount = folio.grandTotal;
          table.info.amount = folio.grandTotal;
          table.info.duration = duration;
          table.lock = folio.lock;
        }
      } else {
        if (table) {
          table.folios = [];
          table.state = 'close';
          table.info.pax = 0;
          table.info.amount = 0;
          table.info.amount = 0;
          table.info.duration = 0;
        }
      }

      updatedTables.push(table);
    }
    debugger
    for (const tableItem of tables) {
      let foundedTable = updatedTables.findIndex(
        (tbl) => tbl?.id == tableItem?.id
      );
      if (foundedTable >= 0) {
        tables[foundedTable] = {
          ...tableItem,
          ...tables[foundedTable],
        };
      }
    }
    this.tableStore.update(() => ({
      tables,
    }));
  }

  private updateTableGroupInfo() {
    let { tables,tableGroups } = this.tableStore.getValue();
    tableGroups.forEach((tableGroup) => {
      let openCount = tables.filter(
        (table) => table.state == 'open' && table.groupId == tableGroup.id
      ).length;
      let tableCount = tables.filter((table) => table.groupId == tableGroup.id)
        .length;
      this.tableStore.update(({ tableGroups }) => {
        let foundedGroup = tableGroups.find((tg) => tg.id == tableGroup.id);
        foundedGroup.info.open = openCount;
        foundedGroup.info.tables = tableCount;
        let ratio = (openCount / tableCount) * 100 || 0;
        ratio = ratio == Infinity ? 0 : ratio;
        foundedGroup.info.occupancyRatio = parseInt(ratio.toFixed(0));
      });
    });
  }

  async fetchTables(id): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let tableGroups = [];
        let tables = [];

        if (this.appService.connection == 'offline') {
          let data = localforage.getItem('tables');
          console.log('BURADA', this.appService.connection);
          resolve(data);
          return;
        }

        this.orwiService
          .serviceRequestPromise(
            '/api/pos/table/getTables',
            { id: id },
            this.sessionquery.token
          )
          .then((o: any) => {
            let storeTables = Object.assign(
              o.response ?? { tables: [], groups: [] }
            );

            for (const iterator of storeTables.groups) {
              let tblgrp = new TableGroup();
              tblgrp.id = iterator.id;
              tblgrp.name = iterator.name;

              tblgrp.info = {
                occupancyRatio: 0,
                tables: storeTables.tables.filter(
                  (x) => x.groupName == iterator.id
                ).length,
                open: 0,
              };
              tableGroups.push(tblgrp);
            }

            for (const iterator of storeTables.tables) {
              let tb: ITable = {
                lock: { status: 'unlocked' },
                info: {
                  pax: 0,
                  waiter: '',
                  duration: 0,
                  amount: 0,
                  lastOrderDuration: 0,
                },
                id: iterator.id,
                name: iterator.name,
                groupId: iterator.groupId,
                state: 'close',
                selected: false,
                folios: [],
                storeId: iterator.storeId,
                coordinates: iterator.coordinates || { x: '0px', y: '0px' },
                color: iterator.color,
              };

              tables.push(tb);
            }
            let resolvedData = { tables: tables, tableGroups: tableGroups };

            this.saveToLocalStorage(resolvedData);
            resolve(resolvedData);
            this.tableStore.update({
              tableGroups: resolvedData.tableGroups,
              tables: resolvedData.tables,
            });
            // console.log('getStoraTables', o);
          });
      } catch (err: any) {
        reject(err);
      }
    });
  }

  async moveTable(source: ITable, target: ITable) {
    let fl = source.folios[0];
    fl.table.id = target.id;
    fl.table.name = target.name;
    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayUpdate(openFolios, fl.id, fl, 'id'),
    }));

    this.tableStore.update({ moved: source });

    this.glb.showLoading();
    await this.folioService._posSaveFolio(fl, 'high');
    this.glb.closeLoading();
  }

  async changeOrCombineByFolio(source: ITable, target: ITable) {
    const isCombine: boolean = target.folios.length > 0;

    const folio: Folio = await this.selectFolio(source);
    // console.log('selected folio for combine:', folio);
    if (!folio) {
      this.glb.toast(
        this.translocoService.translate(
          `Table ${isCombine ? 'combine' : 'change'} is canceled.`
        ),
        '',
        'bottom',
        'warning'
      );
      return;
    }

    if (isCombine) {
      // combine
      this.glb.showLoading();
      await this.folioService.combineFolio(
        { id: target.id, name: target.name },
        folio,
        target.folios[0]
      );
      this.glb.closeLoading();
    } else {
      // move
      this.glb.showLoading();
      await this.folioService.moveFolio(
        { id: target.id, name: target.name },
        folio
      );
      this.glb.closeLoading();
    }
    this.tableStore.update({ moved: source });

    source.folios = source.folios.filter((el) => el.id != folio.id);

    this.glb.toast(
      this.translocoService.translate(
        `Table ${isCombine ? 'combine' : 'change'} is completed.`
      ),
      '',
      'bottom',
      'success'
    );
  }

  async selectFolio(source: ITable): Promise<Folio> {
    const folioCount: number = source?.folios.length;
    // if we don't must select a folio because no have many
    if (folioCount < 2) {
      // return null if source not have a folio
      if (folioCount == 0) {
        return null;
      }
      // return first folio of 1
      return source.folios[0];
    }
    // Open select modal for select a folio in many

    let data = await this.modalService.showSelectFolioModal(source);
    return data as Folio;
  }

  async moveTablesToTarget(tables: ITable[], target: ITable) {
    // Masalardaki folio listeleri alınıp diziye atıldı
    let folios: Folio[] = tables.reduce((currElm, accur) => {
      return [...currElm, ...accur.folios];
    }, []);

    // Folio ların table ları masaya eşitlendi
    try {
      folios.forEach(async (folio) => {
        folio.table.id = target.id;
        folio.table.name = target.name;
        folio.updaters.push({
          userID: this.sessionquery.user.id,
          userName: this.sessionquery.user.name,
          time: new Date(),
          descripton: 'moved',
        });
        this.glb.showLoading();
        await this.folioService._posSaveFolio(folio, 'high');
        this.glb.closeLoading();
      });

      tables.forEach((elm) => ((elm.folios = []), (elm.state = 'close')));
      target.folios.concat(folios);
      target.state = 'open';
      console.log(target, tables);
      return true;
    } catch (error) {
      return false;
    }
  }

  saveToLocalStorage(data) {
    localforage.setItem('tables', data);
  }

  saveTable(table: Partial<ITable>) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/table/saveTable',
          { ...table, storeId: this.orwiStoreStore.getValue().id } as ITable,
          this.sessionquery.token
        )
        .then((o: any) => {
          console.log(o);
          if (o.response) return resolve(o.response);

          return reject(o.error?.code);
        });
    });
  }

  saveTableGroup(table: Partial<TableGroup>) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/table/saveTableGroup',
          {
            ...table,
            storeId: this.orwiStoreStore.getValue().id,
          } as TableGroup,
          this.sessionquery.token
        )
        .then((o: any) => {
          console.log(o);
          if (o.response) {
            this.fetchTables(this.orwiStoreStore.getValue().id);
            this.glb.toast('', 'Başarıyla Kaydedildi', 'middle', 'success');
            return resolve(o.response);
          }

          return reject(o.error?.code);
        });
    });
  }

  deleteTableGroup(tableGroupId) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/table/deleteTableGroup',
          {
            id: tableGroupId,
            storeId: this.orwiStoreStore.getValue().id,
          },
          this.sessionquery.token
        )
        .then(async (o: any) => {
          console.log(o);
          if (o.response) {
            await this.fetchTables(this.orwiStoreStore.getValue().id);
            this.glb.toast('', 'Successfully Deleted', 'middle', 'success');
            return resolve(o.response);
          }
          this.glb.toast(
            this.translocoService.translate('Delete Table Group'),
            o.error.desc,
            'bottom',
            'warning'
          );
          return reject(o?.error);
        });
    });
  }

  deleteTable(tableId) {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/table/deleteTable',
          {
            id: tableId,
            storeId: this.orwiStoreStore.getValue().id,
          },
          this.sessionquery.token
        )
        .then(async (o: any) => {
          console.log(o);
          if (o.response) {
            await this.fetchTables(this.orwiStoreStore.getValue().id);
            this.glb.toast('', 'Successfully Deleted', 'middle', 'success');
            return resolve(o.response);
          }
          this.glb.toast(
            this.translocoService.translate('Delete Table'),
            o.error.desc,
            'bottom',
            'warning'
          );
          return reject(o?.error);
        });
    });
  }
}
