import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { FoodAppAccount, FoodAppProduct, FoodAppModifier, RestaurantProduct, RestoranModifier, RestoranCategory, FoodAppApplication, FoodAppServiceStatus, FoodAppStore } from './dto';
import { FoodAppsIntegrationState, FoodAppsIntegrationStore } from './food-apps-integration.store';

@Injectable({ providedIn: 'root' })
export class FoodAppsIntegrationQuery extends Query<FoodAppsIntegrationState> {

  statuses: FoodAppServiceStatus[] = []
  applications: FoodAppApplication[] = []


  activeAccount: FoodAppAccount

  activeFoodAppProduct: FoodAppProduct
  activeFoodAppModifier: FoodAppModifier

  foodAppAccount$ = this.select("foodAppAccounts")
  foodAppProduct$ = this.select("foodAppProducts")
  foodAppCategorie$ = this.select("foodAppCategories")
  foodAppModifier$ = this.select("foodAppModifiers")
  fooAppStore$ = this.select("foodAppStores")
  foodAppStores: FoodAppStore[]

  foodAppProducts: FoodAppProduct[] = []
  foodAppModifiers: FoodAppModifier[] = []


  restaurantMenu$ = this.select("restaurantMenu")
  restaurantProducts: RestaurantProduct[] = []
  restaurantModifiers: RestoranModifier[];
  restaurantCategories: RestoranCategory[];
  foodAppAplications: FoodAppApplication[];





  constructor(protected store: FoodAppsIntegrationStore) {
    super(store);

    this.select("fooAppApplications").subscribe(o => {
      if (o) this.applications = o
    })

    this.select("foodAppAplications").subscribe(o => {
      if (o) this.foodAppAplications = o
    })

    this.select("activeAccount").subscribe(o => {
      if (o) this.activeAccount = o
    })


    this.select("foodAppProducts").subscribe(o => {
      if (o) this.foodAppProducts = o
    })

    this.select("foodAppModifiers").subscribe(o => {
      if (o) this.foodAppModifiers = o
    })

    this.select("restaurantMenu").subscribe(o => {
      if (o) {
        this.restaurantProducts = o.products
        this.restaurantModifiers = o.modifiers
        this.restaurantCategories = o.categories
      }
    })

    this.select("activeFoodAppProduct").subscribe(o => {
      if (o) this.activeFoodAppProduct = o
    })

    this.select("activeFoodAppModifier").subscribe(o => {
      if (o) this.activeFoodAppModifier = o
    })

    this.select("fooAppStatuses").subscribe(o => {
      this.statuses = o
    })

    this.select("fooAppStatuses").subscribe(o => {
      this.statuses = o.sort((a, b) => b.status - a.status)
    })

    this.select("foodAppStores").subscribe(o => {
      if (o) this.foodAppStores = o
    })


  }


  getIcon(appName) {
    return this.store.getValue().foodAppAplications.find(o => o.id == appName)?.icon

    // if (appName == "yemek-sepeti") {
    //   return "/assets/icons/yemek-sepeti.png"
    // } else if (appName == "getir") {
    //   return "/assets/icons/getir.png"
    // } else if (appName == "trendyol") {
    //   return "/assets/icons/trendyol.png"
    // } else if (appName == "orwi") {
    //   return "/assets/icons/orwi.png"
    // }else if (appName == "migros-yemek") {
    //   return "/assets/icons/migros.png"
    // }
  }

  getDeliveryStatusIcon(folio: Folio) {
    if (folio.deliveryStatus == 'new') {
      return "/assets/icons/start.png"
    } else if (folio.deliveryStatus == "accepted") {
      return "/assets/icons/start.png"
    } else if (folio.deliveryStatus == 'prepared') {
      return "/assets/icons/cooking.png"
    } else if (folio.deliveryStatus == 'routing') {
      return "/assets/icons/delivery-start.png"
    } else if (folio.deliveryStatus == 'completed') {
      return "/assets/icons/delivery-end.png"
    } else if (folio.deliveryStatus == 'cancelled') {
      return "/assets/order-status/order-cancel.gif"
    } else {
      return ""
    }
  }

}

