import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { ModifierGroup } from 'src/app/services/dto/orwi-product';

export interface FolioState {
    activeFolio: Folio;
    loading: boolean
    openFolios: Folio[]
    activeFolioRows: FolioRow[]
    selectedRow: FolioRow,
    changedRows: FolioRow[]
    closedFolios: Folio[]
    activeFolioRow: FolioRow
    showModifiers: boolean
    activeModifiersGroup: ModifierGroup[]
    showModifiersGroup: 'std' | 'must'
    newRow: FolioRow

    prepareDelete: FolioRow
    activeSegment: 'all' | 'new' | 'old'
    paymentFolios: Folio[]

    socketClosed: Folio

    //deleteReasons: DeleteReason[]

}

export function createInitialState(): FolioState {
    return {
        activeFolio: new Folio(),
        loading: false,
        openFolios: [],
        activeFolioRows: [],
        changedRows: [],
        closedFolios: [],
        activeFolioRow: null,
        selectedRow: null,
        showModifiers: false,
        showModifiersGroup: "must",
        activeModifiersGroup: [],
        newRow: null,

        prepareDelete: null,
        activeSegment: "all",

        paymentFolios: [],

        socketClosed: null,

        //deleteReasons: []


    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'folio', resettable: true })
export class FolioStore extends Store<FolioState> {

    constructor() {
        super(createInitialState());
        this.akitaPreUpdate = this.akitaPreUpdate.bind(this);
    }

    akitaPreUpdate(prevState: FolioState, nextState: FolioState) {
        prevState//reserved
        //console.log(">>>>>>", prevState.openFolios)
        return nextState
    }




}