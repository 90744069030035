import { Component, ViewChild } from '@angular/core';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import {
  FoodAppAccount,
  FoodAppCategory,
  FoodAppProduct,
  RestaurantProduct,
} from '../../state/dto';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppsIntegrationService } from '../../state/food-apps-integration.service';
import { FoodAppsIntegrationQuery } from '../../state/food-apps-integration.query';
import CustomStore from 'devextreme/data/custom_store';
// import { LoadOptions } from 'devextreme/data';
import { DxDataGridComponent } from 'devextreme-angular';
import { StringSmiltaryService } from 'src/app/services/helpers/string-smiltary.service';
import { Brand } from 'kerzz-base';
// import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'bulk-product-sync',
  templateUrl: 'bulk-product-sync.component.html',
  styleUrls: ['bulk-product-sync.component.scss'],
})
export class BulkProductSyncComponent {
  foodAppAcoounts: FoodAppAccount[] = [];
  brands: Brand[] = [];
  foodAppsProducts: iFoodAppProduct[] = [];
  filteredFoodAppsProducts: iFoodAppProduct[] = [];
  foodAppsCategories: FoodAppCategory[] = [];
  internalProducts: RestaurantProduct[] = [];
  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
  constructor(
    private publicApiService: PublicApiService,
    private sessionQuery: SessionQuery,
    private glb: GlobalService,
    private stringSmiltary: StringSmiltaryService,
    private integrationService: FoodAppsIntegrationService,
    private integrationQuery: FoodAppsIntegrationQuery
  ) {
    this.fetchFoodApps();
    this.getFoodAppGroups();

    this.integrationQuery.restaurantMenu$.subscribe((o) => {
      if (o) {
        this.internalProducts = o.products;
      }
    });

    // setInterval(() => {
    //   this.progress += 0.01;

    //   // Reset the progress bar when it reaches 100%
    //   // to continuously show the demo
    //   // if (this.progress > 1) {
    //   //   setTimeout(() => {
    //   //     this.progress = 0;
    //   //   }, 1000);
    //   // }
    // }, 1000);
  }

  getFoodAppGroups() {
    this.publicApiService
      .getCollection({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: 'food-apps-categories',
      })
      .subscribe((groups: FoodAppCategory[]) => {
        this.foodAppsCategories = groups;
      });
  }

  async getIntenalMenu(accountId) {
    try {
      await this.integrationService.getRestaurantMenu(accountId);
    } catch (error) {
      console.log('error');
      this.glb.closeLoading();
      return;
    }
  }

  changeCheckbox(e) {
    if (e.detail?.checked) {
      // this.dataGrid.instance.filter(["internalId", '=', '']);
      // this.dataGrid.instance.refresh();
      this.filteredFoodAppsProducts = this.foodAppsProducts.filter(
        (x) => x.internalId === ''
      );
    } else {
      // this.dataGrid.instance.filter([]);
      // this.dataGrid.instance.refresh();
      this.filteredFoodAppsProducts = this.foodAppsProducts;
    }
  }

  showSyncedProds(e) {
    if (e.detail?.checked) {
      this.filteredFoodAppsProducts = this.foodAppsProducts.filter(
        (x) => x.changed === true
      );
    } else {
      this.filteredFoodAppsProducts = this.foodAppsProducts;
    }
  }

  syncProduct() {
    let count: number = 0;
    this.glb.showLoading();
    let prds = this.foodAppsProducts.filter((o) => o.internalId == '');
    let totalCount: number = prds.length;
    for (const iterator of prds) {
      count += 1;
      let restPrds = [
        ...new Set(
          this.internalProducts.map((obj) => obj.name.toLocaleLowerCase())
        ),
      ];
      let res = this.stringSmiltary.findBestMatch(
        iterator.name.toLocaleLowerCase(),
        restPrds
      );
      if (res.bestMatch.rating > 0.6) {
        console.log(res.bestMatch.target, iterator.name);
        let prd = this.internalProducts.find(
          (o) => o.name.toLocaleLowerCase() == res.bestMatch.target
        );
        iterator.internalId = prd.id;
        iterator.changed = true;
        iterator.auto = true;
      }

      let prd = this.internalProducts.find(
        (o) =>
          o.name.toLocaleLowerCase() == iterator.name.toLocaleLowerCase() &&
          o.price == iterator.price
      );
      if (prd) {
        iterator.internalId = prd.id;
        iterator.changed = true;
        iterator.auto = true;
      }
      if (totalCount === count) {
        this.glb.closeAllLoading();
        this.glb.toast(
          'Ürün Eşleştirme',
          'Ürün Eşleştirme Tamamlandı',
          'bottom',
          'success'
        );
      }
    }
  }

  async saveChanges() {
    this.glb.showLoading();
    // let count: number = 0;
    // let totalCount: number = this.foodAppsProducts.filter(
    //   (x) => x.changed === true && x.internalId !== ''
    // ).length;
    let products = this.foodAppsProducts.filter(
      (x) => x.changed === true && x.internalId !== ''
    );
    products = products.map((element) => {
      delete element.brand;
      delete element.groupName;
      return element;
    });

    for await (const element of products) {
      await this.publicApiService.upsertFoodAppProduct({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: 'food-apps-products',
        data: element,
      });
    }
    this.glb.closeAllLoading();
    this.glb.toast('Ürün Eşleştirme', 'İşlem Başarılı', 'bottom', 'success');
  }

  async onRowUpdated(e) {
    //  console.log(e.data)

    //  let count : number  = this.foodAppsProducts.filter(x=> x.name === e.data.name && x.internalId === '' && x.source === e.data.source).length

    //  alert(count + " adet " + e.data.source + " Platformundan eşleşmesi yapılmamış " +  e.data.name + " ürünü bulundu.")
    //  this.foodAppsProducts.map(x=> {
    //   if(x.name === e.data.name && x.internalId === '' && x.source === e.data.source){
    //     x.internalId = e.data.internalId
    //   }
    //  })

    // return
    this.glb.showLoading();
    delete e.data.brand;
    delete e.data.groupName;
    e.data.changed = true;
    console.log('onRowUpdated', e);
    await this.publicApiService.upsertFoodAppProduct({
      _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      col: 'food-apps-products',
      data: e.data,
    });
    this.glb.closeLoading();
    this.glb.toast('Ürün Eşleştirme', 'İşlem Başarılı', 'bottom', 'success');
  }

  fetchFoodApps() {
    this.glb.showLoading();
    try {
      this.publicApiService
        .getCollection({
          _db: 'food-apps-service',
          col: 'accounts',
          _filter: {
            cloudId: this.sessionQuery.activeLicense.orwiStore.cloudId,
          },
        })
        .subscribe(async (acc: FoodAppAccount[]) => {
          console.log('acc', acc);
          this.foodAppAcoounts = acc;
          await this.getIntenalMenu(this.foodAppAcoounts[0].id);
          this.publicApiService
            .getCollection({
              _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
              col: 'brands',
            })
            .subscribe((brand: Brand[]) => {
              console.log('brand', brand);
              this.brands = brand;

              this.publicApiService
                .getCollection({
                  _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
                  col: 'food-apps-products',
                })
                .subscribe((prod: iFoodAppProduct[]) => {
                  prod.map((p) => {
                    this.foodAppAcoounts.map((a) => {
                      if (a.id === p.accountId) {
                        p.brand = a.brand;
                        p.groupName = this.foodAppsCategories.find(
                          (g) => g.id === p.group
                        )?.name;
                        p.changed = false;
                      }
                    });
                  });

                  console.log('foodAppProducts', prod);
                  this.foodAppsProducts = prod;
                  this.filteredFoodAppsProducts = prod;
                  this.glb.closeAllLoading();
                });
              // this.productDataStore = new CustomStore({
              //   key: 'id',
              //   load: (options: LoadOptions) => {
              //     console.log({ options });
              //     let filter = {};
              //     if(options.filter?.length > 0) {
              //       filter = {
              //         [options.filter?.[0]]: options.filter?.[2],
              //       }
              //     }
              //     return this.initProducts(options.skip, this.pageSize, filter);
              //   },
              // });
            });
        });
    } catch (error) {
      console.error('Bulk FoodApp Products', error);
      this.glb.closeAllLoading();
    }
  }

  productDataStore: CustomStore;
  pageSize = 200;

  initProducts(_skip = 0, _limit = this.pageSize, _filter = {}) {
    return new Promise((resolve, reject) => {
      return this.publicApiService
        .getCollection({
          _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
          col: 'food-apps-products',
        })
        .subscribe(
          (prod: iFoodAppProduct[]) => {
            prod.map((p) => {
              this.foodAppAcoounts.map((a) => {
                if (a.id === p.accountId) {
                  p.brand = a.brand;
                  p.groupName = this.foodAppsCategories.find(
                    (g) => g.id === p.group
                  )?.name;
                }
              });
            });
            console.log('foodAppProducts', prod);
            this.foodAppsProducts = prod;
            this.filteredFoodAppsProducts = prod;
            this.glb.closeAllLoading();
            resolve(prod);
            return {
              data: prod,
            };
          },
          (err) => {
            reject('Data Loading Error: ' + err);
          }
        );
    });
  }
}

export interface iFoodAppProduct extends FoodAppProduct {
  brand?: string;
  groupName?: string;
}
