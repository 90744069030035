import { Injectable } from '@angular/core';
import {
  arrayAdd,
  arrayRemove,
  arrayUpdate,
  arrayUpsert,
} from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import * as localforage from 'localforage'; // this works!!!
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NumpadService } from 'src/app/components/ui/numpad/state/numpad.service';
import { OrwiPromptService } from 'src/app/components/ui/orwi-prompt/orwi-prompt.service';
import { ModifiersService } from 'src/app/modules/menu/modifiers/state/modifiers.service';
import { MenuQuery } from 'src/app/modules/menu/state/menu.query';
import {
  Deleters,
  deliveryFolio,
  Folio,
  FolioRow,
  rowType,
  ServiceType,
} from 'src/app/services/dto/orwi-folio';
import { Product } from 'src/app/services/dto/orwi-product';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { OrwiService } from 'src/app/services/orwi/orwi.service';
import { AppService } from 'src/app/services/utils/app.service';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { DeleteProductFolioComponent } from '../components/delete-product-folio/delete-product-folio.component';
import { PaxComponent } from '../components/pax/pax.component';
import { FolioRowListItem } from '../components/product-transfer/models/product-transfer.models';
import { FolioQuery } from './folio.query';
import { FolioStore } from './folio.store';
import { Router } from '@angular/router';
import { HapticService } from 'src/app/services/helpers/haptic.service';
import { GitReasonsComponent } from '../components/git-reasons/git-reasons.component';
import { paymentTypes } from 'src/app/services/dto/orwi-store';
import { SessionQuery } from '../../session/state/session.query';
import { folioLogService } from './folio-logs/folio-logs-service';
import { SSOSessionService } from '../../session/state/sso-session.service';
import { ParametersQuery } from '../../parameters/state/parameters.query';
import { BrandsStore } from '../../brands/state/brands.store';

@Injectable({ providedIn: 'root' })
export abstract class FolioService {
  constructor(
    private modifierService: ModifiersService,
    private orwiService: OrwiService,
    private menuQuery: MenuQuery,
    private idGen: IdGeneratorService,
    private folioQuery: FolioQuery,
    private folioStore: FolioStore,
    private op: OrwiPromptService,
    private orwiStore: OrwiStoreStore,
    private numpadService: NumpadService,
    private appService: AppService,
    private modalService: ModalService,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private router: Router,
    private hs: HapticService,
    private sessionQuery: SessionQuery,
    private folioLogService: folioLogService,
    private ssoService: SSOSessionService,
    private paramQuery: ParametersQuery,
    private brandStore: BrandsStore
  ) {}

  productSub: Subscription;

  async loadOpenFolios() {
    let openFolios = await this._posGetOpenFolios(this.orwiStore.getValue().id);
    this.folioStore.update({
      openFolios: openFolios.filter((x) => x.deliveryStatus != 'cancelled'),
      activeFolio: new Folio(),
      // openFolios.filter((x) => x.deliveryStatus != 'cancelled')?.length > 0
      //   ? openFolios.filter((x) => x.deliveryStatus != 'cancelled')[0]
      //   : new Folio(),
    });
  }

  async initialize() {
    this.loadOpenFolios();

    if (this.productSub) {
      return;
    }

    this.productSub = this.menuQuery.selectedProduct$.subscribe((o) => {
      if (o) {
        this.insertFolioRow(o.product);
        this.modifierService.modifiers(true);
      }
    });

    // this.folioQuery.openFolios$.subscribe((o) => {
    //   localforage.setItem('OpenFolios', o);
    // });

    // this.folioQuery.closedFolio$.subscribe((o) => {
    //   localforage.setItem('OpenFolios', o);
    // });

    this.appService.connection$.subscribe((o) => {
      if (o !== 'offline') {
        this._posImportFolios();
      }
    });

    this.appService.stateChanged$.subscribe(async (o) => {
      if (o) {
        if (this.router.url == '/phone-folio') return;

        // this.glb.showLoading();

        // let id = undefined;
        // if (this.folioStore.getValue().activeFolio)
        //   id = this.folioStore.getValue().activeFolio.id;

        // let _openFolios = await this._posGetOpenFolios(
        //   this.orwiStore.getValue().id
        // );

        // let _activeFolio: Folio;

        // if (id) _activeFolio = _openFolios.find((o) => o.id == id);

        // this.folioStore.update({
        //   openFolios: _openFolios,
        //   activeFolio: _activeFolio,
        // });

        // this.glb.closeLoading();
      }
    });
  }

  async moveProduct(row: FolioRowListItem[], oldFoli: Folio, e: Folio) {
    let oldRows: FolioRow[] = [];
    let targetRows: FolioRow[] = [];
    row.forEach((el) => {
      console.log('moveProd', el);

      const qty: number = +el.folioRow.qty - +el.qty;

      const modifers: FolioRow[] = this.folioStore
        .getValue()
        .activeFolio.rows.filter((ele) => ele.parentID == el.folioRow.id);

      if (qty > 0 && el.slected) {
        oldRows.push({ ...el?.folioRow, qty: qty } as FolioRow);
        modifers.forEach((m) => {
          oldRows.push(m);
        });
      }

      if (!el.slected) {
        oldRows.push({ ...el?.folioRow } as FolioRow);
        modifers.forEach((m) => {
          oldRows.push(m);
        });
      }

      if (el.slected) {
        el.folioRow.recordStatus = 'deleted';
        el.folioRow.deleters.push({
          qty: el.qty,
          reason: 'transfer',
          reasonID: 'transfer',
          time: new Date(),
          userID: this.sessionQuery.user.id,
          userName: this.sessionQuery.user.name,
        });

        oldRows.push({ ...el?.folioRow } as FolioRow);
        modifers
          .filter((c) => c.parentID === el.folioRow.id)
          .map((x) => {
            x.deleters.push({
              qty: el.qty,
              reason: 'transfer',
              reasonID: 'transfer',
              time: new Date(),
              userID: this.sessionQuery.user.id,
              userName: this.sessionQuery.user.name,
            });
            x.recordStatus = 'deleted';
            oldRows.push({ ...x } as FolioRow);
          });
      }

      if (el.slected && el.valid) {
        const id: string = this.idGen.generate();
        console.log('generated id:', id);
        el.folioRow.recordStatus = 'old';
        el.folioRow.deleters = [];
        targetRows.push({ ...el?.folioRow, id: id, qty: el.qty } as FolioRow);
        modifers.forEach((mM) => {
          const modId: string = this.idGen.generate();
          targetRows.push({
            ...mM,
            parentID: id,
            id: modId,
            recordStatus: 'old',
            deleters: [],
          } as FolioRow);
        });
      }
    });
    oldFoli.rows = oldRows;

    console.log('OLD ROWS', oldRows, 'TARGET ROW', targetRows);
    console.log('TransferProd', oldFoli);
    await this._posSaveFolio(oldFoli);
    e.rows = [...e.rows, ...targetRows];
    await this._posSaveFolio(e);
    this.glb.toast(
      this.transloco.translate('Product Migration Process Completed.'),
      '',
      'bottom',
      'success'
    );
  }
  async moveFolio(table: { id: string; name: string }, folio: Folio) {
    folio.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
      descripton: 'closed-move',
    });

    folio.table.id = table.id;
    folio.table.name = table.name;

    folio.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
      descripton: 'moved',
    });

    await this.updateActiveFolio(folio);
  }

  async combineFolio(
    table: { id: string; name: string },
    combinedfolio: Folio,
    folio: Folio
  ) {
    if (!this.ssoService.checkPermission('folio-combine')) {
      this.glb.permissionToast();
      return;
    } else {
      this.glb.showLoading();
      combinedfolio.updaters.push({
        userID: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        time: new Date(),
        descripton: 'closed-combine',
      });

      let stringRows = JSON.stringify(
        combinedfolio.rows.filter((x) => x.recordStatus !== 'deleted')
      );
      let targetRow: FolioRow[] = JSON.parse(stringRows);

      folio.rows = [...folio.rows.filter((x) => x.recordStatus !== 'deleted')];
      targetRow.map((el) => {
        const pId = this.idGen.generate();
        targetRow
          .filter((x) => x.parentID === el.id)
          .map((subRow) => {
            subRow.parentID = pId;
          });
        el.id = pId;
        folio.rows.push(el);
      });
      folio.table.id = table.id;
      folio.table.name = table.name;

      folio.updaters.push({
        userID: this.sessionQuery.user.id,
        userName: this.sessionQuery.user.name,
        time: new Date(),
        descripton: 'combine',
      });
      await this._posSaveFolio(folio);
      // await this.updateActiveFolio(folio);
      // console.log('combine', combinedfolio.rows);

      combinedfolio.status = 'cancelled'; //!KONTROL EDILECEK
      combinedfolio.deliveryStatus = 'cancelled';
      combinedfolio.note = 'closed-combine';
      combinedfolio.rows.map((row) => {
        row.deleters.push({
          qty: row.qty,
          reason: 'deleted-combine',
          reasonID: 'deleted-combine',
          time: new Date(),
          userID: this.sessionQuery.user.id,
          userName: this.sessionQuery.user.name,
        });
        row.recordStatus = 'deleted';
        // row.id = this.idGen.generate()
      });
      // combinedfolio.rows.map(x=> x.folioId = combinedfolio.id)
      this.folioStore.update(({ openFolios }) => ({
        openFolios: arrayUpsert(openFolios, folio.id, folio),
      }));
      await this._posSaveFolio(combinedfolio);
      // await this._posCloseFolio(combinedfolio);
      await this.loadOpenFolios();
      this.glb.closeAllLoading();
    }
  }

  updateActiveFolio(folio: Folio, importance: 'low' | 'med' | 'high' = 'high') {
    // folio.lock.status = 'unlocked';
    // folio.lock.time = moment().toDate();
    // folio.lock.userID = this.sessionQuery.user.id;
    // folio.lock.userName = this.sessionQuery.user.name;
    this.folioStore.update({ activeFolio: folio, activeFolioRows: folio.rows });

    return this._posSaveFolio(
      this.folioStore.getValue().activeFolio,
      importance
    );
  }

  lockUnlockFolio(folioId: string, status: 'locked' | 'unlocked') {
    // folio.lock.status = status;
    // folio.lock.time = moment().toDate();
    // folio.lock.userID = this.sessionQuery.user.id;
    // folio.lock.userName = this.sessionQuery.user.name;
    // this.folioStore.update({ activeFolio: folio, activeFolioRows: folio.rows });

    this.orwiService
      .serviceRequestPromise(
        '/api/pos/folio/setFolioLock',
        {
          id: folioId,
          storeId: this.sessionQuery.activeLicense.orwiStore.id,
          lockStatus: status,
        },
        this.sessionQuery.token
      )
      .then();
  }

  async insertFolioRow(o: Product) {
    let productPrice = o.price;
    if (
      this.folioStore.getValue().activeFolio.type == 'delivery' ||
      this.folioStore.getValue().activeFolio.type == 'take-away'
    ) {
      productPrice = o.dlPrice;
    }
    if (o) {
      let row = this.createFolioRow(
        o.id,
        o.name,
        this.numpadService.getValue('qty'),
        '',
        productPrice,
        o.printer,
        o.ecrdepartment,
        o.vat
      );

      this.folioStore.update((p) => {
        p.activeFolio.rows.push(row);
      });

      this.folioStore.update({ changedRows: [row] });
      this.folioStore.update({ activeFolioRow: row });
      this.folioStore.update({ newRow: row });

      await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');
      await this.hs.hapticsImpactLight(100);
      await this.hs.hapticsImpactMedium(100);
    }
  }

  createFolioRow(
    product_id,
    product_name,
    qty,
    parent_id,
    price,
    printer: string = '',
    ecrDepartment?: string,
    tax?: number,
    image?,
    isGift?: boolean
  ): FolioRow {
    isGift = isGift == undefined ? false : isGift == false ? false : true;
    let row: FolioRow = new FolioRow();
    row.creation = moment().toDate();
    row.id = this.idGen.generate();
    row.name = product_name;
    row.qty = qty;
    row.unitPrice = price;
    row.price = price * qty;
    row.printer = printer;
    row.itemID = product_id;
    (row.creator.userID = this.sessionQuery.user.id),
      (row.creator.userName = this.sessionQuery.user.name),
      (row.ecrDepartmentId = ecrDepartment),
      (row.tax = tax);

    row.itemImage = image;
    row.parentID = parent_id;
    if (product_id === 'note') {
      row.rowType = 'note';
    }
    row.recordStatus = 'new';
    row.isGift = isGift;
    row.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: moment().toDate(),
    });

    return row;
  }

  async createFolio(
    tableId: string,
    tableName: string,
    serviceType: ServiceType = 'table'
  ) {
    let folio = new Folio();
    folio.id = this.idGen.generateMaxi();
    folio.type = serviceType;

    folio.creation = new Date();
    folio.lastChange = new Date();
    folio.table.id = tableId;
    folio.pax = 0;
    folio.uuid = this.idGen.generateUuid();
    folio.table.name = tableName;
    folio.storeId = this.orwiStore.getValue().id;
    folio.total = 0;
    folio.userId = this.sessionQuery.user.id;
    folio.paxChild = 0;
    folio.paxFemale = 0;
    folio.paxMale = 0;
    folio.lock.status = 'unlocked';
    if (this.brandStore.getValue().brands.length > 0) {
      folio.brand = this.brandStore
        .getValue()
        .brands.find((x) => x.isDefault)?.id;
    }
    folio.lock.time = moment().toDate();
    folio.lock.userID = this.sessionQuery.user.id;
    folio.lock.userName = this.sessionQuery.user.name;
    folio.creator.userID = this.sessionQuery.user.id;
    folio.creator.userName = this.sessionQuery.user.name;
    folio.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: moment().toDate(),
    });

    this.folioStore.update({ activeFolio: folio });

    this.folioStore.update(({ openFolios }) => ({
      openFolios: arrayAdd(openFolios, folio),
    }));

    await this.hs.hapticsImpactLight(100);
    await this.hs.hapticsImpactLight(300);
    await this.hs.hapticsImpactMedium(100);

    return this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');
  }

  async askDeleteSelectedRows() {
    const deleteModal = await this.modalService.openModal({
      component: DeleteProductFolioComponent,
      backdropDismiss: false,
      canDismiss: true,
    });

    deleteModal.onDidDismiss().then(({ data }) => {
      if (data?.reason) {
        this.deleteSelectedRows(data.reason);
      }
    });
  }

  getOldFolios(
    type: 'delivery' | 'all' | 'in-store',
    limit = 100
  ): Promise<deliveryFolio[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/folio/getUserFolios',
          { filter: type, limit, skip: 0 },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            this.glb.consolelog(o);
            if (o.response) {
              o.response.map((o) => {
                o['showDetail'] = false;
              });
              resolve(o.response);
            }
          },
          (e) => {
            e;
            //TODO error bass
            reject(false);
          }
        );
    });
  }

  async askGiftSelectedRows() {
    const activeFolioRows: FolioRow[] = this.folioStore.getValue().activeFolio
      .rows;
    const selectedRow: FolioRow = this.folioStore.getValue().selectedRow;
    if (selectedRow.isGift) {
      let ids = [];
      selectedRow.isGift = false;
      this.folioStore.getValue().activeFolio.rows.map((el) => {
        if (el.parentID === selectedRow.id) {
          el.isGift = false;
        }
      });
      for (const iterator of activeFolioRows) {
        ids.push(iterator.id);
      }
      await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');

      this.folioStore.update({ changedRows: activeFolioRows });
      this.folioStore.update(({ activeFolioRows }) => ({
        activeFolioRows: arrayUpdate(
          activeFolioRows,
          ids,
          activeFolioRows,
          'id'
        ),
      }));
      return;
    }
    const deleteModal = await this.modalService.openModal({
      component: GitReasonsComponent,
      backdropDismiss: false,
      canDismiss: true,
    });

    deleteModal.onDidDismiss().then(({ data }) => {
      if (data?.reason) {
        // this.deleteSelectedRows(data.reason);
        this.setGift(data.reason);
      }
    });
  }

  async setGift(reason: { reasonId: string; reasonName: string }) {
    let ids = [];
    let rows = this.folioStore
      .getValue()
      .activeFolioRows.filter((o) => o.selected);
    rows.push(this.folioStore.getValue().selectedRow);
    let subRows = this.folioStore
      .getValue()
      .activeFolio.rows.filter(
        (el) =>
          el.parentID == this.folioStore.getValue().selectedRow.id &&
          el.rowType === 'modifier'
      );
    rows.push(...subRows);

    rows.map((o) => {
      o.isGift = !o.isGift;
    });

    let otherId = this.idGen.generate();
    if (reason.reasonId == 'other') {
      reason.reasonId = otherId;
    }
    this.folioStore.getValue().selectedRow.deleters.push({
      reasonID: reason.reasonId,
      reason: reason.reasonName,
      userID: this.sessionQuery.user.id,
    } as Deleters);
    for (const iterator of rows) {
      ids.push(iterator.id);
    }

    this.folioStore.update({ selectedRow: null, activeFolioRow: null });

    await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');

    this.folioStore.update({ changedRows: rows });
    this.folioStore.update(({ activeFolioRows }) => ({
      activeFolioRows: arrayUpdate(activeFolioRows, ids, rows, 'id'),
    }));
  }

  async deleteSelectedRows(reason: { reasonId: string; reasonName: string }) {
    let ids: string[] = [];
    const activeFolioRows: FolioRow[] = this.folioStore.getValue().activeFolio
      .rows;
    const selectedRow: FolioRow = this.folioStore.getValue().selectedRow;

    const rows = activeFolioRows.filter((o) => {
      const isDeleted: boolean = o.parentID == selectedRow.id;
      return o.selected || isDeleted;
    });

    rows.push(this.folioStore.getValue().selectedRow);
    let otherId = this.idGen.generate();
    if (reason.reasonId == 'other') {
      reason.reasonId = otherId;
    }
    this.folioStore.getValue().selectedRow.deleters.push({
      reasonID: reason.reasonId,
      reason: reason.reasonName,
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: new Date(),
    } as Deleters);

    selectedRow.printed = false;
    rows.map(
      (o) => ((o.recordStatus = 'deleted'), (o.printed = false), (o.new = true))
    );
    //  this.folioStore.getValue().selectedRow.printer = this.menuQuery.getValue().products.find(x => x.id == this.folioStore.getValue().selectedRow.itemID).printer || ""
    for (const iterator of rows) {
      ids.push(iterator.id);
    }

    this.folioStore.update({ selectedRow: null, activeFolioRow: null });
    console.log('active folio:', this.folioStore.getValue().activeFolio);

    await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'low');

    this.folioStore.update({ changedRows: rows });

    this.folioStore.update(({ activeFolioRows }) => ({
      activeFolioRows: arrayUpdate(activeFolioRows, ids, rows, 'id'),
    }));
  }

  async setPax() {
    await this.glb.openModal({
      component: PaxComponent,
      cssClass: 'reason-modal',
    });
  }

  addNote() {
    let note: string = this.folioStore.getValue().activeFolio.note;
    let title = this.transloco.translate('Folio Note');
    let desc = this.transloco.translate('Please enter the folio note');
    if (this.folioStore.getValue().selectedRow) {
      title = this.transloco.translate('Product Note');
      desc = this.transloco.translate('Please enter the product note');
      note = this.folioStore.getValue().selectedRow.note;
      if (!this.ssoService.checkPermission('folio-add-note-product')) {
        this.glb.permissionToast();
        return;
      }
      if (
        !this.ssoService.checkPermission('folio-add-note-old-product') &&
        this.folioStore.getValue().selectedRow.recordStatus === 'old'
      ) {
        this.glb.permissionToast();
        return;
      }
    } else {
      if (!this.ssoService.checkPermission('folio-add-folio-note')) {
        this.glb.permissionToast();
        return;
      }
    }

    let _op = this.op.showComponent({
      title: title,
      message: desc,
      inputs: [
        { placeholder: 'Note', type: 'textarea', id: 'note', value: note },
      ],
    });

    _op.click.subscribe(async (o) => {
      // // console.log('note:',o);
      if (o.inputs === null || o.inputs === undefined) {
        _op.closeClick.emit();
        return;
      }
      let os = o.inputs.find((x) => x.id == 'note');

      // if (!os.value || os.value.toString().trim() === '') {
      //   this.glb.toast('Not', 'Lütfen not giriniz.', 'bottom', 'warning');
      //   return;
      // }
      if (this.folioStore.getValue().selectedRow) {
        // if have a selected row we will add note to their rows

        let row = this.createFolioRow(
          'note',
          os.value,
          this.folioStore.getValue().selectedRow.qty,
          this.folioStore.getValue().selectedRow.id,
          0
        );

        this.folioStore.update(({ activeFolio }) => ({
          activeFolio: {
            ...activeFolio,
            rows: [...activeFolio.rows, row],
          },
        }));
      } else {
        // if dont select any folio this mean we must add note to folio note
        this.folioStore.update(({ activeFolio }) => ({
          activeFolio: {
            ...activeFolio,
            note: os.value ?? '',
          },
        }));
      }
      _op.closeClick.emit();

      await this.saveActiveFolio();

      // // console.log('folioStore: ', this.folioStore.getValue() );
    });
  }
  async saveActiveFolio(): Promise<boolean> {
    try {
      await this._posSaveFolio(this.folioStore.getValue().activeFolio, 'high');
      return true;
    } catch (error) {
      return false;
    }
  }

  async checkFolioEftPos(folio?: Folio): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let paymenTotal: number =
          folio.rows
            .filter((fr) => fr.rowType === 'payment')
            .reduce((sum, item) => sum + item.unitPrice * item.qty, 0) || 0;
        paymenTotal = paymenTotal * -1;
        console.log('checkFolioEftPos', folio, paymenTotal);
        if (folio.rows.filter((x) => x.rowType === 'payment').length > 0) {
          if (paymenTotal === folio.grandTotal) {
            resolve('payment-done');
          }
        } else if (folio.eftPos) {
          resolve('sended-tsm');
        } else {
          resolve('');
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  __getFoliosByDate(date: Date = new Date()) {
    let start = moment(date).startOf('day');
    let end = moment(date).add(1, 'day').endOf('day');

    console.log(start, end);
    let param = {
      startDate: start,
      endDate: end,
      skip: 0,
      limit: 10000,
      id: this.orwiStore.getValue().id,
    };

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getAllFoliosByDate',
          param,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }
  __getFoliosByStartAndEndDate({ start, end }: any) {
    start = moment(
      moment(start).format('YYYY-MM-DD') +
        'T' +
        moment
          .utc(
            this.paramQuery.getValue().posParameters.globalParameters.openTime
          )
          .format('HH:mm')
    )
      .utc(true)
      .toISOString();
    // start = await this.paramQuery.startDate(start);
    //  let eDate = moment(this.dateFilterForm.value.endDate).endOf("day").utc(true).toISOString();
    end = moment(
      moment(end).format('YYYY-MM-DD') +
        'T' +
        moment
          .utc(
            this.paramQuery.getValue().posParameters.globalParameters.closeTime
          )
          .format('HH:mm')
    )
      .utc(true)
      .toISOString();
    // end = await this.paramQuery.endDate(end);

    if (
      moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD')
    ) {
      end = moment(end).add(1, 'd').toISOString();
    }

    let param = {
      startDate: start,
      endDate: end,
      skip: 0,
      limit: 10000,
      id: this.orwiStore.getValue().id,
    };

    console.log('__getFoliosByStartAndEndDate', param);

    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getAllFoliosByDate',
          param,
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  _posSaveFolio(
    folio?: Folio | null,
    importance: 'low' | 'med' | 'high' = 'high'
  ): Promise<Folio> {
    return new Promise(async (resolve, reject) => {
      try {
        let result: any;
        let resultFolio: Folio;
        folio = folio ? folio : this.folioStore.getValue().activeFolio;
        folio.lastChange = new Date();
        folio.rows.map((fl) => {
          if (
            fl.rowType === 'product' ||
            fl.rowType === 'modifier' ||
            fl.rowType === 'custom-modifier'
          ) {
            fl.modifiers = [];
            fl.mustModifiers = [];
          }
        });
        if (this.appService.connection !== 'offline') {
          try {
            if (importance == 'high') {
              result = await this.orwiService.serviceRequestPromise(
                '/api/pos/folio/saveFolio',
                folio,
                this.sessionQuery.token
              );

              if (result.error) {
                throw new Error(result.error);
              } else {
                resultFolio = result.response;
              }
            } else {
              resultFolio = folio;
            }
          } catch (error) {
            resultFolio = folio;
          }
        } else {
          resultFolio = folio;
        }

        this.folioStore.update(({ openFolios }) => ({
          openFolios: arrayUpdate(
            openFolios,
            resultFolio.id,
            resultFolio,
            'id'
          ),
        }));
        resolve(resultFolio);
      } catch (err: any) {
        reject(err);
      }
    });
  }

  _posDoneFolio(folio?: Folio) {
    if (!folio) {
      folio = this.folioStore.getValue().activeFolio;
    }

    folio.lock.status = 'unlocked';
    folio.lock.time = moment().toDate();
    folio.lock.userID = this.sessionQuery.user.id;
    folio.lock.userName = this.sessionQuery.user.name;
    folio.lastChange = new Date();
    folio.rows.map((fl) => {
      if (
        fl.rowType === 'product' ||
        fl.rowType === 'modifier' ||
        fl.rowType === 'custom-modifier'
      ) {
        fl.modifiers = [];
        fl.mustModifiers = [];
      }
    });
    return new Promise<Folio>(async (resolve, reject) => {
      try {
        let result: any;
        let resultFolio: Folio;

        if (this.appService.connection !== 'offline') {
          try {
            result = await this.orwiService.serviceRequestPromise(
              '/api/pos/folio/doneFolio',
              folio,
              this.sessionQuery.token
            );

            if (result.error) {
              throw new Error(result.error);
            } else {
              resultFolio = result.response;
              // console.log("doneFolio",resultFolio)
            }
          } catch (error) {
            resultFolio = folio;
            resultFolio.rows.map((o) => (o.recordStatus = 'old'));
          }
        } else {
          resultFolio = folio;
          resultFolio.rows.map((o) => (o.recordStatus = 'old'));
        }

        this.folioStore.update({ changedRows: [] });
        this.folioStore.update({ activeFolioRow: undefined });
        this.folioStore.update({ newRow: undefined });

        // this.folioStore.update(({ openFolios }) => ({
        //   openFolios: arrayUpdate(openFolios, resultFolio.id, resultFolio, 'id'),
        //   activeFolio: resultFolio
        // }));

        this.folioStore.update(({ openFolios }) => ({
          activeFolio: new Folio(),
          openFolios: arrayUpdate(
            openFolios,
            resultFolio.id,
            resultFolio,
            'id'
          ),
        }));

        await this.hs.hapticsImpactLight(100);
        await this.hs.hapticsImpactMedium(200);
        await this.hs.hapticsImpactLight(100);
        return resolve(resultFolio);
      } catch (err: any) {
        reject(err);
      }
    });
  }

  _posCloseFolio(folio?: Folio): Promise<Folio> {
    if (!this.ssoService.checkPermission('folio-close')) {
      this.glb.permissionToast();
      return Promise.reject(new Error('Permission denied')); // Reject the promise with an error
    } else {
      if (!folio) {
        folio = this.folioStore.getValue().activeFolio;
      }
      folio.lastChange = new Date();
      return new Promise(async (resolve, reject) => {
        try {
          let result: any;
          let resultFolio: Folio;

          // let roleLevel = this.sessionQuery.getHigerRole().level;
          // if (roleLevel < 29) {
          //   this.glb.toast(
          //     this.transloco.translate('Folio Close'),
          //     this.transloco.translate('No Folio Closing Permission'),
          //     'bottom',
          //     'warning'
          //   );
          //   resolve(folio);
          //   return;
          // }

          if (this.appService.connection !== 'offline') {
            try {
              result = await this.orwiService.serviceRequestPromise(
                '/api/pos/folio/closeFolio',
                folio,
                this.sessionQuery.token
              );
              if (result.error) {
                throw new Error(result.error);
              } else {
                resultFolio = result.response;
                this.folioLogService.insertFolioLog({
                  folioId: folio.id,
                  logType: 'folio-close',
                  data: {
                    userId: this.sessionQuery.user.id,
                    userName: this.sessionQuery.user.name,
                  },
                });
              }
            } catch (error) {
              folio.status = 'closed';
              resultFolio = folio;
              resultFolio.rows.map((o) => (o.recordStatus = 'old'));
            }
          } else {
            folio.status = 'closed';
            resultFolio = folio;
            resultFolio.rows.map((o) => (o.recordStatus = 'old'));
          }

          this.folioStore.update(({ openFolios }) => ({
            openFolios: arrayRemove(openFolios, resultFolio.id),
          }));
          this.folioStore.update({ closedFolios: [resultFolio] });

          this.folioStore.update(({ openFolios }) => ({
            openFolios: arrayUpdate(
              openFolios,
              resultFolio.id,
              resultFolio,
              'id'
            ),
          }));

          resolve(resultFolio);
        } catch (err: any) {
          reject(err);
          return err; // Return error in case of rejection
        }
      });
    }
  }

  _posGetFolioById(folioId: string): Promise<Folio> {
    return new Promise(async (resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getFolioById',
          {
            id: folioId,
            storeId: this.sessionQuery.activeLicense.orwiStore.id,
          },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              resolve(o.response);
            } else {
              reject(o?.error);
            }
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  _posGetOpenFolios(id): Promise<Folio[]> {
    return new Promise(async (resolve, reject) => {
      if (this.appService.connection == 'offline') {
        let openfolios: Folio[] = await localforage.getItem('OpenFolios');
        resolve(openfolios);
        return;
      }
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/folio/getOpenFolios',
          { id: id },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            //// console.log('_getOpenFolios', o);
            resolve(o.response);
          },
          (e: any) => {
            reject(e);
          }
        );
    });
  }

  clearStates() {
    this.folioStore.update({
      activeFolio: null,
      activeSegment: 'all',
      activeFolioRow: null,
      newRow: null,
      selectedRow: null,

      changedRows: [],
      closedFolios: [],

      prepareDelete: null,

      activeModifiersGroup: [],
    });
  }

  _posImportFolios() {
    let data = {
      id: this.orwiStore.getValue().id,
      openFolios: this.folioQuery.getValue().openFolios,
      closedFolios: this.folioQuery.getValue().closedFolios,
    };
    if (data.openFolios.length == 0 && data.closedFolios.length == 0) return;
    this.orwiService
      .serviceRequestPromise(
        '/api/pos/folio/importFolios',
        data,
        this.sessionQuery.token
      )
      .then((o: any) => {
        if (o.response) {
          this.folioStore.update({ openFolios: o.response, closedFolios: [] });
          for (const iterator of o.response) {
            this.folioStore.update(({ openFolios }) => ({
              openFolios: arrayUpsert(openFolios, iterator.id, iterator, 'id'),
            }));
          }
        }
      });
  }

  closeAllFolios() {
    this.folioStore
      .getValue()
      .openFolios.forEach(async (el) => await this._posCloseFolio(el));
  }

  createPaymentrow(
    payment_id,
    payment_name,
    total,
    rowType: rowType = 'payment',
    paymentType: paymentTypes,
    payRowID?: any
  ): FolioRow {
    let row: FolioRow = new FolioRow();
    row.id = this.idGen.generate();
    row.name = payment_name;
    row.qty = 1;
    row.unitPrice = total - total * 2;
    row.price = total - total * 2;
    row.itemID = payment_id;
    row.itemImage = '';
    row.parentID = '';
    row.recordStatus = 'new';
    row.rowType = rowType;
    row.paymentType = paymentType;
    row.payRowID = payRowID;
    row.isPayment = row.rowType !== 'discount' ? true : false;
    row.isDiscount = row.rowType === 'discount' ? true : false;
    row.updaters.push({
      userID: this.sessionQuery.user.id,
      userName: this.sessionQuery.user.name,
      time: moment().toDate(),
    });
    return row;
  }
}
