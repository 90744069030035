import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OpenFoliosState, OpenFoliosStore } from './open-folios.store';

@Injectable({ providedIn: 'root' })
export class OpenFoliosQuery extends QueryEntity<OpenFoliosState> {


    folios$ = this.selectAll()

    constructor(protected store: OpenFoliosStore) {
        super(store);
    }
}