import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { FolioRow } from 'src/app/services/dto/orwi-folio';
import { FolioRowListItem } from '../../models/product-transfer.models';

@Component({
  selector: 'foli-row-list',
  templateUrl: './foli-row-list.component.html',
  styleUrls: ['./foli-row-list.component.scss'],
})
export class FoliRowListComponent implements OnInit {
  @Input() set dataSource(value: FolioRow[]) {
    this.selecteds = [];
    value.forEach((el) => {
      this.selecteds.push({
        slected: false,
        qty: el.qty,
        folioRow: el,
        valid: true,
      });
    });

    // value.forEach((el) => {
    //   if (!this.selecteds.some((s) => s.folioRow.id === el.id)) {
    //     this.selecteds.push({
    //       slected: false,
    //       qty: el.qty,
    //       folioRow: el,
    //       valid: true,
    //     });
    //   } else {
    //     this.getSelected(el.id).folioRow = el;
    //   }
    // });
    // this.selecteds = this.selecteds.filter((el) =>
    //   value.some((s) => s.id === el.folioRow.id)
    // );
  }
  getSelected(id: string, index: number): FolioRowListItem {
    return this.selecteds.find((el, i) => el.folioRow.id === id && i === index);
  }
  @Output() onSelectedChange: EventEmitter<FolioRowListItem[]> =
    new EventEmitter<FolioRowListItem[]>();

  selecteds: FolioRowListItem[] = [];

  constructor() { }

  ngOnInit() { }

  ionViewDidEnter() { }
  ids = [];

  isChecked(id: string, index: number) {
    return this.getSelected(id, index).slected;
  }
  isCheckedAll() {
    return !this.selecteds.some((el) => el.slected == false);
  }
  allChecked(checked) {
    if (checked) {
      this.selecteds.forEach((el) => (el.slected = true));
    } else if (this.isCheckedAll()) {
      this.selecteds.forEach((el) => (el.slected = false));
    }
    // this.selecteds.forEach(
    //   (el) =>
    //     (el.slected = checked
    //       ? checked
    //       : this.isCheckedAll()
    //       ? false
    //       : el.slected)
    // );
    this.onSelectedChange.emit(this.selecteds);
  }

  onRowClick(e: string, index) {
    this.getSelected(e, index).slected = !this.isChecked(e, index);
    this.onSelectedChange.emit(this.selecteds);
  }
  qtyChange(e: IonInput, id: string, index) {
    // console.log('qtyChange: ', e);

    if (e.value === null || e.value === undefined) {
      this.getSelected(id, index).valid = false;
      return;
    }
    let value: number = +e.value;
    if (value < 1 || value > this.getSelected(id, index).folioRow.qty) {
      this.getSelected(id, index).valid = false;
      return;
    }
    this.getSelected(id, index).valid = true;
    this.getSelected(id, index).qty = +value;
  }
}
