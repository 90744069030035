import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardService } from '../session/state/guard.service';
import { ModiferSyncComponent } from './components/forms/modifer-sync/modifer-sync.component';
import { ProductSyncComponent } from './components/forms/product-sync/product-sync.component';
import { YsFormComponent } from './components/forms/ys-form/ys-form.component';
import { FoodAppsHome } from './components/food-apps-home/food-apps-home.component';
import { FoodAppsStoreListComponent } from './components/food-apps-store-list/food-apps-store-list.component';
import { BulkProductSyncComponent } from './components/bulk-product-sync/bulk-product-sync.component';
import { BulkModifierSyncComponent } from './components/bulk-modifier-sync/bulk-modifier-sync.component';
import { FoodAppsLogsComponent } from './components/food-apps-logs/food-apps-logs.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

const routes: Routes = [
  {path: '', component: FoodAppsHome, canActivate : [GuardService]},
  {path: 'menu-home', component: FoodAppsHome, canActivate : [GuardService]},
  {path: 'ys-form', component: YsFormComponent, canActivate : [GuardService]},
  {path: 'notifications', component: NotificationsComponent, canActivate : [GuardService]},
  {path: 'product-sync', component: ProductSyncComponent, canActivate : [GuardService]},
  {path: 'modifier-sync', component: ModiferSyncComponent, canActivate : [GuardService]},
  {path: 'food-app-integration-store-list', component: FoodAppsStoreListComponent, canActivate : [GuardService]},
  {path: 'bulk-product-sync', component: BulkProductSyncComponent, canActivate : [GuardService]},
  {path: 'bulk-modifier-sync', component: BulkModifierSyncComponent, canActivate : [GuardService]},
  {path: 'food-apps-logs', component: FoodAppsLogsComponent, canActivate : [GuardService]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FoodAppsIntegrationRoutingModule { }
