import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormArray } from '@angular/forms';
import { Customer } from 'src/app/services/customer/customer.model';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { GlobalService } from 'src/app/services/global.service';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';

@Component({
  selector: 'customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.scss'],
})
export class CustomerCardComponent implements OnInit {
  @Input()
  customerForm: FormGroup;

  @Input()
  isEditing = false;

  @Output()
  onSelectCustomer = new EventEmitter();

  @Output()
  onAddNewAddress = new EventEmitter();

  @Output()
  onDeleteAddress = new EventEmitter();

  customer: Customer;

  selectedAddressId = undefined;

  mockCustomerForm;

  selectedAddressAccordion = undefined;

  get Addresses(): UntypedFormArray {
    return this.customerForm.get('addresses') as UntypedFormArray;
  }

  get customerName() {
    return this.customerForm.get('customerName').value;
  }

  get customerGsm() {
    return this.customerForm.get('gsm').value;
  }

  constructor(
    private glb: GlobalService,
    private idGen: IdGeneratorService,
    private customerService: CustomerService
  ) {}

  changeEditing() {
    this.mockCustomerForm = this.customerForm.value;
    this.isEditing = !this.isEditing;
  }

  cancel() {
    // let emptyAddresses = this.mockCustomerForm.addresses.filter(elm => this.tumPropertylerBosMu(elm)) as any[];
    console.log(this.mockCustomerForm);
    this.mockCustomerForm.addresses = this.mockCustomerForm.addresses.filter(
      (elm, index) => {
        if (this.tumPropertylerBosMu(elm)) {
          this.onDeleteAddress.emit(index);
          return false;
        }

        return true;
      }
    );
    this.customerForm.patchValue(this.mockCustomerForm);
    this.changeEditing();
  }

  tumPropertylerBosMu(obje) {
    for (var prop in obje) {
      if (
        prop !== 'id' &&
        obje.hasOwnProperty(prop) &&
        obje[prop] !== null &&
        obje[prop] !== undefined &&
        obje[prop] !== ''
      ) {
        return false;
      }
    }
    return true;
  }

  async saveCustomer() {
    this.customer = await this.customerService.getAddressByGSM(
      this.customerForm.get('gsm').value
    );
    let address = this.Addresses.value.find((el) => el.selected);
    // this.customer.addresses.find((el) => el.selected) ??
    // ({ id: this.idGeneratorService.generateMaxi() } as CustomerAddress);
    if (!address && this.Addresses.value.length == 1) {
      address = this.Addresses.value[0];
    }

    this.customer.customerName = this.customerForm.get('customerName').value;
    this.customer.addresses = this.Addresses.value;
    this.customer.gsm = this.customerForm.get('gsm').value;
    console.log('CUSTOMER ', this.customer);
    return await this.customerService.save(this.customer);
  }

  async save() {
    if (this.customerForm.invalid) {
      this.customerForm.markAllAsTouched();
      return;
    }
    await this.saveCustomer();
    this.customerForm.markAllAsTouched();
    console.log('CUSTOMER FORM', this.customerForm);
    console.log('CUSTOMER SELECTED ID', this.selectedAddressId);
    if (this.Addresses.controls.length == 0) {
      this.glb.toast(
        '',
        'Please add at least one address',
        'middle',
        'warning'
      );
    }
    if (!this.customerForm.valid) return;

    this.changeEditing();
  }

  changeSelectedAddress(event: any) {
    this.selectedAddressId = event.target.value;
    this.Addresses.controls.forEach((elm) =>
      elm
        .get('selected')
        .setValue(elm.get('id').value == this.selectedAddressId)
    );
  }

  addNewAddress() {
    const id = this.idGen.generateMaxi();
    this.onAddNewAddress.emit({ id });
    this.changeEditing();
    this.selectedAddressAccordion = id;
    setTimeout(() => {
      let idElm = document.querySelector('#address-' + id);
      idElm?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 100);
  }

  SelectCustomer() {
    this.onSelectCustomer.emit(this.customerForm.value);
  }

  ngOnInit() {
    this.customer = this.customerForm.value;
    this.getSelectedAddress();
  }

  getSelectedAddress() {
    this.selectedAddressId =
      this.Addresses.value.find((el) => el.selected == true)?.id ??
      this.Addresses.value?.[0]?.id;
  }
}
