export class PosDevice {
    id: string = ""
    userId: string = ""
    uuid: string = ""
    userLanguage: string = ""
    userRegion: string = ""
    lastUpdate: Date = new Date()
    socketId: string = ""
    storeId: string = ""
    appVersion: string = ""
    ipAdress: string = ""
    userName : string = ""
}