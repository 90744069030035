import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Brand } from 'kerzz-base';


export interface BrandsState {
   key: string,
   brands: Brand[],
   activeBrand: Brand
}

export function createInitialState(): BrandsState {
  return {
    key: '',
    brands: [],
    activeBrand: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'brands' })
export class BrandsStore extends Store<BrandsState> {

  constructor() {
    super(createInitialState());
  }

}
