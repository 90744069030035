import { EntityState } from '@datorama/akita';
import { OrderStatus } from 'src/app/modules/kitchen-display/components/state/dto';
import { AuthLevel } from './orwi-auth';
import { Modifier, ModifierGroup } from './orwi-product';
import { paymentTypes } from './orwi-store';

export interface FolioState extends EntityState<Folio> {}

export class FolioRow {
  id: string;
  name: string;
  itemID: string;
  qty: number = 0;
  unitPrice: number = 0;
  new: boolean = true;
  itemImage: string = '';
  parentID: string = '0';
  printer: string = '';
  modifierID: string;
  modifierGroup: string;
  qtyID: number;
  note: string;
  printed: boolean = false;
  price: number = 0;
  discount: number = 0;
  selected: boolean = false;
  seat: number = 0;
  tax: number = 10;
  ecrDepartmentId: string = '';
  payRowID?: string = "";
  creator: { userID: string; userName: string } = { userID: '', userName: '' };
  updaters: Array<{ userID: string; userName: string; time: Date }> = [];
  deleters: Deleters[] = [];
  recordStatus: RecordStatus = 'new';

  payStatus: PayRow[] = [];

  isModifier: boolean = false;
  isMustModifier: boolean = false;
  isDetachableModifier = false;

  isGift: boolean = false;
  isPaidFull: boolean = false;

  isLoyalty: boolean = false;
  isFree: boolean = false;
  isPayment = false;
  isDiscount = false;

  modifiers: Modifier[] = [];
  mustModifiers: Modifier[] = [];

  modifierGroups: ModifierGroup[] = [];

  rowType: rowType = 'product';
  paymentType: paymentTypes = 'cash';
  externalId = '';
  folioId = '';
  source: 'pos' | 'orwi' = 'orwi';

  creation: Date = new Date();

  approve = false;
  hasModifiers: boolean;

  getRowTotal() {
    return this.unitPrice * this.qty;
  }
  isRefund?: boolean;
  customChecked = false; // customRow
  customStatus: OrderStatus = OrderStatus.waiting;
  customCreated = new Date();


  /*
  * virtual modifiers
  */
  virtualModifiers: FolioRow[];
}

export interface Deleters {
  userID: string;
  userName: string;
  time: Date;
  qty: number;
  reasonID: string;
  reason: string;
}
// export interface Updaters {
//   userID: string;
//   userName: string;
//   time: Date;
//   qty: number;
//   reasonID: string;
//   reason: string;
// }

export class PayRow {
  id: string;
  name: string;
  amount: number;
}

export class Folio {
  display: boolean;

  static isFolio(value: any): value is Folio {
    return (value as Folio).rows !== undefined;
  }
  id: string;
  appName: string;
  creation: Date;
  creator: { userID: string; userName: string } = { userID: '', userName: '' };
  customerName = '';
  deliveryAddress = '';
  deliveryAddressId: string;
  deliveryPaymentType: string = '';
  sequenceNo: number = 0;
  deliveryStaff = '';
  deliveryStatus: DeliveryStatus = 'accepted';
  discount: number = 0;
  externalId: string = '';
  grandTotal: number = 0;
  inoviceAddressId = '';
  isOrwiPay: boolean = false;
  isRating: boolean = false;
  isTimed: boolean = false;
  shortCode : string =""
  kickback: number = 0;
  lastChange: Date;
  lock: {
    status: 'unlocked' | 'locked';
    time: Date;
    userName: string;
    userID: string;
  } = {
    status: 'unlocked',
    time: new Date(),
    userName: '',
    userID: '',
  };
  note = '';
  orwi: OrwiPointTransaction = { pointType: 'orwi', won: 0, spent: 0 };
  parentID: string = '';
  pax = 0;
  plasticCutlery = true;
  rows: FolioRow[] = [];
  scheduleNote = '';
  scheduler: Date;
  service: number = 0;
  source: sources = 'orwi';
  staffId: string = '';
  status: folioStatus = 'open';
  storeId = '';
  table: { id: string; name: string } = { id: '', name: '' };
  tip = 0.0;
  total: number = 0;
  type: ServiceType = 'delivery';
  updaters: Array<{
    userID: string;
    userName: string;
    time: Date;
    descripton?: string;
  }> = [];
  userId = '';
  verifyId: string = '';

  paxChild: number = 0;
  paxFemale: number = 0;
  paxMale: number = 0;
  uuid: string = '';
  eftPos: boolean = false;
  location: { lat: number; lng: number };
  brand: string;
  //storeName = '';
  //deliveryAddressType: 'address' | 'location' = 'address';

  // gsm: string;
  // city: string;
  // town: string;
  // address: string;
  tag: string;

  isRefund?: boolean;

  invoiceInfo?: Partial<{
    name: string;
    taxOffice: string;
    taxNo: string;
    address: string;
    addressCity: string;
    addressTown: string;
    phone: string;
    uuid: string;
    number: string;
    shortCode: string;
    email: string;
  }> = {
    name: null,
    taxOffice: null,
    taxNo: null,
    address: null,
    addressCity: null,
    addressTown: null,
    phone: null,
    uuid: null,
    number: null,
    shortCode: null,
    email: null,
  };
  constructor() {
    this.invoiceInfo = {
      name: null,
      taxOffice: null,
      taxNo: null,
      address: null,
      addressCity: null,
      addressTown: null,
      phone: null,
      uuid: null,
      number: null,
      shortCode: null,
      email: null,
    }
  }
}

export interface FolioInfo {
  preparingEnd: Date;
  preparingStart: Date;
  folioId: string;
  display: boolean;
  closingTime: Date;
  routingStartTime: Date;
  routingEndTime: Date;
  assignCourier: Date;
  deliveryUserFolioCount: number;
  deliveryUserFolioCountText: string;
  serviceType: ServiceType;
}

export type ServiceType =
  | 'self-ordering'
  | 'self'
  | 'table'
  | 'delivery'
  | 'take-away'
  | 'timed-take-away'
  | 'timed-table';
export type RecordStatus = 'new' | 'old' | 'deleted' | 'removed' | 'all';
export type rowType =
  | 'product'
  | 'modifier'
  | 'custom-modifier'
  | 'discount'
  | 'payment'
  | 'promotions'
  | 'bonus'
  | 'note';
export type folioStatus = 'open' | 'closed' | 'cancelled' | 'splitted';
export type sources = 'orwi' | 'loyalt-qr' | 'orwi-pos' | 'pos' | 'food-app';
export const DeliveryStatuses = [
  'none',
  'new',
  'rejected',
  'accepted',
  'prepared',
  'routing',
  'cancelled',
  'completed',
] as const;
export type DeliveryStatus = typeof DeliveryStatuses[number];

export interface schedulerPayload {
  status: string;
  point: number;
  name: string;
  storeId: string;
  type: ServiceType;
}

export interface OrwiPointTransaction {
  pointType: 'orwi' | 'store' | 'stamp';
  spent: number;
  won: number;
}

export class deliveryFolio {
  id = '';
  grandTotal = 0;
  creationDate = new Date();
  storeName = '';
  storeId = '';
  status = '';
  showDetail = false;
  deliveryStatus: 'none' | 'routing' | 'preparing' | 'cancelled' | 'completed';
  type = '';
}

export interface IDiscount {
  id: string;
  authLevel: AuthLevel;
  title: string;
  desctiption?: string;
  percent: number;
  amount: number;
}

export interface FolioLog {
  id: string;
  folioId: string;
  date: Date;
  logType: FolioLogTypes;
  description: string;
  data: string;
  user: { id: string; name: string };
}

export type FolioLogTypes =
  | 'accepted'
  | 'cancelled'
  | 'prepared'
  | 'routing-start'
  | 'routing-end'
  | 'assign-courier'
  | 'clear-courier'
  | 'preparing-start'
  | 'preparing-end'
  | 'folio-open'
  | 'folio-close'
  | 'combine-table'
  | 'change-table'
  | 'waiter-change'
  | 'add-product'
  | 'delete-product'
  | 'add-modifier'
  | 'delete-modifier'
  | 'add-note'
  | 'delete-note';
