import { Component, OnInit } from '@angular/core';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { OpenFoliosQuery } from 'src/app/modules/folio/state/open-folios/open-folios.query';
import { ModalService } from 'src/app/services/helpers/modal.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { GlobalService } from 'src/app/services/global.service';
import { DeliveryFormPage } from '../delivery-form/delivery-form.component';

@Component({
  selector: 'delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.scss'],
})
export class DeliveryListComponent implements OnInit {
  constructor(
    public ofq: OpenFoliosQuery,
    public tq: FolioQuery,
    public mq: SideMenuQuery,
    private modalService: ModalService,
    private glb: GlobalService,
  ) { }

  ngOnInit() { }

  async newTransaction() {
   await this.glb.openModal({
      component: DeliveryFormPage,
      canDismiss: true,
      cssClass: 'wide-modal',
    });
  }

  trackBy(item, id) {
    id
    //TODO:kontrol edilecek id gercekten kullanilmiyorsa kaldir
    return item.id;
  }

  onClickInfo(e, table) {
    this.modalService.showTableInfoModal(e, table)
  }
}
