<ion-header>
  <ion-toolbar>
    <ion-title>
      {{'Account List' | transloco}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="addNew()">
        <ion-icon icon="add"></ion-icon>
      </ion-button>
      <ion-button (click)="close()">
        <ion-icon icon="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar (ionInput)="searchValue.set($event.target.value)" [showClearButton]="true" [debounce]="300"
    placeholder="{{'Search Customer' |transloco}}..."></ion-searchbar>
  <ion-list>
    @for (item of accounts | orwiArrayFilterLive: 'customerName' : searchValue(); track $index) {
    <ion-item button (click)="select(item)">
      {{item.customerName | titlecase}}
    </ion-item>
    } @empty {
      @if(loaded) {
        <div>
          {{'No Customers found' |transloco}}
        </div>
      } @else {
        <div>
          {{'Loading' |transloco}}...
        </div>
      }
    }
  </ion-list>
</ion-content>