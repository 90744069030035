
import { ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Directive } from '@angular/core';
import { Gesture, GestureController, GestureDetail, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ITable } from 'src/app/services/dto/tables';
import { HapticService } from 'src/app/services/helpers/haptic.service';
import { TableQuery } from 'src/app/modules/tables/state/table.query';
import { TableService } from 'src/app/modules/tables/state/table.service';
import { TableStore } from 'src/app/modules/tables/state/table.store';

@Directive({
  selector: '[OrwiDraggable]'
})
export class DraggableDirective implements OnChanges {

  mode: 'design' | 'move' = "design"

  movedSubscription: Subscription
  modeSubscription: Subscription
  findedTableId = ""
  findedTableGroupId = ""
  groupId = ""

  @Input() table: ITable
  gesture: Gesture

  @Output() dragOut: EventEmitter<boolean> = new EventEmitter();
  tableElements: HTMLElement[] = []
  tableGroupElements: HTMLElement[] = []
  gestureStarted: boolean;
  outOfTheBox: boolean;

  constructor(
    private tableStore: TableStore,
    public gestureCtrl: GestureController,
    private tableService: TableService,
    private tQ: TableQuery,
    private plt: Platform,
    private haptic: HapticService,
    private elementRef: ElementRef) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    changes//reserved


    this.init()
  }

  ngAfterViewInit() {

    if (this.plt.is("desktop")) {
      this.pressingEnd = false
      this.pressing = true
      this.gestureStarted = true
    }

    if (this.movedSubscription) this.movedSubscription.unsubscribe()
    this.movedSubscription = this.tQ.moved$.subscribe(o => {
      o//reserved
      this.init()
    })
  }

  ngOnDestroy() {
    this.movedSubscription.unsubscribe()
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    event//reserved

  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  pressing = false
  pressingEnd = false
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  onPress(event) {
    if (event.type == "touchstart") {
      this.pressingEnd = false
      this.outOfTheBox = false

      setTimeout(async () => {
        if (this.pressingEnd) return;
        if (this.outOfTheBox) return;
        let p: HTMLElement = this.elementRef.nativeElement
        p.style.filter = "sepia(1)"
        this.pressing = true
        await this.haptic.hapticsImpactHeavy()

      }, 600);
    } else if (event.type == "touchend") {
      setTimeout(() => {
        this.pressingEnd = true
        this.pressing = false
        let p: HTMLElement = this.elementRef.nativeElement
        p.style.filter = "unset"

      }, 50);
    }

  }


  design() {

  }


  init() {
    this.mode = "move"


    // this.modeSubscription = this.tQ.designMode$.subscribe(o => {
    //   this.mode = o ? "design" : "move"

    //   if (o) {
    //     let p: HTMLElement = this.elementRef.nativeElement


    //     p.classList.add("animate__heartBeat")

    //     setTimeout(() => {
    //       p.classList.remove("animate__heartBeat")
    //     }, 200);



    //   } else {
    //     let p: HTMLElement = this.elementRef.nativeElement
    //     p.classList.remove("animate__heartBeat")

    //   }
    // })

    if (!this.table.transform) this.table.transform = { rotate: 0, scale: 1 }

    let cover: HTMLElement = this.elementRef.nativeElement




    if (this.table.coordinates?.x !== "0px") {
      cover.style.position = "absolute"
      cover.style.top = this.table.coordinates.y
      cover.style.left = this.table.coordinates.x
      cover.style.transform = `
      rotate(${this.table.transform.rotate}deg) 
      scale(${this.table.transform.scale})`;
    } else {
      cover.style.position = ""
      cover.style.top = ""
      cover.style.left = ""
      cover.style.transform = ``;
    }


    let ne: HTMLElement = this.elementRef.nativeElement
    let tableCover: HTMLElement = document.getElementById("table-cover")
    let tableContainer: HTMLElement = document.getElementById("table-container")
 

    tableCover.style.position = "relative";

    if (cover) {

      let width = cover.getBoundingClientRect().width
      let height = cover.getBoundingClientRect().height
      let coverOffset = 80

      this.gesture = this.gestureCtrl.create({

        el: cover,
        gestureName: 'my-gesture',
        threshold: 20,
        maxAngle: 100,
        gesturePriority: 1,
        disableScroll: true,
        passive: true,

        onStart: (detail) => {
          if (!this.pressing) return
          this.gestureStarted = true



          tableContainer.style.overflow = "hidden"
          cover.style.cursor = "default"
          cover.style.zIndex = "100"
          cover.style.opacity = "0.5"
          //cover.style.filter = "invert(1)"


          if (this.table.coordinates?.x == '0px') {
            cover.style.top = detail.currentY - (height / 2) + "px"
            cover.style.left = detail.currentX - (width / 2) + "px"
          }

          this.tableStore.update({ draggingTable: this.table });

          setTimeout(() => {

            this.initEls()

            let div: any = cover.cloneNode(true)

            cover.parentElement.appendChild(div)
            cover = div

            if (this.table.coordinates?.x !== '0px') {
              cover.style.position = "absoulute"
            } else {
              ne.style.top = detail.startY - coverOffset + "px"
              ne.style.left = detail.startX - coverOffset + "px"
              cover.style.position = "fixed"
              cover.style.opacity = "0.8"
            }

          }, 1);

        },

        onEnd: (detail) => {

          detail//reserved

          if (!this.pressing || !this.gestureStarted) return
          this.gestureStarted = false

          cover.classList.remove("xicon")
          cover.style.filter = "unset"
          ne.style.filter = "unset"
          cover.style.transform = `translate(0,0)
          rotate(${this.table.transform.rotate}deg) 
          scale(${this.table.transform.scale})`;

          tableContainer.style.overflow = "scroll"

          this.clearAllAnimations()
          cover.style.opacity = "1";
          cover.style.position = "unset"
          cover.style.cursor = "default"
          cover.style.transition = ".3s ease-out";

          if (this.table.coordinates?.x !== '0px') {
            cover.style.position = "absolute"
            cover.style.top = this.table.coordinates.y
            cover.style.left = this.table.coordinates.x
          }

          setTimeout(() => {
            this.tableStore.update({ draggingTable: null })
            ne.style.position = "unset"
            ne.style.cursor = "default"
            ne.style.opacity = "1"
            ne.style.transition = ".3s ease-out";

            ne.style.transform = `
              translate(0,0)
              rotate(${this.table.transform.rotate}deg) 
              scale(${this.table.transform.scale})  
              `;

            if (this.table.coordinates?.x !== '0px') {
              ne.style.position = "absolute"
              ne.style.top = this.table.coordinates.y
              ne.style.left = this.table.coordinates.x
            }

            cover = ne

          }, 200);

          cover.remove()

          if (this.findedTableId) {
            this.tableService.changeOrCombine(ne.id, this.findedTableId)
          }

        },

        onMove: (detail) => {


          const droppableArea = cover.getBoundingClientRect();
          if ((detail.currentX < droppableArea.left) || (detail.currentX > droppableArea.right)) {
            cover.style.filter = ""
            this.outOfTheBox = true
          }

          if (!this.pressing || !this.gestureStarted) {
            return
          }

          cover.style.transform = `
          translate(${detail.deltaX}px, ${detail.deltaY}px)
          `;

          this.isHostOnTable(detail, cover);
          this.isHostOnTableGroup(detail, cover);
          this.isScroll(detail, cover);

          if (this.findedTableGroupId && this.findedTableGroupId !== this.groupId) {
            this.groupId = this.findedTableGroupId;
            this.tableService.selectTableGroup(this.findedTableGroupId);
            setTimeout(() => {
              this.initEls();
            }, 100);
          }


        }
      }, true)

      this.gesture.enable();

    }
  }


  downScroll = 0
  isScroll(detail: GestureDetail, cover) {
    cover//reserved
    let conent = document.getElementById("table-container")
    if (detail.currentY > (document.body.getBoundingClientRect().height - 50)) {

      if (this.downScroll > (conent.scrollHeight - conent.getBoundingClientRect().height)) return;

      this.downScroll = this.downScroll + 10
      conent.scroll(0, this.downScroll)

    }
    if ((detail.currentY > (conent.getBoundingClientRect().top)) && (detail.currentY < (conent.getBoundingClientRect().top + 150))) {
      if (this.downScroll < 0) return;
      this.downScroll = this.downScroll - 10
      conent.scroll(0, this.downScroll)
    }

  }

  isHostOnTable(detail: GestureDetail, cover) {
    let finded = this.tableElements.find(o => {
      const droppableArea = o.getBoundingClientRect();
      if ((detail.currentX < droppableArea.left) || (detail.currentX > droppableArea.right)) {
        return false;
      }
      if ((detail.currentY < droppableArea.top) || (detail.currentY > droppableArea.bottom)) {
        return false;
      }
      return ((o !== cover) && (o.id !== cover.id));
    })

    this.clearAllAnimations()
    if (finded) {
      this.findedTableId = finded.id
      this.animateTarget(finded, true)
    } else {
      this.findedTableId = ""
    }

  }

  isHostOnTableGroup(detail: GestureDetail, cover) {
    let finded = this.tableGroupElements.find(o => {
      const droppableArea = o.getBoundingClientRect();
      if ((detail.currentX < droppableArea.left) || (detail.currentX > droppableArea.right)) {
        return false;
      }
      if ((detail.currentY < droppableArea.top) || (detail.currentY > droppableArea.bottom)) {
        return false;
      }
      return ((o !== cover) && (o.id !== cover.id));
    })

    if (finded) {
      this.findedTableGroupId = finded.id
      this.animateTarget(finded, true)
    }

  }

  animateTarget(el: HTMLElement, animate: boolean) {

    if (animate) {

      if (!el.classList.contains("xicon")) {
        el.style.animationDelay = "-.75s"
        el.style.animationDuration = ".25s"
        el.classList.add("xicon")
      }




    } else {



      if (el.classList.contains("xicon")) {
        el.style.animationDelay = ""
        el.style.animationDuration = ""
        el.classList.remove("xicon")
      }

    }

  }

  clearAllAnimations() {
    this.tableElements.forEach(o => {
      this.animateTarget(o, false)
    })

    this.tableGroupElements.forEach(o => {
      this.animateTarget(o, false)
    })
  }

  initEls(el?) {
    this.tableElements = []
    this.tableGroupElements = []

    if (el) this.tableElements.push(el)

    let tableGroups = document.getElementsByClassName("c-table-button-group")
    for (let index = 0; index < tableGroups.length; index++) {
      let ps: any = tableGroups.item(index)
      this.tableGroupElements.push(ps)
    }


    let tables = document.getElementsByClassName("c-table-button")
    for (let index = 0; index < tables.length; index++) {
      let ps: any = tables.item(index)
      this.tableElements.push(ps)

    }

  }

  getVisibleDimensions(node, referenceNode) {
    referenceNode = referenceNode || node.parentNode;

    var pos = node.getBoundingClientRect();
    var referencePos = referenceNode.getBoundingClientRect();

    return {
      "width": Math.min(
        node.clientWidth,
        referencePos.left + referenceNode.clientWidth - pos.left,
        node.clientWidth - (referencePos.left - pos.left)
      ),
      "height": Math.min(
        node.clientHeight,
        referencePos.top + referenceNode.clientHeight - pos.top,
        node.clientHeight - (referencePos.top - pos.top)
      )
    }
  }

  // --m1 : -1deg;
  // --p15: 1.5deg;
  // --p1: 1deg;
  // --m15: -1.5deg;

  //if (this.table.transform) {

  // let docStyle = document.documentElement.style
  // let m1 = parseFloat(docStyle.getPropertyValue("--m1").replace("deg", ""))
  // let p15 = parseFloat(docStyle.getPropertyValue("--p15").replace("deg", ""))
  // let p1 = parseFloat(docStyle.getPropertyValue("--p1").replace("deg", ""))
  // let m15 = parseFloat(docStyle.getPropertyValue("--m15").replace("deg", ""))

  // console.log(m1, p15, p1, m15)

  // let sm1 = this.table.transform.rotate + -1 + "deg"
  // let sp15 = this.table.transform.rotate + 1.5 + "deg"
  // let sp1 = this.table.transform.rotate + 1 + "deg"
  // let sm15 = this.table.transform.rotate + -1.5 + "deg"

  // docStyle.setProperty("--m1", sm1)
  // docStyle.setProperty("--p15", sp15)
  // docStyle.setProperty("--p1", sp1)
  // docStyle.setProperty("--m15", sm15)
  // console.log(docStyle.getPropertyValue("--m15"))

  //}


}
