<div style="width: 100%">
  <div class="header">
    <div>
      <!-- {{ "Combine" | transloco }} -->
    </div>
  </div>
  <div class="table-group-cover">
    <orwi-table-group-button
      [ngClass]="{ hide: checkTableGroupChange(item) }"
      class="button  c-table-button-group"
      id="{{ item.id }}"
      (itemSelected)="tableGroupSelected($event)"
      *ngFor="let item of tq.tableGroups$ | async"
      [tableGroup]="item"
    >
    </orwi-table-group-button>
  </div>

  <div id="table-cover" class="table-cover" style="flex-grow: 2">
    <table-button
      [ngClass]="{ hide: checkTableChange(item) }"
      class="c-table-button"
      id="{{ item.id }}"
      OrwiDraggable
      (itemSelected)="tableSelected($event)"
      *ngFor="let item of tq.activeTables | async"
      [table]="item"
    >
    </table-button>
  </div>
</div>
