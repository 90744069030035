import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { GeoTrackingStore, GeoTrackingState } from './geo-tracking.store';

@Injectable({ providedIn: 'root' })
export class GeoTrackingQuery extends Query<GeoTrackingState> {

  currentPosition :{lat: number, lng: number}
  statu$ = this.select("status")
  constructor(protected store: GeoTrackingStore) {
    super(store);

    this.select("currentLocation").subscribe(o => {
      this.currentPosition = o
    })

  }

}
