import { Injectable } from '@angular/core';
import { Folio } from '../dto/orwi-folio';
import { EcrService } from './ecr.service';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { ParametersStore } from 'src/app/modules/parameters/state/parameters.store';

@Injectable({ providedIn: 'root' })
export class EcrCoverService {
    constructor(
        private ecrService: EcrService,
        private folioService: FolioService,
        private parameterStore: ParametersStore
    ) { }

    async sendToEcr(folio: Folio) {
        let ecrModel = this.parameterStore.getValue().posParameters.ecrParameters.ecrModel ?? 'none';
        if (ecrModel === 'none') {
            this.folioService._posCloseFolio(folio)
            return;
        }
        folio.rows.map(x => {
            if (x.rowType == 'payment') {
                x.paymentType = 'bank-transfer'
            }
        })

        let ecrResult: any = await this.ecrService.sendFolioToEcr(
            0,
            folio
        );
        if (ecrResult === false) {
            return;
        }

        // if (ecrModel == 'pavo' || ecrModel == 'ingenico' || ecrModel == 'hugin') {
        //     let ecrResult: any = await this.ecrService.sendFolioToEcr(
        //         0,
        //         folio
        //     );
        //     if (ecrResult === false) {
        //         return;
        //     }
        //     else {
        //         this.folioService._posCloseFolio(folio)
        //     }
        // }
    }

}