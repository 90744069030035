import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { KitchenDisplayLog } from './dto';


export interface KitchenDisplayState {
    folioListData: Folio[],
    orderLogListData: KitchenDisplayLog[],
    selectedRow: FolioRow,
}


export function createInitialState(): KitchenDisplayState {
    return {
        folioListData: [],
        orderLogListData: [],
        selectedRow: null,
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'reports' })
export class KitchenDisplayStore extends Store<KitchenDisplayState> {

    constructor() {
        super(createInitialState());
    }

}