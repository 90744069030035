

<ion-header style="box-shadow: none;">
  <ion-toolbar>
    <ion-title *ngIf="type !== 'edit-table'">
      {{ 'Open New Table' | transloco }}
    </ion-title>
    <ion-title *ngIf="type === 'edit-table'">
      {{ 'Edit Table' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
   <ion-button (click)="close()">
    <span class="material-icons">
      close
      </span>
   </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
<ion-grid>
    <ion-label>{{ "Table Name" | transloco }}</ion-label>
    <ion-spinner slot="end" *ngIf="isSearching"></ion-spinner>
    <ion-input [formControl]="name" [clearInput]="!isSearching" placeholder="{{'Table Name' | transloco}}"></ion-input>
  <ion-text *ngIf="name.hasError('nameConditionAsync')" color="danger">
    <small>{{ "Table name already used" | transloco }}.</small></ion-text
  >

    <div class="modal-bottom">
      <ion-button style="--background: #ED4C4C" (click)="close()">
        {{ "Cancel" | transloco }}</ion-button
      >
      <ion-button
        style="--background: #4CD9ED"
        [disabled]="name.invalid"
        (click)="continue()"
        >{{ this.btnText| transloco }}</ion-button
      >
    </div>
</ion-grid>
</ion-content>