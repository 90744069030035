import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';
import { Discount } from 'src/app/services/dto/orwi-definitions';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';

@Pipe({
  name: 'orwiDiscont',
})
export class OrwiDiscontPipe implements PipeTransform {
  transform(folio: Folio | number | null, discont?: Discount): number {
    return discont ? this.discont(folio, discont) : 0;
  }
  discont(folio: number | Folio, discont: Discount): number {
    if (discont.amount > 0) {
      return discont.amount;
    }

    if (folio && (typeof folio === 'number' || typeof folio === 'string')) {
      if (isNumber(folio)) {
        return +folio * (+discont.rate / 100);
      }
      throw new Error('Folio is not a accept value');
    }
    if (Folio.isFolio(folio)) {
      return this.total(folio) * (+discont.rate / 100);
    }
    throw new Error('Folio is not a accept value');
  }

  total(folio: Folio): number {
    return folio.rows.reduce((acc: number, cur: FolioRow) => {
      return +acc + +cur.price * +cur.qty;
    }, 0);
  }
}
