<div
  class="
    table-btn
    open
    ripple-parent
    ion-activatable
  "
  *ngIf="folio.status == 'open'"
  [ngClass]="{ active: active }"

  (click)="select()"
>
  <div class="info-cover">
    <div class="table-btn-timer">
      <ion-icon name="timer"></ion-icon>
      <div style="margin-left: 5px; font-size: 12px">
        {{ folio?.creation | orwiTime: "D" }}
      </div>
    </div>
    <div (click)="info($event, folio)" class="table-btn-info">
      <ion-icon color="secondary" name="information-circle-outline"></ion-icon>
    </div>
  </div>
  <div style="position: relative">
    <div class="name">
      {{ folio.table.name }}
    </div>

    <div class="pax-cover">
      <ion-icon name="people"></ion-icon>
      <div class="pax">{{ folio.pax }}</div>
    </div>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>
