import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'orwiCurrency',
})
export class OrwiCurrencyPipe implements PipeTransform {
  constructor() {}

  transform(value: number, arg1?) {
    if (!value) {
      value = 0;
    }
    let currencySymbol = '₺';
    if (arg1) {
      currencySymbol = arg1;
    }

    return new CurrencyPipe('tr').transform(value, currencySymbol);
  }
}
