<ion-header>
  <ion-toolbar>
    <ion-progress-bar
      type="indeterminate"
      [ngStyle]="{ 'display': progress === true  ? '' : 'none' }"
    ></ion-progress-bar>
    <ion-back-button
      icon="/assets/icons/back-btn.svg"
      defaultHref="/"
      slot="start"
    ></ion-back-button>
    <ion-title>{{'Kitchen Display' | transloco}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="this.reverseOrder = !this.reverseOrder; filteredList.reverse()">
        <ion-icon name="filter-outline"></ion-icon>
        <p>{{'Change Order' | transloco}}</p>
      </ion-button>
      <ion-button (click)="refresh()">
        <ion-icon name="refresh-outline"></ion-icon>
        <p>{{'Refresh' | transloco}}</p>
      </ion-button>
      <ion-button (click)="settings()">
        <ion-icon name="settings-outline"></ion-icon>
        <p>{{'Settings' | transloco}}</p>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <div *ngIf="_list">
    <ion-segment
      [swipeGesture]="true"
      [scrollable]="true"
      mode="md"
      [value]="activeStatus"
      (ionChange)="statusButtonHandler($event)"
    >
      <ion-segment-button
        [value]="status"
        *ngFor="let status of OrderStatusArray"
      >
        {{ (status | transloco) + ' (' + statusCounter[status] + ')'+ ' (' + getGrandTotal(statusFilteredFolios(filteredList, status)) +'₺)' }}
      </ion-segment-button>
      <ion-segment-button [value]="null">
        {{('all' | transloco)+ ' (' + statusCounter['null'] + ')'+ ' (' + getGrandTotal(_list) +'₺)'}}
      </ion-segment-button>
    </ion-segment>
  </div>
  <div *ngIf="_list?.length > 6">
    <ion-segment
    
    class="page-segment"
      [swipeGesture]="true"
      [scrollable]="true"
      mode="md"
      [value]="activePage"
      (ionChange)="pageButtonHandler($event)"
    >
      <ion-segment-button
      
      class="page-segment-button"
        [value]="index + 1"
        *ngFor="let pages of getNumberArray(Math.ceil((filteredList.length / 6))); let index = index"
      >
        {{ index + 1 + ' ('+ getGrandTotal(getLimitedItems((index + 1) * 6)) +'₺)'}}
      </ion-segment-button>
    </ion-segment>
  </div>
  <div class="container" *ngIf="(list$ | async)">
    <div class="left">
      <ion-card style="box-shadow: 0px 5px 15px #f0f2fe; border-radius: 8px">
        <ion-card-content>
          <div class="filter-groups">
            <!-- Brand Selector -->

            <div class="filter-selector">
              <div class="filter-title">{{'filter by brand' | transloco}}</div>
              <div class="filter-items">
                <div
                  class="filter-item"
                  [ngClass]="{selected: selectedBrandId == null}"
                  (click)="onBrandChange(null)"
                >
                  <div class="filter-count">{{(list$ | async)?.length}}</div>
                  <div>{{'all' | transloco}}</div>
                </div>
                <div
                  class="filter-item"
                  [ngClass]="{selected: selectedBrandId === brand.id}"
                  *ngFor="let brand of brands$ | async as brandsList"
                  (click)="onBrandChange(brand.id)"
                >
                  <div class="filter-count">
                    {{(list$ | async | orwiArrayFilter: 'brand' : brand.id)?.length}}
                  </div>
                  <img class="filter-img" [src]="brand.image || '/assets/images/logo/orwi.svg'" />
                </div>
              </div>
            </div>
            <!-- Brand Selector -->

            <!-- App Selector -->
            <!-- <div class="filter-selector">
              <div class="filter-title">{{'filter by apps' | transloco}}</div>
              <div class="filter-items">
                <div
                  class="filter-item"
                  [ngClass]="{selected: selectedAppId === null}"
                  (click)="onAppChange(null)"
                >
                  <div class="filter-count">
                    {{(list$ | async | orwiArrayFilter: 'brand' :
                    selectedBrandId ).length}}
                  </div>
                  <div>{{'all' | transloco}}</div>
                </div>
                <div
                  class="filter-item"
                  [ngClass]="{selected: selectedAppId === app}"
                  *ngFor="let app of apps"
                  (click)="onAppChange(app)"
                >
                  <div class="filter-count">
                    {{(list$ | async | orwiArrayFilter: 'appName' :
                    app).length}}
                  </div>
                  <img class="filter-img" [src]="foodAppQuery.getIcon(app)" />
                </div>
              </div>
            </div> -->
          </div>
        </ion-card-content>
      </ion-card>
    </div>
    <div class="right">
      <ion-row *ngIf="filteredList">
        <ion-col size="12" sizeMd="4" *ngFor="let item of getLimitedItems(pageIndex * 6)">
          <kitchen-item
            [settings]="folioSettings.value"
            [kitchenItem]="item"
            [kitchenLogs]="kitchenDisplayLogs"
            (onUpdateKitchenLogs)="getKitchenLogs()"
            (handler)="updateStatusHandler()"
          ></kitchen-item>
        </ion-col>
        <ion-col size="12" *ngIf="filteredList.length === 0">
          <div class="not-found-text w-100">
            {{'folio' | transloco}} {{'not found' | transloco}}
          </div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
