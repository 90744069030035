import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuardService } from '../modules/session/state/guard.service';
import { EcrSettingsComponent } from './ui/ecr-settings/ecr-settings.component';




const routes: Routes = [
   {path: 'ecr', component: EcrSettingsComponent, canActivate: [GuardService]},
   {path: 'ecr-settings', component: EcrSettingsComponent, canActivate: [GuardService]},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule { }
