import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SideMenuState, SideMenuStore } from './side-menu.store';

@Injectable({ providedIn: 'root' })
export class SideMenuQuery extends Query<SideMenuState> {

    posMenu$ = this.select("posMenu")
    folioMenu$ = this.select("folioMenu")
    serviceType$ = this.select("servicetType")
    posMenuSelected$ = this.select("selectedPosMenu")
    folioMenuSelected$ = this.select("selectedFolioMenu")
    deleteFolioRow$ = this.select("deleteFolioRow")


    constructor(
        protected store: SideMenuStore
    ) {
        super(store);
    }


}

