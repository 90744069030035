import { Injectable } from '@angular/core';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { Discount } from '../dto/orwi-definitions';
import { GlobalService } from '../global.service';
import { OrwiService } from '../orwi/orwi.service';


@Injectable({
  providedIn: 'root',
})
export class DiscontService {
  constructor(
    private orwiService: OrwiService,
    private sessionQuery: SessionQuery,
    private glb: GlobalService,
  ) {}

  getDiscountReasons(): Promise<Discount[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          '/api/pos/discountReason/getDiscountReasons',
          { id: this.sessionQuery.activeLicense.orwiStore.id },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              resolve(Object.assign(o.response));
            } else if (o.error) {
              this.glb.toastButton(
                o.error.desc,
                'Discount Reasons',
                'top',
                'warning',
                false,
                false,
                true
              );
              resolve(null);
            }
          },
          (e: any) => {
            reject(e)
            // this.oe.folioEvents.next("error")
          }
        );
    });
  }
}
