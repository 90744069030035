import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  FoodAppProduct,
  FoodAppAccount,
  FoodAppModifier,
  FoodAppCategory,
  RestoranProduct as RestaurantProduct,
  RestaurantMenu,
  RestoranModifier,
} from '../../services/dto/integrations';

export interface IntegrationState {
  id: string;
  accounts: FoodAppAccount[];
  activeAccount: FoodAppAccount;

  activeFoodAppProduct: FoodAppProduct;
  activeFoodAppModifier: FoodAppModifier;
  activeRestaurantProduct: RestaurantProduct;
  activeRestaurantModifier: RestoranModifier;

  foodAppCategories: FoodAppCategory[];
  foodAppProducts: FoodAppProduct[];
  foodAppModifiers: FoodAppModifier[];
  foodAppAccounts: FoodAppAccount[];

  restaurantMenu: RestaurantMenu;
  restaurantProduct: RestaurantProduct[];
}

export function createInitialState(): IntegrationState {
  return {
    id: null,
    accounts: [],
    activeAccount: undefined,
    activeFoodAppProduct: undefined,
    activeFoodAppModifier: undefined,
    activeRestaurantProduct: undefined,
    activeRestaurantModifier: undefined,

    foodAppAccounts: [],
    foodAppCategories: [],
    foodAppProducts: [],
    foodAppModifiers: [],

    restaurantMenu: undefined,
    restaurantProduct: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'integration-Store' })
export class IntegrationStore extends Store<IntegrationState> {
  constructor() {
    super(createInitialState());
  }
}
