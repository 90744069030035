import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AppUser, Role } from '../../session/state/dto/sso-dto';



export interface AppUsersState {

  activeAppUser: AppUser,
  appUsers: AppUser[],
  roles: Role[]

}

export function createInitialState(): AppUsersState {
  return {
    activeAppUser: undefined,
    appUsers: [],
    roles: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'BossUsers' })
export class AppUsersStore extends Store<AppUsersState> {

  constructor() {
    super(createInitialState());
  }

}

