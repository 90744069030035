import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ITable } from 'src/app/services/dto/tables';
import { TableQuery } from '../../state/table.query';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'open-table-folios-select',
  templateUrl: './open-table-folios-select.html',
  styleUrls: ['./open-table-folios-select.scss'],
})
export class OpenTableFoliosSelectComponent implements OnInit {
  selected: ITable;
  constructor(public tableQuery: TableQuery,private glb : GlobalService) {}

  @Output()
  onSelectTables: EventEmitter<ITable> = new EventEmitter<ITable>();

  ngOnInit() {
    this.init();
  }

  init() {
    this.selected = undefined;
  }

  onRowClick(e: ITable) {
    console.log('onRowClick',e)
    if (e.folios.length > 0)
    {
      if (e.folios[0].eftPos)
      {
        this.glb.toast('Eft Pos',"Eft Pos'a gönderilmiş adisyonda  işlem yapamazsınız.",'bottom','warning')
        return
      }    
    }

    this.selected = e;
    this.emitToParent();
  }

  isChecked(e: ITable) {
    return this.selected?.id == e.id;
  }

  emitToParent() {
    this.onSelectTables.emit(this.selected);
  }
  clear() {
    this.selected = null;
    this.emitToParent();
  }
}

export interface OpenTableListItem {
  [name: string]: { selected: boolean; item: ITable };
}
