<div style="margin-left: 10px; font-weight: bold">{{'Products' | transloco}}</div>
<div>
  <ion-grid>
    <ion-row>
      <ion-col size="1"
        ><ion-checkbox
          #allCheckBox
          [checked]="isCheckedAll()"
          (ionChange)="allChecked(allCheckBox.checked)"
        ></ion-checkbox
      ></ion-col>
      <ion-col size="2"><b>{{'Qty' | transloco}}</b></ion-col>
      <ion-col><b>{{'Name' | transloco}}</b></ion-col>
      <ion-col><b>{{'Total' | transloco}}</b></ion-col>
      <ion-col size="3"><b>{{'Price' | transloco}}</b></ion-col>
    </ion-row>
  </ion-grid>
</div>
<div style="height: calc(100% - 11rem); overflow-y: scroll">
  <!-- {{ selecteds[0].qty | json }} -->
  <ion-grid>
    <ion-row
      *ngFor="let item of selecteds; let index = index; let isEven = even"
      (click)="onRowClick(item.folioRow.id, index)"
      style="height: 35px;"
      [ngStyle]="{
        'background-color': item.valid
          ? isEven
            ? '#E4E7F8'
            : ''
          : 'rgb(242 62 62 / 63%)'
      }"
    >
      <ion-col size="1"
        ><ion-checkbox
          [checked]="isChecked(item.folioRow.id, index)"
        ></ion-checkbox
      ></ion-col>
      <ion-col size="2">
        <ion-input
          #qtyInput
          [value]="+item.qty"
          style="max-height: 20px; min-height: unset;"
          (ionChange)="qtyChange(qtyInput, item.folioRow.id, index)"
          (click)="$event.preventDefault(); $event.stopPropagation()"
        ></ion-input
      ></ion-col>
      <ion-col>{{ item.folioRow?.name | slice: 0:10 }}</ion-col>
      <ion-col>{{ item.folioRow?.qty }}</ion-col>
      <ion-col size="3">{{
        item.folioRow | folioRowTotal | orwiCurrency
      }}</ion-col>
    </ion-row>
  </ion-grid>
</div>
