import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ITable, TableGroup } from 'src/app/services/dto/tables';

export interface TableState {
    tables: ITable[];
    tableGroups: TableGroup[];
    selectedTableGroup: TableGroup;
    selectedTable: ITable;
    activeTables: ITable[]
    draggingTable: ITable,
    moved: ITable,

    inDesignTable: ITable,
    designMode: boolean

}

export function createInitialState(): TableState {
    return {
        tables: [],
        tableGroups: [],
        selectedTable: null,
        selectedTableGroup: null,
        activeTables: [],
        draggingTable: null,
        moved: null,


        inDesignTable: null,
        designMode: false

    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'table', resettable: true })
export class TableStore extends Store<TableState> {

    constructor() {
        super(createInitialState());
    }



}