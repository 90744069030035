import { Injectable } from '@angular/core';
import { Zeroconf, ZeroconfResult } from '@awesome-cordova-plugins/zeroconf';
import { Platform } from '@ionic/angular';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';

@Injectable({
  providedIn: 'root'
})
export class ZeroConfService {

  zc: ZeroconfResult[] = []

  constructor(
    private orwiStoreQuery: OrwiStoreQuery,
    private plt: Platform
  ) {
    if (this.plt.is("capacitor")) {
      this.initZeroConf()
    }
  }

  initZeroConf() {

    if (!this.plt.is("capacitor")) return;

    Zeroconf.registerAddressFamily = "ipv4"
    Zeroconf.watchAddressFamily = 'ipv4';



    Zeroconf.watch("_orwipos._tcp.", "local.").subscribe(result => {

      if (result.action == "registered") {
        this.zc.push(result)
      } else if (result.action == "added") {
        this.zc.push(result)
      } else if (result.action == "resolved") {
        this.zc.push(result)
      } else if (result.action == "removed") {
        let ix = this.zc.findIndex(o => o.service.name == result.service.name)
        this.zc.splice(ix, 1)
      }

      // console.log("Zeroconf Service Updates:");
      // console.log(result.action)
      // console.log(result.service.ipv4Addresses)
      // console.log(result.service.txtRecord)
      // console.log(result.service.name)
      // console.log(result.service)
    });




    setTimeout(async () => {
      let h = await Zeroconf.getHostname()
      let host = 'orwi-pos-' + h
      let txt = { id: this.orwiStoreQuery._orwiStore?.id || 'none', name: this.orwiStoreQuery._orwiStore?.name || 'none' }
      Zeroconf.register('_orwipos._tcp.', 'local.', host, 80, txt).then(result => {
        console.log('Service registered', result.service);
      });

    }, 5000);



  }




}