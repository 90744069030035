<ion-header [translucent]="true">
  <ion-toolbar style="margin-top: 20px">
    <ion-title>{{ this.integrationQuery.activeFoodAppProduct.name }}</ion-title>
    <ion-searchbar
      class="custom"
      [debounce]="1000"
      (ionChange)="handleChange($event)"
      placeholder="{{ 'Search' | transloco }}"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item-group *ngFor="let item of integrationQuery.restaurantCategories">
      <ion-item-divider>
        <ion-label style="color: #184eae">
          {{ item.name }}
        </ion-label>
      </ion-item-divider>

      <ion-item
        style="color: #171e4e"
        *ngFor="let pitem of getProducts(item.id)"
        (click)="select(pitem)"
      >
        <ion-label>{{ pitem.name }}</ion-label>
        <ion-text>{{ pitem.price | currency: "₺" }}</ion-text>
      </ion-item>
    </ion-item-group>
  </ion-list>
</ion-content>
