<div style="font-weight: bold; margin-left: 10px">{{'Folios' | transloco}}</div>
<div class="buttons">
  <orwi-folio-button
    *ngFor="let item of folioQuery.openFolios$ | async"
    [folio]="item"
    (onSelect)="onSelect(item)"
    [active]="selected?.id === item.id"
    [ngClass]="{ hide: item.id == folioQuery.getValue().activeFolio.id }"
  ></orwi-folio-button>
</div>
