import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { CountryCodesService } from 'src/app/services/location/country-codes.service';
import { PosCountry } from '../../state/dto/sso-dto';


@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.page.html',
  styleUrls: ['./select-country.page.scss'],
})
export class SelectCountryPage implements OnInit {

  countries: PosCountry[] = []
  constructor(public helper: CountryCodesService, private nav: NavController, private glb: GlobalService) {
    this.helper.getCountries()
   }

  ngOnInit() {
    
  }

  ionViewDidEnter() {
    this.countries = this.helper.countries
  }


  ngAfterViewInit() {

   
  }

  search(e) {
    this.glb.consolelog(e.detail.value)
    this.countries = this.helper.countries.filter(o => o.name.toLowerCase().indexOf(e.detail.value.toLowerCase()) > -1)
  }

  select(item: PosCountry) {
    this.helper.activeCountry = item
    this.nav.pop()
  }

}
