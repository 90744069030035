import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MenuState, MenuStore } from './menu.store';

@Injectable({ providedIn: 'root' })
export class MenuQuery extends Query<MenuState> {
  groups$ = this.select('groups');
  activeProducts$ = this.select('activeProducts');
  subGroups$ = this.select('subGroups');
  selectedProduct$ = this.select('selectedProduct');
  selectedGroup$ = this.select('selectedGroup');
  selectedSubGroup$ = this.select('selectedSubGroup');
  products$ = this.select("products");

  allGroups$ = this.select('allGroups');


  get allGroups() {
    return this.getValue().allGroups;
  }
  get subGroups() {
    return this.getValue().subGroups;
  }

  constructor(protected store: MenuStore) {
    super(store);
  }
}
