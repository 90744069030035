import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { FoodAppProduct, RestaurantProduct } from '../../../state/dto';
import { FoodAppsIntegrationQuery } from '../../../state/food-apps-integration.query';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss'],
})
export class ProductSelectComponent implements OnInit {
  public searchField: FormControl;
  foodAppProducts : FoodAppProduct[] = []
  activeGroup = ""
  query = ""

  constructor(
    public integrationQuery: FoodAppsIntegrationQuery,private glb : GlobalService
  ) {
    this.searchField = new FormControl('');
   }

  getProducts(group) {
    return this.integrationQuery.restaurantProducts.filter(o => o.group == group && o.name.toLowerCase().indexOf(this.query) > -1)
  }

  select(item: RestaurantProduct) {
    this.integrationQuery.activeFoodAppProduct.internalId = item.id
    this.integrationQuery.activeFoodAppProduct.changed = true
    this.glb.closeModal([])

  }

  handleChange(event) {
    this.query = event.target.value.toLowerCase();
    //this.results = this.data.filter(d => d.toLowerCase().indexOf(query) > -1);
  }

  ngOnInit() {}

}

