import { Injectable } from '@angular/core';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { mongo_upsert_params } from 'src/app/services/public-api/dto';
import { PublicApiService } from 'src/app/services/public-api/public-api.service';
import { OrderStatus, KitchenDisplayLog, KitchenDisplaySettings } from './dto';
import { KitchenDisplayStore } from './kitchen-display.store';

import { MenuQuery } from 'src/app/modules/menu/state/menu.query';


@Injectable({ providedIn: 'root' })
export class KitchenDisplayService {
  db: string;
  col = 'kitchen-display-logs';
  menuProductsIds = [];
  kitchenDisplaySettings: KitchenDisplaySettings = new KitchenDisplaySettings()
  isRequestPending = false;
  isTerminationRequested = false;
  constructor(
    private folioService: FolioService,
    private publicApi: PublicApiService,
    private sessionQuery: SessionQuery,
    private ig: IdGeneratorService,
    private kitchenDisplayStore: KitchenDisplayStore,
    private menuQuery: MenuQuery,

  ) {
    this.db = sessionQuery.activeLicense.orwiStore.cloudId
    this.menuQuery.allGroups$.subscribe(x => {
      x.map(menuItem => this.menuProductsIds.push(menuItem.id))
    })
  }

  getKitchenDisplayLogs(
    folioId: string[],
    orderStatus: OrderStatus
  ): Promise<KitchenDisplayLog[]> {
    if (orderStatus) {
      let filter = {
        folioId: {
          $in: folioId,
        },
        status: orderStatus,
      };
      return this.publicApi
        .getCollection({ _db: this.db, col: this.col, _filter: filter })
        .toPromise();
    } else {
      let filter = {
        folioId: {
          $in: folioId,
        },
      };
      return this.publicApi
        .getCollection({ _db: this.db, col: this.col, _filter: filter })
        .toPromise(); 
    }
  }

  upsertKitchenDisplayLog(entry: KitchenDisplayLog) {
    if (entry.id == '') {
      let _data: KitchenDisplayLog = {
        id: this.ig.generateMaxi(),
        folioId: entry.folioId,
        creationDate: new Date(),
        itemId: entry.itemId, // rowId
        lastChange: new Date(),
        status: entry.status,
        user: {
          id: this.sessionQuery.user.id,
          name: this.sessionQuery.user.name,
        },
      };
      return this.publicApi.upsert({
        _db: this.db,
        col: this.col,
        data: _data,
      });
    } else {
      entry.lastChange = new Date();
      return this.publicApi.upsert({
        _db: this.db,
        col: this.col,
        data: entry,
      });
    }
  }

  flatFilter(nestedProp, compareKey, compareId, arr) {
    return arr.filter(o => {
      const keep = o[compareKey] === compareId;
      if (keep && o[nestedProp]) {
        o[nestedProp] = this.flatFilter(nestedProp, compareKey, compareId, o[nestedProp]);
      }
      return keep;
    });
  }

  terminateFetchData() {
    this.isTerminationRequested = true;
  }

  fetchData(date?) {
    if (this.isTerminationRequested) {
      this.isTerminationRequested = false;
      return Promise.resolve("Termination requested manually"); 
    }

    if (this.isRequestPending) {
      return Promise.resolve("Terminated previous request");
    }

    this.isRequestPending = true;

    if (!date) date = new Date();

    return new Promise((resolve, reject) => {
      const request = this.folioService.__getFoliosByDate(date);

      request.then((o: Folio[]) => {
        o = o.filter((element) => {
          element.rows = element.rows.filter(item => {
            if (item.parentID !== '' && item.isModifier == true) {
              item.rowType = 'modifier'
            }
            if (item.itemID == "" || !this.menuProductsIds.includes(item.itemID)) {
              return item
            }
            else {
              return this.kitchenDisplaySettings.products.includes(item.itemID)
            }
          })
          return element.rows.find((subElement) => this.kitchenDisplaySettings.products.includes(subElement.itemID))
        });

        let allData = this.kitchenDisplayStore.getValue().folioListData;
        if (allData.length == 0) {
          this.kitchenDisplayStore.update({ folioListData: o.reverse() });
        } else {
          let newLogs = [];
          o.forEach((item) => {
            let founded = allData.find((el) => el.id == item.id);
            if (!founded) {
              newLogs.push(item);
            } else {
              if (item.rows.length != founded.rows.length) {
                newLogs.push(item);
              }
            }
          });

          this.kitchenDisplayStore.update(({ folioListData }) => ({
            folioListData: [...newLogs, ...folioListData].sort(
              (a, b) =>
                new Date(b.creation).getTime() - new Date(a.creation).getTime()
            ),
          }));
        }
        resolve(o);
      }).catch((err: any) => {
        reject(err);
      }).finally(() => {
        this.isRequestPending = false;
      });
    });
  }


  getSettings(deviceId) {
    return this.publicApi.getCollection({
      _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
      col: "kitchen-display-settings",
      _filter: { deviceId: deviceId }
    })
  }


  async saveSettings() {
    const prm = {} as mongo_upsert_params;
    if (this.kitchenDisplaySettings.id == "") {
      this.kitchenDisplaySettings.id = this.ig.generateMaxi()
    }
    let filter = { id: this.kitchenDisplaySettings.id, deviceId: this.kitchenDisplaySettings.deviceId };
    prm._filter = filter
    prm.data = this.kitchenDisplaySettings
    prm._db = this.sessionQuery.activeLicense.orwiStore.cloudId
    prm.col = "kitchen-display-settings"
    return this.publicApi.upsertGeneric(prm)
  }


}
