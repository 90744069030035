<!-- <ion-header>
  <ion-toolbar>
    <ion-title>
      {{ tableName | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <span class="material-icons"> close </span>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->
<orwi-modal-header [title]="tableName" (onClose)="close()"></orwi-modal-header>
<ion-content>
  <ion-grid style="width: 100%; height: auto">
    <ion-item lines="none">
      <div
        style="
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        "
      >
        <div>{{ "Persons Count" | transloco }}</div>
        <div>
          <ion-icon class="pax-cover" name="people"></ion-icon>
          <span> {{ data?.pax }}</span>
        </div>
      </div>
    </ion-item>

    <ion-list style="overflow-y: scroll; height: 55%">
      <ion-item *ngFor="let item of data.rows">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div style="flex: 1">
            {{ item.name }}
          </div>
          <div>
            {{ item.price | orwiCurrency }}
          </div>
        </div>
      </ion-item>
      <ion-item lines="none" *ngIf="data.rows?.length > 0">
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div style="flex: 1">{{ "Total" | transloco }}:</div>
          <div>
            {{ data.grandTotal | orwiCurrency }}
          </div>
        </div>
      </ion-item>
    </ion-list>
  </ion-grid>
</ion-content>
