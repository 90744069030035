<ion-header>
  <ion-toolbar>
    <ion-progress-bar
      type="indeterminate"
      [ngStyle]="{ display: progress === true ? '' : 'none' }"
    ></ion-progress-bar>
    <ion-back-button
      icon="/assets/icons/back-btn.svg"
      defaultHref="/"
      slot="start"
    ></ion-back-button>
    <ion-title>{{ "Kitchen Display Settings" | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="saveSettings()">
        <ion-icon name="save-outline"></ion-icon>
        <p>{{ "Save" | transloco }}</p>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-accordion-group>
        <ion-accordion *ngFor="let item of menuQuery.allGroups$ | async">
          <ion-item slot="header" color="light">
            <ion-label>{{item.name}}</ion-label>
          </ion-item>
          <div class="ion-padding" *ngFor="let pitem of getProducts(item.id)" slot="content">
            {{pitem.name}}
          </div>
        </ion-accordion>       
      </ion-accordion-group> -->

  <ion-list>
    <!-- <ion-item *ngIf="(integrationQuery.foodAppCategorie$ | async).length == 0">
            Kategori Listesi Boş
        </ion-item> -->
    <div [formGroup]="folioSettings">
      <ion-item-divider>
        <ion-label class="p-group">
          <strong>{{ "Folio Details" | transloco }}</strong>
        </ion-label>
        <div>
          {{ "Show Order Number" | transloco }}
          <ion-toggle
            formControlName="orderNumber"
            (ionChange)="setFolioSettings($event)"
            [checked]="this.folioSettings.value.orderNumber"
          ></ion-toggle>
        </div>
        <div>
          {{ "Show Waiter Name" | transloco }}
          <ion-toggle
            formControlName="waiter"
            (ionChange)="setFolioSettings($event)"
            [checked]="this.folioSettings.value.waiter"
          ></ion-toggle>
        </div>
        <div>
          {{ "Show Table Name" | transloco }}
          <ion-toggle
            formControlName="table"
            (ionChange)="setFolioSettings($event)"
            [checked]="this.folioSettings.value.table"
          ></ion-toggle>
        </div>
        <div>
          {{ "Warning Color" | transloco }}:
          <!-- <ion-select
            okText="{{ 'Ok' | transloco }}"
            cancelText="{{ 'Cancel' | transloco }}"
            [value]="this.folioSettings.value.warningColor"
            formControlName="warningColor"
            (ionChange)="setFolioSettings($event)"
          >
            <ion-select-option
              [value]="color.color"
              *ngFor="let color of colorOptions"
              >
              <ion-item>
                {{ color.name }}
                <ion-icon
                  slot="end"
                  [color]="color.color"
                  style="height: 20px; width: 20px; border-radius: 100%"
                ></ion-icon
              >
              </ion-item>
          </ion-select-option>
          </ion-select> -->
            <input
              formControlName="warningColor"
              style="cursor: pointer;"
              [ngStyle]="{ color: this.folioSettings.value.warningColor }"
              [(colorPicker)]="this.folioSettings.value.warningColor"
              [style.background]="this.folioSettings.value.warningColor"
              [cpUseRootViewContainer]="true"
              cpOutputFormat="hex"
              cpFallbackColor="#f4f5f8"
              (colorPickerChange)="setFolioSettings($event)"
              [readonly]="true"
              cpPosition="bottom"
            />
        </div>
        <div>
          {{ "Color Change After (Mins)" | transloco }}:
          <ion-input
            type="number"
            (ionInput)="setFolioSettings($event)"
            formControlName="warningTime"
            placeholder="{{ 'Minutes' | transloco }}"
          ></ion-input>
        </div>
      </ion-item-divider>
    </div>
    <div *ngFor="let item of menuQuery.allGroups$ | async">
      <ion-item-group *ngIf="getProducts(item.id).length > 0">
        <ion-item-divider>
          <ion-label class="p-group">
            <strong>{{ item.name }}</strong>
          </ion-label>
          <ion-toggle
            slot="end"
            (ionChange)="selectGroup($event, item)"
            [checked]="checkedGroups(item.id)"
          ></ion-toggle>
        </ion-item-divider>

        <ion-item-sliding
          *ngFor="let pitem of getProducts(item.id)"
          #slidingCase
        >
          <ion-item style="height: 35px; line-height: 35px">
            <div style="width: 100%; font-size: 14px; margin-bottom: 15px">
              <div class="p-container">
                <div>{{ pitem.name }}</div>
                <ion-toggle
                  (ionChange)="selectProduct($event, pitem)"
                  [checked]="checkedProducts(pitem.id)"
                  slot="end"
                ></ion-toggle>
              </div>
            </div>
          </ion-item>
        </ion-item-sliding>
      </ion-item-group>
    </div>
  </ion-list>
</ion-content>