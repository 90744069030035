import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orwiTime',
})
export class OrwiTimePipe implements PipeTransform {
  transform(value: number | Date, ...args: unknown[]): unknown {
    // D for date
    if (value === null || value === undefined) {
      return '00:00';
    }

    if (args.includes('D')) {
      const startDate: Date = new Date(value);
      const endDate: Date = new Date();
      const time: number = endDate.getTime() - startDate.getTime();

      return this.calculateTime(Math.floor((time / 1000 / 60) << 0));
    }

    return this.calculateTime(value as number);
  }
  calculateTime(value: number): string {
    const minutes: string = (value % 60) + '';
    const hours: string = Math.floor(value / 60) + '';

    const hourString: string = Number(hours) > 0 ? hours + 's' : '';
    const minutesString: string = Number(minutes) > 0 ? minutes + 'dk' : '';
    return `${hourString}${ Number(hours) <=2 ? minutesString: ''}`;
  }
}
