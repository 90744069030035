import { Injectable } from '@angular/core';

import { NumpadQuery } from './numpad.query';
import { NumpadStore, NumpedType } from './numpad.store';

@Injectable({ providedIn: 'root' })
export class NumpadService {
  constructor(
    private numpadStore: NumpadStore,
    private numpadQuery: NumpadQuery
  ) {}

  open(type: NumpedType, value?: any) {
    this.numpadStore.update((state) => ({
      ...state,
      visible: true,
      for: type,
      value: {
        ...state.value,
        [`${type}`]: value ?? state.value[type] ?? '0.0',
      },
    }));
  }

  // get Value(): number {
  //   const key: any = this.numpadQuery.getValue().for;
  //   return +this.numpadQuery.getValue().value[key];
  // }

  setValue(value: string | number, type?: NumpedType) {
    const key: any = type ? type : this.numpadQuery.getValue().for;
    const old: any = { ...this.numpadQuery.getValue().value };
    old[key] = (+value).toFixed(2);
    this.numpadStore.update({ value: old });
  }
  getValue(type?: NumpedType) {
    const key: any = type ? type : this.numpadQuery.getValue().for;
    return +this.numpadQuery.getValue().value[key];
  }

  reset(type?: NumpedType) {
    if (type) {
      this.setValue(1, type);
    } else {
      this.numpadStore.reset();
    }
  }
  close() {
    this.numpadStore.update({ visible: false });
  }
}
