<div
  *ngIf="backDropEnabled"
  (click)="closeButtonClick()"
  class="orwi-actio-backdrop"
></div>

<div id="orwi-prompt-prompt" class="animate__animated orwi-prompt">
  <div
    style="
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    "
  >
    <div id="c1">
      <div *ngIf="closeable" (click)="closeButtonClick()" class="close">
        <ion-icon name="close-outline"></ion-icon>
      </div>
      <div id="orwi-prompt-title" class="title"></div>
    </div>

    <div
      id="c2"
      style="
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
      "
    >
      <div id="orwi-prompt-content" class="content"></div>
      <div *ngIf="inputs.length > 0">
        <div
          class="animate__animated"
          id="input-{{ item.id }}"
          *ngFor="let item of inputs"
          style="margin-bottom: 10px"
        >
          <input
            *ngIf="item.type !== 'textarea'"
            [placeholder]="item.placeholder"
            [(ngModel)]="item.value"
            [type]="item.type"
          />
          <textarea
            style="
              height: 100px;
              padding: 5px;
              width: 100%;
              color: rgb(116, 116, 116);
            "
            *ngIf="item.type == 'textarea'"
            [placeholder]="item.placeholder"
            [(ngModel)]="item.value"
          >
          </textarea>
        </div>
      </div>
    </div>

    <div id="c3">
      <ion-button
        (click)="btnClick(item)"
        [ngStyle]="{
          '--background': item.color == 'secondary' ? '#ED4C4C' : '#4CD9ED'
        }"
        *ngFor="let item of buttons"
      >
        {{ item.text }}</ion-button
      >
    </div>
  </div>
</div>
