import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { GeoTrackingQuery } from 'src/app/modules/geo-tracking/state/geo-tracking.query';
import { ReportsQuery } from 'src/app/modules/reports/state/reports.query';
import { DistanceCalculatorService } from 'src/app/services/helpers/distance-calculator.service';

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent  implements OnInit {
  rows: FolioRow[] = [];
  mainRows: FolioRow[] = [];
  folio: Folio[] = [];
  item;
  isIOS = false;
  constructor(
    private modalController: ModalController,
    public reportsQuery: ReportsQuery,
    public geoTrackingQuery: GeoTrackingQuery,
    private distanceCalculator: DistanceCalculatorService,
    private plt: Platform
  ) {
    this.isIOS = this.plt.is('ios');
    this.reportsQuery.activeFolioRows.subscribe((o) => {
      this.rows = o;
      this.mainRows = o.filter((o) => o.parentID == '');
      console.log(this.mainRows);
    });
    this.reportsQuery.folioListActiveFolio$.subscribe((o) => {
      this.item = o;
      console.log('item', this.item);
    });
  }

  subRows(row: FolioRow) {
    return this.rows.filter((o) => o.parentID == row.id);
  }

  range(item: Folio) {
    let lat1 = this.geoTrackingQuery.currentPosition.lat;
    let lng1 = this.geoTrackingQuery.currentPosition.lng;
    let lat2 = item.location.lat;
    let lng2 = item.location.lng;
    let distance = this.distanceCalculator.distance(lat1, lng1, lat2, lng2);
    return distance.toPrecision(2) + ' KM';
  }

  openMap(item: Folio) {
    if (this.isIOS) {
      window.open(
        `https://maps.apple.com/?q=${item.location.lat},${item.location.lng}`,
        '_blank'
      );
    } else {
      window.open(
        `https://maps.google.com/maps?daddr=${item.location.lat},${item.location.lng}&amp;ll=`,
        '_blank'
      );
    }
  }

  ngOnInit(): void {
    console.log('mainRows', this.mainRows);
    console.log('rows', this.rows);
  }

  close() {
    this.modalController.dismiss();
  }
}
