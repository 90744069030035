import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Reasons, Printer } from 'src/app/services/dto/orwi-definitions';
import { OrwiStore, PaymentType, StoreCover } from 'src/app/services/dto/orwi-store';

export interface OrwiStoreState {
  id: string
  store: OrwiStore
  payTypes: PaymentType[]
  ownedStores: StoreCover[],
  printers: Printer[],
  deleteReasons: Reasons[],
  giftReasons : Reasons[],
  storeInitialized: boolean
  activeStore: string
}

export function createInitialState(): OrwiStoreState {
  return {
    id: null,
    store: null,
    payTypes: null,
    ownedStores: [],
    printers: [],
    deleteReasons: [],
    giftReasons: [],
    storeInitialized: false,
    activeStore: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orwi-Store' })
export class OrwiStoreStore extends Store<OrwiStoreState> {

  constructor() {
    super(createInitialState());


    

  }

}