import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ComponentsModule } from 'src/app/components/components.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeliveryFormPage } from './components/delivery-form/delivery-form.component';
import { DeliveryListComponent } from './components/delivery-list/delivery-list.component';
import { DeliveryStatusComponent } from './components/delivery-status/delivery-status.component';
import { CustomerAddressItemComponent } from './components/customer-address-item/customer-address-item.component';
import { CustomerCardComponent } from './components/customer-card/customer-card.component';


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        ComponentsModule,
        SharedModule,
    ],
    exports: [
        DeliveryListComponent,
        DeliveryFormPage,
        DeliveryStatusComponent,
    ],
    declarations: [
        DeliveryFormPage,
        DeliveryListComponent,
        DeliveryStatusComponent,
        CustomerAddressItemComponent,
        CustomerCardComponent,
    ],
    providers: [],
})
export class DeliveryModule { }
