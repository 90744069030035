<div>
  <div class="modal-content">
    <h2>{{'Syncing Products' | transloco}}</h2>
    @if (progressPercentage !== 100 && progressPercentage !== 0) {
    <p>{{progressText}}</p>
    }
    <div class="progress-bar-container">
      <div class="progress-bar" [style.width.%]="progressPercentage"></div>
    </div>
    <p>{{ progressPercentage }}% {{'Completed' | transloco}}</p>
  </div>
</div>