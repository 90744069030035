<div>
    <orwi-modal-header [title]="'Pax' | transloco"></orwi-modal-header>
    <!-- <h3 style="text-align: center">{{ "" | transloco }}</h3> -->
    <ion-list>
      <ion-item class="ion-item">
        <ion-range
          class="range"
          [(ngModel)]="this.child"
          [pin]="true"
          [pinFormatter]="pinFormatter"
          (ionChange)="pax($event, 'c')"
          [ticks]="true"
          [snaps]="true"
          min="0"
          max="15"
        >
          <ion-label slot="end">{{ "Children" | transloco }}</ion-label>
          <ion-icon slot="start" src="assets/images/child-solid.svg"></ion-icon>
          <!-- <ion-badge slot="end">{{ this.folioStore.getValue().activeFolio.paxChild }}</ion-badge> -->
        </ion-range>
        <div class="input-cover">
          <input
            class="input"
            type="number"
            min="0"
            max="15"
            (change)="pax($event, 'c')"
            [value]="this.child"
            [ngStyle]="{ 'padding-left': this.child >= 10 ? '9.5px' : '14.5px' }"
          />
        </div>
      </ion-item>
      <ion-item class="ion-item">
        <ion-range
          class="range"
          [(ngModel)]="this.female"
          [pin]="true"
          [pinFormatter]="pinFormatter"
          (ionChange)="pax($event, 'f')"
          [ticks]="true"
          [snaps]="true"
          min="0"
          max="15"
        >
          <ion-label slot="end">{{ "Female" | transloco }}</ion-label>
          <ion-icon slot="start" src="assets/images/female-solid.svg"></ion-icon>
          <!-- <ion-badge slot="end">{{ this.folioStore.getValue().activeFolio.paxFemale }}
                  </ion-badge> -->
        </ion-range>
        <div class="input-cover">
          <input
            class="input"
            type="number"
            min="0"
            max="15"
            (change)="pax($event, 'f')"
            [value]="this.female"
            [ngStyle]="{ 'padding-left': this.female >= 10 ? '9.5px' : '14.5px' }"
          />
        </div>
      </ion-item>
      <ion-item class="ion-item">
        <ion-range
          class="range"
          [(ngModel)]="this.male"
          [pin]="true"
          [pinFormatter]="pinFormatter"
          (ionChange)="pax($event, 'm')"
          [ticks]="true"
          [snaps]="true"
          min="0"
          max="15"
        >
          <ion-label slot="end">{{ "Male" | transloco }}</ion-label>
          <ion-icon slot="start" src="assets/images/male-solid.svg"></ion-icon>
          <!-- <ion-badge slot="end">{{ this.folioStore.getValue().activeFolio.paxMale }}
                  </ion-badge> -->
        </ion-range>
        <div class="input-cover">
          <input
            class="input"
            type="number"
            min="0"
            max="15"
            (change)="pax($event, 'm')"
            [value]="this.male"
            [ngStyle]="{ 'padding-left': this.male >= 10 ? '9.5px' : '14.5px' }"
          />
        </div>
      </ion-item>
    </ion-list>
    <ion-item lines="none" class="">
      <div
        style="
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 10px;
          width: 100%;
        "
      >
        <ion-button (click)="done()">
          {{ "done" | transloco }}
        </ion-button>
      </div>
    </ion-item>
  </div>
  
  