import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { AppService } from 'src/app/services/utils/app.service';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root',
})
export class BackOfficeService {
  baseUrl = 'https://im-service.kerzz.com:4800';
  // baseUrl = 'https://192.168.1.127:4800'

  token = '';
  userLanguage = 'en';
  userRegion = 'us';

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private sessionQuery: SessionQuery,
  ) {
  }

  serviceRequest(path, postData, _token = '') {

    this.token = this.sessionQuery.token;
    let url = `${this.baseUrl}${path}`;
    return this.http.post(url, postData, this.getHeaders());
  }

  serviceRequestPromise(path, postData, _token = '') {
    return new Promise((resolve, reject) => {

      if (this.appService.connection == "offline") {
        reject('offline');
        return;
      }

      // this.token = _token;

      let url = `${this.baseUrl}${path}`;
      this.http.post(url, postData, this.getHeaders()).subscribe((o) => {
        resolve(o);
      }, (e) => {
        if (e.status == 0) {
          this.glb.closeLoading();
          setTimeout(() => {
            this.glb.toast('Opss!', this.transloco.translate('There is a problem, try again after a while'), 'middle', 'danger');
          },
            500);
        } else if (e.status == 404 || e.status == 401) {

        } else {


          setTimeout(() => {
            // this.glb.toast('Opss!', e.error.message, 'middle', 'danger');
          }, 500);

        }

        reject(e);
      }
      );
    });
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        accept: 'application/json',
        'x-api-key': 'UjJ4dlltRnVJRmRsWWlCVGFYUmw',
        'x-user-token': this.sessionQuery.token,
        'x-user-name' : this.glb.notTurkishCharacter(this.sessionQuery?.user?.name || '')
      }),
    };
    return httpOptions;
  }
}
