export interface KitchenDisplayLog {
  id: string;
  folioId: string;
  itemId: string;
  creationDate: Date;
  lastChange: Date;
  status: OrderStatus;
  user: { id: string; name: string };
}

export enum OrderStatus {
  waiting = 'waiting',
  prepering = 'prepering',
  prepered = 'prepered',
  cancelled = 'cancelled',
}

export const OrderStatusArray: OrderStatus[] = [
  OrderStatus.waiting,
  OrderStatus.prepering,
  OrderStatus.prepered,
  OrderStatus.cancelled,
];


export class KitchenDisplaySettings {
  id : string
  products : string[]= []
  groups : string[] = []
  deviceId : string = ""
  createDate : Date = new Date() 
  lastEditDate : Date = new Date()
}
