import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { SessionStore } from './session.store';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {

  constructor(private router: Router, private cls: SessionQuery, private sessionStore: SessionStore) { }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.cls.authStatus) {

      return true

    } else {
      console.log('access denied!', state.url)
      this.sessionStore.update({ startingRoute: state.url })
      this.router.navigateByUrl('welcome');
      return false
    }

   
  }
  
}
