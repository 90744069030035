import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneFolioModule } from './modules/phone-folio/phone-folio.module';
import { PhonePosModule } from './modules/phone-pos/phone-pos.module';
import { PhoneRoutingModule } from './phone-routing.module';
import { FolioModule } from '../folio/folio.module';



@NgModule({
    declarations: [
        
    ],
    imports: [
        CommonModule,
        PhoneFolioModule,
        PhonePosModule,
        PhoneRoutingModule,
        FolioModule,
        
    ],
    exports: [
    ]
})
export class PhoneModule { }
