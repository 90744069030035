<ion-header>
    <ion-toolbar></ion-toolbar>
</ion-header>
<ion-content forceOverscroll="false" [fullscreen]="true">
    <orwi-cloud-menu (itemSelected)="menuSelected($event)" [menuType]="'pos'"></orwi-cloud-menu>

    <div id="table" class="animated">

        <div style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;" *ngIf="(selectedPosMenu$ | async)?.id == 'table-service' ||(selectedPosMenu$ | async)?.id === 'change' ">
            <orwi-table-group-container id="table-group-content"></orwi-table-group-container>
            <orwi-table-container style="flex-grow: 2; overflow: scroll"></orwi-table-container>
        </div>

        <div style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;" *ngIf="(selectedPosMenu$ | async)?.id === 'combine' ">
            <orwi-table-combine></orwi-table-combine>
        </div>

        <div style="display: flex;flex-direction: column; justify-content: space-between; height: 100%;" *ngIf="(selectedPosMenu$ | async)?.id === 'transfer' ">
            <product-transfer></product-transfer>
        </div>

        <div *ngIf='(selectedPosMenu$ | async)?.id === "open-folios" '>
            <orwi-open-folios></orwi-open-folios>
        </div>

        <div *ngIf='(selectedPosMenu$ | async)?.id === "self-service" '>
            <orwi-open-folios></orwi-open-folios>
        </div>

        <div *ngIf='(selectedPosMenu$ | async)?.id === "take-away" '>
            <orwi-open-folios></orwi-open-folios>
        </div>

        <div *ngIf='(selectedPosMenu$ | async)?.id === "delivery" '>
            <delivery-list></delivery-list>
        </div>

    </div>
</ion-content>