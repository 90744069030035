<ion-header>
  <ion-toolbar>
    <ion-title>
      {{'Account Add' | transloco}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="save()">
        <ion-icon icon="save"></ion-icon>
      </ion-button>
      <ion-button (click)="close()">
        <ion-icon icon="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="customer-form" [formGroup]="form">
    <!-- <ion-item lines="none" class="ion-no-padding" [ngClass]="{
              'orwi-invalid': GSM.invalid,
              'orwi-touched': GSM.touched || GSM.dirty
            }">
      <ion-label position="stacked">{{ "Phone" | transloco }}</ion-label>
      <orwi-phone-input class="orwi-phone-input" formControlName="gsm">
      </orwi-phone-input>
    </ion-item> -->
    <div>
      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'Current Name' | transloco }} *" class="selector" formControlName="customerName"></ion-input>
        </ion-item>
      </ion-col>

      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'Current No' | transloco }} *" class="selector"
            formControlName="accountCode"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'Tax Administration' | transloco }} *" class="selector"
            formControlName="taxOffice"></ion-input>
        </ion-item>
      </ion-col>

      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'Tax Number' | transloco }} *" class="selector" formControlName="taxNumber"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'phone number' | transloco }} *" class="selector" formControlName="gsm"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'email' | transloco }} *" class="selector" formControlName="mail"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-select toggleIcon="chevron-down-outline" justify="space-between" formControlName="city"
            [label]="'City' | transloco" class="selector" [interfaceOptions]="{ translucent: true}" interface="popover">
            <ion-select-option [value]="item.name" *ngFor="let item of CountryInfo.cities">{{ item.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="6">
        <ion-item lines="none" class="selector-container">
          <ion-select toggleIcon="chevron-down-outline" justify="space-between" formControlName="town"
            [label]="'Town' | transloco" class="selector" [interfaceOptions]="{ translucent: true}" interface="popover">
            <ion-select-option [value]="item.name" *ngFor="let item of CountryInfo.towns">{{ item.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12" sizeMd="12">
        <ion-item lines="none" class="selector-container">
          <ion-input label="{{ 'Address' | transloco }} *" class="selector" formControlName="address"></ion-input>
        </ion-item>
      </ion-col>
    </div>
    <!-- <ion-button (click)="save()" expand="block">
      {{'Save' | transloco}}
    </ion-button> -->
  </div>
</ion-content>