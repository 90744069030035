import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { FolioQuery } from 'src/app/modules/folio/state/folio.query';
import { FolioService } from 'src/app/modules/folio/state/folio.service';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { OpenFoliosQuery } from 'src/app/modules/folio/state/open-folios/open-folios.query';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { SideMenuQuery } from 'src/app/modules/side-menu/state/side-menu.query';
import { FoliRowListComponent } from './components/foli-row-list/foli-row-list.component';
import { FolioRowListItem } from './models/product-transfer.models';
import { FolioRow } from 'src/app/services/dto/orwi-folio';

@Component({
  selector: 'product-transfer',
  templateUrl: './product-transfer.component.html',
  styleUrls: ['./product-transfer.component.scss'],
})
export class ProductTransferComponent implements OnInit {
  rows: FoliRowListComponent;
  activeRows: FolioRow[]
  folioRows: FolioRowListItem[] = [];
  targetFolio: Folio;

  constructor(
    public ofq: OpenFoliosQuery,
    public tq: FolioQuery,
    public mq: SideMenuQuery,
    public folioStore: FolioStore,
    private folioService: FolioService,
    public folioQuery: FolioQuery,
    private modalCtrl: ModalController,
    private glb: GlobalService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    this.activeRows = this.filterRow(this.folioStore.getValue().activeFolio.rows)
  }

  filterRow(row: FolioRow[]) {
    return row.filter(
      (rw) =>     
        !['deleted', 'removed'].includes(rw.recordStatus) &&
        (['product'].includes(rw.rowType))
    );
  }

  close() {
    this.modalCtrl.dismiss();
  }
  onSelectedChange(e: Folio) {
    this.targetFolio = e;
  }
  onSelectedChangeRows(e: FolioRowListItem[]) {
    this.folioRows = e;
  }
  isValid() {
    return (
      this.folioRows?.some((el) => el.slected) &&
      (this.targetFolio != null || this.targetFolio != undefined)
    );
  }
  transfer() {
    if (this.folioRows.some((el) => el.slected && !el.valid)) {
      this.glb.toast(
        this.translocoService.translate('Please check all selected products') +
          '.',
        '',
        'bottom',
        'danger'
      );
      return;
    }
    const activeFolio: Folio = this.folioStore.getValue().activeFolio;
    const selectedItems: FolioRowListItem[] = this.folioRows.filter(
      (el) => el.slected
    );
    if (
      selectedItems.length === this.activeRows.length
      // selectedItems.every((el) => el.qty >= el.folioRow.qty)
    ) {
      this.glb.toast(
        this.translocoService.translate('You cannot move all products') + '.',
        '',
        'bottom',
        'danger'
      );
      return;
    }
      this.folioService.moveProduct(
        this.folioRows,
        activeFolio,
        this.targetFolio
      );
      this.close()
  }
}
