import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FolioStore } from 'src/app/modules/folio/state/folio.store';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { PaymentState, PaymentStore } from './payment.store';

@Injectable({ providedIn: 'root' })
export class PaymentQuery extends Query<PaymentState> {
  visible$ = this.select('visible');
  banknoteVisible$ = this.select('banknoteVisible');
  discountVisible$ = this.select('discountVisible');

  value$ = this.select('value');
  history$ = this.select('history');
  activeHistory$ = this.select((x) =>
    x.history.filter((o) => o.folioId == this.getValue().activeFolioId)
  );

  currencies$ = this.select('currencies');
  selectedCurrency$ = this.select('selectedCurrency');
  folios$ = this.select('folios');
  folios : Folio[] = []
  activeFolioId$ = this.select('activeFolioId');
  activeFolio$ = this.select((__store) =>
    __store.folios.find((__fl) => __fl.id == this.getValue().activeFolioId)
  );

  predefinedDiscounts$ = this.select('predefinedDiscounts');

  constructor(protected store: PaymentStore, private folioStore: FolioStore) {
    super(store);

    this.select("folios").subscribe(o => {
      if (o) this.folios = o
    })
   
  }
  get ActiveFolio(): Folio | null {
    return this.getValue().folios.find(
      (el) => el.id == this.getValue().activeFolioId
    );
  }
  get paid() {
    let folioId = this.store.getValue().activeFolioId;
    return this.store
      .getValue()
      .history.filter((o) => o.folioId == folioId)
      .reduce((sum, item) => sum + item.value, 0);
  }

  get balance() {
    return (this.folioTotal + this.Discont) - this.paid;
  }
  get Total() {
    return this.folioTotal - this.paid - this.Discont;
  }
  get folioTotal() {
    if (this.store.getValue().folios.length == 0) return 0;

    let folioId = this.store.getValue().activeFolioId;
    return this.store
      .getValue()
      .folios.find((fl) => fl.id == folioId)
      .rows.filter((row) => !['deleted', 'removed'].includes(row.recordStatus) && ['product','modifier'].includes(row.rowType) && !row.isGift)
      .reduce((sum, item) => sum + item.qty * (item.unitPrice),0);
  }

  get Discont() {
    if (this.store.getValue().folios.length == 0) return 0;
    let discountTotal = 0
    let folioId = this.store.getValue().activeFolioId;

    this.store.getValue().folios.find((fl) => fl.id == folioId).rows.forEach((x) =>{
      if(x.rowType === 'discount'){
        discountTotal = discountTotal + (x.qty * x.unitPrice)
      }
    })
    return discountTotal
   
  }

  get getDiscount()
  {
    let folioId = this.store.getValue().activeFolioId;
    return  this.store.getValue().folios.find((fl) => fl.id == folioId)
    ?.rows.filter(fr => fr.rowType == 'discount' && fr.recordStatus !== 'deleted').reduce((sum, item) => sum + (item.unitPrice * item.qty), 0) || 0
  }

  get activeHistory() {
    let folioId = this.store.getValue().activeFolioId;
    let hst = this.store.getValue().history.filter((o) => o.folioId == folioId);
    
    return hst;
  }

  get activeSelectedRows() {
    let folioId = this.store.getValue().activeFolioId;
    let rows = this.store
      .getValue()
      .folios.find((o) => o.id == folioId)
      ?.rows?.filter((o) => o.selected);
    return rows;
  }

  get selectedTotal() {
    let folioId = this.store.getValue().activeFolioId;
    let rows = this.store
      .getValue()
      .folios.find((o) => o.id == folioId)
      ?.rows?.filter((o) => o.selected);
    return rows.reduce(
      (sum, item) =>
        sum + item.qty * (item.unitPrice + this.calculateModifs(item.id)),
      0
    );
  }

  calculateModifs(parentId: string) {
    let total = 0;
    let modifiers = this.folioStore.getValue().activeFolio.rows;
    modifiers = modifiers.filter((rw) => rw.parentID == parentId);

    total = modifiers.reduce((acc, curr) => acc + curr.unitPrice, 0);
    return total;
  }

  folioIsDone(folioId) {
    let total = this.store
      .getValue()
      .folios.find((fl) => fl.id == folioId)
      .rows.reduce((sum, item) => sum + item.qty * item.unitPrice, 0);
    let paid = this.store
      .getValue()
      .history.filter((o) => o.folioId == folioId)
      .reduce((sum, item) => sum + item.value, 0);

    return total == paid;
  }
}
