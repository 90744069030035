import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Folio, FolioRow } from '../dto/orwi-folio';
import { OrwiService } from '../orwi/orwi.service';
import { Printer } from '../dto/orwi-definitions';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { TranslocoService } from '@ngneat/transloco';
import { isNumber, toUpper } from 'lodash';
import { ZReport } from '../dto/zReport';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { ParametersQuery } from 'src/app/modules/parameters/state/parameters.query';
import { BrandsStore } from 'src/app/modules/brands/state/brands.store';
import { arrayUpdate } from '@datorama/akita';
import {
  FoodAppStore,
  Notification,
} from 'src/app/modules/food-apps-integration/state/dto';
import { GlobalService } from '../global.service';
import EscPosEncoder from '@manhnd/esc-pos-encoder';
import { BrandsService } from 'src/app/modules/brands/state/brands.service';
import { PublicApiService } from '../public-api/public-api.service';
import { environment } from 'src/environments/environment';
import {
  OrwiPdfItem,
  TOrwiPdf,
} from 'src/app/modules/orwi-pdf/dto/orwi-pdf.model';

export class ESC_POSDefinitions {
  ESC = String.fromCharCode(27);
  FS = String.fromCharCode(28);
  GS = String.fromCharCode(29);
  initialiazePrinter = this.ESC + '@';
  characterTR = this.ESC + 't' + String.fromCharCode(25);
  addLine = String.fromCharCode(10);
  strip = '--------------------------------';
  alignLeft = this.ESC + 'a' + String.fromCharCode(0);
  alignCenter = this.ESC + 'a' + String.fromCharCode(1);
  alignRight = this.ESC + 'a' + String.fromCharCode(2);
  paperPartialCut =
    this.GS + 'V' + String.fromCharCode(66) + String.fromCharCode(3);
  buzzer =
    this.ESC +
    String.fromCharCode(66) +
    String.fromCharCode(3) +
    String.fromCharCode(2);

  //* FONTS
  fontSmall = this.ESC + '!' + String.fromCharCode(10);
  fontNormal = this.ESC + '!' + String.fromCharCode(33);
  FontBig = this.ESC + '!' + String.fromCharCode(52);
  FontBigEm = this.ESC + '!' + String.fromCharCode(52);
  FontBigbiger = this.GS + '!' + String.fromCharCode(52);
}

@Injectable({ providedIn: 'root' })
export class PrinterPrepareService {
  constructor(
    private orwiService: OrwiService,
    public orwiStoreQuery: OrwiStoreQuery,
    private transloco: TranslocoService,
    private orwiStore: OrwiStoreStore,
    private sessionQuery: SessionQuery,
    private parametersQuery: ParametersQuery,
    private brandStore: BrandsStore,
    private publicApi: PublicApiService,
    brandService: BrandsService,
    private glb: GlobalService
  ) {
    brandService; // brands load icin
  }

  maxLength = 32;

  colsNomal = 48;
  colsCondensed = 64;
  colsExpanded = 24;
  twoColPad = 10;
  smallPaper = false;

  async convertFolioV2(folio: Folio) {
    try {
      if (this.smallPaper) {
        this.colsNomal = 42;
        this.colsCondensed = 56;
        this.colsExpanded = 20;
      }
      let encoder = new EscPosEncoder();

      //initialize and lang set
      encoder = encoder.initialize();

      if (folio.brand) {
        let brand = this.brandStore
          .getValue()
          .brands.find((x) => x.id == folio.brand);
        if (brand) {
          if (brand.image) {
            let height = 0;
            let img: any = await new Promise((resolve, reject) => {
              const img = new Image();
              img.crossOrigin = 'Anonymous';
              img.src = brand.image;
              img.onload = () => resolve(img);
              img.onerror = reject;
            });
            height = img.height / (img.width / 320);
            height -= height % 8;
            //TODO: witdh hesaplamasini da otomatik yap
            //let sonuc = height % 8
            //console.log("iamge-w-h", img.width, img.height)
            //console.log("calc-width", height, sonuc)
            encoder = encoder
              .align('center')
              .image(img, 320, height, 'atkinson');
          }
        } else {
          encoder = encoder
            .size(2)
            .align('center')
            .text(this.convertTRK(this.StoreName()))
            .newline();
        }
      } else {
        if (this.brandStore.getValue().brands.length > 0) {
          let brand = this.brandStore
            .getValue()
            .brands.find((x) => x.isDefault);
          if (brand) {
            if (brand.image) {
              let height = 0;
              let img: any = await new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.src = brand.image;
                img.onload = () => resolve(img);
                img.onerror = reject;
              });
              height = img.height / (img.width / 320);
              height -= height % 8;
              //TODO: witdh hesaplamasini da otomatik yap
              //let sonuc = height % 8
              //console.log("iamge-w-h", img.width, img.height)
              //console.log("calc-width", height, sonuc)
              encoder = encoder
                .align('center')
                .image(img, 320, height, 'atkinson');
            }
          }
          else
          {
            encoder = encoder
            .size(2)
            .align('center')
            .text(this.convertTRK(this.StoreName()))
            .newline();
          }
        }
        else {
          encoder = encoder
            .size(2)
            .align('center')
            .text(this.convertTRK(this.StoreName()))
            .newline();
        }
      }
      //timed order info
      if (folio.isTimed) {
        //TODO:translate
        encoder = encoder
          .align('center')
          .bold(true)
          .text(
            '*****' +
            this.convertTRK(
              this.transloco.translate('ILERI TARIHLI SIPARIS') + '*****'
            )
          )
          .newline();
        encoder = encoder
          .align('center')
          .text(
            '*****' +
            this.convertTRK(
              this.transloco.translate('TESLIM ZAMANI') +
              ' : ' +
              moment(folio.scheduler).format('HH:mm') +
              '*****'
            )
          )
          .newline();
        encoder = encoder.bold(false); //reset
      }
      //waiter
      if (!folio.creator.userName) {
        //TODO: bu neden bos gelebiliyor ?
        folio.creator.userName = this.sessionQuery.user.name;
      }
      encoder = encoder
        .size(0)
        .align('left')
        .text(
          this.transloco.translate('Waiter') +
          ' : ' +
          this.convertTRK(folio.creator.userName)
        )
        .newline();
      //service type
      encoder = encoder
        .text(
          this.transloco.translate('service type') +
          ' : ' +
          this.transloco.translate(folio.type)
        )
        .newline();
      //opening time
      // console.log("opening Data",moment(folio.creation).format('DD.MM.YY HH:mm'))
      let openingStr =
        this.convertTRK(this.transloco.translate('Opening')) +
        ' : ' +
        moment(folio.creation).format('DD.MM.YY HH:mm');
      encoder = encoder.text(openingStr);
      //pax count
      let paxStr =
        this.convertTRK(this.transloco.translate('Pax')).substring(0, 4) +
        ' ' +
        folio.pax;
      encoder = encoder
        .text(paxStr.padStart(this.colsNomal - openingStr.length, ' '))
        .newline();
      //table name or user phone
      let tableNameStr =
        this.transloco.translate('Table') + ' : ' + folio.table.name;
      if (folio.type == 'delivery') {
        tableNameStr =
          this.transloco.translate('Phone') + ' : ' + folio.table.id;
      }
      encoder = encoder.bold(true).text(tableNameStr);
      //sequence no
      let sequenceStr =
        this.convertTRK(this.transloco.translate('Check')) +
        ' #' +
        folio.sequenceNo;
      encoder = encoder
        .text(sequenceStr.padStart(this.colsNomal - tableNameStr.length, ' '))
        .newline();
      encoder = encoder.bold(false);
      //spreator
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
      //item captions
      encoder = encoder.text(
        this.convertTRK(this.transloco.translate('Item Name')).padEnd(
          this.colsNomal - 25,
          ' '
        )
      );
      encoder = encoder.text(this.transloco.translate('Qty').padStart(6, ' '));
      encoder = encoder.text('Brm Fyt'.padStart(9, ' ')); //TODO:translate
      encoder = encoder.text('Tutar'.padStart(10, ' ')).newline(); //TODO:translate
      //spreator
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
      //items
      let items = folio.rows.filter(
        (val) =>
          (val.rowType == 'product' ||
            // || val.rowType == 'modifier'
            val.rowType == 'discount') &&
          // || val.rowType == 'note'
          val.recordStatus != 'deleted'
      );
      let allTotal = folio.rows
        .filter((val) => val.recordStatus != 'deleted')
        .filter(val => !val.isGift)
        .filter((val) =>
          ['product', 'modifier', 'discount', 'note'].includes(val.rowType)
        )
        .reduce((pr, acc) => {
          if (acc.rowType === 'modifier') {
            return pr + acc.price;
          }
          return pr + acc.price;
        }, 0);
      for (let index = 0; index < items.length; index++) {
        // let item = items[index];
        // let itemName = this.convertTRK(item.name);
        // if (
        //   item.rowType == 'modifier' ||
        //   item.rowType == 'note' ||
        //   item.isModifier
        // ) {
        //   itemName = this.convertTRK(' *' + item.name);
        //   encoder = encoder.size(-1);
        // } else {
        //   encoder = encoder.size(0);
        // }
        // let itemQty = this.round(item.qty, 3).toString();
        // let itemUnitPrice: string = item.isGift
        //   ? ' 0.00 '
        //   : this.getCurStr(item.unitPrice).padStart(9, ' ');
        // let itemPrice: string = item.isGift
        //   ? this.convertTRK(this.transloco.translate('Gift'))
        //   : this.getCurStr(item.price).padStart(10, ' ');
        // let calcNameList = this.parseString(
        //   itemName,
        //   this.colsNomal -
        //     (itemQty.length + itemUnitPrice.length + itemPrice.length)
        // );
        // let lineData = itemName.padEnd(
        //   this.colsNomal -
        //     (itemQty.length + itemUnitPrice.length + itemPrice.length),
        //   ' '
        // );
        // encoder = encoder.bold(item.rowType == 'discount');
        // if (calcNameList.length > 1) {
        //   let itemNames = this.parseString(itemName, this.colsNomal);
        //   for (let i = 0; i < itemNames.length; i++) {
        //     encoder = encoder.text(itemNames[i]).newline();
        //   }
        //   lineData = ' '.padEnd(
        //     this.colsNomal -
        //       (itemQty.length + itemUnitPrice.length + itemPrice.length),
        //     ' '
        //   );
        // }
        // if (item.rowType == 'discount') {
        //   lineData += ' '.repeat(9 + itemQty.length) + itemPrice;
        // } else {
        //   lineData += itemQty + itemUnitPrice + itemPrice;
        // }
        this.createFolioItem({
          item: items[index],
          encoder,
          rows: folio.rows,
        });
        // encoder = encoder.text(lineData).newline();
      }
      //reset
      encoder = encoder.bold(false);
      //spreator
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
      //TODO:gruplara gore toplamlar eklenecek -> YIYECEK 15.90, ICECEK 17.90
      //base total
      let baseTotal = allTotal;
      encoder = encoder
        .size(2)
        .align('right')
        .text(
          toUpper(this.transloco.translate('Tutar')).padStart(this.twoColPad)
        ) //TODO:trnaslate
        .text(this.getCurStr(baseTotal).padStart(10))
        .newline();
      console.log(
        baseTotal,
        items.filter((v) => !v.isGift)
      );
      //payments
      let paymentItems = folio.rows.filter(
        (val) => val.rowType == 'payment' && val.recordStatus != 'deleted'
      );
      for (let index = 0; index < paymentItems.length; index++) {
        encoder = encoder
          .size(0)
          .align('right')
          .text(
            this.convertTRK(paymentItems[index].name).padStart(this.twoColPad)
          )
          .text(this.getCurStr(paymentItems[index].price).padStart(10))
          .newline();
      }
      encoder = encoder.size(0).bold(false).align('left'); //reset

      //spreator
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

      if (folio.type == 'delivery') {
        //Order Number
        encoder = encoder
          .bold(true)
          .text(
            this.glb.titleCase(folio.appName ?? '') +
            ' Siparis No: ' +
            folio.verifyId
          )
          .newline();
        encoder = encoder.bold(false); //reset
        //Brand Name
        if (folio.brand) {
          let brand = this.brandStore
            .getValue()
            .brands.find((x) => x.id == folio.brand);
          if (brand) {
            encoder = encoder
              .text(
                this.transloco.translate('Brand') +
                ' : ' +
                this.convertTRK(brand.name)
              )
              .newline();
          }
        }
        //Customer Name
        encoder
          .text(
            this.transloco.translate('Musteri') +
            ' : ' +
            this.convertTRK(folio.table.name)
          )
          .newline(); //TODO:translate
        //Delivery Payment Type
        encoder
          .text(
            this.convertTRK(
              this.transloco.translate('Pay Type') +
              ' : ' +
              folio.deliveryPaymentType
            )
          )
          .newline();
        //Address Text
        if (this.parametersQuery.getValue().posParameters.globalParameters.printFolioAddressActive) {
          let adresLines = this.parseString(
            this.transloco.translate('Address') +
            ' : ' +
            this.convertTRK(folio.deliveryAddress),
            this.colsNomal
          );
          for (let index = 0; index < adresLines.length; index++) {
            encoder = encoder.text(adresLines[index]).newline();
          }
        }
      }
      //Customer Note
      if (folio.note) {
        let noteLines = this.parseString(
          this.convertTRK(
            this.transloco.translate('Note') +
            ' : ' +
            folio.note.replace(/\n/g, ' ')
          ),
          this.colsNomal
        );
        for (let index = 0; index < noteLines.length; index++) {
          encoder = encoder.bold(true).text(noteLines[index]).newline();
        }
      }
      //spreator
      if (folio.note || folio.type == 'delivery') {
        encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
      }
      //qr code
      if (this.parametersQuery.getValue().posParameters.globalParameters.printFolioQrActive) {
        let qrData = folio.id + String.fromCharCode(10);
        qrData += folio.sequenceNo + String.fromCharCode(10);
        qrData += folio.storeId + String.fromCharCode(10);
        qrData += folio.type + String.fromCharCode(10);
        qrData +=
          moment(folio.creation).format('DDMMYYYY') + String.fromCharCode(10);
        qrData += folio.brand + String.fromCharCode(10);
        encoder = encoder.align('center').qrcode(qrData, 1, 5, 'm').newline();
      }

      //mali degeri yoktur
      encoder = encoder
        .align('center')
        .size(0)
        .text('* Mali Degeri Yoktur * ')
        .newline();

      //kerzz cloud
      encoder = encoder
        .align('left')
        .size(0)
        .text('KERZZ Cloud v.' + environment.appVersion)

      let printDate: string = moment(new Date()).format('DD.MM.YY HH:mm')
      console.log('PrintFolio', printDate)
      encoder = encoder
        .text(printDate.padStart(this.colsNomal - printDate.length - 7, ' '))
        .newline()

        .newline()
        .newline(); //TODO: gercek version okunacak

      let utf8Encode = new TextEncoder();
      let bytes = utf8Encode.encode(
        String.fromCharCode(27) +
        String.fromCharCode(66) +
        String.fromCharCode(3) +
        String.fromCharCode(2)
      );
      encoder = encoder.raw(Array.from(bytes));
      //close document
      encoder = encoder.cutPartial();
      return encoder.encode();
    } catch (error) {
      await this.publicApi.upsert({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: 'print-folio-log',
        data: {
          createDate: new Date(),
          data: JSON.stringify(error),
          folioId: folio.id,
          requestIp: '',
          methot: 'convertFolioV2',
        },
      });
      return null;
    }
  }

  createFolioItem({
    item,
    encoder,
    rows,
  }: {
    item: FolioRow;
    encoder: any;
    rows: FolioRow[];
  }) {
    let itemName = this.convertTRK(item.name);
    if (
      item.rowType == 'modifier' ||
      item.rowType == 'note' ||
      item.isModifier
    ) {
      itemName = this.convertTRK(' *' + item.name);
      encoder = encoder.size(-1);
    } else {
      encoder = encoder.size(0);
    }
    let itemQty = this.round(item.qty, 3).toString();
    let itemUnitPrice: string = item.isGift
      ? ' 0.00 '
      : this.getCurStr(item.unitPrice).padStart(9, ' ');
    let itemPrice: string = item.isGift
      ? this.convertTRK(this.transloco.translate('Gift'))
      : this.getCurStr(item.price).padStart(10, ' ');
    let calcNameList = this.parseString(
      itemName,
      this.colsNomal -
      (itemQty.length + itemUnitPrice.length + itemPrice.length)
    );
    let lineData = itemName.padEnd(
      this.colsNomal -
      (itemQty.length + itemUnitPrice.length + itemPrice.length),
      ' '
    );
    encoder = encoder.bold(item.rowType == 'discount');
    if (calcNameList.length > 1) {
      let itemNames = this.parseString(itemName, this.colsNomal);
      for (let i = 0; i < itemNames.length; i++) {
        encoder = encoder.text(itemNames[i]).newline();
      }
      lineData = ' '.padEnd(
        this.colsNomal -
        (itemQty.length + itemUnitPrice.length + itemPrice.length),
        ' '
      );
    }
    if (item.rowType == 'discount') {
      lineData += ' '.repeat(9 + itemQty.length) + itemPrice;
    } else {
      lineData += itemQty + itemUnitPrice + itemPrice;
    }
    encoder = encoder.text(lineData).newline();
    rows
      .filter(
        (val) =>
          ['modifier', 'note'].includes(val.rowType) &&
          val.recordStatus != 'deleted' &&
          val.parentID == item.id
      )
      .forEach((modif) => {
        this.createFolioItem({
          item: modif,
          encoder: encoder,
          rows: rows,
        });
      });
    return encoder;
  }

  parseString(val: string, maxWith: number) {
    let valArray = val.split(' ');
    let newVal = new Array<string>();
    let str = '';

    for (let index = 0; index < valArray.length; index++) {
      if (str.length + valArray[index].length > maxWith) {
        newVal.push(str);
        str = valArray[index] + ' ';
      } else {
        str += valArray[index] + ' ';
      }
    }
    if (str) {
      newVal.push(str);
    }
    return newVal;
  }

  StoreName() {
    return this.orwiStore.getValue().store.name;
  }

  printNotifications(notify: Notification) {
    if (this.smallPaper) {
      this.colsNomal = 42;
      this.colsCondensed = 56;
      this.colsExpanded = 20;
    }
    console.log('printNotifications', notify);
    let encoder = new EscPosEncoder();

    //initialize and lang set
    encoder = encoder.initialize();
    encoder = encoder
      .size(1)
      .bold(true)
      .align('center')
      .text(this.convertTRK(notify.source))
      .newline();
    encoder = encoder
      .size(1)
      .bold(true)
      .align('center')
      .text(this.convertTRK(notify.title))
      .newline();
    encoder = encoder.newline();

    //spreator
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

    encoder = encoder
      .size(0)
      .bold(false)
      .align('left')
      .text(this.convertTRK(notify.text))
      .newline();
    encoder = encoder.newline();

    //spreator
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

    if (notify.detail !== '') {
      //spreator
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

      encoder = encoder
        .size(0)
        .bold(false)
        .align('left')
        .text(this.convertTRK(notify.detail))
        .newline();
      encoder = encoder.newline();
    }

    encoder = encoder
      .size(0)
      .bold(false)
      .align('left')
      .text(moment(notify.createDate).format('DD.MM.YY HH:mm'))
      .newline();
    encoder = encoder.newline();

    let utf8Encode = new TextEncoder();
    let bytes = utf8Encode.encode(
      String.fromCharCode(27) +
      String.fromCharCode(66) +
      String.fromCharCode(3) +
      String.fromCharCode(2)
    );
    encoder = encoder.raw(Array.from(bytes));
    //close document
    encoder = encoder.cutPartial();
    return encoder.encode();
  }

  storeStatusChanged(foodAppStore: FoodAppStore, status: string) {
    if (this.smallPaper) {
      this.colsNomal = 42;
      this.colsCondensed = 56;
      this.colsExpanded = 20;
    }
    let encoder = new EscPosEncoder();

    let statusStr = '';
    switch (status) {
      case 'close':
        statusStr = 'Kapali';
        break;
      case 'open':
        statusStr = 'Acik';
        break;
      case 'busy':
        statusStr = 'Yogun';
        break;
      case 'deactive':
        statusStr = 'Deactive';
        break;
      default:
        break;
    }

    //initialize and lang set
    encoder = encoder.initialize();
    encoder
      .align('center')
      .size(2)
      .text(this.convertTRK(this.transloco.translate('Store Status Changed')))
      .newline();
    //spreator
    encoder = encoder.bold(false).size(0); //reset bold
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    //app name
    encoder = encoder
      .align('left')
      .size(0)
      .bold(true)
      .text(this.convertTRK(foodAppStore.source))
      .newline();
    //store name
    encoder = encoder
      .align('left')
      .bold(true)
      .text(this.convertTRK(foodAppStore.name))
      .newline();
    //status
    encoder = encoder
      .align('left')
      .bold(true)
      .text(statusStr + ' Duruma Getirildi.')
      .newline();
    //spreator
    encoder = encoder.bold(false).size(0); //reset bold
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

    //time
    encoder = encoder
      .align('right')
      .bold(true)
      .text(moment().format('DD.MM.YYYY HH:mm'))
      .newline();
    let utf8Encode = new TextEncoder();
    let bytes = utf8Encode.encode(
      String.fromCharCode(27) +
      String.fromCharCode(66) +
      String.fromCharCode(3) +
      String.fromCharCode(2)
    );
    encoder = encoder.raw(Array.from(bytes));
    //close document
    encoder = encoder.cutPartial();
    return encoder.encode();
  }

  convertZReportV2(zReport: ZReport, startDate, endDate, isDetailed) {
    if (this.smallPaper) {
      this.colsNomal = 42;
      this.colsCondensed = 56;
      this.colsExpanded = 20;
    }
    console.log('convertZReportV2', startDate, endDate);
    let encoder = new EscPosEncoder();

    //initialize and lang set
    encoder = encoder.initialize();
    encoder = encoder
      .size(2)
      .align('center')
      .text(this.convertTRK('Z RAPORU'))
      .newline();
    encoder = encoder
      .size(2)
      .align('center')
      .text(this.convertTRK(this.StoreName()))
      .newline();
    encoder = encoder.newline();
    //üst bilgiler
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('Processing Time')) +
        ' : ' +
        moment().format('DD.MM.YY HH:mm')
      )
      .newline();
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('StartDate')) +
        ' : ' +
        moment(startDate).utc(false).format('DD.MM.YY HH:mm').toString()
      )
      .newline();
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('EndDate')) +
        ' : ' +
        moment(endDate).utc(false).format('DD.MM.YY HH:mm').toString()
      )
      .newline();
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('User')) +
        ' : ' +
        this.convertTRK(this.sessionQuery.user.name)
      )
      .newline();

    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    //Servis tiplerine göre satışlar
    encoder = encoder
      .align('center')
      .bold(true)
      .text(
        '** ' +
        this.convertTRK(
          this.transloco.translate('Sales by Service Types') + ' **'
        )
      )
      .newline();
    encoder = encoder.bold(false).text('-'.repeat(this.colsNomal)).newline();
    //başlıklar
    encoder = encoder.text(
      this.convertTRK(this.transloco.translate('service type')).padEnd(
        this.colsNomal - 28,
        ' '
      )
    );
    encoder = encoder.text(this.transloco.translate('Qty').padStart(12, ' '));
    encoder = encoder
      .text(
        this.convertTRK(this.transloco.translate('Total')).padStart(16, ' ')
      )
      .newline();
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    zReport.totalsOfServiceType.forEach((element) => {
      let itemName = this.transloco.translate(element.serviceType);
      let itemQty = this.round(element.totalCount, 3).toString();
      let itemPrice: string = this.getCurStr(element.totalAmount).padStart(
        18,
        ' '
      );
      let calcNameList = this.parseString(
        itemName,
        this.colsNomal - (itemQty.length + itemPrice.length)
      );
      let lineData = itemName.padEnd(
        this.colsNomal - (itemQty.length + itemPrice.length),
        ' '
      );

      if (calcNameList.length > 1) {
        let itemNames = this.parseString(itemName, this.colsNomal);
        for (let i = 0; i < itemNames.length; i++) {
          encoder = encoder.text(itemNames[i]).newline();
        }
        lineData = ' '.padEnd(
          this.colsNomal - (itemQty.length + itemPrice.length),
          ' '
        );
      }
      lineData += itemQty + itemPrice;
      encoder = encoder.text(lineData).newline();
    });
    //Servis tiplerine göre satışlar son

    encoder = encoder.newline();
    encoder = encoder.newline();

    //Gruplara  göre satışlar

    encoder = encoder
      .align('center')
      .bold(true)
      .text(
        '** ' +
        this.convertTRK(
          this.transloco.translate('Sales By Product Groups') + ' **'
        )
      )
      .newline();
    encoder = encoder.bold(false).text('-'.repeat(this.colsNomal)).newline();
    //başlıklar
    encoder = encoder.text(
      this.convertTRK(this.transloco.translate('Group')).padEnd(
        this.colsNomal - 28,
        ' '
      )
    );
    encoder = encoder.text(this.transloco.translate('Qty').padStart(12, ' '));
    encoder = encoder
      .text(
        this.convertTRK(this.transloco.translate('Total')).padStart(16, ' ')
      )
      .newline();
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    zReport.totalsOfItemGroup.forEach((element) => {
      let itemName = this.convertTRK(element.itemName);
      let itemQty = this.round(element.totalCount, 3).toString();
      let itemPrice: string = this.getCurStr(element.totalAmount).padStart(
        18,
        ' '
      );
      let calcNameList = this.parseString(
        itemName,
        this.colsNomal - (itemQty.length + itemPrice.length)
      );
      let lineData = itemName.padEnd(
        this.colsNomal - (itemQty.length + itemPrice.length),
        ' '
      );

      if (calcNameList.length > 1) {
        let itemNames = this.parseString(itemName, this.colsNomal);
        for (let i = 0; i < itemNames.length; i++) {
          encoder = encoder.text(itemNames[i]).newline();
        }
        lineData = ' '.padEnd(
          this.colsNomal - (itemQty.length + itemPrice.length),
          ' '
        );
      }
      lineData += itemQty + itemPrice;
      encoder = encoder.text(lineData).newline();
    });
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

    let baseTotal = zReport.totalsOfItemGroup.reduce(
      (ds, a) => ds + a.totalAmount,
      0
    );

    encoder = encoder
      .size(0)
      .align('left')
      .bold(true)
      .text(toUpper(this.transloco.translate('Total')))
      .text(
        this.getCurStr(baseTotal).padStart(
          this.colsNomal - this.transloco.translate('Total').length
        )
      )
      .newline();
    //Gruplara göre satışlar son

    encoder = encoder.newline();
    encoder = encoder.newline();

    if (isDetailed) {
      //Ürünlere  göre satışlar
      encoder = encoder
        .align('center')
        .bold(true)
        .text(
          '** ' +
          this.convertTRK(
            this.transloco.translate('Sales by Products') + ' **'
          )
        )
        .newline();
      encoder = encoder.bold(false).text('-'.repeat(this.colsNomal)).newline();
      //başlıklar
      encoder = encoder.text(
        this.convertTRK(this.transloco.translate('Item Name')).padEnd(
          this.colsNomal - 28,
          ' '
        )
      );
      encoder = encoder.text(this.transloco.translate('Qty').padStart(12, ' '));
      encoder = encoder
        .text(
          this.convertTRK(this.transloco.translate('Total')).padStart(16, ' ')
        )
        .newline();
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
      zReport.totalsOfItem.forEach((element) => {
        let itemName = this.convertTRK(element.itemName);
        let itemQty = this.round(element.totalCount, 3).toString();
        let itemPrice: string = this.getCurStr(element.totalAmount).padStart(
          18,
          ' '
        );
        let calcNameList = this.parseString(
          itemName,
          this.colsNomal - (itemQty.length + itemPrice.length)
        );
        let lineData = itemName.padEnd(
          this.colsNomal - (itemQty.length + itemPrice.length),
          ' '
        );

        if (calcNameList.length > 1) {
          let itemNames = this.parseString(itemName, this.colsNomal);
          for (let i = 0; i < itemNames.length; i++) {
            encoder = encoder.text(itemNames[i]).newline();
          }
          lineData = ' '.padEnd(
            this.colsNomal - (itemQty.length + itemPrice.length),
            ' '
          );
        }
        lineData += itemQty + itemPrice;
        encoder = encoder.text(lineData).newline();
      });
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

      let itemsTotal = zReport.totalsOfItem.reduce(
        (ds, a) => ds + a.totalAmount,
        0
      );

      encoder = encoder
        .size(0)
        .align('left')
        .bold(false)
        .text(toUpper(this.transloco.translate('Total')))
        .text(
          this.getCurStr(itemsTotal).padStart(
            this.colsNomal - this.transloco.translate('Total').length
          )
        )
        .newline();
      //Ürünlere göre satışlar son

      encoder = encoder.newline();
      encoder = encoder.newline();
    }

    //GENEL BİLGİLER
    encoder = encoder
      .align('center')
      .bold(true)
      .text(
        '** ' +
        this.convertTRK(
          this.transloco.translate('General Information') + ' **'
        )
      )
      .newline();
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

    //toplam satılan ürün sayısı
    encoder = encoder
      .size(0)
      .bold(false)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalCountOfItemsSold')))
      .text(
        zReport.totalStats.totalCountOfItemsSold
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(this.transloco.translate('totalCountOfItemsSold'))
              .length
          )
      )
      .newline();
    //toplam satılan ürün tutarı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalAmountOfItemsSold')))
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfItemsSold).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('totalAmountOfItemsSold'))
            .length
        )
      )
      .newline();

    //iskonto sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalCountOfDiscount')))
      .text(
        zReport.totalStats.totalCountOfDiscount
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(this.transloco.translate('totalCountOfDiscount'))
              .length
          )
      )
      .newline();

    //iskonto toplamı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalAmountOfDiscount')))
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfDiscount).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('totalAmountOfDiscount'))
            .length
        )
      )
      .newline();

    //açılan adisyon sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalCountOfFolio')))
      .text(
        zReport.totalStats.totalCountOfFolio
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(this.transloco.translate('totalCountOfFolio'))
              .length
          )
      )
      .newline();
    //açılan adisyon toplamı

    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalAmountOfFolio')))
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfFolio).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('totalAmountOfFolio'))
            .length
        )
      )
      .newline();

    //İkram ürün sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalCountOfItemsGift')))
      .text(
        zReport.totalStats.totalCountOfItemsGift
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(this.transloco.translate('totalCountOfItemsGift'))
              .length
          )
      )
      .newline();
    //ikram ürün toplamı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalAmountOfItemsGift')))
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfItemsGift).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('totalAmountOfItemsGift'))
            .length
        )
      )
      .newline();
    //iptal ürün sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('totalCountOfItemsCancel'))
      )
      .text(
        zReport.totalStats.totalCountOfItemsCancel
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(
              this.transloco.translate('totalCountOfItemsCancel')
            ).length
          )
      )
      .newline();
    //iptal ürün toplamı
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('totalAmountOfItemsCancel'))
      )
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfItemsCancel).padStart(
          this.colsNomal -
          this.convertTRK(
            this.transloco.translate('totalAmountOfItemsCancel')
          ).length
        )
      )
      .newline();

    // //ayrılan ürün sayısı
    // encoder = encoder.size(0).align("left")
    //   .text(this.convertTRK(this.transloco.translate('totalCountOfItemsMerged')))
    //   .text(zReport.totalStats.totalCountOfItemsMerged.toString().padStart(this.colsNomal - this.convertTRK(this.transloco.translate('totalCountOfItemsMerged')).length)).newline()
    // //ayrılan ürün toplamı
    // encoder = encoder.size(0).align("left")
    //   .text(this.convertTRK(this.transloco.translate('totalAmaountOfItemsMerged')))
    //   .text(this.getCurStr(zReport.totalStats.totalAmaountOfItemsMerged).padStart(this.colsNomal - this.convertTRK(this.transloco.translate('totalAmaountOfItemsMerged')).length)).newline()

    // //Garsoniye toplamı
    // encoder = encoder.size(0).align("left")
    //   .text(this.convertTRK(this.transloco.translate('totalAmountOfServiceFee')))
    //   .text(this.getCurStr(zReport.totalStats.totalAmountOfServiceFee).padStart(this.colsNomal - this.convertTRK(this.transloco.translate('totalAmountOfServiceFee')).length)).newline()

    //Kişi sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalCountOfPax')))
      .text(
        zReport.totalStats.totalCountOfPax
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(this.transloco.translate('totalCountOfPax'))
              .length
          )
      )
      .newline();
    //Kişi sayısı ortalama hasılat
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('avgAmountPerPax')))
      .text(
        this.getCurStr(zReport.totalStats.avgAmountPerPax).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('avgAmountPerPax')).length
        )
      )
      .newline();
    //adisyon başına ortalam hasılar
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('avgAmountPerFolio')))
      .text(
        this.getCurStr(zReport.totalStats.avgAmountPerFolio).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('avgAmountPerFolio'))
            .length
        )
      )
      .newline();

    //Açık adisyın sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalCountOfFolioOpen')))
      .text(
        zReport.totalStats.totalCountOfFolioOpen
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(this.transloco.translate('totalCountOfFolioOpen'))
              .length
          )
      )
      .newline();
    //açık adisyon toplamı
    encoder = encoder
      .size(0)
      .align('left')
      .text(this.convertTRK(this.transloco.translate('totalAmountOfFolioOpen')))
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfFolioOpen).padStart(
          this.colsNomal -
          this.convertTRK(this.transloco.translate('totalAmountOfFolioOpen'))
            .length
        )
      )
      .newline();

    //iptal adisyın sayısı
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('totalCountOfFolioCancel'))
      )
      .text(
        zReport.totalStats.totalCountOfFolioCancel
          .toString()
          .padStart(
            this.colsNomal -
            this.convertTRK(
              this.transloco.translate('totalCountOfFolioCancel')
            ).length
          )
      )
      .newline();
    //iptal adisyon toplamı
    encoder = encoder
      .size(0)
      .align('left')
      .text(
        this.convertTRK(this.transloco.translate('totalAmountOfFolioCancel'))
      )
      .text(
        this.getCurStr(zReport.totalStats.totalAmountOfFolioCancel).padStart(
          this.colsNomal -
          this.convertTRK(
            this.transloco.translate('totalAmountOfFolioCancel')
          ).length
        )
      )
      .newline();

    // //bileştirilen adisyon sayısı
    // encoder = encoder.size(0).align("left")
    //   .text(this.convertTRK(this.transloco.translate('totalCountOfFolioCombine')))
    //   .text(zReport.totalStats.totalCountOfFolioCombine.toString().padStart(this.colsNomal - this.convertTRK(this.transloco.translate('totalCountOfFolioCombine')).length)).newline()
    // //bileştirilen adisyon toplamı
    // encoder = encoder.size(0).align("left")
    //   .text(this.convertTRK(this.transloco.translate('totalAmountOfFolioCombine')))
    //   .text(this.getCurStr(zReport.totalStats.totalAmountOfFolioCombine).padStart(this.colsNomal - this.convertTRK(this.transloco.translate('totalAmountOfFolioCombine')).length)).newline()

    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    encoder = encoder.newline();

    //Genel Bilgiler Son

    //ödeme tiplerine  göre tahsilatlar
    encoder = encoder
      .align('center')
      .bold(true)
      .text(
        '** ' +
        this.convertTRK(this.transloco.translate('salesByPayType') + ' **')
      )
      .newline();
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    //başlıklar
    encoder = encoder
      .bold(false)
      .text(
        this.convertTRK(this.transloco.translate('Pay Type')).padEnd(
          this.colsNomal - 28,
          ' '
        )
      );
    encoder = encoder.text(this.transloco.translate('Qty').padStart(12, ' '));
    encoder = encoder
      .text(
        this.convertTRK(this.transloco.translate('Total')).padStart(16, ' ')
      )
      .newline();
    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
    let changeMoney: number = 0;
    zReport.totalsOfPaymentType.forEach((element) => {
      if (element.itemName !== this.transloco.translate('Change Money')) {
        let itemName = this.convertTRK(element.itemName);
        let itemQty = this.round(element.totalCount, 3).toString();

        // if (element.itemName === 'Nakit' || element.itemName === 'Cash') {
        //   let cashBack: number = zReport.totalsOfPaymentType
        //     .filter((c) => c.itemName === 'Change')
        //     .reduce((sum, item) => sum + item.totalAmount, 0);
        //   element.totalAmount = element.totalAmount - cashBack;
        //   // console.log('Change2',element.totalAmount,cashBack)
        // }

        let itemPrice: string = this.getCurStr(element.totalAmount).padStart(
          18,
          ' '
        );

        let calcNameList = this.parseString(
          itemName,
          this.colsNomal - (itemQty.length + itemPrice.length)
        );
        let lineData = itemName.padEnd(
          this.colsNomal - (itemQty.length + itemPrice.length),
          ' '
        );

        if (calcNameList.length > 1) {
          let itemNames = this.parseString(itemName, this.colsNomal);
          for (let i = 0; i < itemNames.length; i++) {
            encoder = encoder.text(itemNames[i]).newline();
          }
          lineData = ' '.padEnd(
            this.colsNomal - (itemQty.length + itemPrice.length),
            ' '
          );
        }
        lineData += itemQty + itemPrice;
        encoder = encoder.text(lineData).newline();
      } else {
        changeMoney += element.totalAmount;
      }
    });

    encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

    let paymentTotals: number = 0;

    zReport.totalsOfPaymentType.map((x) => {
      if (
        this.orwiStoreQuery.getValue().payTypes.find((c) => c.id === x.itemId)
          .payType !== 'not-paid'
      ) {
        paymentTotals += x.totalAmount;
      }
    });

    // let total: number = paymentTotals - changeMoney;
    // console.log('paymentTotals', paymentTotals, changeMoney, total);

    // this.orwiStoreQuery.getValue().payTypes.filter(x => x.payType !== 'not-paid')

    // let paymentTotals2 = zReport.totalsOfPaymentType
    //   .reduce((ds, a) => ds + a.totalAmount, 0);

    // console.log('ZReport Payments Total : ', paymentTotals2)
    encoder = encoder
      .size(0)
      .bold(false)
      .align('left')
      .bold(true)
      .text(toUpper(this.transloco.translate('Total')))
      .text(
        this.getCurStr(paymentTotals).padStart(
          this.colsNomal - this.transloco.translate('Total').length
        )
      )
      .newline();
    //totalAmountOfFolio göre satışlar son

    encoder = encoder.newline().newline().newline();

    //mali degeri yoktur
    encoder = encoder
      .align('center')
      .size(0)
      .text('* Mali Degeri Yoktur * ')
      .newline()
      .newline();

    //kerzz cloud
    encoder = encoder
      .align('left')
      .size(0)
      .text('KERZZ Cloud v.' + environment.appVersion)
      .newline(); //TODO: gercek version okunacak

    let utf8Encode = new TextEncoder();
    let bytes = utf8Encode.encode(
      String.fromCharCode(27) +
      String.fromCharCode(66) +
      String.fromCharCode(3) +
      String.fromCharCode(2)
    );
    encoder = encoder.raw(Array.from(bytes));
    //end Zreport
    encoder.cutPartial();
    return encoder.encode();
  }

  convertZReport(zreport: ZReport, startDate, endDate) {
    let defs = new ESC_POSDefinitions();
    let str = ' ';
    let data = '';
    data += defs.initialiazePrinter;
    data += defs.characterTR;

    //Header
    data +=
      defs.alignCenter +
      defs.FontBig +
      'Kerzz Cloud ' +
      this.transloco.translate('ZReport') +
      defs.addLine;
    data += defs.addLine;
    data +=
      defs.alignCenter +
      defs.FontBig +
      this.orwiStoreQuery.getValue().store.name +
      defs.addLine;
    data += defs.fontNormal + defs.addLine;
    let splitter = ':';
    let value = 0;
    let desc = '';

    desc = this.transloco.translate('Processing Time');
    data += defs.alignLeft;
    data += desc.substring(0, 12);
    data += str.repeat(12 - desc.substring(0, 12).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(
      16 - moment().format('DD.MM.YY HH:mm').toString().length
    );
    data += moment().format('DD.MM.YY HH:mm');
    data += defs.addLine;
    // let sDate = moment(startDate).startOf("day").utc(true).toISOString()
    // let eDate = moment(endDate).endOf("day").utc(true).toISOString()
    desc = this.transloco.translate('StartDate');
    data += defs.alignLeft;
    data += desc.substring(0, 12);
    data += str.repeat(12 - desc.substring(0, 12).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(
      16 -
      moment(startDate).startOf('day').format('DD.MM.YY HH:mm').toString()
        .length
    );
    data += moment(startDate).startOf('day').format('DD.MM.YY HH:mm');
    data += defs.addLine;

    desc = this.transloco.translate('EndDate');
    data += defs.alignLeft;
    data += desc.substring(0, 12);
    data += str.repeat(12 - desc.substring(0, 12).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(
      16 -
      moment(endDate).endOf('day').format('DD.MM.YY HH:mm').toString().length
    );
    data += moment(endDate).endOf('day').format('DD.MM.YY HH:mm');
    data += defs.addLine;

    desc = this.transloco.translate('User');
    data += defs.alignLeft;
    data += desc.substring(0, 12);
    data += str.repeat(12 - desc.substring(0, 12).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(16 - this.sessionQuery.user.name.length);
    data += this.sessionQuery.user.name;
    data += defs.addLine;

    // data += defs.alignLeft + defs.fontNormal + "İşlem Zamanı " + ': ' + moment().format('DD.MM.YY HH:mm') + defs.addLine
    // data += "Baslangic " + ': ' + moment().format('DD.MM.YY HH:mm') + defs.addLine
    // data += "Bitis " + ': ' + moment().format('DD.MM.YY HH:mm') + defs.addLine
    // data += "Kullanici " + ': ' + this.us.user.name + defs.addLine
    data += defs.strip + defs.addLine;

    //servis Tiplerine göre satışlar başlık
    data +=
      defs.alignCenter +
      defs.fontSmall +
      '** ' +
      this.transloco.translate('Sales by Service Types') +
      ' **' +
      defs.addLine;
    data += defs.fontNormal + defs.strip + defs.addLine;
    data += defs.alignLeft + this.transloco.translate('service type'); //8
    data += str.repeat(10) + this.transloco.translate('Qty'); //4
    data += str.repeat(3) + this.transloco.translate('Total') + defs.addLine; //5
    data += defs.strip + defs.addLine;
    //sercis Tiplerine göre satışlar detay
    let qty = this.round(3, 3);
    let price = '';
    let itemName = '';
    zreport.totalsOfServiceType.forEach((element) => {
      itemName = this.transloco.translate(element.serviceType);
      qty = element.totalCount;
      price = this.getCurStr(element.totalAmount);
      data += defs.alignLeft;
      data += itemName.substring(0, 19);
      data += str.repeat(19 - itemName.substring(0, 19).length);
      data += str.repeat(3 - qty.toString().length);
      data += qty;
      data += str.repeat(9 - price.length);
      data += price;
      data += defs.addLine;
    });

    //servis Tiplerine göre satışlar KÜMÜLE

    data += this.transloco.translate('Total');
    data += str.repeat(
      this.maxLength -
      (7 +
        this.getCurStr(
          zreport.totalsOfServiceType.reduce(
            (sum, item) => sum + item.totalAmount,
            0
          )
        ).length)
    );
    data += this.getCurStr(
      zreport.totalsOfServiceType.reduce(
        (sum, item) => sum + item.totalAmount,
        0
      )
    );
    data += defs.addLine;
    data += defs.addLine;
    data += defs.addLine;
    //servis Tiplerine göre satışlar KÜMÜLE

    //Ürün Gruplarına Göre Satışlar
    data +=
      defs.alignCenter +
      defs.fontSmall +
      '** ' +
      this.transloco.translate('Sales By Product Groups') +
      ' **' +
      defs.addLine;
    data += defs.fontNormal + defs.strip + defs.addLine;
    data +=
      defs.alignLeft + defs.fontNormal + this.transloco.translate('Group'); //8
    data += str.repeat(15) + this.transloco.translate('Qty'); //4
    data += str.repeat(3) + this.transloco.translate('Total') + defs.addLine; //5
    data += defs.strip + defs.addLine;

    zreport.totalsOfItemGroup.forEach((element) => {
      price = this.getCurStr(element.totalAmount);
      qty = element.totalCount;
      data += defs.alignLeft;
      data += element.itemName.substring(0, 19);
      data += str.repeat(19 - element.itemName.substring(0, 19).length);
      data += str.repeat(3 - qty.toString().length);
      data += qty;
      data += str.repeat(9 - price.length);
      data += price;
      data += defs.addLine;
    });
    data += defs.strip + defs.addLine;

    //GRUBLARA GORE SATIŞLAR KÜMÜLE

    data += this.transloco.translate('Total');
    data += str.repeat(
      this.maxLength -
      (7 +
        this.getCurStr(
          zreport.totalsOfItemGroup.reduce(
            (sum, item) => sum + item.totalAmount,
            0
          )
        ).length)
    );
    data += this.getCurStr(
      zreport.totalsOfItemGroup.reduce((sum, item) => sum + item.totalAmount, 0)
    );
    data += defs.addLine;
    data += defs.addLine;
    data += defs.addLine;

    //Ürünlere Göre Satışlar
    data +=
      defs.alignCenter +
      defs.fontSmall +
      '** ' +
      this.transloco.translate('Sales by Products') +
      ' **' +
      defs.addLine;
    data += defs.fontNormal + defs.strip + defs.addLine;
    data +=
      defs.alignLeft + defs.fontNormal + this.transloco.translate('Item Name'); //8
    data += str.repeat(11) + this.transloco.translate('Qty'); //4
    data += str.repeat(3) + this.transloco.translate('Total') + defs.addLine; //5
    data += defs.strip + defs.addLine;

    zreport.totalsOfItem.forEach((element) => {
      price = this.getCurStr(element.totalAmount);
      qty = element.totalCount;
      data += defs.alignLeft;
      data += element.itemName.substring(0, 19);
      data += str.repeat(19 - element.itemName.substring(0, 19).length);
      data += str.repeat(3 - qty.toString().length);
      data += qty;
      data += str.repeat(9 - price.length);
      data += price;
      data += defs.addLine;
    });
    data += defs.strip + defs.addLine;

    //Ürümlere GORE SATIŞLAR KÜMÜLE

    data += this.transloco.translate('Total');
    data += str.repeat(
      this.maxLength -
      (7 +
        this.getCurStr(
          zreport.totalsOfItem.reduce(
            (sum, item) => sum + item.totalAmount,
            0
          )
        ).length)
    );
    data += this.getCurStr(
      zreport.totalsOfItem.reduce((sum, item) => sum + item.totalAmount, 0)
    );
    data += defs.addLine;
    data += defs.addLine;
    data += defs.addLine;

    //GENEL BİLGİLER

    data +=
      defs.alignCenter +
      defs.fontSmall +
      '** ' +
      this.transloco.translate('General Information') +
      ' **' +
      defs.addLine;
    data += defs.fontNormal + defs.strip + defs.addLine;

    desc = this.transloco.translate('totalCountOfItemsSold');
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(
      8 - zreport.totalStats.totalCountOfItemsSold.toString().length
    );
    data += zreport.totalStats.totalCountOfItemsSold;
    data += defs.addLine;

    value = this.round(zreport.totalStats.totalAmountOfItemsSold, 3);
    desc = this.transloco.translate('totalAmountOfItemsSold');
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfFolio');
    value = zreport.totalStats.totalCountOfFolio;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfFolio');
    value = this.round(zreport.totalStats.totalAmountOfFolio, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfItemsGift');
    value = zreport.totalStats.totalCountOfItemsGift;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfItemsGift');
    value = this.round(zreport.totalStats.totalAmountOfItemsGift, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfItemsCancel');
    value = zreport.totalStats.totalCountOfItemsCancel;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfItemsCancel');
    value = this.round(zreport.totalStats.totalAmountOfItemsCancel, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfItemsMerged');
    value = zreport.totalStats.totalCountOfItemsMerged;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmaountOfItemsMerged');
    value = this.round(zreport.totalStats.totalAmaountOfItemsMerged, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfDiscount');
    value = zreport.totalStats.totalCountOfDiscount;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfDiscount');
    value = this.round(zreport.totalStats.totalAmountOfDiscount, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfServiceFee');
    value = this.round(zreport.totalStats.totalAmountOfServiceFee, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfPax');
    value = zreport.totalStats.totalCountOfPax;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('avgAmountPerPax');
    value = this.round(zreport.totalStats.avgAmountPerPax, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('avgAmountPerFolio');
    value = this.round(zreport.totalStats.avgAmountPerFolio, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfFolioOpen');
    value = zreport.totalStats.totalCountOfFolioOpen;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfFolioOpen');
    value = this.round(zreport.totalStats.totalAmountOfFolioOpen, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfFolioCancel');
    value = zreport.totalStats.totalCountOfFolioCancel;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfFolioCancel');
    value = this.round(zreport.totalStats.totalAmountOfFolioCancel, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;

    desc = this.transloco.translate('totalCountOfFolioCombine');
    value = zreport.totalStats.totalCountOfFolioCombine;
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += value;
    data += defs.addLine;

    desc = this.transloco.translate('totalAmountOfFolioCombine');
    value = this.round(zreport.totalStats.totalAmountOfFolioCombine, 3);
    data += defs.alignLeft;
    data += desc.substring(0, 19);
    data += str.repeat(19 - desc.substring(0, 19).length);
    data += str.repeat(2 - splitter.toString().length);
    data += splitter;
    data += str.repeat(8 - value.toString().length);
    data += this.getCurStr(value);
    data += defs.addLine;
    data += defs.strip + defs.addLine;

    //Ödeme Tiplerine Göre Tahsilatlar
    data +=
      defs.alignCenter +
      defs.fontSmall +
      '** ' +
      this.transloco.translate('salesByPayType') +
      ' **' +
      defs.addLine;
    data += defs.fontNormal + defs.strip + defs.addLine;
    data +=
      defs.alignLeft + defs.fontNormal + this.transloco.translate('Pay Type'); //8
    data += str.repeat(9) + this.transloco.translate('Qty'); //10
    data += str.repeat(3) + this.transloco.translate('Total') + defs.addLine; //3
    data += defs.strip + defs.addLine;
    let change: number = 0;
    zreport.totalsOfPaymentType.forEach((element) => {
      if (element.itemName !== 'Change') {
        desc = this.transloco.translate(element.itemName);
        value = this.round(element.totalAmount, 3);
        data += defs.alignLeft;
        qty = element.totalCount;
        data += desc.substring(0, 16);
        data += str.repeat(16 - desc.substring(0, 16).length);
        data += str.repeat(5 - qty.toString().length);
        data += qty;
        data += str.repeat(8 - value.toString().length);
        data += this.getCurStr(value);
        data += defs.addLine;
      } else {
        change += element.totalAmount;
      }
    });

    data += defs.strip + defs.addLine;
    data += this.transloco.translate('Total');
    let totalAmount: number = zreport.totalsOfPaymentType.reduce(
      (sum, item) => sum + item.totalAmount,
      0
    );
    totalAmount = totalAmount - change;
    data += str.repeat(
      this.maxLength - (7 + this.getCurStr(totalAmount).length)
    );

    data += this.getCurStr(totalAmount);
    data += defs.addLine;
    data += defs.addLine;

    data += defs.paperPartialCut;

    data += defs.buzzer;

    return this.convertTRK(data);
  }

  async createTemplate({ content }: { content: TOrwiPdf }) {
    // let defs = new ESC_POSDefinitions();
    // let str = ' ';
    // let data = '';
    // data += defs.initialiazePrinter;
    // data += defs.characterTR;

    // //Header

    // let splitter = ':';

    // let desc = '';

    // desc = this.transloco.translate('User');
    // data += defs.alignLeft;
    // data += desc.substring(0, 12);
    // data += str.repeat(12 - desc.substring(0, 12).length);
    // data += str.repeat(2 - splitter.toString().length);
    // data += splitter;
    // data += str.repeat(16 - this.sessionQuery.user.name.length);
    // data += this.sessionQuery.user.name;
    // data += defs.addLine;

    if (this.smallPaper) {
      this.colsNomal = 42;
      this.colsCondensed = 56;
      this.colsExpanded = 20;
    }
    let encoder = new EscPosEncoder();

    encoder = encoder.initialize();

    for (const item of content.items) {
      switch (item.type) {
        case 'title':
          encoder = this.createTitle(encoder, item);
          break;
        case 'row':
          encoder = this.createRow(encoder, item);
          break;
        case 'table':
          encoder = this.createTable(encoder, item);
          break;
        case 'extra':
          encoder = this.createExtra(encoder, item);
          break;
        default:
          break;
      }
    }

    encoder = encoder.emptyLine(3);
    let utf8Encode = new TextEncoder();
    let bytes = utf8Encode.encode(
      String.fromCharCode(27) +
      String.fromCharCode(66) +
      String.fromCharCode(3) +
      String.fromCharCode(2)
    );
    encoder = encoder.raw(Array.from(bytes));
    encoder = encoder.cutPartial();
    return encoder.encode();
  }

  createTable(encoder: EscPosEncoder, item: OrwiPdfItem) {
    if (item.type == 'table') {
      encoder = encoder.bold(false);
      //spreator
      encoder = encoder
        .bold(true)
        .text('-'.repeat(this.colsNomal))
        .newline()
        .bold(false);
      //item captions
      let colCountHead = {};
      for (const [index, header] of item.headers.entries()) {
        if (index == 0) {
          let slicedTitle = this.convertTRK(
            this.transloco.translate(header.caption)
          ).slice(0, this.colsNomal - 31);
          encoder = encoder.text(slicedTitle.padEnd(this.colsNomal - 30, ' '));
          colCountHead = {
            ...colCountHead,
            [index]: this.convertTRK(
              this.transloco.translate(header.caption)
            ).padEnd(this.colsNomal - 30, ' ').length,
          };
        } else {
          let firstCell =
            this.colsNomal -
            this.transloco
              .translate(item.headers[0].caption)
              .padEnd(this.colsNomal - 30, ' ').length;
          let padCountPerHeader =
            Math.floor(firstCell / (item.headers.length - 1)) - 1;
          let padCount = padCountPerHeader;
          let slicedTitle = this.transloco
            .translate(header.caption)
            .slice(0, padCountPerHeader);
          encoder = encoder.text(' ' + slicedTitle.padEnd(padCount, ' '));
          colCountHead = {
            ...colCountHead,
            [index]: ' ' + slicedTitle.padEnd(padCount, ' ').length,
          };
          console.log({ firstCell }, this.colsNomal, colCountHead, padCount);
        }
      }
      encoder = encoder.newline();

      encoder = encoder
        .bold(true)
        .text('-'.repeat(this.colsNomal))
        .newline()
        .bold(false);

      for (const row of item.items) {
        for (const [index, header] of item.headers.entries()) {
          if (index == 0) {
            let slicedTitle = this.convertTRK(row[header.datafield]).slice(
              0,
              this.colsNomal - 31
            );
            encoder = encoder.text(
              slicedTitle.padEnd(this.colsNomal - 30, ' ')
            );
          } else {
            if (typeof row[header.datafield] == 'number') {
              encoder = encoder.text(
                ' ' +
                String(this.round(row[header.datafield], 3)).padEnd(
                  colCountHead[index],
                  ' '
                )
              );
            } else {
              let slicedTitle = this.convertTRK(row[header.datafield]).slice(
                0,
                colCountHead[index]
              );
              encoder = encoder.text(
                ' ' + slicedTitle.padEnd(colCountHead[index], ' ')
              );
            }
          }
        }
      }
      encoder = encoder.newline();

      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

      return encoder;
    }

    return encoder;
  }

  createExtra(encoder: EscPosEncoder, item: OrwiPdfItem) {
    if (item.type == 'extra') {
      return encoder
        .newline()
        .bold(true)
        .underline(true)
        .align('left')
        .text(this.convertTRK(item.label))
        .newline()
        .underline(false)
        .bold(false)
        .align('left')
        .text(String(this.convertTRK(item.text)));
    }

    return encoder;
  }

  createRow(encoder: EscPosEncoder, item: OrwiPdfItem) {
    if (item.type == 'row') {
      return (encoder = encoder
        .size(0)
        .align('left')
        .text(this.convertTRK(item.label) + ' : ' + this.convertTRK(item.text))
        .newline());
    }

    return encoder;
  }

  createTitle(encoder: EscPosEncoder, item: OrwiPdfItem) {
    if (item.type == 'title') {
      return encoder
        .bold(true)
        .size(1)
        .align('center')
        .text(this.convertTRK(item.text))
        .newline()
        .emptyLine(2)
        .bold(false);
      //   let defs = new ESC_POSDefinitions();
      //   let data = defs.alignCenter +
      //   defs.FontBig +
      //   'ORWI POS ' +
      //   defs.addLine;
      // data += defs.addLine;
      // data +=
      //   defs.alignCenter +
      //   defs.FontBig +
      //   item.text +
      //   defs.addLine;
      // data += defs.fontNormal + defs.addLine
      //   return data;
    }

    return encoder;
  }

  async convertUnitV2(folio: Folio, printer: Printer) {
    try {
      if (this.smallPaper) {
        this.colsNomal = 42;
        this.colsCondensed = 56;
        this.colsExpanded = 20;
      }
      let encoder = new EscPosEncoder();

      //initialize and lang set
      encoder = encoder.initialize();

      if (folio.brand) {
        let brand = this.brandStore
          .getValue()
          .brands.find((x) => x.id == folio.brand);
        if (brand) {
          if (brand.image) {
            let height = 0;
            let img: any = await new Promise((resolve, reject) => {
              const img = new Image();
              img.crossOrigin = 'Anonymous';
              img.src = brand.image;
              img.onload = () => resolve(img);
              img.onerror = reject;
            });
            height = img.height / (img.width / 320);
            height -= height % 8;
            //TODO: witdh hesaplamasini da otomatik yap
            //let sonuc = height % 8
            //console.log("iamge-w-h", img.width, img.height)
            //console.log("calc-width", height, sonuc)
            encoder = encoder
              .align('center')
              .image(img, 320, height, 'atkinson');
          }
        } else {
          encoder = encoder
            .size(2)
            .align('center')
            .text(this.convertTRK(this.StoreName()))
            .newline();
        }
      } else {
        //caption
        encoder = encoder
          .size(2)
          .align('center')
          .text(this.convertTRK(this.StoreName()))
          .newline();
      }

      //timed order info
      if (folio.isTimed) {
        //TODO:translate
        encoder = encoder
          .align('center')
          .bold(true)
          .size(0)
          .text(
            '*****' +
            this.convertTRK(
              this.transloco.translate('ILERI TARIHLI SIPARIS') + '*****'
            )
          )
          .newline();
        encoder = encoder
          .align('center')
          .text(
            '*****' +
            this.convertTRK(
              this.transloco.translate('TESLIM ZAMANI') +
              ' : ' +
              moment(folio.scheduler).format('HH:mm') +
              '*****'
            )
          )
          .newline();
        encoder = encoder.bold(false); //reset
      }
      //printer name and ip
      let parsedIP = printer.ip.split('.');
      let shortIp = '';
      if (parsedIP.length == 4) {
        shortIp = ' / ' + parsedIP[2] + '.' + parsedIP[3];
      }
      encoder = encoder
        .size(0)
        .align('center')
        .bold(true)
        .text(printer.name + shortIp)
        .newline();

      //waiter
      let userName = folio.rows.find((x) => x.recordStatus === 'new')?.creator
        ?.userName;
      if (!userName) {
        //TODO: bu neden bos gelebiliyor ?
        userName = this.sessionQuery.user.name;
      }
      //waiter
      encoder = encoder
        .align('left')
        .bold(true)
        .text(this.convertTRK(userName));
      //service type
      let folioType: string = folio.type;
      if (folioType == 'table') {
        folioType = 'table service';
      }
      let serviceType = this.transloco.translate(folioType);
      encoder = encoder
        .text(
          serviceType.padStart(
            this.colsNomal - this.convertTRK(userName).length,
            ' '
          )
        )
        .newline();
      //spreator
      encoder = encoder.bold(false); //reset bold
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();
      //table/phone
      if (folio.type == 'delivery') {
        encoder = encoder
          .size(2)
          .align('center')
          .text(folio.table.id)
          .newline();
      } else {
        encoder = encoder
          .size(2)
          .align('center')
          .text(this.convertTRK(folio.table.name))
          .newline();
      }
      //sequence no
      let sequenceStr = this.convertTRK(
        this.transloco.translate('Check') + ' #' + folio.sequenceNo
      );
      encoder = encoder.align('left').text(sequenceStr);
      //pax count
      let paxStr =
        this.convertTRK(this.transloco.translate('Pax')).substring(0, 4) +
        ' ' +
        folio.pax;
      encoder = encoder
        .text(paxStr.padStart(this.colsExpanded - sequenceStr.length, ' '))
        .newline();
      //spreator
      encoder = encoder.bold(false).size(0); //reset bold
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

      //items
      folio.rows.forEach((row) => {
        let parent = folio.rows.find((rw) => rw.id == row.parentID);
        if (parent) {
          row.printed = parent.printed;
        }
      });
      let items = folio.rows.filter(
        (val) =>
          val.rowType == 'product' &&
          val.printer == printer.name &&
          !val.printed &&
         ( val.recordStatus != 'old' || val.printed === false)
      );
      console.log('items', items);
      if (items.length == 0) {
        return undefined;
      }
      for (let index = 0; index < items.length; index++) {
        encoder = this.createUnitItem({
          item: items[index],
          encoder,
          rows: folio.rows,
          printer,
        });
        // item.printed = true
      }
      //spreator
      encoder = encoder.bold(false).size(0); //reset bold
      encoder = encoder.text('-'.repeat(this.colsNomal)).newline();

      //customer note
      if (folio.note) {
        let noteLines = this.parseString(
          this.convertTRK(
            this.transloco.translate('Note') +
            ' : ' +
            folio.note.replace(/\n/g, ' ')
          ),
          this.colsNomal
        );
        for (let index = 0; index < noteLines.length; index++) {
          encoder = encoder
            .align('left')
            .bold(true)
            .text(noteLines[index])
            .newline();
        }
      }
      if (folio.type == 'delivery') {
        //Customer Name
        encoder
          .align('left')
          .bold(true)
          .text(
            this.transloco.translate('Musteri') +
            ' : ' +
            this.convertTRK(folio.table.name)
          )
          .newline(); //TODO:translate
      }

      //time
      encoder = encoder
        .align('right')
        .bold(true)
        .text(moment().format('DD.MM.YYYY HH:mm'))
        .newline();

      //close document
      encoder = encoder.cutPartial();
      let utf8Encode = new TextEncoder();
      let bytes = utf8Encode.encode(
        String.fromCharCode(27) +
        String.fromCharCode(66) +
        String.fromCharCode(3) +
        String.fromCharCode(2)
      );
      encoder = encoder.raw(Array.from(bytes));
      return encoder.encode();
    } catch (error) {
      await this.publicApi.upsert({
        _db: this.sessionQuery.activeLicense.orwiStore.cloudId,
        col: 'print-folio-log',
        data: {
          createDate: new Date(),
          data: JSON.stringify(error),
          folioId: folio.id,
          requestIp: '',
          methot: 'ConvertUnitV2',
        },
      });
      return null;
    }
  }

  createUnitItem({ encoder, item, rows, printer }) {
    let isDeleted =
      item.recordStatus == 'deleted' || item.recordStatus == 'removed';
    let qty = this.round(isDeleted ? item.qty * -1 : item.qty, 3);
    let itemText = this.convertTRK(item.name);
    if (item.parentID) {
      if (qty === 1 || qty === 0) {
        itemText = '  #' + itemText;
      } else {
        itemText = '  # ' + qty + ' ' + itemText;
      }
      encoder = encoder.align('left').size(1).text(itemText).newline();
    } else {
      itemText = qty + ' ' + itemText;
      encoder = encoder.align('left').size(2).text(itemText).newline();
    }
    if (isDeleted) {
      encoder = encoder
        .align('center')
        .text('=== ' + this.convertTRK(item.deleters[0]?.reason) + ' ===')
        .newline();
    }

    rows
      .filter(
        (val) =>
          ['modifier', 'note'].includes(val.rowType) &&
          val.printer == printer.name &&
          !val.printed &&
          val.recordStatus != 'old' &&
          val.parentID == item.id
      )
      .forEach((modif) => {
        encoder = this.createUnitItem({
          item: modif,
          encoder: encoder,
          rows: rows,
          printer,
        });
      });

    return encoder;
  }

  round(value: number, decimals: number) {
    try {
      let rnd: any = value + 'e' + decimals;
      return Number(Math.round(rnd) + 'e-' + decimals);
    } catch (err) {
      throw err;
    }
  }

  getCurStr(value: number | string) {
    if (isNumber(value)) {
      let returnVal = value.toLocaleString(
        undefined, // leave undefined to use the browser"s locale,
        // or use a string like "en-US" to override it.
        { minimumFractionDigits: 2 }
      );
      return returnVal;
    }

    return '';
  }

  convertTRK(data: string | number) {
    if (data && typeof data == 'number') {
      return data + '';
    }
    if (data && typeof data == 'string') {
      data = data.replace(/İ/g, 'I');
      data = data.replace(/ı/g, 'i');
      data = data.replace(/Ü/g, 'U');
      data = data.replace(/ü/g, 'u');
      data = data.replace(/ş/g, 's');
      data = data.replace(/Ş/g, 'S');
      data = data.replace(/Ö/g, 'O');
      data = data.replace(/ö/g, 'o');
      data = data.replace(/ğ/g, 'g');
      data = data.replace(/Ğ/g, 'G');
      data = data.replace(/ç/g, 'c');
      data = data.replace(/Ç/g, 'C');
      return data;
    } else {
      return '';
    }
  }

  str2arrayBuffer(str: string): ArrayBuffer {
    var buf = new ArrayBuffer(str.length);
    var bufView = new Uint8Array(buf);
    for (var i = 0; i < str.length; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  getPrinters(): Promise<Printer[]> {
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/printer/getPrinters',
          { id: this.sessionQuery.activeLicense.orwiStore.id },
          this.sessionQuery.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              console.log('printers', o.response);
              this.orwiStore.update({ printers: o.response });
              return resolve(o.response);
            }
            return reject(o.response);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  savePrinter(printer: Printer) {
    let storeId = localStorage.getItem('activeStore');
    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/printer/savePrinter',
          {
            ...printer,
            storeId: storeId,
          },
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            console.log(o.response);
            this.orwiStore.update(({ printers }) => ({
              printers: arrayUpdate(printers, o.response.id, o.response),
            }));
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        })
        .finally(() => {
          this.getPrinters();
        });
    });
  }

  deletePrinter(printer: Printer): Promise<Printer> {
    let storeId = localStorage.getItem('activeStore');

    return new Promise((resolve, reject) => {
      return this.orwiService
        .serviceRequestPromise(
          '/api/pos/printer/deletePrinter',
          {
            id: printer.id,
            storeId: storeId,
          },
          this.sessionQuery.token
        )
        .then((o: any) => {
          if (o.response) {
            return resolve(o.response);
          }

          return reject(o?.error?.code);
        })
        .finally(() => {
          this.getPrinters();
        });
    });
  }
}
