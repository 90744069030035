<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-progress-bar
      type="indeterminate"
      [ngStyle]="{ display: progress === true ? '' : 'none' }"
    ></ion-progress-bar>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>

    <ion-buttons slot="end">
      <ion-button (click)="selectAccount()">
        <ion-icon slot="icon-only" name="copy-outline"></ion-icon>
        {{ "Copy" | transloco }}
      </ion-button>
      <ion-button (click)="save()">
        <ion-icon slot="icon-only" name="save-outline"></ion-icon>
        {{ "Save" | transloco }}
      </ion-button>
      <ion-button (click)="exportexcel()">
        <ion-icon slot="icon-only" name="download-outline"></ion-icon>
        {{ "Export" | transloco }}
      </ion-button>
      <ion-button (click)="syncBestMatch()">
        <ion-icon slot="icon-only" name="sync-outline"></ion-icon>
        {{ "Sync" | transloco }}
      </ion-button>
    </ion-buttons>

    <ion-title>
      {{ this.integrationQuery.activeAccount.description }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item
      style="color: #171e4e"
      *ngIf="(integrationQuery.foodAppCategorie$ | async).length === 0"
    >
      {{ "Category list is empty" | transloco }}
    </ion-item>

    <ion-item-group
      *ngFor="let item of integrationQuery.foodAppCategorie$ | async"
    >
      <ion-item-divider>
        <ion-label>
          {{ item.name }}
        </ion-label>
      </ion-item-divider>

      <ion-item-sliding *ngFor="let pitem of getProducts(item.id)" #slidingCase>
        <ion-item (click)="syncSelect(pitem)">
          <div style="width: 100%; font-size: 14px">
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                color: #171e4e;
              "
            >
              <div>{{ pitem.name }}</div>
              <div>{{ pitem.price | currency: "₺" }}</div>
            </div>
            <div *ngIf="getName(pitem.internalId) !== ''">
              <div
                style="padding-left: 20px; margin-top: 10px; margin-bottom: 5px"
              >
                <ion-icon
                  color="secondary"
                  *ngIf="pitem.changed === true"
                  name="ellipse"
                ></ion-icon>
                <ion-icon
                  *ngIf="pitem.auto === false || pitem.auto === undefined"
                  color="success"
                  name="return-down-forward-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="pitem.auto"
                  color="tertiary"
                  name="git-merge-outline"
                ></ion-icon>
                {{ getName(pitem.internalId) }}
              </div>
            </div>
          </div>
        </ion-item>
        <ion-item-options>
          <ion-item-option
            (click)="deleteMatch(slidingCase, pitem)"
            color="primary"
            >{{ "Delete Match" | transloco }}</ion-item-option
          >
          <ion-item-option
            (click)="deleteRow(slidingCase, pitem)"
            color="danger"
            >{{ "Delete Row" | transloco }}</ion-item-option
          >
        </ion-item-options>
      </ion-item-sliding>
    </ion-item-group>

    <ion-item-group>
      <ion-item-divider>
        <ion-label style="color: #184eae">
          {{ "Ungrouped" | transloco }}
        </ion-label>
      </ion-item-divider>

      <ion-item-sliding *ngFor="let pitem of getProducts('')" #slidingCase>
        <ion-item (click)="syncSelect(pitem)">
          <div style="width: 100%; font-size: 14px">
            <div
              style="
                display: flex;
                justify-content: space-between;
                width: 100%;
                color: #171e4e;
              "
            >
              <div>{{ pitem.name }}</div>
              <div>{{ pitem.price | currency: "₺" }}</div>
            </div>
            <div *ngIf="getName(pitem.internalId) !== ''">
              <div
                style="padding-left: 20px; margin-top: 10px; margin-bottom: 5px"
              >
                <ion-icon
                  color="secondary"
                  *ngIf="pitem.changed === true"
                  name="ellipse"
                ></ion-icon>
                <ion-icon
                  *ngIf="pitem.auto === false || pitem.auto === undefined"
                  color="success"
                  name="return-down-forward-outline"
                ></ion-icon>
                <ion-icon
                  *ngIf="pitem.auto"
                  color="tertiary"
                  name="git-merge-outline"
                ></ion-icon>
                {{ getName(pitem.internalId) }}
              </div>
            </div>
          </div>
        </ion-item>
        <ion-item-options>
          <ion-item-option
            (click)="deleteMatch(slidingCase, pitem)"
            color="primary"
            >{{ "Delete Match" | transloco }}</ion-item-option
          >
          <ion-item-option
            (click)="deleteRow(slidingCase, pitem)"
            color="danger"
            >{{ "Delete Row" | transloco }}</ion-item-option
          >
        </ion-item-options>
      </ion-item-sliding>
    </ion-item-group>
  </ion-list>
</ion-content>
