import {
  AfterViewInit,
  Component,
  HostListener,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CapacitorPresentation } from 'presentation-capacitor';
import { BrandsQuery } from 'src/app/modules/brands/state/brands.query';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { GlobalService } from 'src/app/services/global.service';
import { FolioQuery } from '../../state/folio.query';
import { FolioStore } from '../../state/folio.store';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, AfterViewInit {
  brandQuery = inject(BrandsQuery);
  folioQuery = inject(FolioQuery);
  folioStore = inject(FolioStore);
  globalService = inject(GlobalService);
  activeFolio = new Folio();
  @ViewChild('datagrid', { static: true }) datagrid: DxDataGridComponent;

  productRows = [];
  paymentRows = [];

  totalTRY = this.folioQuery.total$;

  constructor() {
    CapacitorPresentation.addListener('onMessage', (data: any) => {
      this.globalService.toast('', JSON.stringify(data), 'middle', 'success');
    });
  }

  ngOnInit() {
    (window as any).receiveFromPresentationCapacitor = (event) => {
      console.log('event:::::::', JSON.stringify(event));
      this.formatFolio(event);
    };
  }

  ngAfterViewInit() {
    // your code here
  }

  calculateMaxHeight(divFooter: HTMLDivElement, tableName: HTMLDivElement) {
    return `calc(100dvh - ${+divFooter.clientHeight}px -  ${+tableName.clientHeight})`;
  }

  connectionIdx = 0;
  messageIdx = 0;

  response: any;

  addConnection(connection) {
    connection.connectionId = ++this.connectionIdx;

    connection.addEventListener('message', (event) => {
      this.messageIdx++;
      const data = JSON.parse(event.data);
      console.log('', data);
      this.formatFolio(data.data);
      // connection.send('Received message ' + this.messageIdx);
    });

    connection.addEventListener('close', function (event) {
      console.log('', event);
    });
  }

  @HostListener('document:DOMContentLoaded', ['$event'])
  onDomContentLoaded(event: any) {
    event;
    if (navigator['presentation'].receiver) {
      navigator['presentation'].receiver.connectionList.then((list) => {
        list.connections.map((connection) => this.addConnection(connection));
        list.addEventListener('connectionavailable', (event) => {
          this.addConnection(event.connection);
        });
      });
    }
  }

  formatFolio(folio: any) {
    this.activeFolio = folio;
    let nonDeletedRows = this.activeFolio.rows.filter(
      (row) => row.recordStatus != 'deleted'
    );
    this.productRows = [
      ...nonDeletedRows
        .filter((row) => row.rowType == 'product')
        .map((row) => ({
          ...row,
          virtualModifiers: nonDeletedRows.filter(
            (afRow) => afRow.parentID == row.id
          ),
        })),
    ];
    this.paymentRows = nonDeletedRows.filter((rw) =>
      ['discount', 'payment'].includes(rw.rowType)
    );
  }
}
