import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Warehouse, InventoryUnit, Branch, InvoiceShipper, InvoiceVehicle, Cash } from 'kerzz-base';


export interface BackOfficeState {
    warehouses: Warehouse[];
    allWarehouses : Warehouse[];
    inventoryUnits: InventoryUnit[];
    branchList: Branch[];
    shipperList : InvoiceShipper[];
    vehicleList : InvoiceVehicle[];
    selectedShipper  :InvoiceShipper
    selectedVehicle : InvoiceVehicle
    cashRegisters: Cash[]
}

export function createInitialState(): BackOfficeState {
    return {
        warehouses: [],
        allWarehouses : [],
        inventoryUnits: [],
        branchList: [],
        shipperList : [],
        vehicleList : [],
        selectedShipper : null,
        selectedVehicle : null,
        cashRegisters: []
    };
}



@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'backoffice-Store' })
export class BackOfficeStore extends Store<BackOfficeState> {
    constructor() {
        super(createInitialState());
    }
}
