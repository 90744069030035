import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UserLicance } from 'src/app/modules/session/state/dto/sso-dto';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { SessionStore } from 'src/app/modules/session/state/session.store';
import { OrwiStoreQuery } from 'src/app/modules/store/state/store.query';
import { OrwiStoreService } from 'src/app/modules/store/state/store.service';
import { OrwiStoreStore } from 'src/app/modules/store/state/store.store';
import { StoreCover } from 'src/app/services/dto/orwi-store';
import { EcrService } from 'src/app/services/ecr/ecr.service';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'store-list-modal',
  templateUrl: './store-list-modal.component.html',
  styleUrls: ['./store-list-modal.component.scss'],
})
export class StoreListModalComponent implements OnInit {
  lasSelected: UserLicance = undefined;

  constructor(
    public sessionQuery: SessionQuery,
    private sessionStore: SessionStore,
    private orwiService: OrwiStoreService,
    private glb: GlobalService,
    private orwiStore: OrwiStoreStore,
    private orwiStoreQuery: OrwiStoreQuery,
    private transcolo: TranslocoService,
    private ecrService: EcrService
  ) {}

  stores: StoreCover[] = [];
  filteredStore: UserLicance[] = [];
  selectedStore = this.orwiStoreQuery.store$;
  loadingText: string = this.transcolo.translate('Loading stores');

  ngOnInit() {
    this.lasSelected = JSON.parse(localStorage.getItem('lastSelected'));
  }

  async ionViewDidEnter() {
    this.glb.showLoading('');
    await this.orwiService.getUserStores().then(
      (stores) => {
        stores; //reserved
        this.glb.closeLoading();
      },
      (err) => {
        console.log(err);
        this.glb.closeLoading();
      }
    );
    // this.orwiStoreQuery.ownedStores$.subscribe((stores) => {
    //   console.log(stores)
    //   this.stores = stores;
    //   this.filteredStore = this.;
    //   // console.log('filteredStore',this.filteredStore , 'stores', this.stores)
    // });

    this.filteredStore = this.sessionQuery.licances;
  }

  setFilter(e) {
    if (e.detail.value != '') {
      // let filterData = this.userService.user.store.filter(x=>x.id == e.value)

      this.filteredStore = this.sessionQuery.licances.filter((o) =>
        Object.keys(o).some(() =>
          o.brand
            .toString()
            .toLowerCase()
            .includes(e.detail.value.toLowerCase())
        )
      );
      if (this.filteredStore.length == 0) {
        this.loadingText = this.transcolo.translate('Store Not Found');
      }
    } else {
      this.filteredStore = this.sessionQuery.licances;
    }
  }

  async selectLicance(item: UserLicance) {
    this.glb.overRideClosing = false;
    this.glb.showLoading();
    this.sessionStore.update({ activeLicense: item });
    this.orwiService.initialize(item.orwiStore.id).then(async (store) => {
      this.orwiStore.update({ store: store });
      await this.ecrService.getEcrSettingsAndInit().then((x) => {
        x;
        this.ecrService.connectEcr();
      });
    });

    this.glb.closeModal();
    localStorage.setItem('lastSelected', JSON.stringify(item));
    this.glb.overRideClosing = true;
    this.glb.closeAllLoading();
  }
}
