<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button icon="/assets/icons/back-btn.svg"></ion-back-button>
    </ion-buttons>
    <div class="header-center">
      <ion-title>{{ "Bulk Product Sync" | transloco }}</ion-title>
    </div>
    <ion-buttons slot="end">
      <ion-button style="width: 233px!important;" (click)="syncProduct()">
        <p>{{ "Bulk Product Sync" | transloco }}</p>
        <ion-icon slot="icon-only" name="sync-outline"></ion-icon>
      </ion-button>
      <ion-button style="width: 233px!important;" (click)="saveChanges()">
        <p>{{ "Save" | transloco }}</p>
        <ion-icon slot="icon-only" name="save"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>



<ion-content>
  <ion-grid>
    <ion-row style="background: white; display: flex; padding: 15px">
      <ion-col>
        <ion-checkbox (ionChange)="changeCheckbox($event)"
          >Eşleşmesi Olmayanları Göster</ion-checkbox
        >
      </ion-col>
      <ion-col>
        <ion-checkbox (ionChange)="showSyncedProds($event)"
          >Değişiklik Yapılanları Göster</ion-checkbox
        >
      </ion-col>
    </ion-row>
    <ion-row class="scroll-container">
      <dx-data-grid
        #dataGrid
        id="grid-container"
        [showRowLines]="false"
        [showColumnLines]="false"
        [dataSource]="filteredFoodAppsProducts"
        [showBorders]="false"
        [rowAlternationEnabled]="true"
        (onRowUpdated)="onRowUpdated($event)"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        columnResizingMode="nextColumn"
        style="padding-bottom: 40px"
        noDataText="{{ 'No Data' | transloco }}"
        [height]="1500"
      >
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
        <!-- <dxo-scrolling mode="infinite"></dxo-scrolling> -->
        <dxo-editing
          [allowAdding]="false"
          [allowUpdating]="true"
          mode="cell"
        ></dxo-editing>
        <dxi-column
          dataField="accountId"
          caption="Hesap"
          [allowEditing]="false"
        >
          <dxo-lookup
            [dataSource]="foodAppAcoounts"
            displayExpr="description"
            valueExpr="id"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="brand" caption="Marka" [allowEditing]="false">
          <dxo-lookup
            [dataSource]="brands"
            displayExpr="name"
            valueExpr="id"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="groupName"
          caption="Kategori Adı"
          [allowEditing]="false"
        >
          <!-- <dxo-lookup
            [dataSource]="foodAppsCategories"
            displayExpr="name"
            valueExpr="id"
          ></dxo-lookup> -->
        </dxi-column>
        <dxi-column
          dataField="name"
          caption="Ürün Adı"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column dataField="internalId" caption="Kerzz Ürün Adı">
          <dxo-lookup
            [dataSource]="internalProducts"
            displayExpr="name"
            valueExpr="id"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="price"
          caption="Fiyat"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="source"
          caption="Platform"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="isActive"
          caption="Durum"
          [allowEditing]="false"
        ></dxi-column>
        <dxi-column
          dataField="editDate"
          caption="Son İşlem Tarihi"
          format="dd.MM.yyyy"
          dataType="date"
          [allowEditing]="false"
        ></dxi-column>
        <dxo-search-panel
          [visible]="false"
          [highlightCaseSensitive]="true"
          placeholder="{{ 'Search' | transloco }}"
        ></dxo-search-panel>
        <dxo-paging [pageSize]="pageSize"></dxo-paging>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
      </dx-data-grid>
    </ion-row>
  </ion-grid>
</ion-content>

<!-- [showRowLines]="false"
[showColumnLines]="false"
[dataSource]="foodAppsProducts"
[showBorders]="false"
[rowAlternationEnabled]="true"
[(selectedRowKeys)]="selectedWarehouseTransfer"
[allowColumnResizing]="true"
[columnAutoWidth]="true"
columnResizingMode="nextColumn"
style="padding-bottom: 40px"
noDataText="{{'No Data' | transloco}}" -->
