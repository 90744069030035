import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionQuery } from 'src/app/modules/session/state/session.query';
import { IdGeneratorService } from '../helpers/id-generator.service';
import { mongo_delete_params, mongo_get_params, mongo_upsert_params } from './dto';

@Injectable({
  providedIn: 'root',
})
export class PublicApiService {
  apiKey = 'MzR5cjgyMsSxdXJmZmcyamdydWZncmV1amRmbmJodWQ';
  postMongoUrl = 'https://public.kerzz.com:50502/api/database/dataAction';
  // postMongoUrl = 'https://192.168.1.123:50502/api/database/dataAction';

  constructor(
    private ig: IdGeneratorService,
    private http: HttpClient,
    private sessionQuery: SessionQuery,
  ) { }

  getIntgrations(prm: mongo_get_params): Observable<any> {
    let filter = prm._filter ? prm._filter : {};

    return this.http.post(
      this.postMongoUrl,
      {
        job: 'get',
        database: prm._db,
        collection: prm.col,
        filter: filter,
        projection: prm._project,
        limit: prm._limit,
        skip: prm._skip,
        sort: prm._sort,
        withTotalCount: prm._withTotalCount,
      },
      this.getHeaders()
    );
  }

  getCollection(prm: mongo_get_params): Observable<any> {
    let filter = prm._filter ? prm._filter : {};
    return this.http.post(
      this.postMongoUrl,
      {
        job: 'get',
        database: prm._db,
        collection: prm.col,
        filter: filter,
        projection: prm._project,
        limit: prm._limit,
        skip: prm._skip,
        sort: prm._sort,
        withTotalCount: prm._withTotalCount,
      },
      this.getHeaders()
    );
  }

  upsert(prm: mongo_upsert_params): Promise<any> {
    if (!prm.data.id) {
      prm.data.id = this.ig.generate();
    }
    if (prm.data._id) delete prm.data._id;

    let filter = { id: prm.data.id };
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: 'upsert',
          database: prm._db,
          collection: prm.col,
          filter: filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  upsertFoodAppProduct(prm: mongo_upsert_params): Promise<any> {
    if (!prm.data.id) {
      prm.data.id = this.ig.generate();
    }
    let filter = { id: prm.data.id, accountId: prm.data.accountId };
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: 'upsert',
          database: prm._db,
          collection: prm.col,
          filter: filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  upsertFoodAppModifiers(prm: mongo_upsert_params): Promise<any> {
    if (!prm.data.id) {
      prm.data.id = this.ig.generate();
    }
    let filter = { id: prm.data.id, accountId: prm.data.accountId };
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: 'upsert',
          database: prm._db,
          collection: prm.col,
          filter: filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  delete(prm: mongo_delete_params): Promise<any> {
    let filter = prm.filter
    return this.http.post(this.postMongoUrl, {
      job: "delete",
      database: prm._db,
      collection: prm.col,
      filter: filter,
    }, this.getHeaders()
    ).toPromise()
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        "x-user-token": this.sessionQuery.token,
        "x-api-key": this.apiKey,
        'Content-Type': 'application/json', 'Accept': 'application/json'
      })
    }
    return httpOptions
  }


  upsertGeneric(prm: mongo_upsert_params): Promise<any> { 
    if (!prm._filter) {
      prm._filter = { id: prm.data.id };
    }   
   
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: 'upsert',
          database: prm._db,
          collection: prm.col,
          filter: prm._filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  getHeadersJson() {
    return {

      "x-user-token": this.sessionQuery.token,
      "x-api-key": this.apiKey,
      'Content-Type': 'application/json', 'Accept': 'application/json'

    }
  }


}
