<div (tap)="deneme($event)"  class="table-group-btn ripple-parent ion-activatable" [ngClass]="{selected: tableGroup.selected}" (click)="selectItem()">
  <div style="display: flex; width: 100%; justify-content: space-between;">
    <div class="table-group-btn-progress">
      <orwi-circular-progress [id]="this.tableGroup.id" width="20"  height="20" [value]="this.tableGroup.info.occupancyRatio"></orwi-circular-progress>
      <div style="margin-left: 5px; font-size: 12px; color:#6AEADB; font-family: 'Roboto';">{{tableGroup.info.occupancyRatio}} %</div>
    </div>
    <!-- <div (click)="info($event,tableGroup)" class="table-group-btn-info">
      <ion-icon color="secondary" name="information-circle-outline"></ion-icon>
    </div> -->
  </div>
  <div>
    <div class="name">
      {{tableGroup.name}}
    </div>
    <div class="info">
      {{tableGroup.info.open}}/{{tableGroup.info.tables}} {{'Table' | transloco}}
    </div>
  </div>
  <ion-ripple-effect></ion-ripple-effect>
</div>