import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TableState, TableStore } from './table.store';

@Injectable({ providedIn: 'root' })
export class TableQuery extends Query<TableState> {


    tables$ = this.select('tables')
    tableGroups$ = this.select('tableGroups')
    selectedTable = this.select('selectedTable')
    selectedTableGroup = this.select('selectedTableGroup')
    activeTables = this.select("activeTables")
    moved$ = this.select("moved")
    inDesignTable$ = this.select("inDesignTable")
    designMode$ = this.select("designMode")

    constructor(
        protected store: TableStore,
        ) {
        super(store);

        this.designMode$.subscribe(o => {
            if (o) {
                if (!this.store.getValue().inDesignTable && this.store.getValue().tables.length > 0) {
                    this.store.update({ inDesignTable: this.store.getValue().tables[0] })
                }
            } else {
                this.store.update({ inDesignTable: undefined })
            }

        })
    }

}

