import { Injectable } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ZeroConfService } from '../helpers/zero-conf.service';
import { GlobalService } from '../global.service';




@Injectable({
  providedIn: 'root'
})
export class AppService {

  stateChanged$ = new Subject<boolean>()
  deeplinkReceived$ = new Subject<any>()
  keyboardShow$ = new Subject<any>()
  keyboardHide$ = new Subject<any>()
  connection$ = new BehaviorSubject<OrwiConnection>("offline")

  connection: OrwiConnection = "offline"

  constructor(
    private zc: ZeroConfService,
    plt: Platform,
    private route: Router,
    private glb: GlobalService
  ) {

    this.connection$.subscribe(o => {

      this.connection = o
      console.log("Connection Status", o)
    })

    this.zc.initZeroConf()



    App.addListener('appStateChange', async ({ isActive }) => {
      console.log('App state changed. Is active?', isActive, this.route.url)
      setTimeout(() => {
        this.stateChanged$.next(isActive)
      }, 500);

      if (isActive) {
        setTimeout(() => {
          this.zc.initZeroConf()
        }, 700);
      }
    });

    App.addListener('appUrlOpen', (data: any) => {
      this.deeplinkReceived$.next(data.url)
    })

    if (plt.is("capacitor")) {

      Keyboard.addListener('keyboardWillShow', () => {
        // console.log('keyboard did show with height', info.keyboardHeight);
        this.keyboardShow$.next("")

      });

      Keyboard.addListener('keyboardWillHide', () => {
        // console.log('keyboard did hide');
        this.keyboardHide$.next("")
      })


      // To stop listening:
      // handler.remove();

      // Get the current network status


      this.init()
    }







  }

  async init() {


    await Network.addListener('networkStatusChange', (status) => {
      console.log("Network status changed", status);
      if (status.connected) {
        this.glb.networkConnected = true
      } else {
        this.glb.networkConnected = false
      }

    });

    //this.connection$.next("offline")
    this.connection$.next("wan")



    // if (status.connected) {
    //   this.connection$.next("wan")
    // } else {
    //   this.connection$.next("offline")
    // }

  }




}

export type OrwiConnection = 'offline' | 'lan' | 'wan'

