import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { ReportsStore, ReportsState } from './reports.store';

@Injectable({ providedIn: 'root' })
export class ReportsQuery extends Query<ReportsState> {

  folioListData$ = this.select("folioListData")
  folioListDataTotal$ = this.select("folioListDataTotal")
  folioListActiveFolio$ = this.select("folioListActiveFolio")
  activeFolioRows = this.select("folioListActiveFolio").pipe(map(n => n.rows.filter(l => l.rowType !== 'payment')))
  activeFolioPaymentRows = this.select("folioListActiveFolio").pipe(map(n => n.rows.filter(l => l.rowType == 'payment')))
  cancelledFolioList$ = this.select("folioListData").pipe(map(f => f.filter(x=> x.deliveryStatus == 'cancelled')))

  constructor(protected store: ReportsStore) {
    super(store);
  }

}
