import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Discount } from 'src/app/services/dto/orwi-definitions';
import { Folio, rowType } from 'src/app/services/dto/orwi-folio';
import { paymentTypes } from 'src/app/services/dto/orwi-store';

export interface OrwiCurrency {
  symbol: string;
  banknotes: number[];
  name: string;
  exchangeRate: number;
}

export interface PaymentHistory {
  id: string;
  //folioIndex : number
  parentId: string;
  paymentId : string;
  desc: string;
  value: number;
  folioId: string;
  payType: paymentTypes;
  rowType: rowType;
  already: boolean;
  payRowID?: string;
}
export interface PaymentState {
  visible: boolean;
  banknoteVisible: boolean;
  discountVisible: boolean;

  value: number;
  history: PaymentHistory[];
  currencies: OrwiCurrency[];
  selectedCurrency: OrwiCurrency;
  activeFolioId: string;
  folios: Folio[];

  predefinedDiscounts: Discount[];
}

export function createInitialState(): PaymentState {
  return {
    visible: false,
    banknoteVisible: false,
    discountVisible: false,

    value: 1.0,
    history: [],

    currencies: [],
    selectedCurrency: null,
    activeFolioId: '',
    folios: [],

    predefinedDiscounts: [
      {
        id: '1',
        name: 'Orwi İndirimi',
        amount: 0,
        rate: 0.1,
        // authLevel : AuthLevel.developer,
      },
      {
        id: '2',
        name: 'Personel İndirimi',
        amount: 0,
        rate: 0.2,
        // // authLevel : AuthLevel.developer,
      },
    ],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'orwi-payment' })
export class PaymentStore extends Store<PaymentState> {
  constructor() {
    super(createInitialState());
  }
}
