<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        icon="/assets/icons/back-btn.svg"
      ></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="true">
  <ion-list>
    <ion-searchbar (ionInput)="searchValue($event.target.value)" animated="true" placeholder="{{'Search' | transloco}}"></ion-searchbar>
    <ion-item
      *ngFor="let item of filteredStores"
    >
      <ion-label>
        <div
          style="display: flex; align-items: center; gap: 10px; color: #171e4e"
        >
          <div class="img-container">
            <img
              style="height: 32px"
              [title]="item.source"
              [alt]="item.source"
              [src]="getIcon(item.source)"
            />
          </div>
          <div>{{ item.name }}</div>
        </div>
      </ion-label>
      <ion-select
        (ionChange)="statusChange($event, item)"
        interface="action-sheet"
        [interfaceOptions]="customActionSheetOptions"
        [value]="item.status"
        placeholder="{{ 'Select Status' | transloco }}"
      >
        <ion-select-option value="open">{{
          "Open" | transloco
        }}</ion-select-option>
        <ion-select-option value="close">{{
          "Closed" | transloco
        }}</ion-select-option>
        <ion-select-option value="busy">{{
          "Busy" | transloco
        }}</ion-select-option>
        <ion-select-option value="deactive">{{
          "Inactive" | transloco
        }}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>
</ion-content>
