<div style="width: 100%">
    <div id="table-cover" class="table-cover" *ngIf="(mq.posMenuSelected$ | async).id === 'open-folios'"
        style="position: initial !important">

        @for (item of tq.openFolios$ | async; track $index) {
        @defer (on viewport) {
        <orwi-open-folio-button id="{{ item.id }}" class="c-table-button" OrwiDraggableFolio [folio]="item">
        </orwi-open-folio-button>
        } @placeholder {
            <div style="padding: 5px;">
                <ion-skeleton-text style="height: 80px;width:95px"></ion-skeleton-text>
            </div>
        }
        }


    </div>

    <div class="table-cover" *ngIf="(mq.posMenuSelected$ | async).id === 'self-service'"
        style="position: initial !important">
        <div class="table-btn open" (click)="newTransaction()">
            {{ "New Transaction" | transloco }}
        </div>
        @for (item of tq.openSelfFolio$ | async; track $index) {
        @defer (on viewport) {
        <orwi-open-folio-button [folio]="item" (onClickInfo)="onClickInfo($event, item.table.name, item)">
        </orwi-open-folio-button>
        } @placeholder {
            <div style="padding: 5px;">
                <ion-skeleton-text style="height: 80px;width:95px"></ion-skeleton-text>
            </div>
        }
        }

    </div>

    <div class="table-cover" *ngIf="(mq.posMenuSelected$ | async).id === 'take-away'"
        style="position: initial !important">
        <div (click)="newTransaction()" class="table-btn open">
            {{ "New Transaction" | transloco }}
        </div>

        @for (item of tq.openTakeawayFolio$ | async; track $index) {
        @defer (on viewport) {
        <orwi-open-folio-button [folio]="item">
        </orwi-open-folio-button>
        } @placeholder {
            <div style="padding: 5px;">
                <ion-skeleton-text style="height: 80px;width:95px"></ion-skeleton-text>
            </div>
        }
        }


    </div>
</div>
