import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { orwiPrompt, OrwiPromptComponent } from './orwi-prompt.component';

@Injectable({
  providedIn: 'root'
})
export class OrwiPromptService {

  component: ComponentRef<OrwiPromptComponent>[] = []

  constructor(
    private app: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) { }



  showComponent(op: orwiPrompt): OrwiPromptComponent {
    let newNode = document.createElement('div');
    document.getElementById('promptContainer').appendChild(newNode);
    let resolver = this.componentFactoryResolver.resolveComponentFactory(OrwiPromptComponent);
    let cmp = resolver.create(this.injector, [], newNode)


    if (op.title) cmp.instance.title = op.title
    if (op.message)  cmp.instance.message = op.message;
    if (op.buttons) cmp.instance.buttons = op.buttons;
    if (op.closable) cmp.instance.closeable = op.closable;
    if (op.backDropEnabled) cmp.instance.backDropEnabled  = op.backDropEnabled;
    if (op.closeOnBackDropClick) cmp.instance.closeOnBackDropClick = op.closeOnBackDropClick
    if (op.inputs) cmp.instance.inputs = op.inputs
    if (op.height) cmp.instance.height = op.height
    
    this.component.push(cmp)
    this.app.attachView(cmp.hostView);

    cmp.instance.closeClick.subscribe(o => {
      o//reserved
      this.close(cmp.instance)
    })


    return cmp.instance
  }

  destroyComponent(cmp: OrwiPromptComponent) {
   let ix  = this.component.findIndex(o => o.instance == cmp)
   if (ix !== -1) {
    this.component[ix].destroy()
    this.component.splice(ix,1)
   }
  }

  close(cmp: OrwiPromptComponent) {
    this.component.find(o => o.instance == cmp).instance.closeAntimate().then(o => {
      o//reserved
      this.destroyComponent(cmp)
    })
  }
}


